<div id="">

  <div class="toolbar mat-elevation-z6 ">
    <button type="button" (click)="logout()" class="btn  float-right mx-3 my-3 logout " mat-raised-button>
      <!-- <mat-icon matPrefix>highlight_off</mat-icon> -->
      <span>Logout</span>
    </button>
    <button mat-raised-button class="divBg mt-3 ml-3" [matMenuTriggerFor]="crt"><mat-icon>menu</mat-icon>Menu</button>

    <mat-menu class="ItemS" #crt="matMenu">

      <!-- <div class="ItemS "  (click)="showItem='1'" [ngClass]="{'active': showItem =='1'}">
       <mat-icon mat-list-icon>add</mat-icon>
       <div class="mx-2"> New Hospital</div>
       <mat-icon>navigate_next</mat-icon>
     </div>
   -->

      <div class="ItemS " (click)="showItem='17'" [ngClass]="{'active': showItem =='17'}">
        <mat-icon mat-list-icon>
          dashboard
        </mat-icon>
        <div class="mx-2"> Dashboard</div>
        <mat-icon>navigate_next</mat-icon>
      </div>

      <div class="ItemS " (click)="showItem='3'" [ngClass]="{'active': showItem =='3'}">
        <mat-icon mat-list-icon>add</mat-icon>
        <div class="mx-2"> New Patient</div>
        <mat-icon>navigate_next</mat-icon>
      </div>



      <div class="ItemS " (click)="showItem='6'" [ngClass]="{'active': showItem =='6'}">
        <mat-icon mat-list-icon>medication</mat-icon>
        <div class="mx-2"> Hospital</div>
        <mat-icon>navigate_next</mat-icon>
      </div>


      <div class="ItemS " (click)="showItem='33'" [ngClass]="{'active': showItem =='33'}">
        <mat-icon mat-list-icon>
          contact_emergency
        </mat-icon>
        <div class="mx-2"> PT Physicians</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS " (click)="showItem='5'" [ngClass]="{'active': showItem =='5'}">
        <mat-icon mat-list-icon>
          personal_injury
        </mat-icon>
        <div class="mx-2"> Patients</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS " (click)="showItem='37'" [ngClass]="{'active': showItem =='37'}">
        <mat-icon mat-list-icon>
          personal_injury
        </mat-icon>
        <div class="mx-2"> Invoice Receivable</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS " (click)="showItem='43'" [ngClass]="{'active': showItem =='43'}">
        <mat-icon mat-list-icon>
          pending
        </mat-icon>
        <div class="mx-2"> Insurance Pending Payments</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS " (click)="showItem='44'" [ngClass]="{'active': showItem =='44'}">
        <mat-icon mat-list-icon>
          paid
        </mat-icon>
        <div class="mx-2"> Un Paid Balances</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS " (click)="showItem='7'" [ngClass]="{'active': showItem =='7'}">
        <mat-icon mat-list-icon>payments</mat-icon>
        <div class="mx-2"> All Invoices</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS " (click)="showItem='8'" [ngClass]="{'active': showItem =='8'}">
        <mat-icon mat-list-icon>book_online</mat-icon>
        <div class="mx-2"> All Appointments</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS " (click)="showItem='18'" [ngClass]="{'active': showItem =='18'}">
        <mat-icon mat-list-icon>today</mat-icon>
        <div class="mx-2"> Todays Appointments</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS " (click)="showItem='12'" [ngClass]="{'active': showItem =='12'}">
        <mat-icon mat-list-icon>view_module</mat-icon>
        <div class="mx-2"> Departments</div>
        <mat-icon>navigate_next</mat-icon>
      </div>


      <div class="ItemS " (click)="showItem='19'" [ngClass]="{'active': showItem =='19'}">
        <mat-icon>code_file</mat-icon>
        <div class="mx-2"> CPT Codes</div>
        <mat-icon>navigate_next</mat-icon>
      </div>

      <div class="ItemS " (click)="showItem='29'" [ngClass]="{'active': showItem =='29'}">
        <mat-icon>add</mat-icon>
        <div class="mx-2">Add PT Off Dates</div>
        <mat-icon>navigate_next</mat-icon>
      </div>

      <div class="ItemS " (click)="showItem='34'" [ngClass]="{'active': showItem =='34'}">
        <mat-icon>calendar_today</mat-icon>
        <div class="mx-2"> PT Doctor Off Days</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS " (click)="showItem='35'" [ngClass]="{'active': showItem =='35'}">
        <mat-icon>sms</mat-icon>
        <div class="mx-2"> Send SMS</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS " (click)="showItem='36'" [ngClass]="{'active': showItem =='36'}">
        <mat-icon>mail</mat-icon>
        <div class="mx-2"> Send Email</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS " (click)="showItem='45'" [ngClass]="{'active': showItem =='45'}">
        <mat-icon>send</mat-icon>
        <div class="mx-2"> Send Fax</div>
        <mat-icon>navigate_next</mat-icon>
      </div>


    </mat-menu>




  </div>

  <div class="main">
    <!-- <div class="toolbar mat-elevation-z6" ><button type="button"(click)="logout()" class="btn float-right mx-3 my-3 logout " color="accent" mat-raised-button >
<mat-icon matPrefix>highlight_off</mat-icon> 
<span>Logout</span>
</button></div> -->

    <!-- Revenue -->




    <span [ngSwitch]="showItem">

      <div *ngSwitchCase="'0'">

      </div>

      <p *ngSwitchCase="'1'">
        <app-add-hospital></app-add-hospital>
      </p>

      <p *ngSwitchCase="'3'">
        <app-addpatient></app-addpatient>
      </p>


      <p *ngSwitchCase="'5'">
        <app-viewpatient></app-viewpatient>
      </p>

      <p *ngSwitchCase="'6'">
        <app-view-hospital></app-view-hospital>
      </p>
      <p *ngSwitchCase="'7'">
        <app-view-all-invoices></app-view-all-invoices>
      </p>

      <p *ngSwitchCase="'8'">
        <app-view-all-appointments></app-view-all-appointments>
      </p>


      <p *ngSwitchCase="'11'">
        <app-add-department></app-add-department>
      </p>

      <p *ngSwitchCase="'12'">
        <app-view-department></app-view-department>
      </p>

      <p *ngSwitchCase="'14'">
        <app-add-cptcode></app-add-cptcode>
      </p>


      <div *ngSwitchCase="'17'">
        <div class="container-fluid mt-2">
          <div class="statistic-container">
            <div class="statistic-card">
              <mat-accordion>
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      Total Revenue
                    </mat-panel-title>
                    <mat-panel-description>
                      <span class="text-success">{{ Revenue?.Total | currency }}</span>
                    </mat-panel-description>
                  </mat-expansion-panel-header>
                  <div class="d-flex flex-column">
                    <span>Paid: <span class="text-muted ml-2">{{ Revenue?.Paid | currency }}</span></span>
                    <span>Unpaid: <span class="text-muted ml-2">{{ Revenue?.Unpaid | currency }}</span></span>
                    <span>Pending: <span class="text-muted ml-2">{{ Revenue?.Pending | currency }}</span></span>
                    <span>Receivable: <span class="text-muted ml-2">{{ Revenue?.Balance | currency }}</span></span>


                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
            <div class="statistic-card">
              <mat-accordion>
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      Monthly Revenue
                    </mat-panel-title>
                    <mat-panel-description>
                      <span class="text-success">{{ Revenue?.MonthTotal | currency }}</span>
                    </mat-panel-description>
                  </mat-expansion-panel-header>
                  <div class="d-flex flex-column">
                    <span>Paid: <span class="text-muted ml-2">{{ Revenue?.MonthPaid | currency }}</span></span>
                    <span>Unpaid: <span class="text-muted ml-2">{{ Revenue?.MonthUnpaid | currency }}</span></span>
                    <span>Pending: <span class="text-muted ml-2">{{ Revenue?.MonthPending | currency }}</span></span>
                    <span>Receivable: <span class="text-muted ml-2">{{ Revenue?.MonthReceivable | currency
                        }}</span></span>
                    <hr>
                    <span>Software Service Fee: <span class="text-muted ml-2">{{ Revenue?.SoftwareFee |
                        currency}}</span></span>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
            <div class="statistic-card">
              <mat-accordion>
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      Total Patients
                    </mat-panel-title>
                    <mat-panel-description>
                      <span class="text-success">{{ Patients?.Total }}</span>
                    </mat-panel-description>
                  </mat-expansion-panel-header>
                  <div class="d-flex flex-column">
                    <span>Active: <span class="text-muted ml-2">{{ Patients?.Active }}</span></span>
                    <span>Discharged: <span class="text-muted ml-2">{{ Patients?.Discharged }}</span></span>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
            <div class="statistic-card">
              <mat-accordion>
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      No. of new patients
                    </mat-panel-title>
                    <mat-panel-description>
                      <span class="text-success">{{ Patients?.New }}</span>
                    </mat-panel-description>
                  </mat-expansion-panel-header>
                  <div class="d-flex flex-column">
                    <span>Active: <span class="text-muted ml-2">{{ Patients?.NewActive }}</span></span>
                    <span>Discharged: <span class="text-muted ml-2">{{ Patients?.NewDischarged }}</span></span>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
            <div class="statistic-card">
              <mat-accordion>
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      Month Visits
                    </mat-panel-title>
                    <mat-panel-description>
                      <span class="text-success">{{ PatientVisits?.New }}</span>
                    </mat-panel-description>
                  </mat-expansion-panel-header>
                  <div class="d-flex flex-column">
                    <span>Software Fee: <span class="text-muted ml-2">{{ PatientVisits?.SoftwareFee }}</span></span>


                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
            <div class="statistic-card">
              <mat-accordion>
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      Total Doctors
                    </mat-panel-title>
                    <mat-panel-description>
                      <span class="text-success">{{ HospitalStaff?.Doctors?.total }}</span>
                    </mat-panel-description>
                  </mat-expansion-panel-header>
                  <div class="d-flex flex-column">
                    <span>Active: <span class="text-muted ml-2">{{ HospitalStaff?.Doctors?.active }}</span></span>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
            <!-- <div class="statistic-card">
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Total Nurses
            </mat-panel-title>
            <mat-panel-description>
              <span class="text-success">{{ HospitalStaff?.Nurses?.total }}</span>
            </mat-panel-description>
          </mat-expansion-panel-header>
            <div class="d-flex flex-column">
            <span>  Active:  <span class="text-muted ml-2">{{  HospitalStaff?.Nurses?.active }}</span></span>        
            </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div> -->

          </div>
        </div>
        <app-viewpatient></app-viewpatient>
      </div>

      <p *ngSwitchCase="'18'">
        <app-view-appointments-today></app-view-appointments-today>
      </p>
      <p *ngSwitchCase="'19'">
        <app-add-cptcode></app-add-cptcode>
      </p>




      <p *ngSwitchCase="'30'">
        <app-add-time-slot></app-add-time-slot>
      </p>


      <p *ngSwitchCase="'33'">
        <app-view-physical-therapy-doctor></app-view-physical-therapy-doctor>
      </p>
      <p *ngSwitchCase="'34'">
        <app-view-pt-docctor-availability></app-view-pt-docctor-availability>
      </p>
      <p *ngSwitchCase="'35'">
        <app-sms></app-sms>
      </p>
      <p *ngSwitchCase="'36'">
        <app-email></app-email>
      </p>
      <p *ngSwitchCase="'37'">
        <app-patient-has-balance-topay></app-patient-has-balance-topay>
      </p>
      <p *ngSwitchCase="'43'">
        <app-patient-has-insurance-pending-payment></app-patient-has-insurance-pending-payment>
      </p>
      <p *ngSwitchCase="'44'">
        <app-patient-has-unpaid-amount></app-patient-has-unpaid-amount>
      </p>
      <p *ngSwitchCase="'29'">
        <app-add-pt-docctor-availability></app-add-pt-docctor-availability>
      </p>
      <p *ngSwitchCase="'45'">
        <app-fax></app-fax>
      </p>


      <div *ngSwitchDefault>
        <div class="container-fluid mt-2">
          <div class="statistic-container">
            <div class="statistic-card">
              <mat-accordion>
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      Total Revenue
                    </mat-panel-title>
                    <mat-panel-description>
                      <span class="text-success">{{ Revenue?.Total | currency }}</span>
                    </mat-panel-description>
                  </mat-expansion-panel-header>
                  <div class="d-flex flex-column">
                    <span>Paid: <span class="text-muted ml-2">{{ Revenue?.Paid | currency }}</span></span>
                    <span>Unpaid: <span class="text-muted ml-2">{{ Revenue?.Unpaid | currency }}</span></span>
                    <span>Pending: <span class="text-muted ml-2">{{ Revenue?.Pending | currency }}</span></span>
                    <span>Receivable: <span class="text-muted ml-2">{{ Revenue?.Balance | currency }}</span></span>


                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
            <div class="statistic-card">
              <mat-accordion>
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      Monthly Revenue
                    </mat-panel-title>
                    <mat-panel-description>
                      <span class="text-success">{{ Revenue?.MonthTotal | currency }}</span>
                    </mat-panel-description>
                  </mat-expansion-panel-header>
                  <div class="d-flex flex-column">
                    <span>Paid: <span class="text-muted ml-2">{{ Revenue?.MonthPaid | currency }}</span></span>
                    <span>Unpaid: <span class="text-muted ml-2">{{ Revenue?.MonthUnpaid | currency }}</span></span>
                    <span>Pending: <span class="text-muted ml-2">{{ Revenue?.MonthPending | currency }}</span></span>
                    <span>Receivable: <span class="text-muted ml-2">{{ Revenue?.MonthReceivable | currency
                        }}</span></span>
                    <hr>
                    <span>Software Service Fee: <span class="text-muted ml-2">{{ Revenue?.SoftwareFee |
                        currency}}</span></span>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
            <div class="statistic-card">
              <mat-accordion>
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      Total Patients
                    </mat-panel-title>
                    <mat-panel-description>
                      <span class="text-success">{{ Patients?.Total }}</span>
                    </mat-panel-description>
                  </mat-expansion-panel-header>
                  <div class="d-flex flex-column">
                    <span>Active: <span class="text-muted ml-2">{{ Patients?.Active }}</span></span>
                    <span>Discharged: <span class="text-muted ml-2">{{ Patients?.Discharged }}</span></span>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
            <div class="statistic-card">
              <mat-accordion>
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      No. of new patients
                    </mat-panel-title>
                    <mat-panel-description>
                      <span class="text-success">{{ Patients?.New }}</span>
                    </mat-panel-description>
                  </mat-expansion-panel-header>
                  <div class="d-flex flex-column">
                    <span>Active: <span class="text-muted ml-2">{{ Patients?.NewActive }}</span></span>
                    <span>Discharged: <span class="text-muted ml-2">{{ Patients?.NewDischarged }}</span></span>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
            <div class="statistic-card">
              <mat-accordion>
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      Month Visits
                    </mat-panel-title>
                    <mat-panel-description>
                      <span class="text-success">{{ PatientVisits?.New }}</span>
                    </mat-panel-description>
                  </mat-expansion-panel-header>
                  <div class="d-flex flex-column">
                    <span>Software Fee: <span class="text-muted ml-2">{{ PatientVisits?.SoftwareFee }}</span></span>


                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
            <div class="statistic-card">
              <mat-accordion>
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      Total Doctors
                    </mat-panel-title>
                    <mat-panel-description>
                      <span class="text-success">{{ HospitalStaff?.Doctors?.total }}</span>
                    </mat-panel-description>
                  </mat-expansion-panel-header>
                  <div class="d-flex flex-column">
                    <span>Active: <span class="text-muted ml-2">{{ HospitalStaff?.Doctors?.active }}</span></span>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
            <!-- <div class="statistic-card">
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Total Nurses
            </mat-panel-title>
            <mat-panel-description>
              <span class="text-success">{{ HospitalStaff?.Nurses?.total }}</span>
            </mat-panel-description>
          </mat-expansion-panel-header>
            <div class="d-flex flex-column">
            <span>  Active:  <span class="text-muted ml-2">{{  HospitalStaff?.Nurses?.active }}</span></span>        
            </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div> -->

          </div>
        </div>
        <app-viewpatient></app-viewpatient>

      </div>

    </span>

  </div>
</div>