import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { CptCode } from '../../Models/cptcode.model';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { CptcodeService } from 'src/app/services/cptcode.service';

@Component({
  selector: 'app-add-cptcode',
  templateUrl: './add-cptcode.component.html',
  styleUrls: ['./add-cptcode.component.css'],
})
export class AddCptcodeComponent implements OnInit {
  cptCodeList: CptCode[] = [];
  isSuccess = false;
  constructor(
    private fb: UntypedFormBuilder,
    private cptCodeService: CptcodeService
  ) {}

  ngOnInit(): void {
    this.getCptCodes();
  }

  cptFrm = this.fb.group({
    Code: ['', Validators.required],
    Description: ['', Validators.required],
  });

  getCode() {
    return this.cptFrm.get('Code');
  }

  getDescription() {
    return this.cptFrm.get('Description');
  }

  getCptCodes() {
    this.cptCodeService.getCptCodes().subscribe((res) => {
      this.cptCodeList = res;
    });
  }
  tabChanged(event: MatTabChangeEvent): void {
    if (event.index === 0) {
      this.getCode();
    }
  }
  formSubmit() {
    let code = this.cptFrm.value['Code'];
    let description = this.cptFrm.value['Description'];

    let body = {
      Code: code,
      Description: description,
    };

    this.cptCodeService.registerCptCode(body).subscribe((res) => {
      this.cptCodeList.push(res);
      this.isSuccess = true;
      this.cptFrm.reset();
    });
  }
}
