import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { WebcamImage } from 'ngx-webcam';
import { Subject, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Department } from 'src/app/Models/department.model';
import { DepartmentService } from 'src/app/services/department.service';
import { EmailService } from 'src/app/services/email.service';
import { HospitalService } from 'src/app/services/hospital.service';
import { PhysicalTherapyDoctorService } from 'src/app/services/physical-therapy-doctor.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-physical-therapy-doctor',
  templateUrl: './add-physical-therapy-doctor.component.html',
  styleUrls: ['./add-physical-therapy-doctor.component.css'],
})
export class AddPhysicalTherapyDoctorComponent implements OnInit {
  id = 0;
  isSuccess = false;
  dptList: Department[];
  Codes: any[] = [];
  filteredOptions: Observable<string[]>;
  doctorSpeciality: string[] = ['Physical Therapy'];
  imageUrl: string;

  helper = new JwtHelperService();

  myToken = window.localStorage.getItem('token');
  hospitalName: string;

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private physicalTherapyDoctorService: PhysicalTherapyDoctorService,
    private userService: UserService,
    private router: Router,
    private dptService: DepartmentService,
    private http: HttpClient,
    private hosptalService: HospitalService,
    private emailService: EmailService
  ) {}

  ngOnInit(): void {
    // this.route.params.subscribe((res) => {
    //   this.id = res['id'];
    // });

    let decodedToken = this.helper.decodeToken(this.myToken);
    this.id = parseInt(decodedToken.nameid);

    this.hosptalService.getHospitalById(this.id).subscribe((res) => {
      this.hospitalName = res.HospitalName.substring(0, 3);
    });

    this.getDepartments(this.id);

    this.countryCodes();
    this.filteredOptions =
      this.doctorFrm.controls.CountryCode.valueChanges.pipe(
        startWith(''),
        map((value: string) => this._filter(value || ''))
      );
  }

  doctorFrm = this.fb.group({
    DepartmentId: [''],
    DoctorName: ['', Validators.required],
    DoctorQualification: ['', Validators.required],
    DoctorSpecialization: ['', Validators.required],
    Email: ['', Validators.required],
    PhoneNumber: ['', Validators.required],
    NpiNumber: ['', Validators.required],
    NationalId: ['', Validators.required],
    Address: ['', Validators.required],
    Dob: ['', Validators.required],
    Status: [''],
    Password: [
      '',
      [
        Validators.required,
        Validators.pattern(
          '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-zd$@$!%*?&].{7,}$'
        ),
      ],
    ],
    CountryCode: ['+1', Validators.required],
  });

  get DoctorQualification() {
    return this.doctorFrm.get('DoctorQualification');
  }

  get DoctorSpecialization() {
    return this.doctorFrm.get('DoctorSpecialization');
  }

  get DoctorName() {
    return this.doctorFrm.get('DoctorName');
  }
  get Email() {
    return this.doctorFrm.get('Email');
  }
  get DepartmentId() {
    return this.doctorFrm.get('DepartmentId');
  }
  get PhoneNumber() {
    return this.doctorFrm.get('PhoneNumber');
  }
  get NpiNumber() {
    return this.doctorFrm.get('NpiNumber');
  }
  get NationalId() {
    return this.doctorFrm.get('NationalId');
  }

  get Dob() {
    return this.doctorFrm.get('Dob');
  }
  get Address() {
    return this.doctorFrm.get('Address');
  }

  get Status() {
    return this.doctorFrm.get('Status');
  }
  get Password() {
    return this.doctorFrm.get('Password');
  }
  get CountryCode() {
    return this.doctorFrm.get('CountryCode');
  }

  getDepartments(id) {
    this.hosptalService.getDepartment(id).subscribe((res) => {
      this.dptList = res.Departments;
    });
  }
  image(e) {
    let formData = new FormData();
    let files = e.target.files;
    formData.append('file', files[0], files[0].name);

    this.physicalTherapyDoctorService.imageUpload(formData).subscribe(
      (res) => {
        let stImg = environment.imgurl + res['path'];
        this.imageUrl = stImg;
      },
      (err) => console.log(err)
    );
  }

  private trigger: Subject<any> = new Subject();
  public webcamImage!: WebcamImage;
  private nextWebcam: Subject<any> = new Subject();
  sysImage = '';
  public getSnapshot(): void {
    this.trigger.next(void 0);
  }
  public captureImg(webcamImage: WebcamImage): void {
    this.webcamImage = webcamImage;
    this.sysImage = webcamImage!.imageAsDataUrl;
    // console.info('got webcam image', this.sysImage);
  }
  public get invokeObservable(): Observable<any> {
    return this.trigger.asObservable();
  }
  public get nextWebcamObservable(): Observable<any> {
    return this.nextWebcam.asObservable();
  }

  formSubmit() {
    let doctorSpecialization = this.doctorFrm.value['DoctorSpecialization'];
    let doctorQualification = this.doctorFrm.value['DoctorQualification'];
    let doctorName = this.doctorFrm.value['DoctorName'];
    let dob = this.doctorFrm.value['Dob'];
    let email = this.doctorFrm.value['Email'];

    let phoneNumber =
      this.doctorFrm.value['CountryCode'] + this.doctorFrm.value['PhoneNumber'];
    let nationalId = this.doctorFrm.value['NationalId'];
    let npiNumber = this.doctorFrm.value['NpiNumber'];
    let address = this.doctorFrm.value['Address'];
    let status = this.doctorFrm.value['Status'];
    let password = this.doctorFrm.value['Password'];
    let departmentId = this.doctorFrm.value['DepartmentId'];
    let dptName = this.dptList.filter(
      (it) => it.DepartmentId == departmentId
    )[0].DepartmentName;

    let userBody = {
      Email: email,
      Password: password,
      UserName: this.hospitalName + email,
      PhoneNumber: phoneNumber,
    };
    this.userService.registerPTDoctor(userBody).subscribe((res) => {
      let body = {
        HospitalId: this.id,
        PhysicalTherapyDoctorId: res.Id,
        DoctorName: doctorName,
        Dob: dob,
        Email: email,
        PhoneNumber: phoneNumber,
        NationalId: nationalId,
        NpiNumber: npiNumber,
        Address: address,
        Status: status,
        Password: password,
        DepartmentId: departmentId,
        DoctorImage: this.sysImage ? this.sysImage : this.imageUrl,
        DepartmentName: dptName,
        DoctorSpecialization: doctorSpecialization,
        DoctorQualification: doctorQualification,
      };

      this.physicalTherapyDoctorService
        .registerPhysicalTherapyDoctor(body)
        .subscribe((res) => {
          this.isSuccess = true;
          this.doctorFrm.reset();

          this.emailService
            .sendEmail({
              Email: body.Email,
              Subject: 'Patient Login Credentials',
              Template: `<h3>Your Username ${userBody.UserName} please keep this safe. You may reset password now at https://binserapt.com. </h3> `,
            })
            .subscribe();

          const smsBodyDetails =
            'Your Username ' +
            userBody.UserName +
            ' You may reset password now at https://binserapt.com ';
          let smsBody = {
            PhoneNumber: phoneNumber,
            Body: smsBodyDetails,
          };
          this.emailService.sendSms(smsBody).subscribe();
          this.isSuccess = true;

          setTimeout(() => {}, 2000);
        });
    });
  }
  public countryCodes() {
    this.http.get('/assets/json/country-codes.json').subscribe({
      next: (res: any) => {
        this.Codes = res.map((x) => x.dial_code);
      },
    });
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.Codes.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }

  facingMode: string = 'user'; //Set front camera
  allowCameraSwitch = true;

  public get videoOptions(): MediaTrackConstraints {
    const result: MediaTrackConstraints = {};
    if (this.facingMode && this.facingMode !== '') {
      result.facingMode = { ideal: this.facingMode };
    }
    return result;
  }
}
