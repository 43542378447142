import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from 'src/environments/environment';
import { HospitalService } from 'src/app/services/hospital.service';
import { UserService } from 'src/app/services/user.service';
import { EmailService } from 'src/app/services/email.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { startWith, map } from 'rxjs/operators';

@Component({
  selector: 'app-add-hospital',
  templateUrl: './add-hospital.component.html',
  styleUrls: ['./add-hospital.component.css'],
})
export class AddHospitalComponent implements OnInit {
  helper = new JwtHelperService();

  id = 0;
  myToken = window.localStorage.getItem('token');
  isSuccess = false;
  imageUrl = '';
  filteredOptions: Observable<string[]>;
  Codes: any[] = [];

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private hospitalService: HospitalService,
    private router: Router,
    private userService: UserService,
    private emailService: EmailService,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.id = parseInt(decodedToken.nameid);

    this.countryCodes();
    this.filteredOptions = this.hsFrm.controls.CountryCode.valueChanges.pipe(
      startWith(''),
      map((value: string) => this._filter(value || ''))
    );
  }

  hsFrm = this.fb.group({
    HospitalName: ['', Validators.required],
    Password: ['', Validators.required],
    HospitalLogo: ['', Validators.required],
    CountryCode: ['+1', Validators.required],
    HospitalPhone: ['', Validators.required],
    HospitalEmail: ['', Validators.required],
    HospitalAddress: ['', Validators.required],
  });

  get CountryCode() {
    return this.hsFrm.get('CountryCode');
  }

  get Password() {
    return this.hsFrm.get('Password');
  }
  get HospitalName() {
    return this.hsFrm.get('HospitalName');
  }

  get HospitalLogo() {
    return this.hsFrm.get('HospitalLogo');
  }
  get HospitalPhone() {
    return this.hsFrm.get('HospitalPhone');
  }
  get HospitalEmail() {
    return this.hsFrm.get('HospitalEmail');
  }
  get HospitalAddress() {
    return this.hsFrm.get('HospitalAddress');
  }

  image(e) {
    let formData = new FormData();
    let files = e.target.files;
    formData.append('file', files[0], files[0].name);

    this.hospitalService.imageUpload(formData).subscribe(
      (res) => {
        let stImg = environment.imgurl + res['path'];
        this.imageUrl = `<p><img src="${stImg}"/></p>`;
      },
      (err) => console.log(err)
    );
  }
  formSubmit() {
    let hospitalName = this.hsFrm.value['HospitalName'];
    let password = this.hsFrm.value['Password'];
    let hospitalLogo = this.hsFrm.value['HospitalLogo'];
    let hospitalPhone =
      this.hsFrm.value['CountryCode'] + this.hsFrm.value['HospitalPhone'];
    let hospitalEmail = this.hsFrm.value['HospitalEmail'];
    let hospitalAddress = this.hsFrm.value['HospitalAddress'];
    let userBody = {
      UserName: hospitalEmail,
      Email: hospitalEmail,
      Password: password,
      PhoneNumber: hospitalPhone,
    };
    this.userService.registerHospital(userBody).subscribe((res) => {
      let body = {
        HospitalId: res.Id,
        AppUserId: this.id,
        HospitalName: hospitalName,
        HospitalLogo: this.imageUrl,
        HospitalPhone: hospitalPhone,
        HospitalEmail: hospitalEmail,
        HospitalAddress: hospitalAddress,
      };

      this.hospitalService.registerHospital(body).subscribe((res) => {
        this.isSuccess = true;
        this.hsFrm.reset();
        this.emailService
          .sendEmail({
            Email: body.HospitalEmail,
            Subject: 'Patient Login Credentials',
            Template: `<h3>Your Username ${userBody.UserName} please keep this safe. You may reset password now at https://binserapt.com. </h3> `,
          })
          .subscribe();

        const smsBodyDetails =
          'Your Username ' +
          userBody.UserName +
          ' You may reset password now at https://binserapt.com ';
        let smsBody = {
          PhoneNumber: hospitalPhone,
          Body: smsBodyDetails,
        };
        this.emailService.sendSms(smsBody).subscribe();
        this.isSuccess = true;

        setTimeout(() => {}, 2000);
        // this.router.navigate(['/viewHospital/'])
      });
    });
  }

  public countryCodes() {
    this.http.get('/assets/json/country-codes.json').subscribe({
      next: (res: any) => {
        this.Codes = res.map((x) => x.dial_code);
      },
    });
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.Codes.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }
}
// .replace(/ {1,}/g,'')
//     .trim()                      trim gap between username
