<div class="conatiner mx-5 my-5">
    <button type="button" (click)="print()" class="btn btn-primary float-right" data-toggle="modal" data-target=".bd-example-modal-lg"> Print </button>
    <div class="row">
<div class="col-md-4"  *ngFor="let item of familyHistoryList">

    <mat-card class="mat-elevation-z8 mt-3 ">
        <h2 class="clr">Family History</h2>
        
        <p class="float-right">Date : {{item.createdDate | date: 'short' }}</p>

        <hr>
        <mat-card-content  >


            <tr >
                <th> Anesthesia Problems : </th>

                <td>{{item?.AnesthesiaProblems ?'Yes':'No'}}</td>
            </tr>
            <tr >
                <th> Who Had Anesthesia Problems : </th>
                <td>{{item?.WhoHadAnesthesiaProblems }}</td>

            </tr>
            <tr>
                <th> Arthritis: </th>
                <td>{{item?.Arthritis ?'Yes':'No'}}</td>

            </tr>
            <tr>
                <th> Who Had Arthritis : </th>
                <td>{{item?.WhoHadArthritis}}</td>

            </tr>
          
            <tr>
                <th> Cancer : </th>
                <td>{{item?.Cancer ?'Yes':'No'}}</td>

            </tr>
            <tr>
                <th> Who Had Cancer : </th>
                <td>{{item?.WhoHadCancer}}</td>

            </tr>
            <tr>
                <th> Diabetes : </th>
                <td>{{item?.Diabetes ?'Yes':'No'}}</td>

            </tr>
            <tr>
                <th> Who Had Diabetes : </th>
                <td>{{item?.WhoHadDiabetes}}</td>

            </tr>
            <tr>
                <th> Heart Problems : </th>
                <td>{{item?.HeartProblems ?'Yes':'No'}}</td>

            </tr>
            <tr>
                <th> Who Had Heart Problems : </th>
                <td>{{item?.WhoHadHeartProblems}}</td>

            </tr>
            <tr>
                <th> Hypertension : </th>
                <td>{{item?.Hypertension ?'Yes':'No'}}</td>

            </tr>
            <tr>
                <th> Who Had Hypertension : </th>
                <td>{{item?.WhoHadHypertension }}</td>

            </tr>
            <tr>
                <th> Stroke : </th>
                <td>{{item?.Stroke ?'Yes':'No'}}</td>

            </tr>
            <tr>
                <th> Who Had Stroke : </th>
                <td>{{item?.WhoHadStroke}}</td>

            </tr>
            <tr>
                <th> Thyroid Disorder : </th>
                <td>{{item?.ThyroidDisorder ?'Yes':'No'}}</td>

            </tr>
            <tr>
                <th> Who Had Thyroid Disorder : </th>
                <td>{{item?.WhoHadThyroidDisorder}}</td>

            </tr>
        </mat-card-content>
    <!-- <button class="float-right" [routerLink]="['/editFamilyHistory/',item.FamilyHistoryId]" color="primary" mat-mini-fab><mat-icon>edit</mat-icon> </button>
    <button  (click)="delete(item.FamilyHistoryId)"
    mat-mini-fab color="accent"><mat-icon>delete</mat-icon></button> -->

    </mat-card>
</div>
</div>
</div>