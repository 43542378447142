<div class="container-fluid mt-3">
<div class="modal-body " id="printable" >
    <app-view-hospital></app-view-hospital>
 
     <div class="areaStyle mb-1">
  <h2 class="text-center">Discharge Summary</h2>

       <P>Patient ID: {{patient?.PatientId}} </P>

        <P>Patient Name: {{patient?.PatientFirstName}} &nbsp; {{patient?.PatientLastName}} </P>
       <P>Patient D.O.B: {{patient?.PatientDOB}} </P>
       <P>Patient Address: {{patient?.PatientAddress}} </P>
       <button (click)="print()" class="float-right"><mat-icon>print</mat-icon></button>
       </div>
 
<hr>
       <div class=" mt-3" *ngFor="let item of dischargeSummaryList">

          <p class="text-center">Date of Discharge {{item.createdDate | date: 'short'}}</p>
          <p>Complaints: {{item.Complaints}}</p>
          <p>Diagnosis: {{item.Diagnosis}}</p>
          <p>Treatments: {{item.Treatments}}</p>
          <p>Future Treatment Plan: {{item.FutureTreatmentPlan}}</p>
          <p>Description: {{item.Description}}</p>
<hr>
          <h4>Discharge Medications</h4>
          <table class="table table w-100 ">
              <thead><tr>
                  <th>#</th>
                  <th>DrugName</th>
                  <th>Dosage</th>
                  <th>Frequency</th>
                  <th>Duration</th>
                  <th>Quantity</th>
              </tr></thead>
              <tbody>
                  <tr *ngFor="let drug of item.DischargeMedications; let i=index">
                      <td>{{i+1}}</td>
                      <td>{{drug.DrugName}}</td>
                      <td>{{drug.Dosage}}</td>
                      <td>{{drug.Frequency}}</td>
                      <td>{{drug.Duration}}</td>
                      <td>{{drug.Quantity}}</td>
                     
                   
                         
                   
                  </tr>
                 
                  
              </tbody>
          </table>
          <hr>
          <h4>Attended Doctors</h4>
          <ul *ngFor="let dct of item.AttendedDoctors">
          <li>{{dct.DoctorName}} -&nbsp;{{dct.DoctorSpeciality}}</li>
          </ul>
          <hr>
          <p class="pb-5">Signature: {{item.Signature}}</p>

      </div>

       
  </div>

</div>