import { PatientComponent } from './components/patient/patient.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdmindashboardComponent } from './components/admindashboard/admindashboard.component';
import { LoginComponent } from './components/login/login.component';
import { PatientdashboardComponent } from './components/patientdashboard/patientdashboard.component';

import { PatientdetailsComponent } from './components/patientdetails/patientdetails.component';
import { NotepadComponent } from './components/notepad/notepad.component';
import { ViewAllergyComponent } from './components/view-allergy/view-allergy.component';
import { AddAllergyComponent } from './components/add-allergy/add-allergy.component';
import { UpdateAllergyComponent } from './components/update-allergy/update-allergy.component';
import { AddpatientComponent } from './components/addpatient/addpatient.component';
import { ViewSurgicalHistoryComponent } from './components/view-surgical-history/view-surgical-history.component';
import { AddSurgicalHistoryComponent } from './components/add-surgical-history/add-surgical-history.component';
import { AddHospitalComponent } from './components/add-hospital/add-hospital.component';
import { HospitaldetailsComponent } from './components/hospitaldetails/hospitaldetails.component';
import { HospitalDashboardComponent } from './components/hospital-dashboard/hospital-dashboard.component';
import { AddDepartmentComponent } from './components/add-department/add-department.component';
import { ViewDepartmentComponent } from './components/view-department/view-department.component';
import { ViewHospitalComponent } from './components/view-hospital/view-hospital.component';
import { AddAppointmentComponent } from './components/add-appointment/add-appointment.component';
import { AddInvoiceComponent } from './components/add-invoice/add-invoice.component';
import { ViewInvoiceComponent } from './components/view-invoice/view-invoice.component';
import { ViewAllInvoicesComponent } from './components/view-all-invoices/view-all-invoices.component';
import { EmergencyContactComponent } from './components/emergency-contact/emergency-contact.component';
import { InsuranceComponent } from './components/insurance/insurance.component';
import { AddPatientInsuranceComponent } from './components/add-patient-insurance/add-patient-insurance.component';
import { AddEmergencyContactComponent } from './components/add-emergency-contact/add-emergency-contact.component';
import { PatientsummaryComponent } from './components/patientsummary/patientsummary.component';
import { PtmedicalrecordsComponent } from './components/ptmedicalrecords/ptmedicalrecords.component';
import { PtinvoicesComponent } from './components/ptinvoices/ptinvoices.component';
import { ViewAppointmentComponent } from './components/view-appointment/view-appointment.component';
import { PrintinvoiceComponent } from './components/printinvoice/printinvoice.component';
import { ViewAllAppointmentsComponent } from './components/view-all-appointments/view-all-appointments.component';
import { UpdateInvoiceComponent } from './components/update-invoice/update-invoice.component';

import { AddCptcodeComponent } from './components/add-cptcode/add-cptcode.component';
import { ViewCptcodeComponent } from './components/view-cptcode/view-cptcode.component';

import { AddAppointmentPatientComponent } from './components/add-appointment-patient/add-appointment-patient.component';
import { UpdatepatientComponent } from './components/updatepatient/updatepatient.component';

import { SetpasswordComponent } from './components/setpassword/setpassword.component';
import { ViewAppointmentsTodayComponent } from './components/view-appointments-today/view-appointments-today.component';
import { DrawPadComponent } from './components/draw-pad/draw-pad.component';

import { RoundbookComponent } from './components/roundbook/roundbook.component';
import { PtSummaryComponent } from './components/pt-summary/pt-summary.component';
import { AddTimeSlotComponent } from './components/add-time-slot/add-time-slot.component';

import { SigningPadComponent } from './components/signing-pad/signing-pad.component';
import { ViewPatientInsuranceComponent } from './components/view-patient-insurance/view-patient-insurance.component';
import { ViewPatientIdproofComponent } from './components/view-patient-idproof/view-patient-idproof.component';
import { AddPatientIdproofComponent } from './components/add-patient-idproof/add-patient-idproof.component';
import { AddMriComponent } from './components/add-mri/add-mri.component';
import { ViewMriComponent } from './components/view-mri/view-mri.component';
import { ScannerComponent } from './components/scanner/scanner.component';
import { SuperAdminDashboardComponent } from './components/super-admin-dashboard/super-admin-dashboard.component';
import { AddCompanyComponent } from './components/add-company/add-company.component';
import { CompanyComponent } from './components/company/company.component';
import { AuthguardGuardService } from './services/authguard-guard.service';
import { AdminGuard } from './admin/admin.guard';

import { AllPatientsComponent } from './components/all-patients/all-patients.component';
import { PrintmedicationComponent } from './components/printmedication/printmedication.component';

import { AddDischargeSummaryComponent } from './components/add-discharge-summary/add-discharge-summary.component';
import { ViewDischargeSummaryComponent } from './components/view-discharge-summary/view-discharge-summary.component';
import { PrintDischargeSummaryComponent } from './components/print-discharge-summary/print-discharge-summary.component';
import { UpdateDischargeSummaryComponent } from './components/update-discharge-summary/update-discharge-summary.component';
import { AddVaccineComponent } from './components/add-vaccine/add-vaccine.component';

import { PatientIntakeItemsComponent } from './components/patient-intake-items/patient-intake-items.component';
import { TwofaComponent } from './components/twofa/twofa.component';
import { AddSocialhistoryComponent } from './components/add-socialhistory/add-socialhistory.component';
import { AddFamilyhistoryComponent } from './components/add-familyhistory/add-familyhistory.component';
import { ViewFamilyhistoryComponent } from './components/view-familyhistory/view-familyhistory.component';
import { ViewSocialhistoryComponent } from './components/view-socialhistory/view-socialhistory.component';
import { AddMedicalHistoryComponent } from './components/add-medical-history/add-medical-history.component';
import { ViewHippaComplianceComponent } from './components/view-hippa-compliance/view-hippa-compliance.component';
import { AddHippaComplianceComponent } from './components/add-hippa-compliance/add-hippa-compliance.component';
import { AddPhysicalTherapyDoctorComponent } from './components/add-physical-therapy-doctor/add-physical-therapy-doctor.component';
import { AddPtInitialEvaluationComponent } from './components/add-pt-initial-evaluation/add-pt-initial-evaluation.component';
import { AddPhysicalTherapyVisitComponent } from './components/add-physical-therapy-visit/add-physical-therapy-visit.component';
import { ViewPtInitialEvaluationComponent } from './components/view-pt-initial-evaluation/view-pt-initial-evaluation.component';
import { ViewPhysicalTherapyVisitComponent } from './components/view-physical-therapy-visit/view-physical-therapy-visit.component';
import { ViewPhysicalTherapyDoctorComponent } from './components/view-physical-therapy-doctor/view-physical-therapy-doctor.component';
import { PhysicalTherapyDashboardComponent } from './components/physical-therapy-dashboard/physical-therapy-dashboard.component';
import { PhysicalTherapyPatientComponent } from './components/physical-therapy-patient/physical-therapy-patient.component';
import { AddPhysicalTherapyBookingComponent } from './components/add-physical-therapy-booking/add-physical-therapy-booking.component';
import { ResetpasswordComponent } from './components/resetpassword/resetpassword.component';
import { ViewMedicalHistoryComponent } from './components/view-medical-history/view-medical-history.component';
import { AddPhysicaltherapyBookingByadminComponent } from './components/add-physicaltherapy-booking-byadmin/add-physicaltherapy-booking-byadmin.component';
import { ViewUploadRadiologyReportComponent } from './components/view-upload-radiology-report/view-upload-radiology-report.component';
import { AddUploadRadiologyReportComponent } from './components/add-upload-radiology-report/add-upload-radiology-report.component';
import { ViewAllhospitalsComponent } from './components/view-allhospitals/view-allhospitals.component';
import { ViewDoctorAppointmentsTodayComponent } from './components/view-doctor-appointments-today/view-doctor-appointments-today.component';
import { ViewDoctorAppointmentsComponent } from './components/view-doctor-appointments/view-doctor-appointments.component';
import { UpdateAppointmentComponent } from './components/update-appointment/update-appointment.component';
import { PatientDataScannerComponent } from './components/patient-data-scanner/patient-data-scanner.component';
import { UpdatePtInitalEvaluationComponent } from './components/update-pt-inital-evaluation/update-pt-inital-evaluation.component';
import { UpdatePhysicalTherapyVisitComponent } from './components/update-physical-therapy-visit/update-physical-therapy-visit.component';
import { ViewPhysicalTherapyVisitPatientsideComponent } from './components/view-physical-therapy-visit-patientside/view-physical-therapy-visit-patientside.component';
import { PrintInvoicePatientSideComponent } from './components/print-invoice-patient-side/print-invoice-patient-side.component';
import { ViewpatientComponent } from './components/viewpatient/viewpatient.component';
import { PatientHasBalanceTopayComponent } from './components/patient-has-balance-topay/patient-has-balance-topay.component';
import { AddRefferalComponent } from './components/add-refferal/add-refferal.component';
import { ViewRefferalComponent } from './components/view-refferal/view-refferal.component';
import { PatientHistoryComponent } from './components/patient-history/patient-history.component';
import { UpdateAppointmentByDoctorComponent } from './components/update-appointment-by-doctor/update-appointment-by-doctor.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { PatientHasInsurancePendingPaymentComponent } from './components/patient-has-insurance-pending-payment/patient-has-insurance-pending-payment.component';
import { PatientHasUnpaidAmountComponent } from './components/patient-has-unpaid-amount/patient-has-unpaid-amount.component';
import { BulkImportPatientComponent } from './components/bulk-import-patient/bulk-import-patient.component';
import { InvoiceHasABalanceComponent } from './components/invoice-has-a-balance/invoice-has-a-balance.component';
import { InsuranceClaimComponent } from './components/insurance-claim/insurance-claim.component';
import { AddOfficePolicyConsentComponent } from './components/add-office-policy-consent/add-office-policy-consent.component';
import { ViewOfficePolicyConsentComponent } from './components/view-office-policy-consent/view-office-policy-consent.component';
import { AddConsentForServiceComponent } from './components/add-consent-for-service/add-consent-for-service.component';
import { ViewConsentForServiceComponent } from './components/view-consent-for-service/view-consent-for-service.component';
import { PatientViewOfficePolicyComponent } from './components/patient-view-office-policy/patient-view-office-policy.component';
import { PatientViewConsentForServiceComponent } from './components/patient-view-consent-for-service/patient-view-consent-for-service.component';
import { PatientViewHippaComplianceComponent } from './components/patient-view-hippa-compliance/patient-view-hippa-compliance.component';
import { AddDryNeedlingConsentComponent } from './components/add-dry-needling-consent/add-dry-needling-consent.component';
import { ViewDryNeedlingConsentComponent } from './components/view-dry-needling-consent/view-dry-needling-consent.component';
import { PatientViewDryNeedlingConsentComponent } from './components/patient-view-dry-needling-consent/patient-view-dry-needling-consent.component';
import { PatientViewExersiceLiabilityWaiverComponent } from './components/patient-view-exersice-liability-waiver/patient-view-exersice-liability-waiver.component';
import { AddExersiceLiabilityWaiverComponent } from './components/add-exersice-liability-waiver/add-exersice-liability-waiver.component';
import { ViewExersiceLiabilityWaiverComponent } from './components/view-exersice-liability-waiver/view-exersice-liability-waiver.component';
import { FaxComponent } from './components/fax/fax.component';
import { ViewPatientPaymentComponent } from './components/view-patient-payment/view-patient-payment.component';
import { ViewPaymentHistoryComponent } from './components/view-payment-history/view-payment-history.component';

const routes: Routes = [
  { path: '', component: LoginComponent },
  {
    path: 'patientDash/:id',
    component: PatientdashboardComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'physicalTherapyDash',
    component: PhysicalTherapyDashboardComponent,
    canActivate: [AuthguardGuardService],
  },

  {
    path: 'adminDash',
    component: AdmindashboardComponent,
    canActivate: [AuthguardGuardService, AdminGuard],
  },
  {
    path: 'superAdminDash',
    component: SuperAdminDashboardComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'createPatient',
    component: AddpatientComponent,
    canActivate: [AuthguardGuardService],
  },

  {
    path: 'physicalTherapyPatient/:id',
    component: PhysicalTherapyPatientComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewDepartment',
    component: ViewDepartmentComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'patientDetails/:id',
    component: PatientdetailsComponent,
    canActivate: [AuthguardGuardService],
  },

  {
    path: 'hospitalDash',
    component: HospitalDashboardComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewPhysicalTherapyDoctor',
    component: ViewPhysicalTherapyDoctorComponent,
    canActivate: [AuthguardGuardService],
  },

  {
    path: 'addDepartment/:id',
    component: AddDepartmentComponent,
    canActivate: [AuthguardGuardService],
  },

  {
    path: 'addAppointment/:id',
    component: AddAppointmentComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addAppointmentPT/:id',
    component: AddAppointmentPatientComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addPTBooking/:id',
    component: AddPhysicalTherapyBookingComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addPTBookingByAdmin/:id',
    component: AddPhysicaltherapyBookingByadminComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addInvoice/:id',
    component: AddInvoiceComponent,
    canActivate: [AuthguardGuardService],
  },

  {
    path: 'addMRI/:id',
    component: AddMriComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addPatient',
    component: AddpatientComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addPhysicalTherapyDoctor',
    component: AddPhysicalTherapyDoctorComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addHospital',
    component: AddHospitalComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addCompany',
    component: AddCompanyComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addRefferal/:id',
    component: AddRefferalComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewRefferal/:id',
    component: ViewRefferalComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'allPatient/:id',
    component: AllPatientsComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewPatient/:id',
    component: ViewpatientComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewPatienthasBalanceToPay/:id',
    component: PatientHasBalanceTopayComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewPatienthasInsurancePendingPayment/:id',
    component: PatientHasInsurancePendingPaymentComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewPatienthasUnPaidBalance/:id',
    component: PatientHasUnpaidAmountComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addCptCode',
    component: AddCptcodeComponent,
    canActivate: [AuthguardGuardService],
  },

  {
    path: 'addPtInitialEvaluation/:id',
    component: AddPtInitialEvaluationComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addPhysicalTherapyVisit/:id',
    component: AddPhysicalTherapyVisitComponent,
    canActivate: [AuthguardGuardService],
  },

  {
    path: 'addPatientIntakeItems/:id',
    component: PatientIntakeItemsComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addPatientHistory/:id',
    component: PatientHistoryComponent,
    canActivate: [AuthguardGuardService],
  },

  { path: 'scanner', component: ScannerComponent },

  { path: 'twofa', component: TwofaComponent },

  {
    path: 'addDichargeSummary',
    component: AddDischargeSummaryComponent,
    canActivate: [AuthguardGuardService],
  },

  {
    path: 'updatePatient/:id',
    component: UpdatepatientComponent,
    canActivate: [AuthguardGuardService],
  },

  {
    path: 'addAllergy/:id',
    component: AddAllergyComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addVaccine/:id',
    component: AddVaccineComponent,
    canActivate: [AuthguardGuardService],
  },

  {
    path: 'addSurgicalHistory/:id',
    component: AddSurgicalHistoryComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addFamilyHistory/:id',
    component: AddFamilyhistoryComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addSocialHistory/:id',
    component: AddSocialhistoryComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addMedicalHistory/:id',
    component: AddMedicalHistoryComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addHippaCompliance/:id',
    component: AddHippaComplianceComponent,
    canActivate: [AuthguardGuardService],
  },

  {
    path: 'addEmergencyContact/:id',
    component: AddEmergencyContactComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addPatientInsurance/:id',
    component: AddPatientInsuranceComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addPatientIdProof/:id',
    component: AddPatientIdproofComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addTimeSlot',
    component: AddTimeSlotComponent,
    canActivate: [AuthguardGuardService],
  },

  {
    path: 'ptSummary/:id',
    component: PtSummaryComponent,
    canActivate: [AuthguardGuardService],
  },

  {
    path: 'patientInsurance/:id',
    component: ViewPatientInsuranceComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'patientIdProof/:id',
    component: ViewPatientIdproofComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'printMedication/:id',
    component: PrintmedicationComponent,
    canActivate: [AuthguardGuardService],
  },

  {
    path: 'editAllergy/:id',
    component: UpdateAllergyComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'editAppointment/:id',
    component: UpdateAppointmentComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'editAppointmentByDoctor/:id',
    component: UpdateAppointmentByDoctorComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'patientDataScanner',
    component: PatientDataScannerComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'editInvoice/:id',
    component: UpdateInvoiceComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'notePad',
    component: NotepadComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'drawPad/:id',
    component: DrawPadComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'roundBook/:id',
    component: RoundbookComponent,
    canActivate: [AuthguardGuardService],
  },

  {
    path: 'viewPTinitialEvaluation/:id',
    component: ViewPtInitialEvaluationComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewPhysicalTherapyVisit/:id',
    component: ViewPhysicalTherapyVisitComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewPhysicalTherapyVisitPatientSide/:id',
    component: ViewPhysicalTherapyVisitPatientsideComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'updatePhysicalTherapyVisit/:id',
    component: UpdatePhysicalTherapyVisitComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'updatePtInitalEvaluation/:id',
    component: UpdatePtInitalEvaluationComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewUploadRadiologyreport/:id',
    component: ViewUploadRadiologyReportComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'uploadRadiolgyreport/:id',
    component: AddUploadRadiologyReportComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'company',
    component: CompanyComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewHospital/:id',
    component: ViewHospitalComponent,
    canActivate: [AuthguardGuardService],
  },

  {
    path: 'viewAllHospital',
    component: ViewAllhospitalsComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewAllergy/:id',
    component: ViewAllergyComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewInvoice/:id',
    component: ViewInvoiceComponent,
    canActivate: [AuthguardGuardService],
  },

  {
    path: 'viewSurgicalHistory/:id',
    component: ViewSurgicalHistoryComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewFamliyHistory/:id',
    component: ViewFamilyhistoryComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewSocialHistory/:id',
    component: ViewSocialhistoryComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewHippaCompliance/:id',
    component: ViewHippaComplianceComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'PatientViewHippaCompliance/:id',
    component: PatientViewHippaComplianceComponent,
    canActivate: [AuthguardGuardService],
  },

  {
    path: 'viewAllInvoices',
    component: ViewAllInvoicesComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewAllAppointments',
    component: ViewAllAppointmentsComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewTodaysAppointments',
    component: ViewAppointmentsTodayComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'drAppointment/:id',
    component: ViewDoctorAppointmentsComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'drTodaysAppointment/:id',
    component: ViewDoctorAppointmentsTodayComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewPTinsurance',
    component: InsuranceComponent,
    canActivate: [AuthguardGuardService],
  },

  {
    path: 'viewCptCode',
    component: ViewCptcodeComponent,
    canActivate: [AuthguardGuardService],
  },

  {
    path: 'viewDischargeSummary/:id',
    component: ViewDischargeSummaryComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'printDischargeSummary/:id',
    component: PrintDischargeSummaryComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'updateDischargeSummary/:id',
    component: UpdateDischargeSummaryComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewMri/:id',
    component: ViewMriComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewMedicalHistory/:id',
    component: ViewMedicalHistoryComponent,
    canActivate: [AuthguardGuardService],
  },

  { path: 'signature', component: SigningPadComponent },

  {
    path: 'viewEmergencyContact',
    component: EmergencyContactComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'patientSummary/:id',
    component: PatientsummaryComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'ptMedicalRecords/:id',
    component: PtmedicalrecordsComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'ptInvoices/:id',
    component: PtinvoicesComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'ptAppointment/:id',
    component: ViewAppointmentComponent,
    canActivate: [AuthguardGuardService],
  },

  {
    path: 'patient/:id',
    component: PatientComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'printInvoice/:id',
    component: PrintinvoiceComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'printInvoicePatientSide/:id',
    component: PrintInvoicePatientSideComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addOfficePolicy/:id',
    component: AddOfficePolicyConsentComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewOfficePolicy/:id',
    component: ViewOfficePolicyConsentComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'patientViewOfficePolicy/:id',
    component: PatientViewOfficePolicyComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addExersiceLiabilityWaiver/:id',
    component: AddExersiceLiabilityWaiverComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewExersiceLiabilityWaiver/:id',
    component: ViewExersiceLiabilityWaiverComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'patientViewExersiceLiabilityWaiver/:id',
    component: PatientViewExersiceLiabilityWaiverComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addDryNeedlingConsent/:id',
    component: AddDryNeedlingConsentComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewDryNeedlingConsent/:id',
    component: ViewDryNeedlingConsentComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'patientViewDryNeedlingConsent/:id',
    component: PatientViewDryNeedlingConsentComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addConsentForService/:id',
    component: AddConsentForServiceComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewConsentForService/:id',
    component: ViewConsentForServiceComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'patientViewConsentForService/:id',
    component: PatientViewConsentForServiceComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'bulkImportDatas',
    component: BulkImportPatientComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'invoiceBalances/:id',
    component: InvoiceHasABalanceComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewPatientPayment/:id',
    component: ViewPatientPaymentComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewPaymentHistory/:id',
    component: ViewPaymentHistoryComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
  },
  {
    path: 'fax',
    component: FaxComponent,
  },
  {
    path: 'insurance-claim',
    component: InsuranceClaimComponent,
  },
  { path: 'setpassword', component: SetpasswordComponent },
  { path: 'resetpassword', component: ResetpasswordComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
