import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';
import { CptCode } from 'src/app/Models/cptcode.model';
import { IcdCode } from 'src/app/Models/icdcode.model';
import { CptcodeService } from 'src/app/services/cptcode.service';
import { IcdCodeService } from 'src/app/services/icd-code.service';
import { PhysicalTherapyDoctorService } from 'src/app/services/physical-therapy-doctor.service';
import { PtInitialEvaluationService } from 'src/app/services/pt-initial-evaluation.service';
import { VoiceRecognitionAbletoSitForService } from 'src/app/services/voice-recognition-ableto-sit-for.service';
import { VoiceRecognitionAmbulatiionService } from 'src/app/services/voice-recognition-ambulatiion.service';
import { VoiceRecognitionBeindependantService } from 'src/app/services/voice-recognition-beindependant.service';
import { VoiceRecognitionCommentsService } from 'src/app/services/voice-recognition-comments.service';
import { VoiceRecognitionDiagnosisTestingService } from 'src/app/services/voice-recognition-diagnosis-testing.service';
import { VoiceRecognitionFrequencyandDurationService } from 'src/app/services/voice-recognition-frequencyand-duration.service';
import { VoiceRecognitionFunctionalGoal1Service } from 'src/app/services/voice-recognition-functional-goal1.service';
import { VoiceRecognitionFunctionalGoal2Service } from 'src/app/services/voice-recognition-functional-goal2.service';
import { VoiceRecognitionFunctionalGoal3Service } from 'src/app/services/voice-recognition-functional-goal3.service';
import { VoiceRecognitionGoalTimeService } from 'src/app/services/voice-recognition-goal-time.service';
import { VoiceRecognitionJointMobilizationService } from 'src/app/services/voice-recognition-joint-mobilization.service';
import { VoiceRecognitionLivingEnvironmentService } from 'src/app/services/voice-recognition-living-environment.service';
import { VoiceRecognitionMechanismOfInjuryService } from 'src/app/services/voice-recognition-mechanism-of-injury.service';
import { VoiceRecognitionMedicalHistoryService } from 'src/app/services/voice-recognition-medical-history.service';
import { VoiceRecognitionMobilityService } from 'src/app/services/voice-recognition-mobility.service';
import { VoiceRecognitionOccupationService } from 'src/app/services/voice-recognition-occupation.service';
import { VoiceRecognitionPainDecreasesWithService } from 'src/app/services/voice-recognition-pain-decreases-with.service';
import { VoiceRecognitionPainIncreasesWithService } from 'src/app/services/voice-recognition-pain-increases-with.service';
import { VoiceRecognitionPalpitationService } from 'src/app/services/voice-recognition-palpitation.service';
import { VoiceRecognitionPatientGoalService } from 'src/app/services/voice-recognition-patient-goal.service';
import { VoiceRecognitionPostureService } from 'src/app/services/voice-recognition-posture.service';
import { VoiceRecognitionProblem1Service } from 'src/app/services/voice-recognition-problem1.service';
import { VoiceRecognitionProblem2Service } from 'src/app/services/voice-recognition-problem2.service';
import { VoiceRecognitionPrognosisService } from 'src/app/services/voice-recognition-prognosis.service';
import { VoiceRecognitionReturnToWorkService } from 'src/app/services/voice-recognition-return-to-work.service';
import { VoiceRecognitionSoftTissueMobilizationService } from 'src/app/services/voice-recognition-soft-tissue-mobilization.service';
import { VoiceRecognitionStretchingService } from 'src/app/services/voice-recognition-stretching.service';
import { VoiceRecognitionTreatmentAreaService } from 'src/app/services/voice-recognition-treatment-area.service';
import { VoiceRecognitionTreatmentPlanService } from 'src/app/services/voice-recognition-treatment-plan.service';
import { VoiceRecognitionWeeknessService } from 'src/app/services/voice-recognition-weekness.service';

@Component({
  selector: 'app-add-pt-initial-evaluation',
  templateUrl: './add-pt-initial-evaluation.component.html',
  styleUrls: ['./add-pt-initial-evaluation.component.css'],
})
export class AddPtInitialEvaluationComponent implements OnInit {
  id = 0;
  DoctorId: Number;
  helper = new JwtHelperService();
  myToken = window.localStorage.getItem('token');
  icdCodeList: IcdCode[];
  filteredOptions: Observable<IcdCode[]>;

  Frm: FormGroup;
  isSuccess = false;
  autoFillItems = [
    { value: '1x' },
    { value: '2x' },
    { value: '3x' },
    { value: '4x' },
    { value: '5x' },
    { value: '6x' },
    { value: '7x' },
    { value: '8x' },
    { value: '9x' },
    { value: '10x' },
    { value: '11x' },
    { value: '12x' },
    { value: '13x' },
    { value: '14x' },
    { value: '15x' },
    { value: '16x' },
    { value: '17x' },
    { value: '18x' },
    { value: '19x' },
    { value: '20x' },
  ];
  allRom = [
    { value: 'WNL' },
    { value: '5% Limited' },
    { value: '10% Limited' },
    { value: '15% Limited' },
    { value: '20% Limited' },
    { value: '25% Limited' },
    { value: '30% Limited' },
    { value: '35% Limited' },
    { value: '40% Limited' },
    { value: '45% Limited' },
    { value: '50% Limited' },
    { value: '55% Limited' },
    { value: '60% Limited' },
    { value: '65% Limited' },
    { value: '70% Limited' },
    { value: '75% Limited' },
    { value: '80% Limited' },
    { value: '85% Limited' },
    { value: '90% Limited' },
    { value: '95% Limited' },
    { value: '100% Limited' },
  ];
  painScale = [
    { value: '1' },
    { value: '2' },
    { value: '3' },
    { value: '4' },
    { value: '5' },
    { value: '6' },
    { value: '7' },
    { value: '8' },
    { value: '9' },
    { value: '10' },
  ];
  autoRange = [
    { value: 'Increased' },
    { value: 'Normal' },
    { value: 'Stable' },
    { value: 'Decreased' },
  ];
  autoIndependance = [
    { value: 'Complete Independence' },
    { value: 'Partial Independence' },
    { value: 'Not Independent' },
    { value: 'Need help ' },
  ];
  autoPain = [
    { value: 'Severe' },
    { value: 'Moderate' },
    { value: 'Mild' },
    { value: 'Extremely Severe' },
  ];
  abletoSitFor = [
    { value: '1 hr' },
    { value: '2 hr' },
    { value: '3 hr' },
    { value: '4 hr' },
    { value: '5 hr' },
    { value: '6 hr' },
    { value: '7 hr' },
    { value: '8 hr' },
    { value: '9 hr' },
    { value: '10 hr' },
  ];
  returnToWork = [
    { value: 'Within 1 Week' },
    { value: 'Within 2 Weeks' },
    { value: 'Within 3 Weeks' },
    { value: 'Within 4 Weeks' },
    { value: 'Within 5 Weeks' },
    { value: 'Within 6 Weeks' },
    { value: 'Within 7 Weeks' },
    { value: 'Within 8 Weeks' },
    { value: 'Within 9 Weeks' },
    { value: 'Within 10 Weeks' },
    { value: 'Within 1 Months' },
    { value: 'Within 2 Months' },
    { value: 'Within 3 Months' },
    { value: 'Within 4 Months' },
    { value: 'Within 5 Months' },
    { value: 'Within 6 Months' },
    { value: 'Within 7Months' },
    { value: 'Within 8 Months' },
    { value: 'Within 9 Months' },
    { value: 'Within 10 Months' },
    { value: 'May take longer' },
    { value: 'Cannot Predict' },
  ];
  STPtestVal = [{ value: '+' }, { value: '-' }];
  listeningMedicalHistory = false;
  listeningLivingEnvironment = false;
  listeningOccupation = false;
  listeningMechanismOfInjury = false;
  listeningPainIncreasesWith = false;
  listeningPainDecreasesWith = false;
  listeningDiagnosisTesting = false;
  listeningPatientGoal = false;
  listeningTreatmentArea = false;
  listeningPalpitation = false;
  listeningPosture = false;
  listeningComments = false;
  listeningWeekness = false;
  listeningMobility = false;
  listeningAmbulatiion = false;
  listeningProblem1 = false;
  listeningProblem2 = false;
  listeningPrognosis = false;
  listeningFunctionalGoal1 = false;
  listeningFunctionalGoal2 = false;
  listeningFunctionalGoal3 = false;
  listeningFrequencyandDuration = false;
  listeningTreatmentPlan = false;
  listeningJointMobilization = false;
  listeningSoftTissueMobilization = false;
  listeningStretching = false;
  listeningGoalTime = false;
  listeningBeindependant = false;
  listeningAbletoSitfor: boolean;
  listeningReturnToWork: boolean;
  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private ptInitalEvaluationService: PtInitialEvaluationService,
    private physicaltherpyDoctorService: PhysicalTherapyDoctorService,
    private icdCodeService: IcdCodeService,
    private http: HttpClient,
    public serviceMedicalHistory: VoiceRecognitionMedicalHistoryService,
    public serviceLivingEnvironment: VoiceRecognitionLivingEnvironmentService,
    public serviceOccupation: VoiceRecognitionOccupationService,
    public serviceMechanismOfInjury: VoiceRecognitionMechanismOfInjuryService,
    public servicePainIncreasesWith: VoiceRecognitionPainIncreasesWithService,
    public servicePainDecreasesWith: VoiceRecognitionPainDecreasesWithService,
    public serviceDiagnosisTesting: VoiceRecognitionDiagnosisTestingService,
    public servicePatientGoal: VoiceRecognitionPatientGoalService,
    public serviceTreatmentArea: VoiceRecognitionTreatmentAreaService,
    public servicePalpitation: VoiceRecognitionPalpitationService,
    public servicePosture: VoiceRecognitionPostureService,
    public serviceComments: VoiceRecognitionCommentsService,
    public serviceWeekness: VoiceRecognitionWeeknessService,
    public serviceMobility: VoiceRecognitionMobilityService,
    public serviceAmbulatiion: VoiceRecognitionAmbulatiionService,
    public serviceProblem1: VoiceRecognitionProblem1Service,
    public serviceProblem2: VoiceRecognitionProblem2Service,
    public servicePrognosis: VoiceRecognitionPrognosisService,
    public serviceGoalTime: VoiceRecognitionGoalTimeService,
    public serviceBeindependant: VoiceRecognitionBeindependantService,
    public serviceAbletoSitfor: VoiceRecognitionAbletoSitForService,
    public serviceReturnToWork: VoiceRecognitionReturnToWorkService,
    public serviceFunctionalGoal1: VoiceRecognitionFunctionalGoal1Service,
    public serviceFunctionalGoal2: VoiceRecognitionFunctionalGoal2Service,
    public serviceFunctionalGoal3: VoiceRecognitionFunctionalGoal3Service,
    public serviceFrequencyandDuration: VoiceRecognitionFrequencyandDurationService,
    public serviceJointMobilization: VoiceRecognitionJointMobilizationService,
    public serviceTreatmentPlan: VoiceRecognitionTreatmentPlanService,
    public serviceSoftTissueMobilization: VoiceRecognitionSoftTissueMobilizationService,
    public serviceStretching: VoiceRecognitionStretchingService
  ) {
    this.serviceMedicalHistory.init();
    this.serviceLivingEnvironment.init();
    this.serviceOccupation.init();
    this.serviceMechanismOfInjury.init();
    this.servicePainIncreasesWith.init();
    this.servicePainDecreasesWith.init();
    this.serviceDiagnosisTesting.init();
    this.servicePatientGoal.init();
    this.serviceGoalTime.init();
    this.serviceBeindependant.init();
    this.serviceAbletoSitfor.init();
    this.serviceReturnToWork.init();
    this.serviceTreatmentArea.init();
    this.servicePalpitation.init();
    this.servicePosture.init();
    this.serviceComments.init();
    this.serviceWeekness.init();
    this.serviceMobility.init();
    this.serviceAmbulatiion.init();
    this.serviceProblem1.init();
    this.serviceProblem2.init();
    this.servicePrognosis.init();
    this.serviceFunctionalGoal1.init();
    this.serviceFunctionalGoal2.init();
    this.serviceFunctionalGoal3.init();
    this.serviceFrequencyandDuration.init();
    this.serviceJointMobilization.init();
    this.serviceTreatmentPlan.init();
    this.serviceSoftTissueMobilization.init();
    this.serviceStretching.init();
  }

  ngOnInit(): void {
    this.getIcdCode();
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.DoctorId = parseInt(decodedToken.nameid);
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });

    this.Frm = this.fb.group({
      DoctorId: this.DoctorId,
      DoctorName: [''],
      InitialEvaluationDiagnosisCodes: this.fb.array([]),
      PatientId: this.id,
      InjuryDate: ['', Validators.required],
      MechanismOfInjury: ['', Validators.required],
      PainIncreasesWith: ['', Validators.required],
      PainDecreasesWith: ['', Validators.required],
      MedicalHistory: ['', Validators.required],
      LivingEnvironment: ['', Validators.required],
      Occupation: ['', Validators.required],
      DiagnosisTesting: ['', Validators.required],
      PatientGoal: ['', Validators.required],
      PainWorstLevel: ['', Validators.required],
      PainCurrentLevel: ['', Validators.required],
      PainBestLevel: ['', Validators.required],
      TreatmentArea: ['', Validators.required],
      Palpitation: ['', Validators.required],
      Posture: ['', Validators.required],
      FlexionCervical: ['', Validators.required],
      ExtensionCervical: ['', Validators.required],
      LeftSideFlexionCervical: ['', Validators.required],
      RightSideFlexionCervical: ['', Validators.required],
      LeftRotationCervical: ['', Validators.required],
      RightRotationCervical: ['', Validators.required],
      FlexionLumbar: ['', Validators.required],
      ExtensionLumbar: ['', Validators.required],
      LeftSideFlexionLumbar: ['', Validators.required],
      RightSideFlexionLumbar: ['', Validators.required],
      LeftRotationLumbar: ['', Validators.required],
      RightRotationLumbar: ['', Validators.required],
      LowerAbdominals: ['', Validators.required],
      HipFlexors: ['', Validators.required],
      HipExtensors: ['', Validators.required],
      SpineSpecialTests: ['', Validators.required],
      ThomasText: ['', Validators.required],
      SlumpTest: ['', Validators.required],
      PhoneInstabilityTest: ['', Validators.required],
      Comments: [''],
      //HipRom
      AbductionArromLeft: ['', Validators.required],
      AbductionArromRight: ['', Validators.required],
      AbductionFromLeft: ['', Validators.required],
      AbductionFromRight: ['', Validators.required],

      FlexionWithKneeFlexedArromLeft: ['', Validators.required],
      FlexionWithKneeFlexedArromRight: ['', Validators.required],
      FlexionWithKneeFlexedFromLeft: ['', Validators.required],
      FlexionWithKneeFromRight: ['', Validators.required],

      FlexionWithKneeExtendedArromLeft: ['', Validators.required],
      FlexionWithKneeExtendedArromRight: ['', Validators.required],
      FlexionWithKneeExtendedFromLeft: ['', Validators.required],
      FlexionWithKneeExtendedFromRight: ['', Validators.required],

      HipExtensionArromLeft: ['', Validators.required],
      HipExtensionArromRight: ['', Validators.required],
      HipExtensionFromLeft: ['', Validators.required],
      HipExtensionFromRight: ['', Validators.required],

      HipExternalRotationArromLeft: ['', Validators.required],
      HipExternalRotationArromRight: ['', Validators.required],
      HipExternalRotationFromLeft: ['', Validators.required],
      HipExternalRotationFromRight: ['', Validators.required],

      //KneeRom
      KneeFlexionArromLeft: ['', Validators.required],
      KneeFlexionArromRight: ['', Validators.required],
      KneeFlexionFromLeft: ['', Validators.required],
      KneeFlexionFromRight: ['', Validators.required],

      KneeExtensionArromLeft: ['', Validators.required],
      KneeExtensionArromRight: ['', Validators.required],
      KneeExtensionFromLeft: ['', Validators.required],
      KneeExtensionFromRight: ['', Validators.required],

      //Assessment
      //problem List
      Pain: ['', Validators.required],
      Weekness: ['', Validators.required],
      Mobility: ['', Validators.required],
      Ambulatiion: ['', Validators.required],
      RangeOfMotion: ['', Validators.required],
      Problem1: ['', Validators.required],
      Problem2: [''],
      Prognosis: ['', Validators.required],

      //Goals

      GoalTime: ['', Validators.required],
      AbletoSitFor: ['', Validators.required],
      ReturnToWork: ['', Validators.required],
      BeIndependentandSelfGrooming: ['', Validators.required],
      FunctionalGoal1: ['', Validators.required],
      FunctionalGoal2: [''],
      FunctionalGoal3: [''],

      //Plan
      //TreatmentPlan

      FrequencyandDuration: ['', Validators.required],
      TreatmentPlan: ['', Validators.required],

      //TreatmentFlowSheet
      //Manual Therapy

      JointMobilization: ['', Validators.required],
      SoftTissueMobilization: ['', Validators.required],
      Stretching: ['', Validators.required],

      //Lwer Extermity Exercise Flowsheet
      //Other
      PirformisStr: ['', Validators.required],
      LungeStr: ['', Validators.required],

      //Spine Execise Flowsheet
      //Thoracic Extension
      FoamRoller: ['', Validators.required],
      LionLatStretch: ['', Validators.required],
      CatandCamelStretch: ['', Validators.required],
      LowerTrunkRotation: ['', Validators.required],

      TimeIn: ['', Validators.required],
      TimeOut: ['', Validators.required],
      Signature: ['', Validators.required],
    });
    this.addItem();
  }
  public addItem(): void {
    const item = this.fb.group({
      Code: [''],
      // Description: [''],
    });
    this.InitialEvaluationDiagnosisCodes.push(item);
  }

  public removeItem(i: number): void {
    this.InitialEvaluationDiagnosisCodes.removeAt(i);
  }
  get InitialEvaluationDiagnosisCodes(): UntypedFormArray {
    return this.Frm.get('InitialEvaluationDiagnosisCodes') as UntypedFormArray;
  }
  get DiagnosisCodes() {
    return this.Frm.get('DiagnosisCodes');
  }

  get Signature() {
    return this.Frm.get('Signature');
  }

  get DoctorName() {
    return this.Frm.get('DoctorName');
  }

  get LungeStr() {
    return this.Frm.get('LungeStr');
  }

  get PirformisStr() {
    return this.Frm.get('PirformisStr');
  }

  get Stretching() {
    return this.Frm.get('Stretching');
  }

  get SoftTissueMobilization() {
    return this.Frm.get('SoftTissueMobilization');
  }

  get JointMobilization() {
    return this.Frm.get('JointMobilization');
  }

  get TreatmentPlan() {
    return this.Frm.get('TreatmentPlan');
  }

  get FrequencyandDuration() {
    return this.Frm.get('FrequencyandDuration');
  }

  get FunctionalGoal3() {
    return this.Frm.get('FunctionalGoal3');
  }

  get FunctionalGoal2() {
    return this.Frm.get('FunctionalGoal2');
  }

  get FunctionalGoal1() {
    return this.Frm.get('FunctionalGoal1');
  }

  get BeIndependentandSelfGrooming() {
    return this.Frm.get('BeIndependentandSelfGrooming');
  }

  get ReturnToWork() {
    return this.Frm.get('ReturnToWork');
  }

  get AbletoSitFor() {
    return this.Frm.get('AbletoSitFor');
  }

  get GoalTime() {
    return this.Frm.get('GoalTime');
  }

  get Prognosis() {
    return this.Frm.get('Prognosis');
  }

  get Problem2() {
    return this.Frm.get('Problem2');
  }

  get Problem1() {
    return this.Frm.get('Problem1');
  }

  get RangeOfMotion() {
    return this.Frm.get('RangeOfMotion');
  }

  get Ambulatiion() {
    return this.Frm.get('Ambulatiion');
  }

  get Mobility() {
    return this.Frm.get('Mobility');
  }

  get Weekness() {
    return this.Frm.get('Weekness');
  }

  get Pain() {
    return this.Frm.get('Pain');
  }

  get KneeExtensionFromRight() {
    return this.Frm.get('KneeExtensionFromRight');
  }

  get KneeExtensionFromLeft() {
    return this.Frm.get('KneeExtensionFromLeft');
  }

  get KneeExtensionArromRight() {
    return this.Frm.get('KneeExtensionArromRight');
  }

  get KneeExtensionArromLeft() {
    return this.Frm.get('KneeExtensionArromLeft');
  }

  get KneeFlexionFromRight() {
    return this.Frm.get('KneeFlexionFromRight');
  }

  get KneeFlexionFromLeft() {
    return this.Frm.get('KneeFlexionFromLeft');
  }

  get KneeFlexionArromRight() {
    return this.Frm.get('KneeFlexionArromRight');
  }

  get KneeFlexionArromLeft() {
    return this.Frm.get('KneeFlexionArromLeft');
  }

  get HipExternalRotationFromRight() {
    return this.Frm.get('HipExternalRotationFromRight');
  }

  get HipExternalRotationFromLeft() {
    return this.Frm.get('HipExternalRotationFromLeft');
  }

  get HipExternalRotationArromRight() {
    return this.Frm.get('HipExternalRotationArromRight');
  }

  get HipExternalRotationArromLeft() {
    return this.Frm.get('HipExternalRotationArromLeft');
  }

  get HipExtensionFromRight() {
    return this.Frm.get('HipExtensionFromRight');
  }

  get HipExtensionFromLeft() {
    return this.Frm.get('HipExtensionFromLeft');
  }

  get HipExtensionArromRight() {
    return this.Frm.get('HipExtensionArromRight');
  }

  get HipExtensionArromLeft() {
    return this.Frm.get('HipExtensionArromLeft');
  }

  get FlexionWithKneeFromRight() {
    return this.Frm.get('FlexionWithKneeFromRight');
  }

  get FlexionWithKneeExtendedArromLeft() {
    return this.Frm.get('FlexionWithKneeExtendedArromLeft');
  }

  get FlexionWithKneeExtendedArromRight() {
    return this.Frm.get('FlexionWithKneeExtendedArromRight');
  }

  get FlexionWithKneeExtendedFromLeft() {
    return this.Frm.get('FlexionWithKneeExtendedFromLeft');
  }

  get FlexionWithKneeExtendedFromRight() {
    return this.Frm.get('FlexionWithKneeExtendedFromRight');
  }

  get FlexionWithKneeFlexedFromLeft() {
    return this.Frm.get('FlexionWithKneeFlexedFromLeft');
  }

  get FlexionWithKneeFlexedArromRight() {
    return this.Frm.get('FlexionWithKneeFlexedArromRight');
  }

  get FlexionWithKneeFlexedArromLeft() {
    return this.Frm.get('FlexionWithKneeFlexedArromLeft');
  }

  get AbductionFromRight() {
    return this.Frm.get('AbductionFromRight');
  }

  get AbductionFromLeft() {
    return this.Frm.get('AbductionFromLeft');
  }

  get AbductionArromRight() {
    return this.Frm.get('AbductionArromRight');
  }

  get AbductionArromLeft() {
    return this.Frm.get('AbductionArromLeft');
  }

  get Comments() {
    return this.Frm.get('Comments');
  }

  get PhoneInstabilityTest() {
    return this.Frm.get('PhoneInstabilityTest');
  }

  get SlumpTest() {
    return this.Frm.get('SlumpTest');
  }

  get ThomasText() {
    return this.Frm.get('ThomasText');
  }

  get SpineSpecialTests() {
    return this.Frm.get('SpineSpecialTests');
  }

  get HipExtensors() {
    return this.Frm.get('HipExtensors');
  }

  get HipFlexors() {
    return this.Frm.get('HipFlexors');
  }

  get LowerAbdominals() {
    return this.Frm.get('LowerAbdominals');
  }

  get RightRotationLumbar() {
    return this.Frm.get('RightRotationLumbar');
  }

  get LeftRotationLumbar() {
    return this.Frm.get('LeftRotationLumbar');
  }

  get RightSideFlexionLumbar() {
    return this.Frm.get('RightSideFlexionLumbar');
  }

  get LeftSideFlexionLumbar() {
    return this.Frm.get('LeftSideFlexionLumbar');
  }

  get ExtensionLumbar() {
    return this.Frm.get('ExtensionLumbar');
  }

  get FlexionLumbar() {
    return this.Frm.get('FlexionLumbar');
  }

  get RightRotationCervical() {
    return this.Frm.get('RightRotationCervical');
  }

  get LeftRotationCervical() {
    return this.Frm.get('LeftRotationCervical');
  }

  get RightSideFlexionCervical() {
    return this.Frm.get('RightSideFlexionCervical');
  }

  get LeftSideFlexionCervical() {
    return this.Frm.get('LeftSideFlexionCervical');
  }

  get ExtensionCervical() {
    return this.Frm.get('ExtensionCervical');
  }

  get FlexionCervical() {
    return this.Frm.get('FlexionCervical');
  }

  get Posture() {
    return this.Frm.get('Posture');
  }

  get Palpitation() {
    return this.Frm.get('Palpitation');
  }

  get TreatmentArea() {
    return this.Frm.get('TreatmentArea');
  }

  get PainBestLevel() {
    return this.Frm.get('PainBestLevel');
  }

  get PainCurrentLevel() {
    return this.Frm.get('PainCurrentLevel');
  }

  get PainWorstLevel() {
    return this.Frm.get('PainWorstLevel');
  }

  get PatientGoal() {
    return this.Frm.get('PatientGoal');
  }

  get DiagnosisTesting() {
    return this.Frm.get('DiagnosisTesting');
  }

  get Occupation() {
    return this.Frm.get('Occupation');
  }

  get LivingEnvironment() {
    return this.Frm.get('LivingEnvironment');
  }

  get MedicalHistory() {
    return this.Frm.get('MedicalHistory');
  }

  get PainDecreasesWith() {
    return this.Frm.get('PainDecreasesWith');
  }
  get PainIncreasesWith() {
    return this.Frm.get('PainIncreasesWith');
  }

  get MechanismOfInjury() {
    return this.Frm.get('MechanismOfInjury');
  }
  get InjuryDate() {
    return this.Frm.get('InjuryDate');
  }
  getIcdCode() {
    this.icdCodeService.getIcdCodes().subscribe((res) => {
      this.icdCodeList = res;
    });
  }
  tracker(index: number, item: any) {
    return index;
  }
  startServiceMedicalHistory() {
    this.serviceMedicalHistory.start();
    this.listeningMedicalHistory = true;
  }

  stopServiceMedicalHistory() {
    this.serviceMedicalHistory.stop();
    this.listeningMedicalHistory = false;
  }

  onMedicalHistoryChange(e) {
    this.serviceMedicalHistory.medicalhistory = e.target.value;
  }

  onMedicalHistoryBlur() {
    this.stopServiceMedicalHistory();
    this.stopServiceLivingEnvironment();
    this.stopServiceOccupation();
    this.stopServiceMechanismOfInjury();
    this.stopServicePainIncreasesWith();
    this.stopServicePainDecreasesWith();
    this.stopServiceDiagnosisTesting();
    this.stopServicePatientGoal();
    this.stopServiceTreatmentArea();
    this.stopServicePalpitation();
    this.stopServicePosture();
    this.stopServiceComments();
    this.stopServiceWeekness();
    this.stopServiceMobility();
    this.stopServiceAmbulatiion();
    this.stopServiceProblem1();
    this.stopServiceProblem2();
    this.stopServicePrognosis();
    this.stopServiceFunctionalGoal1();
    this.stopServiceFunctionalGoal2();
    this.stopServiceFunctionalGoal3();
    this.stopServiceFrequencyandDuration();
    this.stopServiceTreatmentPlan();
    this.stopServiceJointMobilization();
    this.stopServiceSoftTissueMobilization();
    this.stopServiceStretching();
    this.stopServiceGoalTime();
    this.stopServiceBeindependant();
    this.stopServiceAbletoSitfor();
    this.stopServiceReturnToWork();
  }

  startServiceLivingEnvironment() {
    this.serviceLivingEnvironment.start();
    this.listeningLivingEnvironment = true;
  }

  stopServiceLivingEnvironment() {
    this.serviceLivingEnvironment.stop();
    this.listeningLivingEnvironment = false;
  }

  onLivingEnvironmentChange(e) {
    this.serviceLivingEnvironment.livingEnvironment = e.target.value;
  }

  onLivingEnvironmentBlur() {
    this.stopServiceLivingEnvironment();
    this.stopServiceMedicalHistory();
    this.stopServiceOccupation();
    this.stopServiceMechanismOfInjury();
    this.stopServicePainIncreasesWith();
    this.stopServicePainDecreasesWith();
    this.stopServiceDiagnosisTesting();
    this.stopServicePatientGoal();
    this.stopServiceTreatmentArea();
    this.stopServicePalpitation();
    this.stopServicePosture();
    this.stopServiceComments();
    this.stopServiceWeekness();
    this.stopServiceMobility();
    this.stopServiceAmbulatiion();
    this.stopServiceProblem1();
    this.stopServiceProblem2();
    this.stopServicePrognosis();
    this.stopServiceFunctionalGoal1();
    this.stopServiceFunctionalGoal2();
    this.stopServiceFunctionalGoal3();
    this.stopServiceFrequencyandDuration();
    this.stopServiceTreatmentPlan();
    this.stopServiceJointMobilization();
    this.stopServiceSoftTissueMobilization();
    this.stopServiceStretching();
    this.stopServiceGoalTime();
    this.stopServiceBeindependant();
    this.stopServiceAbletoSitfor();
    this.stopServiceReturnToWork();
  }

  startServiceOccupation() {
    this.serviceOccupation.start();
    this.listeningOccupation = true;
  }

  stopServiceOccupation() {
    this.serviceOccupation.stop();
    this.listeningOccupation = false;
  }

  onOccupationChange(e) {
    this.serviceOccupation.occupation = e.target.value;
  }

  onOccupationBlur() {
    this.stopServiceOccupation();
    this.stopServiceMedicalHistory();
    this.stopServiceLivingEnvironment();

    this.stopServiceMechanismOfInjury();
    this.stopServicePainIncreasesWith();
    this.stopServicePainDecreasesWith();
    this.stopServiceDiagnosisTesting();
    this.stopServicePatientGoal();
    this.stopServiceTreatmentArea();
    this.stopServicePalpitation();
    this.stopServicePosture();
    this.stopServiceComments();
    this.stopServiceWeekness();
    this.stopServiceMobility();
    this.stopServiceAmbulatiion();
    this.stopServiceProblem1();
    this.stopServiceProblem2();
    this.stopServicePrognosis();
    this.stopServiceFunctionalGoal1();
    this.stopServiceFunctionalGoal2();
    this.stopServiceFunctionalGoal3();
    this.stopServiceFrequencyandDuration();
    this.stopServiceTreatmentPlan();
    this.stopServiceJointMobilization();
    this.stopServiceSoftTissueMobilization();
    this.stopServiceStretching();
    this.stopServiceGoalTime();
    this.stopServiceBeindependant();
    this.stopServiceAbletoSitfor();
    this.stopServiceReturnToWork();
  }

  startServiceMechanismOfInjury() {
    this.serviceMechanismOfInjury.start();
    this.listeningMechanismOfInjury = true;
  }

  stopServiceMechanismOfInjury() {
    this.serviceMechanismOfInjury.stop();
    this.listeningMechanismOfInjury = false;
  }

  onMechanismOfInjuryChange(e) {
    this.serviceMechanismOfInjury.mechanismofInjury = e.target.value;
  }

  onMechanismOfInjuryBlur() {
    this.stopServiceMechanismOfInjury();
    this.stopServiceLivingEnvironment();

    this.stopServiceOccupation();
    this.stopServiceMedicalHistory();
    this.stopServicePainIncreasesWith();
    this.stopServicePainDecreasesWith();
    this.stopServiceDiagnosisTesting();
    this.stopServicePatientGoal();
    this.stopServiceTreatmentArea();
    this.stopServicePalpitation();
    this.stopServicePosture();
    this.stopServiceComments();
    this.stopServiceWeekness();
    this.stopServiceMobility();
    this.stopServiceAmbulatiion();
    this.stopServiceProblem1();
    this.stopServiceProblem2();
    this.stopServicePrognosis();
    this.stopServiceFunctionalGoal1();
    this.stopServiceFunctionalGoal2();
    this.stopServiceFunctionalGoal3();
    this.stopServiceFrequencyandDuration();
    this.stopServiceTreatmentPlan();
    this.stopServiceJointMobilization();
    this.stopServiceSoftTissueMobilization();
    this.stopServiceStretching();
    this.stopServiceGoalTime();
    this.stopServiceBeindependant();
    this.stopServiceAbletoSitfor();
    this.stopServiceReturnToWork();
  }

  startServicePainDecreasesWith() {
    this.servicePainDecreasesWith.start();
    this.listeningPainDecreasesWith = true;
  }

  stopServicePainDecreasesWith() {
    this.servicePainDecreasesWith.stop();
    this.listeningPainDecreasesWith = false;
  }

  onPainDecreasesWithChange(e) {
    this.servicePainDecreasesWith.painDecreaseswith = e.target.value;
  }

  onPainDecreasesWithBlur() {
    this.stopServicePainIncreasesWith();
    this.stopServiceLivingEnvironment();

    this.stopServiceMechanismOfInjury();
    this.stopServiceOccupation();
    this.stopServiceMedicalHistory();
    this.stopServiceMedicalHistory();
    this.stopServicePainDecreasesWith();
    this.stopServiceDiagnosisTesting();
    this.stopServicePatientGoal();
    this.stopServiceTreatmentArea();
    this.stopServicePalpitation();
    this.stopServicePosture();
    this.stopServiceComments();
    this.stopServiceWeekness();
    this.stopServiceMobility();
    this.stopServiceAmbulatiion();
    this.stopServiceProblem1();
    this.stopServiceProblem2();
    this.stopServicePrognosis();
    this.stopServiceFunctionalGoal1();
    this.stopServiceFunctionalGoal2();
    this.stopServiceFunctionalGoal3();
    this.stopServiceFrequencyandDuration();
    this.stopServiceTreatmentPlan();
    this.stopServiceJointMobilization();
    this.stopServiceSoftTissueMobilization();
    this.stopServiceStretching();
    this.stopServiceGoalTime();
    this.stopServiceBeindependant();
    this.stopServiceAbletoSitfor();
    this.stopServiceReturnToWork();
  }

  startServicePainIncreasesWith() {
    this.servicePainIncreasesWith.start();
    this.listeningPainIncreasesWith = true;
  }

  stopServicePainIncreasesWith() {
    this.servicePainIncreasesWith.stop();
    this.listeningPainIncreasesWith = false;
  }

  onPainIncreasesWithChange(e) {
    this.servicePainIncreasesWith.painIncreaseswith = e.target.value;
  }

  onPainIncreasesWithBlur() {
    this.stopServicePainDecreasesWith();
    this.stopServicePainIncreasesWith();
    this.stopServiceMechanismOfInjury();
    this.stopServiceLivingEnvironment();

    this.stopServiceOccupation();
    this.stopServiceMedicalHistory();
    this.stopServiceDiagnosisTesting();
    this.stopServicePatientGoal();
    this.stopServiceTreatmentArea();
    this.stopServicePalpitation();
    this.stopServicePosture();
    this.stopServiceComments();
    this.stopServiceWeekness();
    this.stopServiceMobility();
    this.stopServiceAmbulatiion();
    this.stopServiceProblem1();
    this.stopServiceProblem2();
    this.stopServicePrognosis();
    this.stopServiceFunctionalGoal1();
    this.stopServiceFunctionalGoal2();
    this.stopServiceFunctionalGoal3();
    this.stopServiceFrequencyandDuration();
    this.stopServiceTreatmentPlan();
    this.stopServiceJointMobilization();
    this.stopServiceSoftTissueMobilization();
    this.stopServiceStretching();
    this.stopServiceGoalTime();
    this.stopServiceBeindependant();
    this.stopServiceAbletoSitfor();
    this.stopServiceReturnToWork();
  }

  startServiceDiagnosisTesting() {
    this.serviceDiagnosisTesting.start();
    this.listeningDiagnosisTesting = true;
  }

  stopServiceDiagnosisTesting() {
    this.serviceDiagnosisTesting.stop();
    this.listeningDiagnosisTesting = false;
  }

  onDiagnosisTestingChange(e) {
    this.serviceDiagnosisTesting.diagnosis = e.target.value;
  }

  onDiagnosisTestingBlur() {
    this.stopServiceDiagnosisTesting();
    this.stopServicePainDecreasesWith();
    this.stopServicePainIncreasesWith();
    this.stopServiceMechanismOfInjury();
    this.stopServiceLivingEnvironment();

    this.stopServiceOccupation();
    this.stopServiceMedicalHistory();
    this.stopServicePatientGoal();
    this.stopServiceTreatmentArea();
    this.stopServicePalpitation();
    this.stopServicePosture();
    this.stopServiceComments();
    this.stopServiceWeekness();
    this.stopServiceMobility();
    this.stopServiceAmbulatiion();
    this.stopServiceProblem1();
    this.stopServiceProblem2();
    this.stopServicePrognosis();
    this.stopServiceFunctionalGoal1();
    this.stopServiceFunctionalGoal2();
    this.stopServiceFunctionalGoal3();
    this.stopServiceFrequencyandDuration();
    this.stopServiceTreatmentPlan();
    this.stopServiceJointMobilization();
    this.stopServiceSoftTissueMobilization();
    this.stopServiceStretching();
    this.stopServiceGoalTime();
    this.stopServiceBeindependant();
    this.stopServiceAbletoSitfor();
    this.stopServiceReturnToWork();
  }

  startServicePatientGoal() {
    this.servicePatientGoal.start();
    this.listeningPatientGoal = true;
  }

  stopServicePatientGoal() {
    this.servicePatientGoal.stop();
    this.listeningPatientGoal = false;
  }

  onPatientGoalChange(e) {
    this.servicePatientGoal.patientGoal = e.target.value;
  }

  onPatientGoalBlur() {
    this.stopServicePatientGoal();
    this.stopServiceDiagnosisTesting();
    this.stopServicePainDecreasesWith();
    this.stopServicePainIncreasesWith();
    this.stopServiceMechanismOfInjury();
    this.stopServiceLivingEnvironment();

    this.stopServiceOccupation();
    this.stopServiceMedicalHistory();
    this.stopServiceTreatmentArea();
    this.stopServicePalpitation();
    this.stopServicePosture();
    this.stopServiceComments();
    this.stopServiceWeekness();
    this.stopServiceMobility();
    this.stopServiceAmbulatiion();
    this.stopServiceProblem1();
    this.stopServiceProblem2();
    this.stopServicePrognosis();
    this.stopServiceFunctionalGoal1();
    this.stopServiceFunctionalGoal2();
    this.stopServiceFunctionalGoal3();
    this.stopServiceFrequencyandDuration();
    this.stopServiceTreatmentPlan();
    this.stopServiceJointMobilization();
    this.stopServiceSoftTissueMobilization();
    this.stopServiceStretching();
    this.stopServiceGoalTime();
    this.stopServiceBeindependant();
    this.stopServiceAbletoSitfor();
    this.stopServiceReturnToWork();
  }

  startServiceTreatmentArea() {
    this.serviceTreatmentArea.start();
    this.listeningTreatmentArea = true;
  }

  stopServiceTreatmentArea() {
    this.serviceTreatmentArea.stop();
    this.listeningTreatmentArea = false;
  }

  onTreatmentAreaChange(e) {
    this.serviceTreatmentArea.treatment = e.target.value;
  }

  onTreatmentAreaBlur() {
    this.stopServiceTreatmentArea();
    this.stopServicePatientGoal();
    this.stopServiceDiagnosisTesting();
    this.stopServicePainDecreasesWith();
    this.stopServicePainIncreasesWith();
    this.stopServiceMechanismOfInjury();
    this.stopServiceLivingEnvironment();

    this.stopServiceOccupation();
    this.stopServiceMedicalHistory();
    this.stopServicePalpitation();
    this.stopServicePosture();
    this.stopServiceComments();
    this.stopServiceWeekness();
    this.stopServiceMobility();
    this.stopServiceAmbulatiion();
    this.stopServiceProblem1();
    this.stopServiceProblem2();
    this.stopServicePrognosis();
    this.stopServiceFunctionalGoal1();
    this.stopServiceFunctionalGoal2();
    this.stopServiceFunctionalGoal3();
    this.stopServiceFrequencyandDuration();
    this.stopServiceTreatmentPlan();
    this.stopServiceJointMobilization();
    this.stopServiceSoftTissueMobilization();
    this.stopServiceStretching();
    this.stopServiceGoalTime();
    this.stopServiceBeindependant();
    this.stopServiceAbletoSitfor();
    this.stopServiceReturnToWork();
  }

  startServicePalpitation() {
    this.servicePalpitation.start();
    this.listeningPalpitation = true;
  }

  stopServicePalpitation() {
    this.servicePalpitation.stop();
    this.listeningPalpitation = false;
  }

  onPalpitationChange(e) {
    this.servicePalpitation.palpitation = e.target.value;
  }

  onPalpitationBlur() {
    this.stopServicePalpitation();
    this.stopServiceTreatmentArea();
    this.stopServicePatientGoal();
    this.stopServiceDiagnosisTesting();
    this.stopServicePainDecreasesWith();
    this.stopServicePainIncreasesWith();
    this.stopServiceMechanismOfInjury();
    this.stopServiceLivingEnvironment();

    this.stopServiceOccupation();
    this.stopServiceMedicalHistory();
    this.stopServicePosture();
    this.stopServiceComments();
    this.stopServiceWeekness();
    this.stopServiceMobility();
    this.stopServiceAmbulatiion();
    this.stopServiceProblem1();
    this.stopServiceProblem2();
    this.stopServicePrognosis();
    this.stopServiceFunctionalGoal1();
    this.stopServiceFunctionalGoal2();
    this.stopServiceFunctionalGoal3();
    this.stopServiceFrequencyandDuration();
    this.stopServiceTreatmentPlan();
    this.stopServiceJointMobilization();
    this.stopServiceSoftTissueMobilization();
    this.stopServiceStretching();
    this.stopServiceGoalTime();
    this.stopServiceBeindependant();
    this.stopServiceAbletoSitfor();
    this.stopServiceReturnToWork();
  }

  startServicePosture() {
    this.servicePosture.start();
    this.listeningPosture = true;
  }

  stopServicePosture() {
    this.servicePosture.stop();
    this.listeningPosture = false;
  }

  onPostureChange(e) {
    this.servicePosture.posture = e.target.value;
  }

  onPostureBlur() {
    this.stopServicePosture();
    this.stopServicePalpitation();
    this.stopServiceTreatmentArea();
    this.stopServicePatientGoal();
    this.stopServiceDiagnosisTesting();
    this.stopServicePainDecreasesWith();
    this.stopServicePainIncreasesWith();
    this.stopServiceMechanismOfInjury();
    this.stopServiceLivingEnvironment();

    this.stopServiceOccupation();
    this.stopServiceMedicalHistory();
    this.stopServiceComments();
    this.stopServiceWeekness();
    this.stopServiceMobility();
    this.stopServiceAmbulatiion();
    this.stopServiceProblem1();
    this.stopServiceProblem2();
    this.stopServicePrognosis();
    this.stopServiceFunctionalGoal1();
    this.stopServiceFunctionalGoal2();
    this.stopServiceFunctionalGoal3();
    this.stopServiceFrequencyandDuration();
    this.stopServiceTreatmentPlan();
    this.stopServiceJointMobilization();
    this.stopServiceSoftTissueMobilization();
    this.stopServiceStretching();
    this.stopServiceGoalTime();
    this.stopServiceBeindependant();
    this.stopServiceAbletoSitfor();
    this.stopServiceReturnToWork();
  }

  startServiceComments() {
    this.serviceComments.start();
    this.listeningComments = true;
  }

  stopServiceComments() {
    this.serviceComments.stop();
    this.listeningComments = false;
  }

  onCommentsChange(e) {
    this.serviceComments.comment = e.target.value;
  }

  onCommentsBlur() {
    this.stopServiceComments();
    this.stopServicePosture();
    this.stopServicePalpitation();
    this.stopServiceTreatmentArea();
    this.stopServicePatientGoal();
    this.stopServiceDiagnosisTesting();
    this.stopServicePainDecreasesWith();
    this.stopServicePainIncreasesWith();
    this.stopServiceMechanismOfInjury();
    this.stopServiceLivingEnvironment();

    this.stopServiceOccupation();
    this.stopServiceMedicalHistory();
    this.stopServiceWeekness();
    this.stopServiceMobility();
    this.stopServiceAmbulatiion();
    this.stopServiceProblem1();
    this.stopServiceProblem2();
    this.stopServicePrognosis();
    this.stopServiceFunctionalGoal1();
    this.stopServiceFunctionalGoal2();
    this.stopServiceFunctionalGoal3();
    this.stopServiceFrequencyandDuration();
    this.stopServiceTreatmentPlan();
    this.stopServiceJointMobilization();
    this.stopServiceSoftTissueMobilization();
    this.stopServiceStretching();
    this.stopServiceGoalTime();
    this.stopServiceBeindependant();
    this.stopServiceAbletoSitfor();
    this.stopServiceReturnToWork();
  }

  startServiceWeekness() {
    this.serviceWeekness.start();
    this.listeningWeekness = true;
  }

  stopServiceWeekness() {
    this.serviceWeekness.stop();
    this.listeningWeekness = false;
  }

  onWeeknessChange(e) {
    this.serviceWeekness.weekness = e.target.value;
  }

  onWeeknessBlur() {
    this.stopServiceWeekness();
    this.stopServiceComments();
    this.stopServicePosture();
    this.stopServicePalpitation();
    this.stopServiceTreatmentArea();
    this.stopServicePatientGoal();
    this.stopServiceDiagnosisTesting();
    this.stopServicePainDecreasesWith();
    this.stopServicePainIncreasesWith();
    this.stopServiceMechanismOfInjury();
    this.stopServiceLivingEnvironment();

    this.stopServiceOccupation();
    this.stopServiceMedicalHistory();
    this.stopServiceMobility();
    this.stopServiceAmbulatiion();
    this.stopServiceProblem1();
    this.stopServiceProblem2();
    this.stopServicePrognosis();
    this.stopServiceFunctionalGoal1();
    this.stopServiceFunctionalGoal2();
    this.stopServiceFunctionalGoal3();
    this.stopServiceFrequencyandDuration();
    this.stopServiceTreatmentPlan();
    this.stopServiceJointMobilization();
    this.stopServiceSoftTissueMobilization();
    this.stopServiceStretching();
    this.stopServiceGoalTime();
    this.stopServiceBeindependant();
    this.stopServiceAbletoSitfor();
    this.stopServiceReturnToWork();
  }

  startServiceMobility() {
    this.serviceMobility.start();
    this.listeningMobility = true;
  }

  stopServiceMobility() {
    this.serviceMobility.stop();
    this.listeningMobility = false;
  }

  onMobilityChange(e) {
    this.serviceMobility.mobility = e.target.value;
  }

  onMobilityBlur() {
    this.stopServiceMobility();
    this.stopServiceWeekness();
    this.stopServiceComments();
    this.stopServicePosture();
    this.stopServicePalpitation();
    this.stopServiceTreatmentArea();
    this.stopServicePatientGoal();
    this.stopServiceDiagnosisTesting();
    this.stopServicePainDecreasesWith();
    this.stopServicePainIncreasesWith();
    this.stopServiceMechanismOfInjury();
    this.stopServiceLivingEnvironment();

    this.stopServiceOccupation();
    this.stopServiceMedicalHistory();
    this.stopServiceAmbulatiion();
    this.stopServiceProblem1();
    this.stopServiceProblem2();
    this.stopServicePrognosis();
    this.stopServiceFunctionalGoal1();
    this.stopServiceFunctionalGoal2();
    this.stopServiceFunctionalGoal3();
    this.stopServiceFrequencyandDuration();
    this.stopServiceTreatmentPlan();
    this.stopServiceJointMobilization();
    this.stopServiceSoftTissueMobilization();
    this.stopServiceStretching();
    this.stopServiceGoalTime();
    this.stopServiceBeindependant();
    this.stopServiceAbletoSitfor();
    this.stopServiceReturnToWork();
  }

  startServiceAmbulatiion() {
    this.serviceAmbulatiion.start();
    this.listeningAmbulatiion = true;
  }

  stopServiceAmbulatiion() {
    this.serviceAmbulatiion.stop();
    this.listeningAmbulatiion = false;
  }

  onAmbulatiionChange(e) {
    this.serviceAmbulatiion.ambulation = e.target.value;
  }

  onAmbulatiionBlur() {
    this.stopServiceAmbulatiion();
    this.stopServiceMobility();
    this.stopServiceWeekness();
    this.stopServiceComments();
    this.stopServicePosture();
    this.stopServicePalpitation();
    this.stopServiceTreatmentArea();
    this.stopServicePatientGoal();
    this.stopServiceDiagnosisTesting();
    this.stopServicePainDecreasesWith();
    this.stopServicePainIncreasesWith();
    this.stopServiceMechanismOfInjury();
    this.stopServiceLivingEnvironment();

    this.stopServiceOccupation();
    this.stopServiceMedicalHistory();
    this.stopServiceProblem1();
    this.stopServiceProblem2();
    this.stopServicePrognosis();
    this.stopServiceFunctionalGoal1();
    this.stopServiceFunctionalGoal2();
    this.stopServiceFunctionalGoal3();
    this.stopServiceFrequencyandDuration();
    this.stopServiceTreatmentPlan();
    this.stopServiceJointMobilization();
    this.stopServiceSoftTissueMobilization();
    this.stopServiceStretching();
    this.stopServiceGoalTime();
    this.stopServiceBeindependant();
    this.stopServiceAbletoSitfor();
    this.stopServiceReturnToWork();
  }
  startServiceProblem1() {
    this.serviceProblem1.start();
    this.listeningProblem1 = true;
  }

  stopServiceProblem1() {
    this.serviceProblem1.stop();
    this.listeningProblem1 = false;
  }

  onProblem1Change(e) {
    this.serviceProblem1.problem1 = e.target.value;
  }

  onProblem1Blur() {
    this.stopServiceProblem1();
    this.stopServiceAmbulatiion();
    this.stopServiceMobility();
    this.stopServiceWeekness();
    this.stopServiceComments();
    this.stopServicePosture();
    this.stopServicePalpitation();
    this.stopServiceTreatmentArea();
    this.stopServicePatientGoal();
    this.stopServiceDiagnosisTesting();
    this.stopServicePainDecreasesWith();
    this.stopServicePainIncreasesWith();
    this.stopServiceMechanismOfInjury();
    this.stopServiceLivingEnvironment();

    this.stopServiceOccupation();
    this.stopServiceMedicalHistory();
    this.stopServiceProblem2();
    this.stopServicePrognosis();
    this.stopServiceFunctionalGoal1();
    this.stopServiceFunctionalGoal2();
    this.stopServiceFunctionalGoal3();
    this.stopServiceFrequencyandDuration();
    this.stopServiceTreatmentPlan();
    this.stopServiceJointMobilization();
    this.stopServiceSoftTissueMobilization();
    this.stopServiceStretching();
    this.stopServiceGoalTime();
    this.stopServiceBeindependant();
    this.stopServiceAbletoSitfor();
    this.stopServiceReturnToWork();
  }
  startServiceProblem2() {
    this.serviceProblem2.start();
    this.listeningProblem2 = true;
  }

  stopServiceProblem2() {
    this.serviceProblem2.stop();
    this.listeningProblem2 = false;
  }

  onProblem2Change(e) {
    this.serviceProblem2.problem2 = e.target.value;
  }

  onProblem2Blur() {
    this.stopServiceProblem2();
    this.stopServiceProblem1();
    this.stopServiceAmbulatiion();
    this.stopServiceMobility();
    this.stopServiceWeekness();
    this.stopServiceComments();
    this.stopServicePosture();
    this.stopServicePalpitation();
    this.stopServiceTreatmentArea();
    this.stopServicePatientGoal();
    this.stopServiceDiagnosisTesting();
    this.stopServicePainDecreasesWith();
    this.stopServicePainIncreasesWith();
    this.stopServiceMechanismOfInjury();
    this.stopServiceLivingEnvironment();

    this.stopServiceOccupation();
    this.stopServiceMedicalHistory();
    this.stopServicePrognosis();
    this.stopServiceFunctionalGoal1();
    this.stopServiceFunctionalGoal2();
    this.stopServiceFunctionalGoal3();
    this.stopServiceFrequencyandDuration();
    this.stopServiceTreatmentPlan();
    this.stopServiceJointMobilization();
    this.stopServiceSoftTissueMobilization();
    this.stopServiceStretching();
    this.stopServiceGoalTime();
    this.stopServiceBeindependant();
    this.stopServiceAbletoSitfor();
    this.stopServiceReturnToWork();
  }

  startServicePrognosis() {
    this.servicePrognosis.start();
    this.listeningPrognosis = true;
  }

  stopServicePrognosis() {
    this.servicePrognosis.stop();
    this.listeningPrognosis = false;
  }

  onPrognosisChange(e) {
    this.servicePrognosis.prognosis = e.target.value;
  }

  onPrognosisBlur() {
    this.stopServicePrognosis();
    this.stopServiceProblem2();
    this.stopServiceProblem1();
    this.stopServiceAmbulatiion();
    this.stopServiceMobility();
    this.stopServiceWeekness();
    this.stopServiceComments();
    this.stopServicePosture();
    this.stopServicePalpitation();
    this.stopServiceTreatmentArea();
    this.stopServicePatientGoal();
    this.stopServiceDiagnosisTesting();
    this.stopServicePainDecreasesWith();
    this.stopServicePainIncreasesWith();
    this.stopServiceMechanismOfInjury();
    this.stopServiceLivingEnvironment();

    this.stopServiceOccupation();
    this.stopServiceMedicalHistory();
    this.stopServiceFunctionalGoal1();
    this.stopServiceFunctionalGoal2();
    this.stopServiceFunctionalGoal3();
    this.stopServiceFrequencyandDuration();
    this.stopServiceTreatmentPlan();
    this.stopServiceJointMobilization();
    this.stopServiceSoftTissueMobilization();
    this.stopServiceStretching();
    this.stopServiceGoalTime();
    this.stopServiceBeindependant();
    this.stopServiceAbletoSitfor();
    this.stopServiceReturnToWork();
  }

  startServiceFunctionalGoal1() {
    this.serviceFunctionalGoal1.start();
    this.listeningFunctionalGoal1 = true;
  }

  stopServiceFunctionalGoal1() {
    this.serviceFunctionalGoal1.stop();
    this.listeningFunctionalGoal1 = false;
  }

  onFunctionalGoal1Change(e) {
    this.serviceFunctionalGoal1.functionalgoal1 = e.target.value;
  }

  onFunctionalGoal1Blur() {
    this.stopServiceFunctionalGoal1();
    this.stopServicePrognosis();
    this.stopServiceProblem2();
    this.stopServiceProblem1();
    this.stopServiceAmbulatiion();
    this.stopServiceMobility();
    this.stopServiceWeekness();
    this.stopServiceComments();
    this.stopServicePosture();
    this.stopServicePalpitation();
    this.stopServiceTreatmentArea();
    this.stopServicePatientGoal();
    this.stopServiceDiagnosisTesting();
    this.stopServicePainDecreasesWith();
    this.stopServicePainIncreasesWith();
    this.stopServiceMechanismOfInjury();
    this.stopServiceLivingEnvironment();

    this.stopServiceOccupation();
    this.stopServiceMedicalHistory();
    this.stopServiceFunctionalGoal2();
    this.stopServiceFunctionalGoal3();
    this.stopServiceFrequencyandDuration();
    this.stopServiceTreatmentPlan();
    this.stopServiceJointMobilization();
    this.stopServiceSoftTissueMobilization();
    this.stopServiceStretching();
    this.stopServiceGoalTime();
    this.stopServiceBeindependant();
    this.stopServiceAbletoSitfor();
    this.stopServiceReturnToWork();
  }

  startServiceFunctionalGoal2() {
    this.serviceFunctionalGoal2.start();
    this.listeningFunctionalGoal2 = true;
  }

  stopServiceFunctionalGoal2() {
    this.serviceFunctionalGoal2.stop();
    this.listeningFunctionalGoal2 = false;
  }

  onFunctionalGoal2Change(e) {
    this.serviceFunctionalGoal2.functionalgoal2 = e.target.value;
  }

  onFunctionalGoal2Blur() {
    this.stopServiceFunctionalGoal2();
    this.stopServiceFunctionalGoal1();
    this.stopServicePrognosis();
    this.stopServiceProblem2();
    this.stopServiceProblem1();
    this.stopServiceAmbulatiion();
    this.stopServiceMobility();
    this.stopServiceWeekness();
    this.stopServiceComments();
    this.stopServicePosture();
    this.stopServicePalpitation();
    this.stopServiceTreatmentArea();
    this.stopServicePatientGoal();
    this.stopServiceDiagnosisTesting();
    this.stopServicePainDecreasesWith();
    this.stopServicePainIncreasesWith();
    this.stopServiceMechanismOfInjury();
    this.stopServiceLivingEnvironment();

    this.stopServiceOccupation();
    this.stopServiceMedicalHistory();
    this.stopServiceFunctionalGoal3();
    this.stopServiceFrequencyandDuration();
    this.stopServiceTreatmentPlan();
    this.stopServiceJointMobilization();
    this.stopServiceSoftTissueMobilization();
    this.stopServiceStretching();
    this.stopServiceGoalTime();
    this.stopServiceBeindependant();
    this.stopServiceAbletoSitfor();
    this.stopServiceReturnToWork();
  }

  startServiceFunctionalGoal3() {
    this.serviceFunctionalGoal3.start();
    this.listeningFunctionalGoal3 = true;
  }

  stopServiceFunctionalGoal3() {
    this.serviceFunctionalGoal3.stop();
    this.listeningFunctionalGoal3 = false;
  }

  onFunctionalGoal3Change(e) {
    this.serviceFunctionalGoal3.functionalgoal3 = e.target.value;
  }

  onFunctionalGoal3Blur() {
    this.stopServiceFunctionalGoal3();
    this.stopServiceFunctionalGoal2();
    this.stopServiceFunctionalGoal1();
    this.stopServicePrognosis();
    this.stopServiceProblem2();
    this.stopServiceProblem1();
    this.stopServiceAmbulatiion();
    this.stopServiceMobility();
    this.stopServiceWeekness();
    this.stopServiceComments();
    this.stopServicePosture();
    this.stopServicePalpitation();
    this.stopServiceTreatmentArea();
    this.stopServicePatientGoal();
    this.stopServiceDiagnosisTesting();
    this.stopServicePainDecreasesWith();
    this.stopServicePainIncreasesWith();
    this.stopServiceMechanismOfInjury();
    this.stopServiceLivingEnvironment();

    this.stopServiceOccupation();
    this.stopServiceMedicalHistory();
    this.stopServiceFrequencyandDuration();
    this.stopServiceTreatmentPlan();
    this.stopServiceJointMobilization();
    this.stopServiceSoftTissueMobilization();
    this.stopServiceStretching();
    this.stopServiceGoalTime();
    this.stopServiceBeindependant();
    this.stopServiceAbletoSitfor();
    this.stopServiceReturnToWork();
  }

  startServiceFrequencyandDuration() {
    this.serviceFrequencyandDuration.start();
    this.listeningFrequencyandDuration = true;
  }

  stopServiceFrequencyandDuration() {
    this.serviceFrequencyandDuration.stop();
    this.listeningFrequencyandDuration = false;
  }

  onFrequencyandDurationChange(e) {
    this.serviceFrequencyandDuration.frequency = e.target.value;
  }

  onFrequencyandDurationBlur() {
    this.stopServiceFrequencyandDuration();
    this.stopServiceFunctionalGoal3();
    this.stopServiceFunctionalGoal2();
    this.stopServiceFunctionalGoal1();
    this.stopServicePrognosis();
    this.stopServiceProblem2();
    this.stopServiceProblem1();
    this.stopServiceAmbulatiion();
    this.stopServiceMobility();
    this.stopServiceWeekness();
    this.stopServiceComments();
    this.stopServicePosture();
    this.stopServicePalpitation();
    this.stopServiceTreatmentArea();
    this.stopServicePatientGoal();
    this.stopServiceDiagnosisTesting();
    this.stopServicePainDecreasesWith();
    this.stopServicePainIncreasesWith();
    this.stopServiceMechanismOfInjury();
    this.stopServiceLivingEnvironment();

    this.stopServiceOccupation();
    this.stopServiceMedicalHistory();
    this.stopServiceTreatmentPlan();
    this.stopServiceJointMobilization();
    this.stopServiceSoftTissueMobilization();
    this.stopServiceStretching();
    this.stopServiceGoalTime();
    this.stopServiceBeindependant();
    this.stopServiceAbletoSitfor();
    this.stopServiceReturnToWork();
  }

  startServiceTreatmentPlan() {
    this.serviceTreatmentPlan.start();
    this.listeningTreatmentPlan = true;
  }

  stopServiceTreatmentPlan() {
    this.serviceTreatmentPlan.stop();
    this.listeningTreatmentPlan = false;
  }

  onTreatmentPlanChange(e) {
    this.serviceTreatmentPlan.treatment = e.target.value;
  }

  onTreatmentPlanBlur() {
    this.stopServiceTreatmentPlan();
    this.stopServiceFrequencyandDuration();
    this.stopServiceFunctionalGoal3();
    this.stopServiceFunctionalGoal2();
    this.stopServiceFunctionalGoal1();
    this.stopServicePrognosis();
    this.stopServiceProblem2();
    this.stopServiceProblem1();
    this.stopServiceAmbulatiion();
    this.stopServiceMobility();
    this.stopServiceWeekness();
    this.stopServiceComments();
    this.stopServicePosture();
    this.stopServicePalpitation();
    this.stopServiceTreatmentArea();
    this.stopServicePatientGoal();
    this.stopServiceDiagnosisTesting();
    this.stopServicePainDecreasesWith();
    this.stopServicePainIncreasesWith();
    this.stopServiceMechanismOfInjury();
    this.stopServiceLivingEnvironment();

    this.stopServiceOccupation();
    this.stopServiceMedicalHistory();
    this.stopServiceJointMobilization();
    this.stopServiceSoftTissueMobilization();
    this.stopServiceStretching();
    this.stopServiceGoalTime();
    this.stopServiceBeindependant();
    this.stopServiceAbletoSitfor();
    this.stopServiceReturnToWork();
  }

  startServiceJointMobilization() {
    this.serviceJointMobilization.start();
    this.listeningJointMobilization = true;
  }

  stopServiceJointMobilization() {
    this.serviceJointMobilization.stop();
    this.listeningJointMobilization = false;
  }

  onJointMobilizationChange(e) {
    this.serviceJointMobilization.jointMobilisation = e.target.value;
  }

  onJointMobilizationBlur() {
    this.stopServiceJointMobilization();
    this.stopServiceTreatmentPlan();
    this.stopServiceFrequencyandDuration();
    this.stopServiceFunctionalGoal3();
    this.stopServiceFunctionalGoal2();
    this.stopServiceFunctionalGoal1();
    this.stopServicePrognosis();
    this.stopServiceProblem2();
    this.stopServiceProblem1();
    this.stopServiceAmbulatiion();
    this.stopServiceMobility();
    this.stopServiceWeekness();
    this.stopServiceComments();
    this.stopServicePosture();
    this.stopServicePalpitation();
    this.stopServiceTreatmentArea();
    this.stopServicePatientGoal();
    this.stopServiceDiagnosisTesting();
    this.stopServicePainDecreasesWith();
    this.stopServicePainIncreasesWith();
    this.stopServiceMechanismOfInjury();
    this.stopServiceLivingEnvironment();

    this.stopServiceOccupation();
    this.stopServiceMedicalHistory();
    this.stopServiceSoftTissueMobilization();
    this.stopServiceStretching();
    this.stopServiceGoalTime();
    this.stopServiceBeindependant();
    this.stopServiceAbletoSitfor();
    this.stopServiceReturnToWork();
  }

  startServiceSoftTissueMobilization() {
    this.serviceSoftTissueMobilization.start();
    this.listeningSoftTissueMobilization = true;
  }

  stopServiceSoftTissueMobilization() {
    this.serviceSoftTissueMobilization.stop();
    this.listeningSoftTissueMobilization = false;
  }

  onSoftTissueMobilizationChange(e) {
    this.serviceSoftTissueMobilization.softTissue = e.target.value;
  }

  onSoftTissueMobilizationBlur() {
    this.stopServiceSoftTissueMobilization();
    this.stopServiceJointMobilization();
    this.stopServiceTreatmentPlan();
    this.stopServiceFrequencyandDuration();
    this.stopServiceFunctionalGoal3();
    this.stopServiceFunctionalGoal2();
    this.stopServiceFunctionalGoal1();
    this.stopServicePrognosis();
    this.stopServiceProblem2();
    this.stopServiceProblem1();
    this.stopServiceAmbulatiion();
    this.stopServiceMobility();
    this.stopServiceWeekness();
    this.stopServiceComments();
    this.stopServicePosture();
    this.stopServicePalpitation();
    this.stopServiceTreatmentArea();
    this.stopServicePatientGoal();
    this.stopServiceDiagnosisTesting();
    this.stopServicePainDecreasesWith();
    this.stopServicePainIncreasesWith();
    this.stopServiceMechanismOfInjury();
    this.stopServiceLivingEnvironment();

    this.stopServiceOccupation();
    this.stopServiceMedicalHistory();
    this.stopServiceStretching();
    this.stopServiceGoalTime();
    this.stopServiceBeindependant();
    this.stopServiceAbletoSitfor();
    this.stopServiceReturnToWork();
  }

  startServiceStretching() {
    this.serviceStretching.start();
    this.listeningStretching = true;
  }

  stopServiceStretching() {
    this.serviceStretching.stop();
    this.listeningStretching = false;
  }

  onStretchingChange(e) {
    this.serviceStretching.streching = e.target.value;
  }

  onStretchingBlur() {
    this.stopServiceStretching();
    this.stopServiceSoftTissueMobilization();
    this.stopServiceJointMobilization();
    this.stopServiceTreatmentPlan();
    this.stopServiceFrequencyandDuration();
    this.stopServiceFunctionalGoal3();
    this.stopServiceFunctionalGoal2();
    this.stopServiceFunctionalGoal1();
    this.stopServicePrognosis();
    this.stopServiceProblem2();
    this.stopServiceProblem1();
    this.stopServiceAmbulatiion();
    this.stopServiceMobility();
    this.stopServiceWeekness();
    this.stopServiceComments();
    this.stopServicePosture();
    this.stopServicePalpitation();
    this.stopServiceTreatmentArea();
    this.stopServicePatientGoal();
    this.stopServiceDiagnosisTesting();
    this.stopServicePainDecreasesWith();
    this.stopServicePainIncreasesWith();
    this.stopServiceMechanismOfInjury();
    this.stopServiceLivingEnvironment();

    this.stopServiceOccupation();
    this.stopServiceMedicalHistory();
    this.stopServiceGoalTime();
    this.stopServiceBeindependant();
    this.stopServiceAbletoSitfor();
    this.stopServiceReturnToWork();
  }

  startServiceGoalTime() {
    this.serviceGoalTime.start();
    this.listeningGoalTime = true;
  }

  stopServiceGoalTime() {
    this.serviceGoalTime.stop();
    this.listeningGoalTime = false;
  }

  onGoalTimeChange(e) {
    this.serviceGoalTime.goaltime = e.target.value;
  }

  onGoalTimeBlur() {
    this.stopServiceGoalTime();
    this.stopServiceStretching();
    this.stopServiceSoftTissueMobilization();
    this.stopServiceJointMobilization();
    this.stopServiceTreatmentPlan();
    this.stopServiceFrequencyandDuration();
    this.stopServiceFunctionalGoal3();
    this.stopServiceFunctionalGoal2();
    this.stopServiceFunctionalGoal1();
    this.stopServicePrognosis();
    this.stopServiceProblem2();
    this.stopServiceProblem1();
    this.stopServiceAmbulatiion();
    this.stopServiceMobility();
    this.stopServiceWeekness();
    this.stopServiceComments();
    this.stopServicePosture();
    this.stopServicePalpitation();
    this.stopServiceTreatmentArea();
    this.stopServicePatientGoal();
    this.stopServiceDiagnosisTesting();
    this.stopServicePainDecreasesWith();
    this.stopServicePainIncreasesWith();
    this.stopServiceMechanismOfInjury();
    this.stopServiceLivingEnvironment();

    this.stopServiceOccupation();
    this.stopServiceMedicalHistory();
    this.stopServiceBeindependant();
    this.stopServiceAbletoSitfor();
    this.stopServiceReturnToWork();
  }

  startServiceBeindependant() {
    this.serviceBeindependant.start();
    this.listeningBeindependant = true;
  }

  stopServiceBeindependant() {
    this.serviceBeindependant.stop();
    this.listeningBeindependant = false;
  }

  onBeindependantChange(e) {
    this.serviceBeindependant.textMessage1 = e.target.value;
  }

  onBeindependantBlur() {
    this.stopServiceGoalTime();
    this.stopServiceStretching();
    this.stopServiceSoftTissueMobilization();
    this.stopServiceJointMobilization();
    this.stopServiceTreatmentPlan();
    this.stopServiceFrequencyandDuration();
    this.stopServiceFunctionalGoal3();
    this.stopServiceFunctionalGoal2();
    this.stopServiceFunctionalGoal1();
    this.stopServicePrognosis();
    this.stopServiceProblem2();
    this.stopServiceProblem1();
    this.stopServiceAmbulatiion();
    this.stopServiceMobility();
    this.stopServiceWeekness();
    this.stopServiceComments();
    this.stopServicePosture();
    this.stopServicePalpitation();
    this.stopServiceTreatmentArea();
    this.stopServicePatientGoal();
    this.stopServiceDiagnosisTesting();
    this.stopServicePainDecreasesWith();
    this.stopServicePainIncreasesWith();
    this.stopServiceMechanismOfInjury();
    this.stopServiceLivingEnvironment();

    this.stopServiceOccupation();
    this.stopServiceMedicalHistory();
    this.stopServiceBeindependant();
    this.stopServiceAbletoSitfor();
    this.stopServiceReturnToWork();
  }

  startServiceAbletoSitfor() {
    this.serviceAbletoSitfor.start();
    this.listeningAbletoSitfor = true;
  }

  stopServiceAbletoSitfor() {
    this.serviceAbletoSitfor.stop();
    this.listeningAbletoSitfor = false;
  }

  onAbletoSitforChange(e) {
    this.serviceAbletoSitfor.abletositFor = e.target.value;
  }

  onAbletoSitforBlur() {
    this.stopServiceAbletoSitfor();
    this.stopServiceGoalTime();
    this.stopServiceStretching();
    this.stopServiceSoftTissueMobilization();
    this.stopServiceJointMobilization();
    this.stopServiceTreatmentPlan();
    this.stopServiceFrequencyandDuration();
    this.stopServiceFunctionalGoal3();
    this.stopServiceFunctionalGoal2();
    this.stopServiceFunctionalGoal1();
    this.stopServicePrognosis();
    this.stopServiceProblem2();
    this.stopServiceProblem1();
    this.stopServiceAmbulatiion();
    this.stopServiceMobility();
    this.stopServiceWeekness();
    this.stopServiceComments();
    this.stopServicePosture();
    this.stopServicePalpitation();
    this.stopServiceTreatmentArea();
    this.stopServicePatientGoal();
    this.stopServiceDiagnosisTesting();
    this.stopServicePainDecreasesWith();
    this.stopServicePainIncreasesWith();
    this.stopServiceMechanismOfInjury();
    this.stopServiceLivingEnvironment();

    this.stopServiceOccupation();
    this.stopServiceMedicalHistory();
    this.stopServiceBeindependant();
    this.stopServiceReturnToWork();
  }

  startServiceReturnToWork() {
    this.serviceReturnToWork.start();
    this.listeningReturnToWork = true;
  }

  stopServiceReturnToWork() {
    this.serviceReturnToWork.stop();
    this.listeningReturnToWork = false;
  }

  onReturnToWorkChange(e) {
    this.serviceReturnToWork.returnTowrok = e.target.value;
  }

  onReturnToWorkBlur() {
    this.stopServiceReturnToWork();
    this.stopServiceAbletoSitfor();
    this.stopServiceGoalTime();
    this.stopServiceStretching();
    this.stopServiceSoftTissueMobilization();
    this.stopServiceJointMobilization();
    this.stopServiceTreatmentPlan();
    this.stopServiceFrequencyandDuration();
    this.stopServiceFunctionalGoal3();
    this.stopServiceFunctionalGoal2();
    this.stopServiceFunctionalGoal1();
    this.stopServicePrognosis();
    this.stopServiceProblem2();
    this.stopServiceProblem1();
    this.stopServiceAmbulatiion();
    this.stopServiceMobility();
    this.stopServiceWeekness();
    this.stopServiceComments();
    this.stopServicePosture();
    this.stopServicePalpitation();
    this.stopServiceTreatmentArea();
    this.stopServicePatientGoal();
    this.stopServiceDiagnosisTesting();
    this.stopServicePainDecreasesWith();
    this.stopServicePainIncreasesWith();
    this.stopServiceMechanismOfInjury();
    this.stopServiceLivingEnvironment();

    this.stopServiceOccupation();
    this.stopServiceMedicalHistory();
    this.stopServiceBeindependant();
  }
  stopAll() {
    this.stopServiceReturnToWork();
    this.stopServiceAbletoSitfor();
    this.stopServiceGoalTime();
    this.stopServiceStretching();
    this.stopServiceSoftTissueMobilization();
    this.stopServiceJointMobilization();
    this.stopServiceTreatmentPlan();
    this.stopServiceFrequencyandDuration();
    this.stopServiceFunctionalGoal3();
    this.stopServiceFunctionalGoal2();
    this.stopServiceFunctionalGoal1();
    this.stopServicePrognosis();
    this.stopServiceProblem2();
    this.stopServiceProblem1();
    this.stopServiceAmbulatiion();
    this.stopServiceMobility();
    this.stopServiceWeekness();
    this.stopServiceComments();
    this.stopServicePosture();
    this.stopServicePalpitation();
    this.stopServiceTreatmentArea();
    this.stopServicePatientGoal();
    this.stopServiceDiagnosisTesting();
    this.stopServicePainDecreasesWith();
    this.stopServicePainIncreasesWith();
    this.stopServiceMechanismOfInjury();
    this.stopServiceLivingEnvironment();

    this.stopServiceOccupation();
    this.stopServiceMedicalHistory();
    this.stopServiceBeindependant();
  }

  formSubmit() {
    this.ptInitalEvaluationService
      .registerPtInitalEvaluation(this.Frm.value)
      .subscribe((res) => {
        this.isSuccess = true;
        this.Frm.reset();
      });
  }
}
