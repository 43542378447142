import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/internal/operators/catchError';
import { environment } from 'src/environments/environment';
import { User } from '../Models/user.model';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  url = environment.Url + 'Users';
  userHospital = environment.Url + 'Users/hospital';
  addHospitalUrl = environment.Url + 'Users/createHospital';

  addPatientUrl = environment.Url + 'Users/createPatient';
  addDoctorUrl = environment.Url + 'Users/createDoctor';
  addNurseUrl = environment.Url + 'Users/createNurse';
  addlabManagerUrl = environment.Url + 'Users/createLabManager';
  addlPharmaUrl = environment.Url + 'Users/createPharmacyMember';
  addlRadioUrl = environment.Url + 'Users/createRadiolgyMember';
  addPTDoctor = environment.Url + 'Users/createPhysicalTherapyDoctor';
  resetPassword = environment.Url + 'Users/ResetPassword';

  forgotPassUrl = environment.Url + 'Users/ForgotPassword';

  twoFactorAuthentication = environment.Url + 'Users/TwoFactorAuth';
  iveriFy = environment.Url + 'Users/veriFy';
  // forgotPassUrl = 'http://localhost:46740/api/Users/ForgotPassword';

  constructor(private http: HttpClient) {}

  TwoFa(body) {
    return this.http
      .post(this.twoFactorAuthentication, body)
      .pipe(catchError(this.handleError));
  }
  veriFy(body) {
    return this.http
      .post(this.iveriFy, body)
      .pipe(catchError(this.handleError));
  }

  registerHospital(body) {
    return this.http.post<User>(this.addHospitalUrl, body);
  }

  registerCompanyAdmin(body) {
    return this.http.post<User>(this.url, body);
  }
  registerUser(body) {
    return this.http.post<User>(this.addPatientUrl, body);
  }
  registerDoctor(body) {
    return this.http.post<User>(this.addDoctorUrl, body);
  }
  registerNurse(body) {
    return this.http.post<User>(this.addNurseUrl, body);
  }
  registerLabManager(body) {
    return this.http.post<User>(this.addlabManagerUrl, body);
  }
  registerRadiologyMember(body) {
    return this.http.post<User>(this.addlRadioUrl, body);
  }
  registerPharmacyMember(body) {
    return this.http.post<User>(this.addlPharmaUrl, body);
  }
  registerPTDoctor(body) {
    return this.http.post<User>(this.addPTDoctor, body);
  }

  updateUser(id, body) {
    return this.http.put<User>(this.url + '/' + id, body);
  }
  getUsersById(id) {
    return this.http.get<User>(this.url + '/' + id);
  }
  getUserHospital(id) {
    return this.http.get<User>(this.userHospital + '/' + id);
  }

  getAllUsers() {
    return this.http.get<User[]>(this.url);
  }

  forgotPassword(body) {
    return this.http
      .post(this.forgotPassUrl, body)
      .pipe(catchError(this.handleError));
  }

  ResetPassword(body) {
    return this.http
      .post<User>(this.url + '/ResetPassword', body)
      .pipe(catchError(this.handleError));
  }

  deleteUser(id) {
    return this.http
      .delete<User>(this.url + '/' + id)
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      if (error.status == 400) {
        console.log(error.error);
        return throwError(error.error);
      } else if (error.status == 401) {
        return throwError('invalid username or password');
      } else if (error.status == 0) {
        return throwError('network failure please try again later ');
      }
      return throwError(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }
}
