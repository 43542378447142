

<div class="container">
    <div class="row">
        <div class="col-lg regFrm mat-elevation-z8">
            <h1>New Time Slots</h1>
            <form [formGroup]="timFrm" (ngSubmit)="formSubmit()" class="example-form">
                <mat-form-field class="example-full-width">
                    <mat-label>Doctor Name</mat-label>
                    <!-- <input type="text" formControlName="DoctorName" matInput >
                    <mat-error >Please enter Doctor Name</mat-error> -->

                    <mat-select formControlName="DoctorId" (selectionChange)="getDoctorDates()">

                      <mat-option *ngFor="let doctor of doctorList" [value]="doctor.DoctorId">{{doctor.DoctorName}}</mat-option>
          
                    </mat-select>
                    <mat-error >Please select doctor</mat-error>

                  </mat-form-field>
                <mat-form-field class="example-full-width">
                    <mat-label>Available Date</mat-label>
                    <!-- <input type="text" formControlName="DoctorName" matInput >
                    <mat-error >Please enter Doctor Name</mat-error> -->

                      <mat-select formControlName="DoctorDateId"  >
                      <mat-option *ngFor="let dt of DoctorAvalableDatList" [value]="dt.DoctorDateId">{{dt.AvailableDate}}</mat-option>
          
                    </mat-select>
                    <mat-error >Please select doctor</mat-error>

                  </mat-form-field>
                <mat-form-field class="example-full-width">
                    <mat-label> Time Slots </mat-label> 
                    <input [ngxTimepicker]="picker" matInput formControlName="TimeSlots">
<ngx-material-timepicker #picker></ngx-material-timepicker>
                    <mat-error >Please enter Available TimeSlots</mat-error>
                  </mat-form-field>
                

                 

                <button [disabled]="timFrm.invalid"  class="float-right lgBtCl" mat-button >
                    <mat-icon>save</mat-icon>
                    </button>
              </form>
              <div *ngIf="isSuccess"  class="alert alert-success alert-dismissible fade show mt-5" role="alert">
                Successfully submitted
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
        </div>
    </div>
</div>
