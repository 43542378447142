<p>
  <mat-toolbar class="lgBtCl mat-elevation-z6">
    <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon"
      [matMenuTriggerFor]="crt">
      <mat-icon>menu</mat-icon>


    </button>
    <span>Binsera PT</span>
    <mat-menu class="ItemS mx-5" #crt="matMenu">
      <!-- <div class="ItemS">
        <mat-icon mat-list-icon>search</mat-icon>
        <a routerLink="/scanner">Scanner</a>
      </div> -->
    </mat-menu>

    <span class="example-spacer"></span>
    <button mat-icon-button class="example-icon favorite-icon" aria-label="Example icon-button with heart icon">
      <mat-icon>favorite</mat-icon>
    </button>
    <button mat-icon-button class="example-icon" aria-label="Example icon-button with share icon">
      <mat-icon>share</mat-icon>
    </button>

  </mat-toolbar>
</p>