import { Component } from '@angular/core';

@Component({
  selector: 'app-dry-needling-consent',
  templateUrl: './dry-needling-consent.component.html',
  styleUrls: ['./dry-needling-consent.component.css']
})
export class DryNeedlingConsentComponent {

}
