import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Department } from 'src/app/Models/department.model';
import { Doctor } from 'src/app/Models/doctor.model';
import { Medicine } from 'src/app/Models/medicine.model';
import { PhysicalTherapyDoctor } from 'src/app/Models/physicaltherapydoctor.model';
import { DepartmentService } from 'src/app/services/department.service';
import { DischargeSummaryService } from 'src/app/services/discharge-summary.service';
import { DoctorService } from 'src/app/services/doctor.service';
import { MedicineService } from 'src/app/services/medicine.service';
import { PhysicalTherapyDoctorService } from 'src/app/services/physical-therapy-doctor.service';

@Component({
  selector: 'app-add-discharge-summary',
  templateUrl: './add-discharge-summary.component.html',
  styleUrls: ['./add-discharge-summary.component.css'],
})
export class AddDischargeSummaryComponent implements OnInit {
  id = 0;
  medicineList: Medicine[];

  isSuccess = false;

  Frm: FormGroup;
  doctorList: PhysicalTherapyDoctor[];
  dpartmentList: Department[];
  doctorSpeciality: string[] = [
    'Physical Therapy'
  ];


  constructor(private fb: UntypedFormBuilder, private route: ActivatedRoute,private medicineService:MedicineService,
    private departmentService:DepartmentService,private doctorService:PhysicalTherapyDoctorService,private dischargeService:DischargeSummaryService) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });

    this.Frm = this.fb.group({
      PatientId: this.id,
      Complaints: [''],
      Diagnosis: [''],
      Treatments: [''],
      FutureTreatmentPlan: [''],
      Description: [''],
      Signature: [''],

      AttendedDoctors: this.fb.array([]),
    });

    this.getMedicine();
    this.getDoctors();
    this.addAttendedDoctors();
    this.getDepartments();
  }



  public addAttendedDoctors(): void {
    const doctor = this.fb.group({
      DoctorName: [''],
      DoctorSpeciality: [''],
      DepartmentName: [''],
    });
    this.AttendedDoctors.push(doctor);
  }

  public removeAttendedDoctors(i: number): void {
    this.AttendedDoctors.removeAt(i);
  }


  get Complaints() {
    return this.Frm.get('Complaints');
  }
  get Diagnosis() {
    return this.Frm.get('Diagnosis');
  }
  get Treatments() {
    return this.Frm.get('Treatments');
  }
  get FutureTreatmentPlan() {
    return this.Frm.get('FutureTreatmentPlan');
  }
  get Description() {
    return this.Frm.get('Description');
  }

  get DoctorSpeciality() {
    return this.Frm.get('DoctorSpeciality');
  }
  get DoctorName() {
    return this.Frm.get('DoctorName');
  }
  get DepartmentName() {
    return this.Frm.get('DepartmentName');
  }



  get AttendedDoctors(): UntypedFormArray {
    return this.Frm.get('AttendedDoctors') as UntypedFormArray;
  }



  get Quantity() {
    return this.Frm.get('Quantity');
  }

 

  get Signature() {
    return this.Frm.get('Signature');
  }



  getMedicine(){
    this.medicineService.getMedicines().subscribe(res=>{
      this.medicineList = res;
    })
  }

  getDoctors(){
    this.doctorService.getPhysicalTherapyDoctors().subscribe(res=>{
      this.doctorList = res
    })
  }
  getDepartments(){
    this.departmentService.getDepartments().subscribe(res=>{
      this.dpartmentList = res
    })
  }

  formSubmit() {

this.dischargeService.registerDischargeSummary(this.Frm.value).subscribe(res=>{
  this.Frm.reset();
  this.isSuccess=true;
})
  }
}
