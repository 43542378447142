import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Hospital } from 'src/app/Models/hospital.model';
import { OfficePolicy } from 'src/app/Models/officepolicy.model';
import { OfficePolicyService } from 'src/app/services/office-policy.service';
import { PatientService } from 'src/app/services/patient.service';

@Component({
  selector: 'app-patient-view-office-policy',
  templateUrl: './patient-view-office-policy.component.html',
  styleUrls: ['./patient-view-office-policy.component.css'],
})
export class PatientViewOfficePolicyComponent implements OnInit {
  id = 0;
  officePolicyList: OfficePolicy[] = [];
  hospital: Hospital;
  constructor(
    private route: ActivatedRoute,
    private patientService: PatientService,
    private officePolicyService: OfficePolicyService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });

    this.getOfficePolicy(this.id);
    this.patientService.getpatienHospitalId(this.id).subscribe((res) => {
      this.hospital = res.Hospital;
    });
  }

  getOfficePolicy(id) {
    this.patientService.GetOfficePolicyConsent(id).subscribe((res) => {
      this.officePolicyList = res.OfficePolicys;
    });
  }

  print() {
    window.print();
  }

  delete(id) {
    this.officePolicyService.deleteOfficePolicy(id).subscribe((res) => {
      this.getOfficePolicy(this.id);
    });
  }
}
