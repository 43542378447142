import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'safeHtml',
})
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(html: any, styleSelector: any, styleValue: any): SafeHtml {
    const style = ` style = "${styleSelector}: ${styleValue};"`;
    const indexPosition = html.indexOf('>');
    const newHtml = [
      html.slice(0, indexPosition),
      style,
      html.slice(indexPosition),
    ].join('');
    return this.sanitizer.bypassSecurityTrustHtml(newHtml);
  }
}
