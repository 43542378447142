import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConsentForService } from 'src/app/Models/consentforservice.model';
import { Hospital } from 'src/app/Models/hospital.model';
import { OfficePolicy } from 'src/app/Models/officepolicy.model';
import { ConsentForServiceService } from 'src/app/services/consent-for-service.service';
import { PatientService } from 'src/app/services/patient.service';

@Component({
  selector: 'app-view-consent-for-service',
  templateUrl: './view-consent-for-service.component.html',
  styleUrls: ['./view-consent-for-service.component.css'],
})
export class ViewConsentForServiceComponent implements OnInit {
  id = 0;
  consentForServiceList: ConsentForService[] = [];
  hospital: Hospital;
  constructor(
    private route: ActivatedRoute,
    private patientService: PatientService,
    private consentForServiceService: ConsentForServiceService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
    this.patientService.getpatienHospitalId(this.id).subscribe((res) => {
      this.hospital = res.Hospital;
    });
    this.getConsentForservice(this.id);
  }

  getConsentForservice(id) {
    this.patientService.GetConsentForService(id).subscribe((res) => {
      this.consentForServiceList = res.ConsentForServices;
    });
  }

  print() {
    window.print();
  }

  delete(id) {
    this.consentForServiceService
      .deleteConsentForService(id)
      .subscribe((res) => {
        this.getConsentForservice(this.id);
      });
  }
}
