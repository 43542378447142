import { Platform } from '@angular/cdk/platform';
import { Component, ElementRef, HostListener, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DoctornoteService } from 'src/app/services/doctornote.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-draw-pad',
  templateUrl: './draw-pad.component.html',
  styleUrls: ['./draw-pad.component.css']
})
export class DrawPadComponent implements OnInit {
  id=0;
  @ViewChild('undo', { static: true })
  public undoBtn!: ElementRef<HTMLButtonElement>;
  @ViewChild('redo', { static: true })
  public redoBtn!: ElementRef<HTMLButtonElement>;
  @ViewChild('pen', { static: true })
  public penBtn!: ElementRef<HTMLButtonElement>;
  @ViewChild('eraser', { static: true })
  public erasorBtn!: ElementRef<HTMLButtonElement>;
  @ViewChild('editor', { static: true })
  public editor!: ElementRef<HTMLDivElement>;
  isEraserActive = false;
  isPenActive = true;

  constructor(private plt:Platform,private route: ActivatedRoute,  private cd: ChangeDetectorRef,
    private router: Router,private doctornoteService:DoctornoteService,private http: HttpClient) { }
    ngOnInit(): void {

      this.route.params.subscribe(res=>{
        this.id= res['id']
        // console.log(this.id);
      })
      this.initializeBrush();
    }
    ngAfterViewInit(): void {
      this.intializeCanvas();
      this.addEventListeners();
    }


    @ViewChild('canvas', { static: false }) canvas: ElementRef;
    ctx: CanvasRenderingContext2D;
    brush: any;
    strokes: any[] = [];
    tempStrokesArray: any[] = [];
    currentStroke: any = {};
    currentStrokeIndex: number = 0;
    previousStrokeIndex: number = 0;
    previousStrokeLength: number = 0;
    resultsArray: any[] = [];
    hasUndo: boolean = false;
    undoStrokesArray: any[] = [];
    backwardCount: number = 0;
    drawPadTab = false;
    textTab = true;
    ExportedData = '';
  
    getText() {
  
       
  
        let body={
          "DoctorNoteImg":this.ExportedData,
          "PatientId":this.id
        }
        this.doctornoteService.registerDoctorNote(body).subscribe(res=>{
          this.router.navigate(['/doctorDash/'])
        })
    }  

    private initializeBrush(): void {
      const brush = {
        x: 0,
        y: 0,
        color: '#000000',
        size: 3,
        down: false,
        eraser: false,
      };
      this.brush = brush;
    }
  
    /**
     *  Initialize canvas
     */
    private intializeCanvas(): void {
      this.ctx = this.canvas.nativeElement.getContext('2d');
      this.canvas.nativeElement.width = 900;
      this.canvas.nativeElement.height = 750;
  
      const mouseEvent = (e) => {
        if (!e.touches) {
          this.brush.x = e.pageX - this.canvas.nativeElement.offsetLeft;
          this.brush.y = e.pageY - this.canvas.nativeElement.offsetTop;
        } else {
          this.brush.x = e.touches[0]?.pageX - this.canvas.nativeElement.offsetLeft;
          this.brush.y = e.touches[0]?.pageY - this.canvas.nativeElement.offsetTop;
        }
  
  
        this.currentStroke.points.push({
          x: this.brush.x,
          y: this.brush.y,
        });
  
        this.redraw();
      };
  
      ['touchstart', 'mousedown'].forEach(event => {
        this.canvas.nativeElement.addEventListener(event, (e) => {
          e.preventDefault();
          this.brush.down = true;
          this.currentStroke = {
            color: this.brush.color,
            size: this.brush.size,
            points: [],
            eraser: this.brush.eraser,
          };
          this.strokes.push(this.currentStroke);
  
          mouseEvent(e);
        });
      });
  
      ['touchend', 'mouseup'].forEach(event => {
        this.canvas.nativeElement.addEventListener(event, (e) => {
          this.brush.down = false;
          mouseEvent(e);
          this.currentStroke = null;
        });
      });
  
      ['touchmove', 'mousemove'].forEach(event => {
        this.canvas.nativeElement.addEventListener(event, (e) => {
          e.preventDefault();
          if (this.brush.down) mouseEvent(e);
        });
      })
  
  
  
  
  
  
    }
    /**
     * Add event listeners for colour and tool buttons
     */
    private addEventListeners(): void {
      const colours =
        document.querySelectorAll<HTMLDivElement>('.colours .colour');
      colours.forEach((singleColour) => {
        singleColour.addEventListener('click', () => {
          this.brush.size = 3;
          this.brush.color = singleColour.getAttribute('colour')!;
        });
      });
  
      const tools = document.querySelectorAll<HTMLDivElement>('.tools .tool');
      tools.forEach((tool) => {
        tool.addEventListener('click', () => {
          switch (tool.getAttribute('tool')) {
            case 'pencil':
              this.brush.size = 3;
              this.brush.eraser = false;
              this.brush.color = 'black';
              break;
            case 'clear':
              this.ctx.clearRect(
                0,
                0,
                this.canvas.nativeElement.width,
                this.canvas.nativeElement.height
              );
              this.brush.size = 3;
              this.brush.color = 'black';
              localStorage.removeItem('canvas_strokes');
              this.clearCanvas();
              this.brush.eraser = false;
              break;
            case 'eraser':
              this.brush.size = 20;
              this.brush.color = 'white';
              this.brush.eraser = true;
              break;
            default:
              this.brush.size = 3;
              this.brush.color = 'black';
              this.brush.eraser = false;
          }
        });
      });
    }
  
    /**
     * Draw strokes
     */
    private redraw(): void {
      this.ctx.clearRect(
        0,
        0,
        this.canvas.nativeElement.width,
        this.canvas.nativeElement.height
      );
      this.ctx.lineCap = 'round';
      for (let i = 0; i < this.strokes.length; i++) {
        const s = this.strokes[i];
        this.ctx.strokeStyle = s.color;
        this.ctx.lineWidth = s.size;
        this.ctx.beginPath();
        this.ctx.moveTo(s.points[0].x, s.points[0].y);
        for (let j = 0; j < s.points.length; j++) {
          const p = s.points[j];
          this.ctx.lineTo(p.x, p.y);
        }
        this.ctx.stroke();
      }
    }
  
    /**
     * Change color of brush
     * @param event Event
     */
    private changeColor(event: Event): void {
      this.brush.color = (event.target as HTMLInputElement).value;
    }
  
    /**
     * Change size of brush
     * @param event Event
     */
    private changeBrushSize(event: Event): void {
      this.brush.size = +(event.target as HTMLInputElement).value;
    }
  
    /**
     * Clear canvas
     */
    private clearCanvas(): void {
      this.previousStrokeLength = 0;
      this.currentStrokeIndex = 0;
      this.tempStrokesArray = [];
      this.resultsArray = [];
      this.strokes = [];
      this.redraw();
    }
  
    /**
     * Selects the text from suggestion and add it to the top of the array
     * @param item Text array like ['hello', 'world', 'how', 'are', 'you']
     * @param index index of selected text
     */
    public getSuggestion(item: any, index: number): void {
      let text = [];
      const deletedElement = item.splice(index, 1)[0];
      item.unshift(deletedElement);
      this.resultsArray.forEach((texts: any) => {
        text.push(texts[0]);
        this.ExportedData = text.join(' ');
      });
    }
  
    public removeSuggestion(index: number): void {
      let text = [];
      this.resultsArray.splice(index, 1);
      this.resultsArray.forEach((texts: any) => {
        text.push(texts[0]);
        this.ExportedData = text.join(' ');
      });
    }
  
    /**
     * Calls the server with current strokes and returns the result
     */
    recognizeText() {
      let x = [];
      this.tempStrokesArray.push([...this.strokes]);
      x = this.tempStrokesArray[this.currentStrokeIndex]
        .filter((s) => s.color != 'white')
        .map((stroke) => stroke.points);
  
      const notErased = this.tempStrokesArray[this.currentStrokeIndex]
        .filter((s) => !s.eraser)
        .map((s) => s.points)
        .slice(this.previousStrokeLength);
      const erased = this.tempStrokesArray[this.currentStrokeIndex]
        .filter((s) => s.eraser)
        .map((s) => s.points);
      if (notErased.length) {
        for (let i = 0; i < notErased.length; i++) {
          for (let ie = 0; ie < notErased[i].length; ie++) {
            const notErasedCoord = notErased[i][ie];
            for (let j = 0; j < erased.length; j++) {
              for (let je = 0; je < erased[j].length; je++) {
                const erasedCoord = erased[j][je];
                if (
                  (notErasedCoord.x === erasedCoord.x &&
                    Math.abs(notErasedCoord.y - erasedCoord.y) <= 2) ||
                  (notErasedCoord.y === erasedCoord.y &&
                    Math.abs(notErasedCoord.x - erasedCoord.x) <= 2)
                ) {
                  notErased.splice(i, 1);
                  i--;
                  break;
                }
              }
            }
          }
        }
      }
      console.log('written coords length after', notErased.length);
  
      let strokes = notErased.map((p: any) => {
        let x = p.map((point: any) => point.x);
        let y = p.map((point: any) => point.y);
        return [x, y];
      });
      const payload = {
        options: 'enable_pre_space',
        requests: [
          {
            writing_guide: {
              writing_area_width: 900,
              writing_area_height: 750,
            },
            ink: strokes,
            language: 'en',
          },
        ],
      };
  
      if (strokes.length) {
        this.http
          .post(
            'https://www.google.com.tw/inputtools/request?ime=handwriting&app=mobilesearch&cs=1&oe=UTF-8',
            payload
          )
          .subscribe((res) => {
            const index = this.hasUndo
              ? this.currentStrokeIndex - 1
              : this.currentStrokeIndex;
            this.resultsArray[index] = res[1][0][1].splice(0, 5);
            this.resultsArray = this.resultsArray.filter(
              (result) => result.length > 0
            );
            this.currentStrokeIndex = this.currentStrokeIndex + 1;
            this.previousStrokeLength = this.strokes.length;
            this.backwardCount = 0;
            strokes = [];
            this.cd.detectChanges();
            this.hasUndo = false;
            let text = [];
            this.resultsArray.forEach((texts: any) => {
              text.push(texts[0]);
              this.ExportedData = text.join(' ');
            });
          });
      } else {
        this.currentStrokeIndex = this.currentStrokeIndex + 1;
      }
    }
  
    undo(): void {
      this.hasUndo = true;
      this.strokes.pop();
      this.previousStrokeLength =
        this.currentStrokeIndex === 1
          ? 0
          : this.strokes.length -
            this.resultsArray[this.currentStrokeIndex - 2].length;
      this.backwardCount = this.backwardCount + 1;
      this.redraw();
    }
  
    startDrawpad() {
      this.textTab = false;
      this.drawPadTab = true;
      // this.intializeCanvas();
      // this.addEventListeners();
    }
  }
