import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DryNeedlingConsent } from 'src/app/Models/dryneedlingconsent.model';
import { Hospital } from 'src/app/Models/hospital.model';
import { DryNeedlingConsentService } from 'src/app/services/dry-needling-consent.service';
import { PatientService } from 'src/app/services/patient.service';

@Component({
  selector: 'app-patient-view-dry-needling-consent',
  templateUrl: './patient-view-dry-needling-consent.component.html',
  styleUrls: ['./patient-view-dry-needling-consent.component.css'],
})
export class PatientViewDryNeedlingConsentComponent implements OnInit {
  id = 0;
  dryNeedlingConsentList: DryNeedlingConsent[] = [];
  hospital: Hospital;
  constructor(
    private route: ActivatedRoute,
    private patientService: PatientService,
    private dryNeedlingConsentService: DryNeedlingConsentService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
    this.patientService.getpatienHospitalId(this.id).subscribe((res) => {
      this.hospital = res.Hospital;
    });
    this.getDryNeedlingConsent(this.id);
  }

  getDryNeedlingConsent(id) {
    this.patientService.GetDryNeedlingConsent(id).subscribe((res) => {
      this.dryNeedlingConsentList = res.DryNeedlingConsents;
    });
  }

  print() {
    window.print();
  }

  delete(id) {
    this.dryNeedlingConsentService
      .deleteDryNeedlingConsent(id)
      .subscribe((res) => {
        this.getDryNeedlingConsent(this.id);
      });
  }
}
