import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import * as moment from 'moment';
import { Doctor } from 'src/app/Models/doctor.model';
import { PhysicalTherapyDoctor } from 'src/app/Models/physicaltherapydoctor.model';
import { DoctorService } from 'src/app/services/doctor.service';
import { HospitalService } from 'src/app/services/hospital.service';
import { PatientService } from 'src/app/services/patient.service';
import { RefferalService } from 'src/app/services/refferal.service';

@Component({
  selector: 'app-add-refferal',
  templateUrl: './add-refferal.component.html',
  styleUrls: ['./add-refferal.component.css'],
})
export class AddRefferalComponent implements OnInit {
  id = 0;
  isSuccess = false;
  doctorList: PhysicalTherapyDoctor[];
  helper = new JwtHelperService();
  myToken = window.localStorage.getItem('token');
  hospitalId = 0;
  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private doctorService: DoctorService,
    private referalService: RefferalService,
    private hospitalService: HospitalService,
    private patientService: PatientService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
    this.patientService.getpatienHospitalId(this.id).subscribe((res) => {
      this.hospitalId = res.Hospital.HospitalId;
      this.getDoctors(this.hospitalId);
    });

    this.getDoctors(this.hospitalId);
  }

  labFrm = this.fb.group({
    RefferingDoctorName: ['', Validators.required],
    RefferedToDoctorName: ['', Validators.required],
    Subject: ['', Validators.required],
    Description: ['', Validators.required],
    Date: ['', Validators.required],
    Email: ['', Validators.required],
    Phone: ['', Validators.required],
    Address: ['', Validators.required],
  });

  get RefferedToDoctorName() {
    return this.labFrm.get('RefferedToDoctorName');
  }

  get RefferingDoctorName() {
    return this.labFrm.get('RefferingDoctorName');
  }
  get Address() {
    return this.labFrm.get('Address');
  }
  get Phone() {
    return this.labFrm.get('Phone');
  }
  get Email() {
    return this.labFrm.get('Email');
  }
  get Subject() {
    return this.labFrm.get('Subject');
  }
  get Description() {
    return this.labFrm.get('Description');
  }
  get Date() {
    return this.labFrm.get('Date');
  }

  getDoctors(id) {
    this.hospitalService.getDoctors(id).subscribe((res) => {
      this.doctorList = res.PhysicalTherapyDoctors;
    });
  }

  formSubmit() {
    let refferedToDoctorName = this.labFrm.value['RefferedToDoctorName'];
    let refferingDoctorName = this.labFrm.value['RefferingDoctorName'];
    let address = this.labFrm.value['Address'];
    let phone = this.labFrm.value['Phone'];
    let email = this.labFrm.value['Email'];
    let subject = this.labFrm.value['Subject'];
    let description = this.labFrm.value['Description'];
    let date = this.labFrm.value['Date'];
    date = moment(date).format('MM/DD/YYYY');

    let body = {
      PatientId: this.id,
      RefferingDoctorName: refferingDoctorName,
      RefferedToDoctorName: refferedToDoctorName,
      Subject: subject,
      Email: email,
      Phone: phone,
      Address: address,
      Description: description,
      Date: date,
    };

    this.referalService.registerRefferal(body).subscribe((res) => {
      this.isSuccess = true;
      this.labFrm.reset();
    });
  }
}
