import {
  HttpHeaders,
  HttpErrorResponse,
  HttpClient,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/internal/operators/catchError';
import { environment } from 'src/environments/environment';
import { Patient } from '../Models/patient.model';
import { EmailPayload } from '../Models/email.model';

@Injectable({
  providedIn: 'root',
})
export class PatientService {
  url = environment.Url + 'Patients';
  addPatientUrl = environment.Url + 'Users/createPatient';

  allergy = environment.Url + 'Patients/allergy';
  visits = environment.Url + 'Patients/visits';
  doctorNotes = environment.Url + 'Patients/doctorNotes';
  prescription = environment.Url + 'Patients/prescription';
  surgery = environment.Url + 'Patients/surgicalHistory';
  invoice = environment.Url + 'Patients/invoices';
  labReport = environment.Url + 'Patients/labReport';
  radiologyReport = environment.Url + 'Patients/radiologyReport';
  labOrder = environment.Url + 'Patients/labOrder';
  radiologyOrder = environment.Url + 'Patients/radiologyOrder';
  radiologyMRI = environment.Url + 'Patients/radiologyMRI';
  radiologyMRI_V1 = environment.Url + 'Patients/radiologyMRI_V1';

  emContact = environment.Url + 'Patients/emergencyContact';
  ptInsurance = environment.Url + 'Patients/patientInsurance';
  ptIdProof = environment.Url + 'Patients/patientIdProof';
  ptBooking = environment.Url + 'Patients/patientBooking';
  ptMedication = environment.Url + 'Patients/patientMedication';
  nurseReport = environment.Url + 'Patients/nurseReport';
  bradenScale = environment.Url + 'Patients/patientBradenScale';
  hesterDavies = environment.Url + 'Patients/patientHesterDavies';
  patientImport = environment.Url + 'Patients/patientImport';
  purposefulRounds = environment.Url + 'Patients/purposefulRounds';
  nursePatient = environment.Url + 'Patients/nursePatient';
  dischargeSummary = environment.Url + 'Patients/dischargeSummary';
  patientVaccines = environment.Url + 'Patients/patientVaccines';
  otherTask = environment.Url + 'Patients/otherTask';
  medicineGiven = environment.Url + 'Patients/medicineGiven';
  intakeAssessment = environment.Url + 'Patients/intakeAssessment';
  communicationAssessment =
    environment.Url + 'Patients/communicationAssessment';
  doctorReport = environment.Url + 'Patients/doctorReport';
  nurseNote = environment.Url + 'Patients/nurseNote';
  generalSaftey = environment.Url + 'Patients/generalSaftey';
  familyHistory = environment.Url + 'Patients/familyHistory';
  socialHistory = environment.Url + 'Patients/socialHistory';
  medicalHistory = environment.Url + 'Patients/medicalHistory';
  hippaCompliance = environment.Url + 'Patients/hippaCompliance';
  physicalTherapyVisits = environment.Url + 'Patients/physicalTherapyVisits';
  ptInitialEvaluations = environment.Url + 'Patients/ptInitialEvaluations';
  refferal = environment.Url + 'Patients/PatientRefferal';
  officePolicyConsent = environment.Url + 'Patients/OfficePolicyConsent';
  consentForService = environment.Url + 'Patients/consentForService';
  dryNeedlingConsent = environment.Url + 'Patients/dryNeedlingConsent';
  exersiceLiabilityWaiver =
    environment.Url + 'Patients/exersiceLiabilityWaiver';

  patienHospitalId = environment.Url + 'Patients/patienHospitalId';
  doctorId = environment.Url + 'Patients/doctorId';

  physicalTherapyBookings =
    environment.Url + 'Patients/physicalTherapyBookings';
  completePatientInformationUrl =
    environment.Url + 'Patients/completePatientForm';

  uploadedRadiologyReport =
    environment.Url + 'Patients/uploadedRadiologyReport';
  patientPayment = environment.Url + 'Patients/patientPayment';

  emailPresciption = environment.Url + 'Emails/generalEmail';
  emailInvoice = environment.Url + 'Emails/generalEmail';
  emailInsuarance = environment.Url + 'Emails/generalEmail';
  emailRefferal = environment.Url + 'Emails/refferalEmail';

  imgUrl = environment.Url + 'ImageUpload';

  header = new HttpHeaders({
    Authorization: 'token' + localStorage.getItem('token'),
  });
  constructor(private http: HttpClient) {}

  imageUpload(body) {
    return this.http.post(this.imgUrl, body);
  }

  sendPrescription(body) {
    return this.http
      .post<EmailPayload>(this.emailPresciption, body)
      .pipe(catchError(this.handleError));
  }
  sendInvoice(body) {
    return this.http
      .post<EmailPayload>(this.emailInvoice, body)
      .pipe(catchError(this.handleError));
  }
  PatientBulkImport(body) {
    return this.http
      .post<Patient>(this.patientImport, body)
      .pipe(catchError(this.handleError));
  }
  registerUser(body) {
    return this.http
      .post<Patient>(this.addPatientUrl, body)
      .pipe(catchError(this.handleError));
  }
  registerPatient(body) {
    return this.http
      .post<Patient>(this.url, body)
      .pipe(catchError(this.handleError));
  }

  getPatients() {
    return this.http
      .get<Patient[]>(this.url)
      .pipe(catchError(this.handleError));
  }
  getPatientById(id) {
    return this.http
      .get<Patient>(this.url + '/' + id)
      .pipe(catchError(this.handleError));
  }
  getPatientAllergy(id) {
    return this.http.get<Patient>(this.allergy + '/' + id);
  }
  getPatieninvoices(id) {
    return this.http.get<Patient>(this.invoice + '/' + id);
  }
  getPatienLabReport(id) {
    return this.http.get<Patient>(this.labReport + '/' + id);
  }
  getPatientRadiologyReport(id) {
    return this.http.get<Patient>(this.radiologyReport + '/' + id);
  }
  getPatienLabOrder(id) {
    return this.http.get<Patient>(this.labOrder + '/' + id);
  }
  getRadiologyOrder(id) {
    return this.http.get<Patient>(this.radiologyOrder + '/' + id);
  }
  getRadiologyMRI(id) {
    return this.http.get<Patient>(this.radiologyMRI + '/' + id);
  }
  getRadiologyMRI_V1(id) {
    return this.http.get<Patient>(this.radiologyMRI_V1 + '/' + id);
  }
  getPatientEMcontact(id) {
    return this.http.get<Patient>(this.emContact + '/' + id);
  }
  getPatientInsurance(id) {
    return this.http.get<Patient>(this.ptInsurance + '/' + id);
  }
  getPatientIdProof(id) {
    return this.http.get<Patient>(this.ptIdProof + '/' + id);
  }
  getPatientsurgery(id) {
    return this.http.get<Patient>(this.surgery + '/' + id);
  }
  getPatientBooking(id) {
    return this.http.get<Patient>(this.ptBooking + '/' + id);
  }
  getPatientMedication(id) {
    return this.http.get<Patient>(this.ptMedication + '/' + id);
  }
  getNurseReport(id) {
    return this.http.get<Patient>(this.nurseReport + '/' + id);
  }
  getPatientPrescription(id) {
    return this.http.get<Patient>(this.prescription + '/' + id);
  }
  getPatientvisits(id) {
    return this.http.get<Patient>(this.visits + '/' + id);
  }
  getdoctorNotes(id) {
    return this.http.get<Patient>(this.doctorNotes + '/' + id);
  }
  getBradenScale(id) {
    return this.http.get<Patient>(this.bradenScale + '/' + id);
  }
  getHesterDavies(id) {
    return this.http.get<Patient>(this.hesterDavies + '/' + id);
  }
  getpurposefulRounds(id) {
    return this.http.get<Patient>(this.purposefulRounds + '/' + id);
  }
  getVaccines(id) {
    return this.http.get<Patient>(this.patientVaccines + '/' + id);
  }
  getotherTasks(id) {
    return this.http.get<Patient>(this.otherTask + '/' + id);
  }
  getIntakeAssessment(id) {
    return this.http.get<Patient>(this.intakeAssessment + '/' + id);
  }
  getCommunicationAssessment(id) {
    return this.http.get<Patient>(this.communicationAssessment + '/' + id);
  }
  getdischargeSummary(id) {
    return this.http.get<Patient>(this.dischargeSummary + '/' + id);
  }
  getmedicineGiven(id) {
    return this.http.get<Patient>(this.medicineGiven + '/' + id);
  }
  getDoctorReport(id) {
    return this.http.get<Patient>(this.doctorReport + '/' + id);
  }
  getNurseNote(id) {
    return this.http.get<Patient>(this.nurseNote + '/' + id);
  }
  getGeneralSaftey(id) {
    return this.http.get<Patient>(this.generalSaftey + '/' + id);
  }
  getFamilyHistory(id) {
    return this.http.get<Patient>(this.familyHistory + '/' + id);
  }
  getSocialHistory(id) {
    return this.http.get<Patient>(this.socialHistory + '/' + id);
  }
  getMedicalHistory(id) {
    return this.http.get<Patient>(this.medicalHistory + '/' + id);
  }
  getHippaCompliance(id) {
    return this.http.get<Patient>(this.hippaCompliance + '/' + id);
  }
  getPTInitialEvaluations(id) {
    return this.http.get<Patient>(this.ptInitialEvaluations + '/' + id);
  }
  getPhysicalTherapyVisits(id) {
    return this.http.get<Patient>(this.physicalTherapyVisits + '/' + id);
  }
  getPhysicalTherapyBookings(id) {
    return this.http.get<Patient>(this.physicalTherapyBookings + '/' + id);
  }
  GetUploadedRadiologyReport(id) {
    return this.http.get<Patient>(this.uploadedRadiologyReport + '/' + id);
  }
  getDoctorId(id) {
    return this.http.get<Patient>(this.doctorId + '/' + id);
  }
  getpatienHospitalId(id) {
    return this.http.get<Patient>(this.patienHospitalId + '/' + id);
  }
  GetRefferal(id) {
    return this.http.get<Patient>(this.refferal + '/' + id);
  }
  GetConsentForService(id) {
    return this.http.get<Patient>(this.consentForService + '/' + id);
  }
  GetOfficePolicyConsent(id) {
    return this.http.get<Patient>(this.officePolicyConsent + '/' + id);
  }
  GetDryNeedlingConsent(id) {
    return this.http.get<Patient>(this.dryNeedlingConsent + '/' + id);
  }
  GetExersiceLiabilityWaiver(id) {
    return this.http.get<Patient>(this.exersiceLiabilityWaiver + '/' + id);
  }
  sendInvoiceToInsurance(body) {
    return this.http
      .post<EmailPayload>(this.emailInsuarance, body)
      .pipe(catchError(this.handleError));
  }
  sendRefferal(body) {
    return this.http
      .post<EmailPayload>(this.emailRefferal, body)
      .pipe(catchError(this.handleError));
  }
  getPatientPayment(id) {
    return this.http.get<Patient>(this.patientPayment + '/' + id);
  }
  updatePatient(id, body) {
    return this.http
      .put<Patient>(this.url + '/' + id, body)
      .pipe(catchError(this.handleError));
  }

  updateNursePatient(id, body) {
    return this.http
      .put<Patient>(this.nursePatient + '/' + id, body)
      .pipe(catchError(this.handleError));
  }

  deletePatient(id) {
    return this.http
      .delete<Patient>(this.url + '/' + id)
      .pipe(catchError(this.handleError));
  }

  completePatientInformation(id) {
    return this.http.get<Patient>(
      this.completePatientInformationUrl + '/' + id
    );
  }

  validateDicomFile(fileUrl) {
    let file;
    this.http.get(fileUrl).subscribe({
      next: (res: any) => {
        let blob = new Blob(res);
        file = blob;
        return file;
      },
    });
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      if (error.status == 400) {
        console.log(error.error);
        return throwError(error.error);
      } else if (error.status == 401) {
        return throwError('invalid username or password');
      } else if (error.status == 0) {
        return throwError('network failure please try again later ');
      }
      return throwError(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }
}
