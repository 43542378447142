import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { Vaccine } from 'src/app/Models/vaccine.model';
import { PatientService } from 'src/app/services/patient.service';
import { VaccineService } from 'src/app/services/vaccine.service';

@Component({
  selector: 'app-view-vaccine',
  templateUrl: './view-vaccine.component.html',
  styleUrls: ['./view-vaccine.component.css'],
})
export class ViewVaccineComponent implements OnInit {
  id = 0;
  vaccineList: Vaccine[] = [];
  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = [
    'VaccineName',
    'VaccineProvider',
    'DateTaken',
    'Description',
    'Delete',
  ];

  constructor(
    private patientService: PatientService,
    private route: ActivatedRoute,
    private router: Router,
    private vaccineService: VaccineService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });

    this.getVaccine(this.id);
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  getVaccine(id) {
    this.patientService.getVaccines(id).subscribe((res) => {
      this.dataSource = new MatTableDataSource(res.Vaccines);
      this.dataSource.paginator = this.paginator;
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  delete(id) {
    this.vaccineService.deleteVaccine(id).subscribe((res) => {
      alert('deleted');
      this.getVaccine(this.id);
    });
  }

  tabChanged(event: MatTabChangeEvent): void {
    if (event.index === 0) {
      this.getVaccine(this.id);
    }
  }
}
