<div class="mt-4 ">
    <mat-tab-group backgroundColor="primary">
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon>person</mat-icon>
                <span class="ml-2 mt-1">Patient Info</span>
            </ng-template>
            <div class="row my-4 mx-0">
                <div class="col-lg-6 col-md-6 h-100 mx-auto mb-5">
                    <div class="patient-image mx-auto" [ngStyle]="{
                'background-image': 'url(' + Patient?.PatientImage + ')'
              }"></div>
                </div>
                <!-- <div class="col-lg-9 col-md-6"> -->
                <!-- <div class="row mx-0"> -->
                <div class="col-lg-6 col-md-6 p-info-cols mb-5">
                    <mat-card>
                        <mat-card-content>
                            <h2>Personal Information</h2>
                            <table class="w-100">
                                <tr>
                                    <td>D.O.B:</td>
                                    <td>{{ Patient?.PatientDOB }}</td>
                                </tr>
                                <tr>
                                    <td>Gender:</td>
                                    <td>{{ Patient?.PatientGender }}</td>
                                </tr>
                                <tr>
                                    <td>Email:</td>
                                    <td>{{ Patient?.PatientEmail }}</td>
                                </tr>
                                <tr>
                                    <td>Cell No:</td>
                                    <td>{{ Patient?.PhoneNumber }}</td>
                                </tr>
                                <tr>
                                    <td>SSN:</td>
                                    <td>{{ Patient?.PatientNationalId }}</td>
                                </tr>
                                <tr>
                                    <td>Blood Group:</td>
                                    <td>{{ Patient?.BloodGroup }}</td>
                                </tr>
                                <tr>
                                    <td>Height:</td>
                                    <td>{{ Patient?.PatientHeight }}</td>
                                </tr>
                                <tr>
                                    <td>Weight:</td>
                                    <td>{{ Patient?.PatientWeight }}</td>
                                </tr>
                            </table>
                        </mat-card-content>
                    </mat-card>
                </div>

                <!-- </div> -->
                <!-- </div> -->
            </div>
        </mat-tab>
        <!-- <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">medical_services</mat-icon>
                <span class="ml-2 mt-1">Current Medications</span>
            </ng-template>
            <app-prescription-display></app-prescription-display>

        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">medical_services</mat-icon>
                <span class="ml-2 mt-1">Past Medications</span>
            </ng-template>
            <app-view-pastmedication></app-view-pastmedication>
        </mat-tab> -->


        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">content_cut</mat-icon>
                <span class="ml-2 mt-1">Surgical Hostory</span>
            </ng-template>
            <app-view-surgical-history></app-view-surgical-history>
        </mat-tab>


        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">warning</mat-icon>
                <span class="ml-2 mt-1">Allergy History</span>
            </ng-template>
            <app-view-allergy></app-view-allergy>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">history</mat-icon>
                <span class="ml-2 mt-1">Medical History</span>
            </ng-template>
            <app-view-medical-history></app-view-medical-history>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">history</mat-icon>
                <span class="ml-2 mt-1">Family History</span>
            </ng-template>
            <app-view-familyhistory></app-view-familyhistory>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">history</mat-icon>
                <span class="ml-2 mt-1">Social History</span>
            </ng-template>
            <app-view-socialhistory></app-view-socialhistory>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">vaccines</mat-icon>
                <span class="ml-2 mt-1">Immunization</span>
            </ng-template>
            <app-view-vaccine></app-view-vaccine>
        </mat-tab>
        <!-- <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">bug_report</mat-icon>
                <span class="ml-2 mt-1"> Lab Reports</span>
            </ng-template>
            <app-view-labreport></app-view-labreport>
        </mat-tab> -->
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">vaccines</mat-icon>
                <span class="ml-2 mt-1">ID Proof</span>
            </ng-template>
            <app-view-patient-idproof></app-view-patient-idproof>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">moving_ministry</mat-icon>
                <span class="ml-2 mt-1">Office Plolicy</span>
            </ng-template>
            <app-view-office-policy-consent></app-view-office-policy-consent>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">meical_services</mat-icon>
                <span class="ml-2 mt-1">Consent For Service</span>
            </ng-template>
            <app-view-consent-for-service></app-view-consent-for-service>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">vaccines</mat-icon>
                <span class="ml-2 mt-1">IMS/Dry Needling Consent</span>
            </ng-template>
            <app-view-dry-needling-consent></app-view-dry-needling-consent>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">fitness_center</mat-icon>
                <span class="ml-2 mt-1">Excercise Liability Waiver</span>
            </ng-template>
            <app-view-exersice-liability-waiver></app-view-exersice-liability-waiver>
        </mat-tab>

        <mat-tab>

            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">qr_code</mat-icon>
                <span class="ml-2 mt-1">QR Code</span>
            </ng-template>

            <div class="container">
                <div class="row">
                    <div class="col-lg-6" id="printable">
                        <qrcode *ngIf="PatientId" [qrdata]="PatientId" [width]="156" [errorCorrectionLevel]="'M'">
                        </qrcode>
                        <!-- <qr-code value="Yo world!" size="300" errorCorrectionLevel="M"></qr-code> -->

                    </div>
                    <button class="btn backG float-right mr-3" onClick="window.focus(); window.print()"
                        value="print"><mat-icon>print</mat-icon></button>

                    <!-- <a class="btn btn-light text-capitalize border-0" data-mdb-ripple-color="dark"  (click)="print()"><i
              class="fas fa-print text-primary"></i> Print</a> -->
                </div>

            </div>

        </mat-tab>

        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">history</mat-icon>
                <span class="ml-2 mt-1">Hipaa Compliance</span>
            </ng-template>
            <app-view-hippa-compliance></app-view-hippa-compliance>
        </mat-tab>
        <!-- <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">medical_services</mat-icon>
                <span class="ml-2 mt-1">Pharmacy</span>
            </ng-template>
            <app-view-patient-pharmacy></app-view-patient-pharmacy>
        </mat-tab> -->


        <!-- <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">medication</mat-icon>
                <span class="ml-2 mt-1"> Narcotic (Opioid) consent
                </span>
            </ng-template>
            <app-view-narcotic-medical-policy-agreement></app-view-narcotic-medical-policy-agreement>
        </mat-tab> -->
        <!-- <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">info</mat-icon>
                <span class="ml-2 mt-1"> GENERAL CONSENT </span>
            </ng-template>
            <app-view-general-consent-for-treatment></app-view-general-consent-for-treatment>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">Apartment</mat-icon>
                <span class="ml-2 mt-1">OFFICE POLICIES agreement</span>
            </ng-template>
            <app-view-office-policy-consent></app-view-office-policy-consent>
        </mat-tab>

        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">photo_camera</mat-icon>
                <span class="ml-2 mt-1"> PHOTOGRAPH/VIDEO consent</span>
            </ng-template>
            <app-view-photographic-consent></app-view-photographic-consent>
        </mat-tab> -->

    </mat-tab-group>
</div>

<!-- <app-navbar></app-navbar> -->
<div class="mx-5">

</div>

<!-- LabReport  -->

<!-- Modal -->
<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{ display: displayStyle1 }">

    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">
                    Inusrance Image Front
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="ins-Front-image mx-auto" [ngStyle]="{
            'background-image': 'url(' + Insurance[0]?.InsImageFront + ')'
          }"></div>
                <!-- <div [innerHTML]="Insurance[0]?.InsImageFront"></div> -->
            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-danger" (click)="closePopFront()">
                    Close
                </button>
                <!-- <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Close
          </button> -->
                <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
            </div>
        </div>
    </div>
</div>

<!-- Back Image Modal -->
<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{ display: displayStyle }">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title"> Inusrance ImageBack</h4>
            </div>
            <div class="modal-body lab-document">
                <div class="ins-Back-image mx-auto" [ngStyle]="{
            'background-image': 'url(' + Insurance[0]?.InsImageBack + ')'
          }"></div>
                <!-- <div [innerHTML]="Insurance[0]?.InsImageBack"></div> -->
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-danger" (click)="closePopup()">
                    Close
                </button>
            </div>
        </div>
    </div>
</div>



<div class="card upcoming-appointment" *ngIf="upcomingAppointment">
    <div class="card-body mx-auto">
        <h2 class="mb-1">Upcoming Appointment </h2>
        <!-- <p>You have an upcoming appointment
      </p> -->
        <table class="w-100">
            <tr>
                <td>Doctor Name</td>
                <td>{{upcomingAppointment.DoctorName}}</td>
            </tr>
            <tr>
                <td>Doctor Speciality</td>
                <td>{{upcomingAppointment.DoctorSpecialization}}</td>
            </tr>
            <tr>
                <td>Date</td>
                <td>{{upcomingAppointment.VisitDate}}</td>
            </tr>
            <tr>
                <td>Time</td>
                <td>{{upcomingAppointment.VisitTime}}</td>
            </tr>
        </table>
    </div>
</div>