<div class="conatiner mx-5 my-5" id="printable">
    <button type="button" (click)="print()" class="btn btn-primary float-right" data-toggle="modal"
        data-target=".bd-example-modal-lg"> Print </button>
    <div class="row">
        <div class="col-md-10" *ngFor="let item of dryNeedlingConsentList">

            <mat-card class="mat-elevation-z8 mt-3 ">
                <h1>INTRAMUSCULAR STIMULATION (IMS) OR
                    TRIGGER POINT DRY NEEDLING (TDN) RELEASE
                    FORM</h1>

                <p class="float-right">Date : {{item.createdDate | date: 'short' }}</p>

                <hr>
                <mat-card-content>
                    <hr>

                    <h2>
                        {{hospital.HospitalName}}

                    </h2>

                    <p>The IMS/TDN treatment involves the insertion of fine filament needles into
                        tight muscle bands or ͩtrigger points.ͪ These are defined as regions where
                        the nerve meets the muscle to cause it to contract, and for some reason have
                        not received the signal to relax. The rationale for needling these areas is to
                        turn off the muscle. With this technique, you may feel a cramping or
                        tightening sensation, dull ache or tingling. Your therapist will communicate
                        to you what are normal sensations and adjust needle placement as necessary
                        for your comfort and maximal benefit. You may feel immediate relief, or you
                        may experience discomfort for a few days following treatment. Treatment is
                        intended to either decrease pain, increase musculoskeletal movement, or
                        assist in a gradual return to improved function. Again, your therapist will
                        communicate rationale for this choice of treatment and expected outcomes.

                    </p>
                    <p>
                        IMS/TDN may also be used to turn on muscles that have been turned off due
                        to disuse or deactivated due to injury. In this scenario, the needle is inserted
                        to activate a twitch response. You may be asked to actively contract with the
                        response to strengthen the signal between the nerve and the muscle it is to
                        contract. Again, your therapist will communicate rationale for this choice of
                        treatment and expected outcomes.
                    </p>

                    <p>IMS/TDN may be used in conjunction with the application of electrical
                        stimulation. Electrical stimulation amplifies the desired effects/response and
                        can achieve results in a shorter duration of time than the needle alone in
                        13 / 15 GCPT, LLC
                        some cases. Again, your therapist will communicate rationale for this choice
                        of treatment and expected outcomes.</p>
                    <p>
                        IMS/TDN is not to be confused with Chinese Acupuncture. IMS and
                        acupuncture are different techniques. IMS is a scientifically proven method
                        for diagnosing and treating both acute and chronic pain and movement
                        dysfunction.

                    </p>
                    <p>
                        Like any treatment there are possible complications, though they are rare in
                        occurrence, it is recommended you read through the possible risks prior to
                        giving consent to treatment.
                    </p>
                    <h2>How Does It Work?</h2>
                    <p>
                        Nerves require a chemical change to activate muscle. When the chemical
                        relationship between the two is altered by problems of the nerve ͛due to
                        mechanical deformation/stretch/compression/disuse͜ there may be muscle
                        pain and weakness. The immediate benefits of IMS are due to the
                        mechanical effect of the needle. This allows ͩknotsͪ to be broken up and the
                        muscle to start working properly. The longer͡term benefit comes from
                        restoring muscular homeostasis and activation.
                    </p>
                    <h2>Contraindications:</h2>
                    <p>IMS is not recommended for patients who are pregnant, have had recent
                        surgery ͛12 weeks in the same region, 6 weeks in an unrelated region͜, have a
                        local infection, have a systemic infection, are hemophiliacs, are on blood
                        thinners, or are averse to this treatment modality. Please inform your
                        therapist if you have any of the listed contra͡indications or any medical
                        cognition you think might prevent you from benefiting from this treatment
                        modality.</p>
                    <h2>Risks:</h2>
                    <p>
                        There are risks associated with IMS, as with any needling technique. There is a
                        chance of infection. However the needles used are sterile, individually
                        wrapped needles that are discarded after each use, and alcohol is used to
                        prep the treatment area. A small bruise/contusion can develop where the
                        needle is inserted if a blood vessel is hit when inserting the needle, but the
                        needle does no permanent damage to said vessels. To avoid lung puncture
                        14 / 15 GCPT, LLC
                        or collapse, or internal bleeding, IMS is never performed over major organs
                        such as the lungs, or kidneys.
                    </p>
                    <h1>PLEASE CIRCLE ALL THAT APPLY AND DISCUSS WITH YOUR THERAPIST:</h1>
                    <strong>Recent Surgery – Pregnant – Blood Disorder – Epilepsy/Siezure -
                        Previous Fainting – Low Pain Tolerance</strong>
                    <mat-label>Any known disease or infection that can be transmitted through bodily
                        fluids:?</mat-label>
                    <mat-radio-group formControlName="IsAnyKnownTransmittedDisease">
                        <mat-radio-button class="ml-3" value="true">Yes</mat-radio-button>
                        <mat-radio-button class="ml-3" value="false">No</mat-radio-button>
                    </mat-radio-group>
                    <p>If yes, please discuss with your practitioner.</p>


                    <strong>By signing below, I acknowledge that I have read, understand, and agree to the terms and
                        conditions of this form and that I am authorized as the patient or the Patient’s Legal
                        Representative to sign this document.</strong>




                    <tr>
                        <th>This consent was signed by: </th>
                        <td>{{item?.AuthorisedSignatoryName}}</td>

                    </tr>

                    <tr>
                        <th> Signature: </th>
                        <td><img src="{{item?.Signature}}" alt=""></td>

                    </tr>

                </mat-card-content>
                <button (click)="delete(item.OfficePolicyId)" mat-mini-fab
                    color="accent"><mat-icon>delete</mat-icon></button>
                <!-- <button class="float-right" [routerLink]="['/editFamilyHistory/',item.FamilyHistoryId]" color="primary" mat-mini-fab><mat-icon>edit</mat-icon> </button>
    -->

            </mat-card>
        </div>
    </div>
</div>