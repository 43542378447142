<!-- <div id="dashboard"> -->

<!-- <div class="content">
        <div class="sidebar mat-elevation"> -->
<!-- <div class="header">
            <img src="../../../assets/admin.png" class="image" alt="">

            <h4 class="text-white ml-2">Patient Details</h4>
           </div> -->

<div class="toolbar mat-elevation-z6">

  <button mat-raised-button class="backG" [matMenuTriggerFor]="crt"><mat-icon>menu</mat-icon>Menu</button>

  <mat-menu class="ItemS" #crt="matMenu">

    <div class="ItemS ">
      <mat-icon mat-list-icon>PT Dashboard</mat-icon>
      <div class="mx-2" [routerLink]="['/physicalTherapyDash/']">dashboard</div>
      <mat-icon>navigate_next</mat-icon>
    </div>
    <div class="ItemS " (click)="showItem='5'" [ngClass]="{'active': showItem =='5'}">
      <mat-icon mat-list-icon>dashboard</mat-icon>
      <div class="mx-2">Back</div>
      <mat-icon>navigate_next</mat-icon>
    </div>

    <div class="ItemS " (click)="showItem='1'" [ngClass]="{'active': showItem =='1'}">
      <mat-icon mat-list-icon>summarize</mat-icon>
      <div class="mx-2">Patient Summary</div>
      <mat-icon>navigate_next</mat-icon>
    </div>



    <div class="ItemS" (click)="showItem='2'" [ngClass]="{'active': showItem =='2'}">
      <mat-icon mat-list-icon>add</mat-icon>
      <div class="mx-2">New Visit</div>
      <mat-icon>navigate_next</mat-icon>
    </div>

    <div class="ItemS" (click)="showItem='3'" [ngClass]="{'active': showItem =='3'}">
      <mat-icon mat-list-icon>add</mat-icon>
      <div class="mx-2">New Initial Evaluation</div>
      <mat-icon>navigate_next</mat-icon>
    </div>
    <div class="ItemS" (click)="showItem='9'" [ngClass]="{'active': showItem =='9'}">
      <mat-icon mat-list-icon>add</mat-icon>
      <div class="mx-2"> New Appointment</div>
      <mat-icon>navigate_next</mat-icon>
    </div>
    <hr>
    <div class="ItemS" (click)="showItem='61'" [ngClass]="{'active': showItem =='61'}">
      <mat-icon mat-list-icon>add</mat-icon>
      <div class="mx-2">New Refferal</div>
      <mat-icon>navigate_next</mat-icon>
    </div>

    <hr>
    <div class="ItemS" (click)="showItem='18'" [ngClass]="{'active': showItem =='18'}">
      <mat-icon mat-list-icon>add</mat-icon>
      <div class="mx-2">New Hand Writting Note</div>
      <mat-icon>navigate_next</mat-icon>
    </div>
    <hr>
    <div class="ItemS" (click)="showItem='16'" [ngClass]="{'active': showItem =='16'}">
      <mat-icon mat-list-icon>add</mat-icon>
      <div class="mx-2">New Invoice</div>
      <mat-icon>navigate_next</mat-icon>
    </div>


    <div class="ItemS" (click)="showItem='17'" [ngClass]="{'active': showItem =='17'}">
      <mat-icon mat-list-icon>receipt</mat-icon>
      <div class="mx-2">View Invoice</div>
      <mat-icon>navigate_next</mat-icon>
    </div>
    <hr>

    <div class="ItemS" (click)="showItem='10'" [ngClass]="{'active': showItem =='10'}">
      <mat-icon mat-list-icon>visibility</mat-icon>
      <div class="mx-2"> View Appointments</div>
      <mat-icon>navigate_next</mat-icon>
    </div>
    <div class="ItemS" (click)="showItem='4'" [ngClass]="{'active': showItem =='4'}">
      <mat-icon mat-list-icon>visibility</mat-icon>
      <div class="mx-2">View Inital Evaluation</div>
      <mat-icon>navigate_next</mat-icon>
    </div>

    <div class="ItemS" (click)="showItem='5'" [ngClass]="{'active': showItem =='5'}">
      <mat-icon mat-list-icon>visibility</mat-icon>
      <div class="mx-2">View PT Visit</div>
      <mat-icon>navigate_next</mat-icon>
    </div>

    <div class="ItemS" (click)="showItem='62'" [ngClass]="{'active': showItem =='62'}">
      <mat-icon mat-list-icon>file_open</mat-icon>
      <div class="mx-2">View Refferal</div>
      <mat-icon>navigate_next</mat-icon>
    </div>
    <div class="ItemS" (click)="showItem='19'" [ngClass]="{'active': showItem =='19'}">
      <mat-icon mat-list-icon>note</mat-icon>
      <div class="mx-2">Hand Written Note</div>
      <mat-icon>navigate_next</mat-icon>
    </div>
    <div class="ItemS" (click)="showItem='6'" [ngClass]="{'active': showItem =='6'}">
      <mat-icon mat-list-icon>show_chart</mat-icon>
      <div class="mx-2">Upload Radiology Report</div>
      <mat-icon>navigate_next</mat-icon>
    </div>
    <div class="ItemS" (click)="showItem='7'" [ngClass]="{'active': showItem =='7'}">
      <mat-icon mat-list-icon>view_array</mat-icon>
      <div class="mx-2">View Uploaded Radiology Report</div>
      <mat-icon>navigate_next</mat-icon>
    </div>
    <div class="ItemS" (click)="showItem='11'" [ngClass]="{'active': showItem =='11'}">
      <mat-icon mat-list-icon>image</mat-icon>
      <div class="mx-2">Upload MRI Image</div>
      <mat-icon>navigate_next</mat-icon>
    </div>
    <div class="ItemS" (click)="showItem='8'" [ngClass]="{'active': showItem =='8'}">
      <mat-icon mat-list-icon>image</mat-icon>
      <div class="mx-2">View MRI images</div>
      <mat-icon>navigate_next</mat-icon>
    </div>


  </mat-menu>
</div>



<span [ngSwitch]="showItem">

  <p *ngSwitchCase="'1'">
    <app-patientsummary></app-patientsummary>
  </p>


  <p *ngSwitchCase="'2'">
    <app-add-physical-therapy-visit></app-add-physical-therapy-visit>
  </p>
  <p *ngSwitchCase="'3'">
    <app-add-pt-initial-evaluation></app-add-pt-initial-evaluation>
  </p>

  <p *ngSwitchCase="'4'">
    <app-view-pt-initial-evaluation></app-view-pt-initial-evaluation>
  </p>

  <p *ngSwitchCase="'5'">
    <app-view-physical-therapy-visit></app-view-physical-therapy-visit>

  </p>


  <p *ngSwitchCase="'6'">
    <app-add-upload-radiology-report></app-add-upload-radiology-report>

  </p>

  <p *ngSwitchCase="'7'">
    <app-view-upload-radiology-report></app-view-upload-radiology-report>

  </p>
  <p *ngSwitchCase="'11'">
    <app-add-mri></app-add-mri>
  </p>


  <p *ngSwitchCase="'8'">
    <app-view-mri></app-view-mri>
  </p>
  <p *ngSwitchCase="'9'">
    <app-add-appointment></app-add-appointment>
  </p>
  <p *ngSwitchCase="'10'">
    <app-view-appointment></app-view-appointment>
  </p>
  <p *ngSwitchCase="'16'">
    <app-add-invoice></app-add-invoice>
  </p>
  <p *ngSwitchCase="'17'">
    <app-view-invoice></app-view-invoice>
  </p>
  <p *ngSwitchCase="'18'">
    <app-draw-pad></app-draw-pad>
  </p>
  <p *ngSwitchCase="'19'">
    <app-vew-doctor-note></app-vew-doctor-note>
  </p>
  <p *ngSwitchCase="'61'">
    <app-add-refferal></app-add-refferal>
  </p>
  <p *ngSwitchCase="'62'">
    <app-view-refferal></app-view-refferal>

  </p>
  <p *ngSwitchDefault>

    <app-view-physical-therapy-visit></app-view-physical-therapy-visit>
  </p>







</span>

<!-- </div>
     </div> -->
<!-- </div> -->