import {
  HttpHeaders,
  HttpClient,
  HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { PhysicalTherapyVisit } from 'src/app/Models/physicaltheraapyvisit.model';

@Injectable({
  providedIn: 'root',
})
export class PhysicalTherapyVisitService {
  url = environment.Url + 'PhysicalTherapyVisits';
  diagnosisCodes = environment.Url + 'PhysicalTherapyVisits/diagnosisCodes';
  patientVisitImport =
    environment.Url + 'PhysicalTherapyVisits/patientVisitImport';

  header = new HttpHeaders({
    Authorization: 'token' + localStorage.getItem('token'),
  });

  constructor(private http: HttpClient) {}

  registerPhysicalTherapyVisit(body) {
    return this.http
      .post<PhysicalTherapyVisit>(this.url, body)
      .pipe(catchError(this.handleError));
  }

  getPhysicalTherapyVisits() {
    return this.http
      .get<PhysicalTherapyVisit[]>(this.url)
      .pipe(catchError(this.handleError));
  }
  getPhysicalTherapyVisitById(id) {
    return this.http.get<PhysicalTherapyVisit>(this.url + '/' + id);
  }

  getDiagnosisCodes(id) {
    return this.http.get<PhysicalTherapyVisit>(this.diagnosisCodes + '/' + id);
  }

  updatePhysicalTherapyVisit(id, body) {
    return this.http
      .put<PhysicalTherapyVisit>(this.url + '/' + id, body)
      .pipe(catchError(this.handleError));
  }

  deletePhysicalTherapyVisit(id) {
    return this.http
      .delete<PhysicalTherapyVisit>(this.url + '/' + id)
      .pipe(catchError(this.handleError));
  }
  getPatientVisitJsonData(id) {
    return this.http.get<PhysicalTherapyVisit>(
      this.patientVisitImport + '/' + id
    );
  }
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      if (error.status == 400) {
        console.log(error.error);
        return throwError(error.error);
      } else if (error.status == 401) {
        return throwError('invalid username or password');
      } else if (error.status == 0) {
        return throwError('network failure please try again later ');
      }
      return throwError(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }
}
