<div class="container">
    <div class="row">
        <div class="col-lg regFrm mat-elevation-z8">
            <h1>OFFICE POLICIES</h1>
            <form [formGroup]="Frm" (ngSubmit)="formSubmit()" class="example-form">

                <hr>

                <h2>
                    {{hospital.HospitalName}}

                </h2>


                <p>All patients must recognize that they are responsible for the charges incurred for physical therapy.
                    We will attempt to verify what your insurance benefits are. However, quotation of benefits from
                    your insurance company does not guarantee payment. We will submit billing to your insurance
                    company free of charge. In the event that your insurance carrier does not submit payment for
                    services rendered, a statement will be issued to you for payment.
                    Missed appointments are a loss for everyone. Cancellations without adequate notice cannot be
                    filled and take valuable time from other patients, thus they are subject to a $25 late cancel/no
                    show fee. Therefore, we require that you notify us 24 hours in advance if you are unable to
                    attend a scheduled appointment. If you are 15 minutes late or greater, the therapist will have the
                    option of seeing you or rescheduling. Checks that are returned for any reason are subject to a $10
                    service fee.
                </p>
                <h2>Authorization and Assignment of Benefits:</h2>
                <p>
                    I hereby authorize and direct you, my insurance company, to pay directly to Cameron Park
                    Physical Therapy Center, Inc. such sums as may be due and owing this office for services
                    rendered to me, both by reason of accident of illness, and by reason of any other bills that are due
                    this office and withhold such sums from any disability benefits, medical payment benefits, nofault
                    benefits, health and accident benefits, worker’s compensation benefits, or any other
                    insurance benefits obligated to reimburse me or from any settlement, judgment , or verdict on my
                    behalf as may be necessary to adequately protect said office. This is to act as an assignment of
                    my rights and benefits to the extent of the office’s services provided.
                    In the event my insurance company, obligated to make payments to me upon the charges made by
                    this office for their services, refuses to make such payments, upon demand by me or this office, I
                    hereby assign and transfer to this office any and all causes of action that I might have or that
                    might exist in my favor against such company and authorize this office
                </p>
                <mat-label>Do you have a lawyer representing you for this medical condition?</mat-label>
                <mat-radio-group formControlName="IslawyerrepresentingyouForThisMedicalCondition">
                    <mat-radio-button class="ml-3" value="true">Yes</mat-radio-button>
                    <mat-radio-button class="ml-3" value="false">No</mat-radio-button>
                </mat-radio-group>


                <p>I understand that I remain personally responsible for the amounts due this office for their services.
                    I further understand and agree that this assignment and authorization does not constitute any
                    consideration for the office to await payments and they may demand payments from me
                    immediately upon rendering services at their option.
                    I authorize this office to release any information pertinent to my case to any insurance company,
                    billing service, adjuster, or attorney to facilitate collection under this assignment and
                    authorization.</p>
                <h1>MEDICARE PATIENTS: </h1>
                <p>
                    We bill our standard fees to Medicare, they pay 80% of their
                    allowable fees, and the difference is billed to your secondary insurance. If you do not have a
                    secondary insurance or your secondary insurance denies payment, you will receive a statement
                    following the receipt of Medicare’s allotment. Unless other arrangements are made, you will be
                    responsible for the remaining 20% of the Medicare allowable rate.

                </p>
                <strong>By signing below, I acknowledge that I have read, understand, and agree to the terms and
                    conditions of this form and that I am authorized as the patient or the Patient’s Legal
                    Representative to sign this document.</strong>

                <ul>
                    <li> Office Pick Up CD/Films can be picked up up from our office office for no
                        charge.</li>
                    <li> U.S. Shipment CDS/Films returned to the patient within the United States will be charged a $50
                        handling fee plus actual postage determined by carrier (USPS/FedEx, DHL).</li>
                    <li> International Shipment CDS/Films returned to the patient outside of the United States will be
                        charged a $200 handling fee plus actual postage determined by carrier (USPS/FedEx, DHL).</li>
                </ul>

                <div class="row">

                    <div class="col-md-12">


                        <mat-form-field class="example-full-width">
                            <mat-label>This consent was signed by(Patient/Legal Representative Print Name):</mat-label>
                            <input type="text" formControlName="AuthorisedSignatoryName" matInput>
                            <mat-error>Please enter Patient/Legal Representative Print Name</mat-error>
                        </mat-form-field>
                        <!-- <mat-form-field class="example-full-width">
                <mat-label>Signature:</mat-label>
                <input type="text" formControlName="Signauture" matInput>
                <mat-error>Please enter Description</mat-error>
              </mat-form-field> -->


                    </div>

                    <div class="container mt-5">
                        <canvas (mousedown)="onMouseDown($event)" (mousemove)="onMouseMove($event)"
                            (touchmove)="onMouseMove($event)" (touchstart)="onMouseDown($event)" #signPad width="350"
                            height="200">
                        </canvas>
                        <button type="button" class="ml-3" color="primary" mat-raised-button
                            (click)="saveSignature($event)">Save</button>

                        <button type="button" class="ml-3" color="accent" mat-raised-button
                            (click)="clearSignature($event)">Clear</button>
                    </div>



                </div>
                <button type="submit" [disabled]="Frm.invalid" class="float-right lgBtCl" mat-button>
                    <mat-icon>save</mat-icon>
                </button>
                <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
                    Successfully submitted
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </form>

        </div>

    </div>
</div>