<div class="mt-5">
    <mat-tab-group (selectedTabChange)="tabChanged($event)">
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">add</mat-icon>
                Surgical History
            </ng-template>
            <!-- (submitAction)="handleSubmitAction()" -->
            <app-add-surgical-history></app-add-surgical-history>

        </mat-tab>
        <!-- *ngIf="!currentPatient.IsVaccineCompleted" -->
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">add</mat-icon>
                Immunization
            </ng-template>
            <!-- (submitAction)="handleSubmitAction()" -->
            <app-add-vaccine></app-add-vaccine>

        </mat-tab>
        <!-- *ngIf="!currentPatient.IsAllergyCompleted" -->
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">add</mat-icon>
                Allergy
            </ng-template>
            <app-add-allergy></app-add-allergy>
            <!-- (submitAction)="handleSubmitAction()" -->
        </mat-tab>

    </mat-tab-group>
</div>