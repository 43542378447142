import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Doctor } from 'src/app/Models/doctor.model';
import { DoctorDate } from 'src/app/Models/doctordate.model';
import { DoctorService } from 'src/app/services/doctor.service';
import { TimeslotService } from 'src/app/services/timeslot.service';

@Component({
  selector: 'app-add-time-slot',
  templateUrl: './add-time-slot.component.html',
  styleUrls: ['./add-time-slot.component.css'],
})
export class AddTimeSlotComponent implements OnInit {
  doctorList: Doctor[] = [];
  DoctorAvalableDatList: DoctorDate[] = [];

  isSuccess = false;

  constructor(
    private fb: UntypedFormBuilder,
    private timeSlotservice: TimeslotService,
    private doctorService: DoctorService
  ) {}

  ngOnInit(): void {
    this.getDoctors();
    this.getDoctorDates();
  }

  timFrm = this.fb.group({
    TimeSlots: ['', Validators.required],
    DoctorName: [''],
    AvailableDate: [''],
    DoctorId: ['', Validators.required],
    DoctorDateId: ['', Validators.required],
  });

  get AvailableDate() {
    return this.timFrm.get('AvailableDate');
  }
  get DoctorName() {
    return this.timFrm.get('DoctorName');
  }
  get TimeSlots() {
    return this.timFrm.get('TimeSlots');
  }
  get DoctorId() {
    return this.timFrm.get('DoctorId');
  }
  get DoctorDateId() {
    return this.timFrm.get('DoctorDateId');
  }

  getDoctors() {
    this.doctorService.getDoctors().subscribe((res) => {
      this.doctorList = res;
    });
  }

  getDoctorDates() {
    this.doctorService
      .getDoctorAvailableDates(this.DoctorId.value)
      .subscribe((res) => {
        this.DoctorAvalableDatList = res.DoctorDates;
      });
  }

  formSubmit() {
    let timeSlots = this.timFrm.value['TimeSlots'];
    let doctorDateId = this.timFrm.value['DoctorDateId'];
    let doctorId = this.timFrm.value['DoctorId'];
    let idoctorName = this.doctorList.filter((it) => it.DoctorId == doctorId)[0]
      .DoctorName;
    let availableDate = this.DoctorAvalableDatList.filter(
      (it) => it.DoctorDateId == doctorDateId
    )[0].AvailableDate;

    let body = {
      TimeSlots: timeSlots,
      DoctorId: doctorId,
      DoctorName: idoctorName,
      DoctorDateId: doctorDateId,
      AvailableDate: availableDate,
    };

    this.timeSlotservice.registerTimeSlot(body).subscribe((res) => {
      this.isSuccess = true;
      this.timFrm.reset();
    });
  }
}
