<div class=" mt-5">
    <!-- <div class="" >
        <mat-icon mat-list-icon>undo</mat-icon>
        <div class="mx-2 text-primary " [routerLink]="['/patientDash/',this.id]"> Back</div>
       
      </div> -->
    <h2 class="txt-primary mt-3">Appointments </h2>
    <div class="ml-3 mt-2">
        <div class="row">
            <div class="col-lg-6 "><span class="float-right"></span></div>
            <div class="col-lg-6 ">
                <mat-form-field appearance="fill" class="example-full-width">
                    <mat-label>Search by Visit Date</mat-label>
                    <mat-icon matPrefix>search</mat-icon>
                    <input type="text" #searchValue (keyup)="search(searchValue)" matInput>

                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <table class="table table-stripped">
                <thead>
                    <th>PatientId</th>
                    <th>DoctorName</th>
                    <th>DoctorSpecialization</th>
                    <th>Appointment Date</th>
                    <th>Visit Time</th>
                    <th>Description</th>
                    <th>Status</th>

                </thead>
                <tbody>
                    <tr *ngFor="let item of filteredList">

                        <td class="ml-2">{{item.PatientId}}</td>
                        <td class="ml-2">{{item.DoctorName}}</td>
                        <td class="ml-2">{{item.DoctorSpecialization}}</td>
                        <td class="ml-2">{{item.VisitDate}}</td>
                        <td class="ml-2">{{item.VisitTime}}</td>
                        <td class="ml-2">{{item.Message}}</td>
                        <td class="ml-2">{{item.Status}}</td>
                        <td>
                            <button type="button"
                                [routerLink]="['/editAppointmentByDoctor/',item.PhysicalTherapyBookingId]" mat-mini-fab
                                color="primary">
                                <mat-icon matPrefix>edit</mat-icon>
                            </button>
                        </td>
                        <td>
                            <button (click)="delete(item.PhysicalTherapyBookingId)" mat-mini-fab
                                color="accent"><mat-icon>delete</mat-icon></button>
                        </td>
                    </tr>

                </tbody>
            </table>
            <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>

        </div>

    </div>
</div>