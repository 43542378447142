import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { DoctorService } from 'src/app/services/doctor.service';
import { EmailService } from 'src/app/services/email.service';
import { LabmanagerService } from 'src/app/services/labmanager.service';
import { PatientService } from 'src/app/services/patient.service';
import { PatientvisitService } from 'src/app/services/patientvisit.service';
import { PharmacyMemberService } from 'src/app/services/pharmacy-member.service';
import { PhysicalTherapyDoctorService } from 'src/app/services/physical-therapy-doctor.service';
import { PhysicalTherapyVisitService } from 'src/app/services/physical-therapy-visit.service';
import { RadiologyMemberService } from 'src/app/services/radiology-member.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-bulk-import-patient',
  templateUrl: './bulk-import-patient.component.html',
  styleUrls: ['./bulk-import-patient.component.css'],
})
export class BulkImportPatientComponent implements OnInit {
  selectedFile: any;
  helper = new JwtHelperService();
  myToken = window.localStorage.getItem('token');
  id = 0;
  selectedPatientVisitFile: any;
  selectedPhysicalTherapistFile: any;
  constructor(
    private patientService: PatientService,
    private route: ActivatedRoute,
    private userService: UserService,
    private emailService: EmailService,
    private fb: FormBuilder,
    private doctorService: DoctorService,
    private physicalTherapyDoctorService: PhysicalTherapyDoctorService,
    private pharmacyMemberService: PharmacyMemberService,
    private labManagerService: LabmanagerService,
    private radiolologyMemberService: RadiologyMemberService,
    private patientVisitService: PhysicalTherapyVisitService
  ) {}

  ngOnInit(): void {
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.id = parseInt(decodedToken.nameid);
  }

  onFileSelected(event) {
    this.selectedFile = event.target.files[0];
  }
  onFilePhysicalTherapistSelected(event) {
    this.selectedPhysicalTherapistFile = event.target.files[0];
  }
  onFilePatientVisitSelected(event) {
    this.selectedPatientVisitFile = event.target.files[0];
  }

  uploadFile() {
    const fileReader = new FileReader();
    fileReader.readAsText(this.selectedFile, 'UTF-8');
    fileReader.onload = () => {
      const fileContent = fileReader.result;
      try {
        const upladodedData = JSON.parse(fileContent as string);

        //    Validate the data structure
        if (!Array.isArray(upladodedData.Patients)) {
          alert('Invalid upladodedData structure');
        }
        upladodedData.Patients.forEach((ph) => {
          if (typeof ph.Email !== 'string') {
            alert('Invalid data structure');
          }
        });

        upladodedData.Patients.forEach((data) => {
          let userBody = {
            Email: data.Email,
            Password: data.Password,
            UserName: data.Email,
            PhoneNumber: data.PhoneNumber,
          };
          this.userService.registerUser(userBody).subscribe((res) => {
            let body = {
              PatientId: res.Id,
              DoctorId: data.DoctorId,
              WardId: data.WardId,
              WardName: data.WardName,
              DepartmentId: data.DepartmentId,
              DepartmentName: data.DepartmentName,
              PatientFirstName: data.PatientFirstName,
              PatientLastName: data.PatientLastName,
              PatientDOB: data.PatientDOB,
              PatientGender: data.PatientGender,
              Email: data.Email,
              Password: data.Password,
              PhoneNumber: data.PhoneNumber,
              PatientAddress: data.PatientAddress,
              PatientHeight: data.PatientHeight,
              PatientWeight: data.PatientWeight,
              NameOfParent: data.NameOfParent,
              DoctorName: data.DoctorName,
              PatientNationalId: data.PatientNationalId,
              MaritalStatus: data.MaritalStatus,
              Occupation: data.Occupation,
              BloodGroup: data.BloodGroup,
              Status: 'Active',
              HospitalId: data.HospitalId,
            };

            this.patientService.registerPatient(body).subscribe({
              next: (res) => {
                // this.isSuccess = true;
                alert('Upload With success');

                this.emailService
                  .sendEmail({
                    Email: body.Email,
                    Subject: 'Patient Login Credentials',
                    Template: `<h3>Your Username ${userBody.UserName} please keep this safe.Temporary Password ${userBody.Password} You may reset password now at https://binsera.net. </h3> `,
                  })
                  .subscribe();

                const smsBodyDetails =
                  'Your Username ' +
                  userBody.UserName +
                  'Temporary Password' +
                  userBody.Password +
                  ' You may reset password now at https://binsera.net';
                let smsBody = {
                  PhoneNumber: data.PhoneNumber,
                  Body: smsBodyDetails,
                };
                this.emailService.sendSms(smsBody).subscribe();

                setTimeout(() => {}, 2000);
              },
            });
          });
        });
      } catch (error) {
        console.error('Invalid JSON format:', error);
      }
    };

    fileReader.onerror = (error) => console.log(error);
  }
  // uploadFileDoctors() {
  //   const fileReader = new FileReader();
  //   fileReader.readAsText(this.selectedPhysicalTherapistFile, 'UTF-8');
  //   fileReader.onload = () => {
  //     const fileContent = fileReader.result;
  //     try {
  //       const upladodedData = JSON.parse(fileContent as string);

  //       //    Validate the data structure
  //       if (!Array.isArray(upladodedData.Doctors)) {
  //         alert('Invalid upladodedData structure');
  //       }
  //       upladodedData.Doctors.forEach((dr) => {
  //         if (typeof dr.Email !== 'string') {
  //           alert('Invalid data structure');
  //         }
  //       });

  //       upladodedData.Doctors.forEach((data) => {
  //         let userBody = {
  //           Email: data.Email,
  //           Password: data.Password,
  //           UserName: data.Email,
  //           PhoneNumber: data.PhoneNumber,
  //         };
  //         console.log(userBody);
  //         this.userService.registerDoctor(userBody).subscribe((res) => {
  //           let body = {
  //             HospitalId: this.id,
  //             DoctorId: res.Id,
  //             DepartmentId: data.DepartmentId,
  //             DepartmentName: data.DepartmentName,
  //             DoctorName: data.DoctorName,
  //             NpiNumber: data.NpiNumber,
  //             LicenceNumber: data.LicenceNumber,
  //             DoctorQualification: data.DoctorQualification,
  //             DoctorSpecialization: data.DoctorSpecialization,
  //             DoctorDOB: data.DoctorDOB,
  //             Email: data.Email,
  //             PhoneNumber: data.PhoneNumber,
  //             DoctorAddress: data.DoctorAddress,
  //             StartDate: data.StartDate,
  //             Status: 'Active',
  //           };

  //           this.doctorService.registerDoctor(body).subscribe({
  //             next: (res) => {
  //               // this.isSuccess = true;
  //               alert('Upload With success');

  //               this.emailService
  //                 .sendEmail({
  //                   Email: body.Email,
  //                   Subject: ' Login Credentials',
  //                   Template: `<h3>Your Username ${userBody.UserName} please keep this safe.Temporary Password ${userBody.Password}. You may reset password now at https://binsera.net. </h3> `,
  //                 })
  //                 .subscribe();

  //               const smsBodyDetails =
  //                 'Your Username ' +
  //                 userBody.UserName +
  //                 'Temporary Password' +
  //                 userBody.Password +
  //                 ' You may reset password now at https://binsera.net';
  //               let smsBody = {
  //                 PhoneNumber: data.PhoneNumber,
  //                 Body: smsBodyDetails,
  //               };
  //               this.emailService.sendSms(smsBody).subscribe();

  //               setTimeout(() => {}, 2000);
  //             },
  //           });
  //         });
  //       });
  //     } catch (error) {
  //       console.error('Invalid JSON format:', error);
  //     }
  //   };

  //   fileReader.onerror = (error) => console.log(error);
  // }
  uploadFilePhyscalTherapist() {
    const fileReader = new FileReader();
    fileReader.readAsText(this.selectedPhysicalTherapistFile, 'UTF-8');
    fileReader.onload = () => {
      const fileContent = fileReader.result;
      try {
        const upladodedData = JSON.parse(fileContent as string);

        //    Validate the data structure
        if (!Array.isArray(upladodedData.PhysicalTherapyDoctors)) {
          alert('Invalid upladodedData structure');
        }
        upladodedData.PhysicalTherapyDoctors.forEach((dr) => {
          if (typeof dr.Email !== 'string') {
            alert('Invalid data structure');
          }
        });

        upladodedData.PhysicalTherapyDoctors.forEach((data) => {
          let userBody = {
            Email: data.Email,
            Password: data.Password,
            UserName: data.Email,
            PhoneNumber: data.PhoneNumber,
          };
          console.log(userBody);
          this.userService.registerPTDoctor(userBody).subscribe((res) => {
            let body = {
              PhysicalTherapyDoctorId: res.Id,
              DoctorName: data.DoctorName,
              Dob: data.Dob,
              Email: data.Email,
              PhoneNumber: data.PhoneNumber,
              NationalId: data.NationalId,
              Address: data.DoctorAddress,
              Status: status,
              Password: data.Password,
              DepartmentId: data.DepartmentId,
              DepartmentName: data.DepartmentName,
              DoctorSpecialization: data.DoctorSpecialization,
              DoctorQualification: data.DoctorQualification,
              HospitalId: data.HospitalId,
            };

            this.physicalTherapyDoctorService
              .registerPhysicalTherapyDoctor(body)
              .subscribe({
                next: (res) => {
                  // this.isSuccess = true;
                  alert('Upload With success');

                  this.emailService
                    .sendEmail({
                      Email: body.Email,
                      Subject: ' Login Credentials',
                      Template: `<h3>Your Username ${userBody.UserName} please keep this safe.Temporary Password ${userBody.Password}. You may reset password now at https://binsera.net. </h3> `,
                    })
                    .subscribe();

                  const smsBodyDetails =
                    'Your Username ' +
                    userBody.UserName +
                    'Temporary Password' +
                    userBody.Password +
                    ' You may reset password now at https://binsera.net';
                  let smsBody = {
                    PhoneNumber: data.PhoneNumber,
                    Body: smsBodyDetails,
                  };
                  this.emailService.sendSms(smsBody).subscribe();

                  setTimeout(() => {}, 2000);
                },
              });
          });
        });
      } catch (error) {
        console.error('Invalid JSON format:', error);
      }
    };

    fileReader.onerror = (error) => console.log(error);
  }

  //processed at front end
  uploadFilePhysicalTherapyVisit() {
    const fileReader = new FileReader();
    fileReader.readAsText(this.selectedPatientVisitFile, 'UTF-8');
    fileReader.onload = () => {
      const fileContent = fileReader.result;
      try {
        const upladodedData = JSON.parse(fileContent as string);

        //    Validate the data structure
        if (!Array.isArray(upladodedData.PhysicalTherapyVisits)) {
          alert('Invalid upladodedData structure');
        }
        upladodedData.PhysicalTherapyVisits.forEach((dr) => {
          if (typeof dr.DoctorName !== 'string') {
            alert('Invalid data structure');
          }
        });

        upladodedData.PhysicalTherapyVisits.forEach((data) => {
          let body = {
            PhysicalTherapyVisitId: data.PhysicalTherapyVisitId,
            TreatmentDate: data.TreatmentDate,
            Episode: data.Episode,
            Referrer: data.Referrer,
            DiagnosisCode: data.DiagnosisCode,
            CptCode: data.CptCode,
            NextFollowUp: data.NextFollowUp,
            Subjective: data.Subjective,
            Objective: data.Objective,
            Assessment: data.Assessment,
            Plan: data.Plan,
            JointMobilization: data.JointMobilization,
            SoftTissueMobilization: data.SoftTissueMobilization,
            Stretching: data.Stretching,
            SingleLegBalance: data.SingleLegBalance,
            StepUps: data.StepUps,
            QuadStr: data.QuadStr,
            BirdDogs: data.BirdDogs,
            Rotations: data.Rotations,
            PelvicTilts: data.PelvicTilts,
            AbdominalISOs: data.AbdominalISOs,
            LionLatStretch: data.LionLatStretch,
            ChildsPose: data.ChildsPose,
            CatandCamelStretch: data.CatandCamelStretch,
            DownwardDog: data.DownwardDog,
            createdDate: data.createdDate,
            updatedDate: data.updatedDate,
            Status: data.Status,
            TimeIn: data.TimeIn,
            TimeOut: data.TimeOut,
            Signature: data.Signature,
            DoctorName: data.DoctorName,
            DoctorId: data.DoctorId,
            DiagnosisCodes: data.DiagnosisCodes,
            SoftwareFee: data.SoftwareFee,
            Unit: data.Unit,
            Duration: data.Duration,
            ConsultationFee: data.ConsultationFee,
            PatientId: data.PatientId,
            HospitalId: data.HospitalId,
          };

          this.patientVisitService
            .registerPhysicalTherapyVisit(body)
            .subscribe({
              next: (res) => {
                // this.isSuccess = true;
                alert('Upload With success');
              },
            });
        });
      } catch (error) {
        console.error('Invalid JSON format:', error);
      }
    };

    fileReader.onerror = (error) => console.log(error);
  }
  //processed at backend
  uploadPatientVisit() {
    const fileReader = new FileReader();
    fileReader.readAsText(this.selectedPatientVisitFile, 'UTF-8');
    fileReader.onload = () => {
      const fileContent = fileReader.result;
      try {
        const data = JSON.parse(fileContent as string);

        // Validate the data structure
        if (!Array.isArray(data.PhysicalTherapyVisits)) {
          alert('Invalid data structure');
        }
        data.PhysicalTherapyVisits.forEach((sub) => {
          if (typeof sub.DoctorName !== 'string') {
            alert('Invalid data structure');
          }
          // Add more checks as necessary...
        });

        this.patientVisitService.getPatientVisitJsonData(data).subscribe(
          (res) => {
            alert('Upload With success');
          },
          (err) => {
            alert('Error uploading the data');
          }
        );
        // this.http.post('https://your-api-url.com/upload', data)
        // .subscribe(
        //     response => console.log(response),
        //     error => console.log(error)
        // );
      } catch (error) {
        console.error('Invalid JSON format:', error);
      }
    };
    fileReader.onerror = (error) => console.log(error);
  }
}
