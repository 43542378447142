import { Platform } from '@angular/cdk/platform';
import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DoctornoteService } from 'src/app/services/doctornote.service';
import { PatientvisitService } from 'src/app/services/patientvisit.service';

@Component({
  selector: 'app-roundbook',
  templateUrl: './roundbook.component.html',
  styleUrls: ['./roundbook.component.css'],
})
export class RoundbookComponent implements OnInit {
  id = 0;
  @ViewChild('canvas', { static: true })
  canvasRef!: ElementRef<HTMLCanvasElement>;
  private ctx!: CanvasRenderingContext2D;
  private paint = false;
  private lineWidth = 2;
  private colour = 'black';
  private coord = { x: 0, y: 0 };

  ngAfterViewInit(): void {
    this.ctx = this.canvasRef.nativeElement.getContext('2d')!;
    this.resize();
    this.addEventListeners();
  }

  @HostListener('window:resize')
  onWindowResize(): void {
    this.resize();
  }

  private addEventListeners(): void {
    this.canvasRef.nativeElement.addEventListener('mousedown', (e) =>
      this.startPainting(e)
    );
    this.canvasRef.nativeElement.addEventListener('mouseup', () =>
      this.stopPainting()
    );
    this.canvasRef.nativeElement.addEventListener('mousemove', (e) =>
      this.sketch(e)
    );

    const colours =
      document.querySelectorAll<HTMLDivElement>('.colours .colour');
    colours.forEach((singleColour) => {
      singleColour.addEventListener('click', () => {
        this.lineWidth = 2;
        this.colour = singleColour.getAttribute('colour')!;
      });
    });

    const tools = document.querySelectorAll<HTMLDivElement>('.tools .tool');
    tools.forEach((tool) => {
      tool.addEventListener('click', () => {
        switch (tool.getAttribute('tool')) {
          case 'pencil':
            this.lineWidth = 2;
            this.colour = 'black';
            break;
          case 'clear':
            this.ctx.clearRect(
              0,
              0,
              this.canvasRef.nativeElement.width,
              this.canvasRef.nativeElement.height
            );
            this.lineWidth = 2;
            this.colour = 'black';
            break;
          case 'eraser':
            this.lineWidth = 20;
            this.colour = 'white';
            break;
          default:
            this.lineWidth = 2;
            this.colour = 'black';
        }
      });
    });
  }

  private resize(): void {
    const canvasData = this.ctx.getImageData(
      0,
      0,
      this.ctx.canvas.width,
      this.ctx.canvas.height
    );
    this.ctx.canvas.width = window.innerWidth;
    this.ctx.canvas.height = window.innerHeight;
    this.ctx.putImageData(canvasData, 0, 0);
  }

  private getPosition(e: MouseEvent): void {
    this.coord.x = e.clientX - this.canvasRef.nativeElement.offsetLeft;
    this.coord.y = e.clientY - this.canvasRef.nativeElement.offsetTop;
  }

  private startPainting(e: MouseEvent): void {
    this.paint = true;
    this.getPosition(e);
  }

  private stopPainting(): void {
    this.paint = false;
  }

  private sketch(e: MouseEvent): void {
    if (this.paint) {
      this.ctx.beginPath();

      this.ctx.lineWidth = this.lineWidth;
      this.ctx.lineCap = 'round';
      this.ctx.strokeStyle = this.colour;

      this.ctx.moveTo(this.coord.x, this.coord.y);
      this.getPosition(e);
      this.ctx.lineTo(this.coord.x, this.coord.y);

      this.ctx.stroke();
    }
  }

  constructor(
    private plt: Platform,
    private route: ActivatedRoute,
    private router: Router,
    private doctornoteService: DoctornoteService,
    private patientVisitService: PatientvisitService
  ) {}

  exportImage() {
    const dataUrl: string = this.canvasRef.nativeElement.toDataURL();

    let stImag = `<p><img src="${dataUrl}"/></p>`;

    let body = {
      PatientNote: stImag,
    };

    this.patientVisitService
      .updatePatientVisit(this.id, body)
      .subscribe((res) => {
        this.router.navigate(['/doctorDash/']);
      });
  }
  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
      // console.log(this.id);
    });
  }
}
