import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PatientbookingService } from 'src/app/services/patientbooking.service';
import { BarcodeScanner } from '@capacitor-community/barcode-scanner';
import * as moment from 'moment';
import { PhysicaltherapyBookingService } from 'src/app/services/physicaltherapy-booking.service';
import { PatientService } from 'src/app/services/patient.service';
@Component({
  selector: 'app-scanner',
  templateUrl: './scanner.component.html',
  styleUrls: ['./scanner.component.css'],
})
export class ScannerComponent implements OnInit {
  id = 0;
  isSuccess = false;
  Token = 0;

  constructor(
    private patientService: PatientService,
    private patientBooking: PhysicaltherapyBookingService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
  }

  // https://www.npmjs.com/package/angularx-qrcode
  qrCodeString = 'This is a secret qr code message';
  scannedResult: any;
  content_visibility = '';

  // startScan() {
  //   this.barcodeScanner.scan().then(barcodeData => {
  //     console.log('Barcode data', barcodeData);
  //     this.scannedResult = barcodeData?.text;
  //    }).catch(err => {
  //        console.log('Error', err);
  //    });
  // }

  async checkPermission() {
    try {
      // check or request permission
      const status = await BarcodeScanner.checkPermission({ force: true });
      if (status.granted) {
        // the user granted permission
        return true;
      }
      return false;
    } catch (e) {
      // console.log(e);
    }
  }

  async startScan(e) {
    // console.log(e)
    try {
      const permission = await this.checkPermission();
      // if(!permission) {
      //   debugger
      //   return;
      // }
      await BarcodeScanner.hideBackground();
      document.querySelector('body').classList.add('scanner-active');
      this.content_visibility = 'hidden';
      const result = await BarcodeScanner.startScan();
      // console.log(result);
      BarcodeScanner.showBackground();
      document.querySelector('body').classList.remove('scanner-active');
      this.content_visibility = '';
      if (result?.hasContent) {
        this.scannedResult = result.content;
        const Id = parseInt(this.scannedResult);

        this.patientService
          .getPhysicalTherapyBookings(Id)
          .subscribe((res: any) => {
            if (res.PhysicalTherapyBookings?.length) {
              res.PhysicalTherapyBookings.forEach((booking, id) => {
                let dat = Date.now();
                //  moment(dat).format('MM/DD/YYYY');
                const today = moment(dat);
                const appointmentDate = moment(booking.VisitDate);
                const isSameDay = appointmentDate.isSame(today, 'day');

                if (isSameDay && booking.Status.includes('not confirmed')) {
                  const model = {
                    DoctorName: booking.DoctorName,
                    DoctorSpecialization: booking.DoctorSpecialization,
                    VisitDate: booking.VisitDate,
                    VisitType: booking.VisitType,
                    VisitTime: booking.VisitTime,
                    Message: booking.Message,
                    Status: 'Confirmed',
                    Tocken: booking.DoctorName + Date.now(),
                    // Tocken: Math.floor(Math.random() * 100) + 1

                    // Tocken: Date.now().toString().padStart(2,'0') +  Date.now().toString().padEnd(2,'0')
                  };
                  this.Token = model.Tocken;
                  // res.PatientBookings[id].Status = 'Confirmed';
                  this.patientBooking
                    .updatePhysicalTherapyBooking(
                      booking.PhysicalTherapyBookingId,
                      model
                    )
                    .subscribe({
                      next: (res: any) => {
                        if (res.StatusCode === 200) {
                          this.isSuccess = true;
                        }
                      },
                    });
                  // this.patientBooking
                  //   .updateBookingStatus(
                  //     booking.PhysicalTherapyBookingId,
                  //     model
                  //   )
                  //   .subscribe({
                  //     next: (res: any) => {
                  //       if (res.StatusCode === 200) {
                  //         this.isSuccess = true;
                  //       }
                  //     },
                  //   });
                }
              });
            }
          });
      }
    } catch (e) {
      this.stopScan();
    }
  }

  stopScan() {
    BarcodeScanner.showBackground();
    BarcodeScanner.stopScan();
    document.querySelector('body').classList.remove('scanner-active');
    this.content_visibility = '';
  }

  ngOnDestroy(): void {
    this.stopScan();
  }
}
