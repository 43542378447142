import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Doctor } from 'src/app/Models/doctor.model';
import { DoctorService } from 'src/app/services/doctor.service';

import { UserService } from 'src/app/services/user.service';
import { WardService } from 'src/app/services/ward.service';
import { RoomService } from 'src/app/services/room.service';
import { Room } from 'src/app/Models/room.model';
import { Ward } from 'src/app/Models/ward.model';
import { BedService } from 'src/app/services/bed.service';
import { Bed } from '../../Models/bed.model';
import { PhysicalTherapyDoctor } from 'src/app/Models/physicaltherapydoctor.model';
import { PhysicalTherapyDoctorService } from 'src/app/services/physical-therapy-doctor.service';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import * as moment from 'moment';
import { PatientService } from 'src/app/services/patient.service';
import { WebcamImage } from 'ngx-webcam';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-updatepatient',
  templateUrl: './updatepatient.component.html',
  styleUrls: ['./updatepatient.component.css'],
})
export class UpdatepatientComponent implements OnInit {
  id = 0;
  Gender: string[] = ['Male', 'Female', 'Other'];
  bloodGroup: string[] = ['A+', 'B+', 'O+', 'AB+', 'A-', 'O-', 'B-', 'AB-'];
  maritalStatus: string[] = ['Married', 'Single', 'Widow', 'Other'];
  doctorSpeciality: string[] = ['Physical Therapy'];
  doctorList: PhysicalTherapyDoctor[] = [];

  StatusArr: any[] = [
    { title: 'Active', value: 'Active' },
    { title: 'Discharged', value: 'Discharged' },
  ];
  IsNewPatientArr: any[] = [
    { head: 'Yes', value: 'true' },
    { head: 'No', value: 'No' },
  ];
  isSuccess: boolean;
  PatientId: any;
  filteredOptions: Observable<string[]>;
  Codes: any[] = [];
  imageUrl: string;

  constructor(
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private patientService: PatientService,
    private userService: UserService,
    private router: Router,
    private doctorService: PhysicalTherapyDoctorService,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });

    this.getDoctors();

    this.patientService.getPatientById(this.id).subscribe((res) => {
      this.ptFrm.patchValue(res);
      this.PatientEmail.setValue(res.PatientEmail);
      this.PatientId.setValue(res.PatientId);
      // this.PatientFirstName.setValue(res.PatientFirstName)
      // this.PatientLastName.setValue(res.PatientLastName)
      // this.PatientDOB.setValue(res.PatientDOB)
      // this.PatientDOB.setValue(moment(res.PatientDOB).format('MM/DD/YYYY'));

      // this.PatientGender.setValue(res.PatientGender)
      // this.PatientPhone.setValue(res.PatientPhone)
      // this.PatientAddress.setValue(res.PatientAddress)
      this.DoctorName.setValue(res.DoctorName);
      // this.PatientNationalId.setValue(res.PatientNationalId)
      // this.MaritalStatus.setValue(res.MaritalStatus)
      // this.Occupation.setValue(res.Occupation)
      // this.BloodGroup.setValue(res.BloodGroup)
      // this.WardName.setValue(res.WardName)
      // this.RoomNo.setValue(res.RoomNo)
      // this.BedNumber.setValue(res.BedNumber)
      // this.Status.setValue(res.Status);
      this.IsNewPatient.setValue(res.IsNewPatient);
    });

    this.countryCodes();
    this.filteredOptions = this.ptFrm.controls.CountryCode.valueChanges.pipe(
      startWith(''),
      map((value: string) => this._filter(value || ''))
    );
  }

  ptFrm = this.fb.group({
    PatientFirstName: [''],
    PatientLastName: [''],
    PatientDOB: [''],
    PatientGender: [''],
    Email: [''],
    CountryCode: ['+1', Validators.required],
    PhoneNumber: [''],
    PatientAddress: [''],
    DoctorName: [''],
    PatientNationalId: [''],
    MaritalStatus: [''],
    Occupation: [''],
    BloodGroup: [''],
    PhysicalTherapyDoctorId: [],
    ReferredDoctor: [''],
    Status: [''],
    IsNewPatient: [''],
  });

  get CountryCode() {
    return this.ptFrm.get('CountryCode');
  }
  get IsNewPatient() {
    return this.ptFrm.get('IsNewPatient');
  }
  get ReferredDoctor() {
    return this.ptFrm.get('ReferredDoctor');
  }

  get PhysicalTherapyDoctorId() {
    return this.ptFrm.get('PhysicalTherapyDoctorId');
  }
  get PatientFirstName() {
    return this.ptFrm.get('PatientFirstName');
  }
  get PatientLastName() {
    return this.ptFrm.get('PatientLastName');
  }
  get PatientDOB() {
    return this.ptFrm.get('PatientDOB');
  }
  get PatientGender() {
    return this.ptFrm.get('PatientGender');
  }
  get PatientEmail() {
    return this.ptFrm.get('Email');
  }
  get PhoneNumber() {
    return this.ptFrm.get('PhoneNumber');
  }
  get PatientAddress() {
    return this.ptFrm.get('PatientAddress');
  }

  get DoctorName() {
    return this.ptFrm.get('DoctorName');
  }
  get PatientNationalId() {
    return this.ptFrm.get('PatientNationalId');
  }
  get MaritalStatus() {
    return this.ptFrm.get('MaritalStatus');
  }
  get Occupation() {
    return this.ptFrm.get('Occupation');
  }
  get BloodGroup() {
    return this.ptFrm.get('BloodGroup');
  }

  get Status() {
    return this.ptFrm.get('Status');
  }
  getDoctors() {
    this.doctorService.getPhysicalTherapyDoctors().subscribe((res) => {
      this.doctorList = res;
    });
  }
  image(e) {
    let formData = new FormData();
    let files = e.target.files;
    formData.append('file', files[0], files[0].name);

    this.patientService.imageUpload(formData).subscribe(
      (res) => {
        let stImg = environment.imgurl + res['path'];
        this.imageUrl = stImg;
        // this.imageUrl = `<p><img src="${stImg}"/></p>`;
      },
      (err) => console.log(err)
    );
  }

  private trigger: Subject<any> = new Subject();
  public webcamImage!: WebcamImage;
  private nextWebcam: Subject<any> = new Subject();
  sysImage = '';
  public getSnapshot(e): void {
    e.preventDefault;
    this.trigger.next(void 0);
  }
  public captureImg(webcamImage: WebcamImage): void {
    this.webcamImage = webcamImage;
    this.sysImage = webcamImage!.imageAsDataUrl;
  }
  public get invokeObservable(): Observable<any> {
    return this.trigger.asObservable();
  }
  public get nextWebcamObservable(): Observable<any> {
    return this.nextWebcam.asObservable();
  }

  formSubmit() {
    let patientFirstName = this.ptFrm.value['PatientFirstName'];
    let patientLastName = this.ptFrm.value['PatientLastName'];
    let patientDOB = this.ptFrm.value['PatientDOB'];
    patientDOB = moment(patientDOB).format('MM/DD/YYYY');
    let patientGender = this.ptFrm.value['PatientGender'];
    let patientEmail = this.ptFrm.value['Email'];
    let isNewPatient = this.ptFrm.value['IsNewPatient'];
    let phoneNumber = this.ptFrm.value['PhoneNumber'];
    // let phoneNumber =
    //   this.ptFrm.value['CountryCode'] + this.ptFrm.value['PhoneNumber'];
    let patientAddress = this.ptFrm.value['PatientAddress'];
    let referredDoctor = this.ptFrm.value['ReferredDoctor'];
    let patientNationalId = this.ptFrm.value['PatientNationalId'];
    let DoctorName = this.ptFrm.value['DoctorName'];
    let mMaritalStatus = this.ptFrm.value['MaritalStatus'];
    let occupation = this.ptFrm.value['Occupation'];
    let bloodGroup = this.ptFrm.value['BloodGroup'];
    let doctorId = this.ptFrm.value['PhysicalTherapyDoctorId'];
    let idoctorName = this.doctorList.filter(
      (it) => it.PhysicalTherapyDoctorId == doctorId
    )[0].DoctorName;

    // let userBody = {
    //   Email: patientEmail,
    //   UserName: phoneNumber,
    //   PhoneNumber: phoneNumber,
    // };
    // this.userService.updateUser(this.id, userBody).subscribe((res) => {});

    let body = {
      PhysicalTherapyDoctorId: doctorId,
      PatientFirstName: patientFirstName,
      PatientLastName: patientLastName,
      PatientDOB: patientDOB,
      PatientGender: patientGender,
      PatientEmail: patientEmail,
      PhoneNumber: phoneNumber,
      ReferredDoctor: referredDoctor,
      PatientAddress: patientAddress,
      DoctorName: idoctorName,
      PatientNationalId: patientNationalId,
      MaritalStatus: mMaritalStatus,
      Occupation: occupation,
      BloodGroup: bloodGroup,
      Status: this.Status.value,
      IsNewPatient: this.IsNewPatient.value,
      PatientImage: this.sysImage ? this.sysImage : this.imageUrl,
    };

    this.patientService.updatePatient(this.id, body).subscribe((res) => {
      this.isSuccess = true;
      this.router.navigate(['/hospitalDash']);
    });
  }

  public countryCodes() {
    this.http.get('/assets/json/country-codes.json').subscribe({
      next: (res: any) => {
        this.Codes = res.map((x) => x.dial_code);
      },
    });
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.Codes.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }
  facingMode: string = 'user'; //Set front camera
  allowCameraSwitch = true;

  public get videoOptions(): MediaTrackConstraints {
    const result: MediaTrackConstraints = {};
    if (this.facingMode && this.facingMode !== '') {
      result.facingMode = { ideal: this.facingMode };
    }
    return result;
  }
}
