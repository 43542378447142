import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MedicationService } from 'src/app/services/medication.service';
import { PatientService } from 'src/app/services/patient.service';

@Component({
  selector: 'app-printmedication',
  templateUrl: './printmedication.component.html',
  styleUrls: ['./printmedication.component.css'],
})
export class PrintmedicationComponent {
  id = 0;
  drugList;
  constructor(
    private medicationService: MedicationService,
    private route: ActivatedRoute,
    private router: Router,
    private patientService: PatientService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });

    this.getDrugs(this.id);
  }

  getDrugs(id) {
    this.medicationService.getDrugs(id).subscribe((res) => {
      this.drugList = res;
    });
  }
}
