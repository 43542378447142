<div class="conatiner mx-5 my-5">
    <button type="button" (click)="print()" class="btn btn-primary float-right" data-toggle="modal"
        data-target=".bd-example-modal-lg"> Print </button>
    <div class="row">
        <div class="col-md-10" *ngFor="let item of hippaComplianceList">
            <h2>
                {{hospital.HospitalName}}

            </h2>
            <mat-card class="mat-elevation-z8 mt-3 ">
                <h2 class="clr">Hipaa Compliance Consent</h2>

                <p class="float-right">Date : {{item.createdDate | date: 'short' }}</p>

                <hr>
                <mat-card-content>
                    <p>Our Notice of Privacy Practices provides information about how we may use or disclose protected
                        health information.</p>
                    <p>The notice contains a patient’s rights section describing your rights under the law. You
                        ascertain that by your signature that
                        you have reviewed our notice before signing this consent.</p>

                    <p>The terms of the notice may change, if so, you will be notified at your next visit to update your
                        signature/date.</p>
                    <p>You have the right to restrict how your protected health information is used and disclosed for
                        treatment, payment or
                        healthcare operations. We are not required to agree with this restriction, but if we do, we
                        shall honor this agreement. The
                        HIPAA (Health Insurance Portability and Accountability Act of 1996) law allows for the use of
                        the information for treatment,
                        payment, or healthcare operations.</p>

                    <p>By signing this form, you consent to our use and disclosure of your protected healthcare
                        information and potentially
                        anonymous usage in a publication. You have the right to revoke this consent in writing, signed
                        by you. However, such a
                        revocation will not be retroactive.</p>

                    <p>
                        By signing this form, I understand that: </p>
                    <ul>
                        <li>Protected health information may be disclosed or used for treatment, payment, or healthcare
                            operations.</li>
                        <li>The practice reserves the right to change the privacy policy as allowed by law.</li>
                        <li>The practice has the right to restrict the use of the information but the practice does not
                            have to agree to those
                            restrictions.</li>
                        <li>The patient has the right to revoke this consent in writing at any time and all full
                            disclosures will then cease.</li>
                        <li>The practice may condition receipt of treatment upon execution of this consent.</li>
                    </ul>


                    <tr>
                        <th> May we phone, email, or send a text to you to confirm appointments? </th>

                        <td>{{item?.PhoneEmailTextConsent ?'Yes':'No'}}</td>
                    </tr>

                    <tr>
                        <th> May we leave a message on your answering machine at home or on your cell phone? </th>
                        <td>{{item?.LeaveVoiceMessageConsent ?'Yes':'No'}}</td>

                    </tr>


                    <tr>
                        <th> May we discuss your medical condition with any member of your family? : </th>
                        <td>{{item?.ConsetToDiscussMedicalConditionWithFamilyMember ?'Yes':'No'}}</td>

                    </tr>
                    <tr>
                        <th>If YES, please name the members allowed: </th>
                        <td>{{item?.AuthorizedFamilyMemberName}}</td>

                    </tr>

                    <tr>
                        <th>This consent was signed by: </th>
                        <td>{{item?.PatientName}}</td>

                    </tr>

                    <tr>
                        <th> Signauture: </th>
                        <td><img src="{{item?.Signauture}}" alt=""></td>

                    </tr>

                </mat-card-content>
                <button (click)="delete(item.HippaComplianceId)" mat-mini-fab
                    color="accent"><mat-icon>delete</mat-icon></button>
                <!-- <button class="float-right" [routerLink]="['/editFamilyHistory/',item.FamilyHistoryId]" color="primary" mat-mini-fab><mat-icon>edit</mat-icon> </button>
    -->

            </mat-card>
        </div>
    </div>
</div>