import { HttpClient } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { Patient } from 'src/app/Models/patient.model';
import { PhysicalTherapyDoctor } from 'src/app/Models/physicaltherapydoctor.model';
import { PhysicalTherapyDoctorDate } from 'src/app/Models/physicaltherapydoctordate.model';
import { PtTimeSlot } from 'src/app/Models/pttimeslot.model';
import { EmailService } from 'src/app/services/email.service';
import { HospitalService } from 'src/app/services/hospital.service';
import { PatientService } from 'src/app/services/patient.service';
import { PhysicalTherapyDoctorDateService } from 'src/app/services/physical-therapy-doctor-date.service';
import { PhysicalTherapyDoctorService } from 'src/app/services/physical-therapy-doctor.service';
import { PhysicaltherapyBookingService } from 'src/app/services/physicaltherapy-booking.service';

@Component({
  selector: 'app-view-pt-docctor-availability',
  templateUrl: './view-pt-docctor-availability.component.html',
  styleUrls: ['./view-pt-docctor-availability.component.css'],
})
export class ViewPtDocctorAvailabilityComponent implements OnInit {
  helper = new JwtHelperService();
  id = 0;
  myToken = window.localStorage.getItem('token');
  options: string[] = ['New Patient', 'Follow Up'];
  doctorSpeciality: string[] = ['Physical Therapy'];
  doctorList: PhysicalTherapyDoctor[];
  @Output() showAppointmentList = new EventEmitter<string>();

  TimeSltId: any;
  DateId: any;
  DrId = 0;
  Codes: any[] = [];

  t = ['08:00 AM', '08:00 AM'];
  timeSlots: PtTimeSlot[] = [];

  takenSlots: any;
  UnAvailableDates: PhysicalTherapyDoctorDate[] = [];
  DoctorAvalableDateList: PhysicalTherapyDoctorDate[] = [];
  selectedItem;
  selectedSlot;
  selectedDate: PhysicalTherapyDoctorDate;
  isSuccess = false;
  filteredOptions: Observable<string[]>;
  patient: Patient;
  constructor(
    private appointmentService: PhysicaltherapyBookingService,
    private fb: UntypedFormBuilder,
    private doctorService: PhysicalTherapyDoctorService,
    private hospitalService: HospitalService,

    private http: HttpClient
  ) {}

  ngOnInit(): void {
    this.createTimeSlots();
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.id = parseInt(decodedToken.nameid);

    this.getDoctors(this.id);
    this.countryCodes();
    this.filteredOptions =
      this.addVisitFrm.controls.CountryCode.valueChanges.pipe(
        startWith(''),
        map((value: string) => this._filter(value || ''))
      );
  }

  createTimeSlots() {
    const TimeSlots = [];
    for (let hours = 0; hours < 24; hours++) {
      for (let min = 0; min < 60; min = min + 15) {
        if (hours > 8 && hours < 18) {
          const minStr = String(min).padStart(2, '0');
          const isAm = hours < 12 || hours == 0;
          const formattedTime = `${hours % 12 ? hours % 12 : 12}:${minStr} ${
            isAm ? 'am' : 'pm'
          }`;
          TimeSlots.push(formattedTime);
        }
      }
    }
    return TimeSlots;
  }

  unAvailable(date: Date): boolean {
    if (this.UnAvailableDates) {
      const unAvailableDates = this.UnAvailableDates.filter(
        (date) => date.UnAvailableDate
      ).map((d) => new Date(d.UnAvailableDate).toISOString().slice(0, 10));
      const currentDate = date.toISOString().slice(0, 10);
      return (
        !unAvailableDates?.includes(currentDate) &&
        moment(date).valueOf() >= moment().startOf('day').valueOf() &&
        this.disableWeekend(date)
      );
    }
    return true;
  }

  boundDateStart = this.unAvailable.bind(this);

  addVisitFrm = this.fb.group({
    DoctorName: [''],
    DoctorSpecialization: ['', Validators.required],
    PhysicalTherapyDoctorId: [''],
    PhysicalTherapyDoctorDateId: [],
    NotificationConsent: [null, Validators.required],
  });

  get visitForm() {
    return this.addVisitFrm.controls;
  }

  get PhysicalTherapyDoctorDateId() {
    return this.addVisitFrm.get('PhysicalTherapyDoctorDateId');
  }
  get PhysicalTherapyDoctorId() {
    return this.addVisitFrm.get('PhysicalTherapyDoctorId');
  }
  get VisitType() {
    return this.addVisitFrm.get('VisitType');
  }
  get DoctorName() {
    return this.addVisitFrm.get('DoctorName');
  }

  get DoctorSpecialization() {
    return this.addVisitFrm.get('DoctorSpecialization');
  }

  get VisitDate() {
    return this.addVisitFrm.get('VisitDate');
  }
  get VisitTime() {
    return this.addVisitFrm.get('VisitTime');
  }

  get Message() {
    return this.addVisitFrm.get('Message');
  }
  get PhoneNumber() {
    return this.addVisitFrm.get('PhoneNumber');
  }

  datePickerDisabled = true;
  getDoctors(id) {
    this.hospitalService.getDoctors(id).subscribe((res) => {
      this.doctorList = res.PhysicalTherapyDoctors;
    });
  }

  getTimeSlots() {
    this.doctorService
      .getDoctorAvailableDates(this.PhysicalTherapyDoctorId.value)
      .subscribe((res) => {
        this.UnAvailableDates = res.PhysicalTherapyDoctorDates;
        this.datePickerDisabled = false;
      });
  }

  disableWeekend(d) {
    if (d.getDay() != 0 && d.getDay() != 6) {
      return d;
    }
  }
  formSubmit() {}

  public checkForTimeSlots(selectedDate) {
    const today = selectedDate.toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    });
    const bookedSlots = this.appointmentService.getPhysicalTherapyBookings();
    bookedSlots.subscribe({
      next: (res) => {
        this.timeSlots = this.createTimeSlots();
        this.takenSlots = res
          .filter((x) => x.BookedSlots && x.VisitDate == today)
          .map((y) => y.BookedSlots);
      },
    });
  }

  public selectSlot(selectedSlot): void {
    this.selectedSlot = selectedSlot;
  }
  public selectDate(selectedDate): void {
    this.checkForTimeSlots(selectedDate.value);
  }

  public selectTime() {
    this.selectedSlot = '';
  }

  public countryCodes() {
    this.http.get('/assets/json/country-codes.json').subscribe({
      next: (res: any) => {
        this.Codes = res.map((x) => x.dial_code);
      },
    });
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.Codes.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }
}

//   doctoDatesList: PhysicalTherapyDoctorDate[];
//   displayedColumns: string[] = ['PhysicalTherapyDoctorId', 'DoctorName','UnAvailableDate'];
//   dataSource: MatTableDataSource<any>;
// constructor(
//   private doctorDatesService:PhysicalTherapyDoctorDateService

// ){}
//   ngOnInit(): void {

// this.getDoctorAvailability();
// }
// @ViewChild(MatPaginator) paginator: MatPaginator;
// @ViewChild(MatSort) sort: MatSort;

// getDoctorAvailability(){
//   this.doctorDatesService.getPhysicalTherapyDoctorDates().subscribe(res=>{
//     this.dataSource = new MatTableDataSource(res);
//     this.dataSource.paginator=this.paginator;
//   })
// }

// tabChanged(event: MatTabChangeEvent): void {
//   if (event.index === 0) {
//     this.getDoctorAvailability();

//   }
// }

// applyFilter(event: Event) {
//   const filterValue = (event.target as HTMLInputElement).value;
//   this.dataSource.filter = filterValue.trim().toLowerCase();

//   if (this.dataSource.paginator) {
//     this.dataSource.paginator.firstPage();
//   }
// }
// }
