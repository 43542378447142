<div>

    <button mat-raised-button color="primary" [routerLink]="['/physicalTherapyDash/']"><mat-icon
            mat-list-icon>undo</mat-icon>
        Dashboard</button>
</div>
<div class="container">
    <div class="regFrm mat-elevation-z8">
        <div class="row">
            <div class="col-lg">
                <h1>New Patient Visit</h1>
                <h4>PT SOAP</h4>

                <form [formGroup]="Frm" (ngSubmit)="formSubmit()" class="example-form">
                    <div class="row">

                        <div class="col-lg-4">
                            <mat-form-field class="example-full-width">
                                <mat-label>Episode</mat-label>
                                <input type="text" formControlName="Episode" matInput>
                                <mat-error>Please enter Episode Name eg:Physical Therapy</mat-error>
                            </mat-form-field>
                        </div>

                        <div class="col-lg-4">
                            <mat-form-field class="example-full-width">
                                <mat-label>Referrer</mat-label>
                                <input type="text" formControlName="Referrer" matInput>
                                <mat-error>Please enter Refrer</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-lg-4">
                            <mat-form-field class="example-full-width">
                                <mat-label>Next Follow Up</mat-label>
                                <input type="date" formControlName="NextFollowUp" matInput>
                                <mat-error>Please enter Next Follow Up</mat-error>
                            </mat-form-field>

                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-12">
                            <!-- <mat-form-field class="example-full-width">
                  <mat-label>Diagnosis Codes</mat-label>
                  <input type="text" formControlName="DiagnosisCodes" matInput>
                  <mat-error>Please enter Diagnosis Codes</mat-error>
                </mat-form-field>  -->
                            <!-- <mat-form-field class="example-full-width">
                  <mat-label>Diagnosis Codes</mat-label>
                  <mat-select formControlName="DiagnosisCode">
  
                    <lib-mat-select-search [list]="cptCodeList" [searchProperties]="['Code']"
                      (filtered)="filteredOptions = $event">
                    </lib-mat-select-search>
  
                    <mat-option *ngFor="let ml of filteredOptions" [value]="ml.Code">
  
  
                      {{ml.Code}}</mat-option>
  
                  </mat-select>
  
                  <mat-error>Diagnosis Codes required</mat-error>
  
                </mat-form-field> -->


                        </div>


                    </div>
                    <h3>Click + button to Add Multiple Diagnosis codes</h3>
                    <div class="" formArrayName="DiagnosisCodes">
                        <div class="row" *ngFor="let item of DiagnosisCodes.controls; let i=index">
                            <ng-container [formGroup]="item">
                                <input type="text" hidden formControlName="DiagnosisCodeId">
                                <!-- <div class="col-lg-3">

                                    <mat-form-field class="example-full-width">
                                        <mat-label>Diagnosis Code Id</mat-label>
                                        <input type="text" matInput formControlName="DiagnosisCodeId"
                                            (change)="updateInvoiceItems(i)">
                                        <mat-error>Please Enter Value</mat-error>

                                    </mat-form-field>
                                </div> -->

                                <div class="col-lg-11">

                                    <mat-form-field class="example-full-width">
                                        <mat-label>Diagnosis Codes</mat-label>
                                        <mat-select formControlName="Code">
                                            <mat-option>
                                                <lib-mat-select-search [list]="icdCodeList"
                                                    [searchProperties]="['Code']" (filtered)="filteredOptions = $event">
                                                </lib-mat-select-search>
                                            </mat-option>
                                            <cdk-virtual-scroll-viewport [ngStyle]="{height: '200px'}" [itemSize]="20">
                                                <mat-option *cdkVirtualFor="let entry of filteredOptions"
                                                    (click)="updateCode(item.value.DiagnosisCodeId,ml.Code)"
                                                    [value]="entry.Code">
                                                    {{entry.Code}}
                                                </mat-option>
                                            </cdk-virtual-scroll-viewport>


                                        </mat-select>


                                        <mat-error>Diagnosis Codes required</mat-error>

                                    </mat-form-field>

                                </div>







                                <div class="col-lg-1">
                                    <button type="button" mat-mini-fab
                                        (click)="removeItem(i, item.value.DiagnosisCodeId)"><mat-icon>remove</mat-icon></button>

                                </div>
                            </ng-container>
                        </div>

                        <button type="button" mat-mini-fab color="primary"
                            (click)="addItem()"><mat-icon>add</mat-icon></button>


                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-form-field class="example-full-width">
                                <mat-label>Subjective</mat-label>
                                <input type="text" formControlName="Subjective" matInput>
                                <mat-error>Please enter Subjective</mat-error>
                            </mat-form-field>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-form-field class="example-full-width">
                                <mat-label>Objective</mat-label>
                                <input type="text" formControlName="Objective" matInput>
                                <mat-error>Please enter Objective</mat-error>
                            </mat-form-field>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-form-field class="example-full-width">
                                <mat-label>Assessment</mat-label>

                                <textarea matInput formControlName="Assessment" cdkTextareaAutosize
                                    #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                                    cdkAutosizeMaxRows="140"></textarea>
                                <mat-error>Please enter Assessment</mat-error>
                            </mat-form-field>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-form-field appearance="fill" class="example-full-width">
                                <mat-label>Plan</mat-label>

                                <textarea matInput formControlName="Plan" cdkTextareaAutosize
                                    #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="5"
                                    cdkAutosizeMaxRows="140"></textarea>
                            </mat-form-field>

                        </div>

                    </div>
                    <hr>
                    <h2>Treatment Flowsheet</h2>
                    <h4>Manual Therapy</h4>
                    <div class="row">
                        <div class="col-lg-4">
                            <mat-form-field class="example-full-width">
                                <mat-label>Joint Mobilization</mat-label>
                                <input type="text" formControlName="JointMobilization" matInput>
                                <mat-error>Please enter Joint Mobilization</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-lg-4">
                            <mat-form-field class="example-full-width">
                                <mat-label>Soft Tissue Mobilization</mat-label>
                                <input type="text" formControlName="SoftTissueMobilization" matInput>
                                <mat-error>Please enter Soft Tissue Mobilization</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-lg-4">
                            <mat-form-field class="example-full-width">
                                <mat-label>Stretching</mat-label>
                                <input type="text" formControlName="Stretching" matInput>
                                <mat-error>Please enter Stretching</mat-error>
                            </mat-form-field>
                        </div>

                    </div>
                    <hr>
                    <h2>Lower Extremity exercise flowsheet</h2>

                    <div class="row">
                        <div class="col-lg-4">
                            <mat-form-field class="example-full-width">
                                <mat-label>Single Leg Balance</mat-label>
                                <mat-select formControlName="SingleLegBalance">
                                    <mat-option *ngFor="let single of autoFillItems"
                                        [value]="single.value">{{single.value}}</mat-option>
                                </mat-select>
                                <!-- <input type="text" formControlName="SingleLegBalance" matInput> -->
                                <mat-error>Please enter Single Leg Balance</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-lg-4">
                            <mat-form-field class="example-full-width">
                                <mat-label>Step Up's</mat-label>
                                <mat-select formControlName="StepUps">
                                    <mat-option *ngFor="let step of autoFillItems"
                                        [value]="step.value">{{step.value}}</mat-option>
                                </mat-select>
                                <!-- <input type="text" formControlName="StepUps" matInput> -->
                                <mat-error>Please enter Step Up's</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-lg-4">
                            <mat-form-field class="example-full-width">
                                <mat-label>Quad Str</mat-label>
                                <mat-select formControlName="QuadStr">
                                    <mat-option *ngFor="let str of autoFillItems"
                                        [value]="str.value">{{str.value}}</mat-option>
                                </mat-select>
                                <!-- <input type="text" formControlName="QuadStr" matInput> -->
                                <mat-error>Please enter Quad Str</mat-error>
                            </mat-form-field>
                        </div>

                    </div>
                    <h2>Spine Exercise Flow Sheet</h2>
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-form-field class="example-full-width">
                                <mat-label>Bird Dogs</mat-label>
                                <mat-select formControlName="BirdDogs">
                                    <mat-option *ngFor="let bird of autoFillItems"
                                        [value]="bird.value">{{bird.value}}</mat-option>
                                </mat-select>
                                <!-- <input type="text" formControlName="BirdDogs" matInput> -->
                                <mat-error>Please enter Bird Dogs</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <h4>Thoracic Spine</h4>
                    <div class="row">
                        <div class="col-lg-4">
                            <mat-form-field class="example-full-width">
                                <mat-label>Rotations</mat-label>
                                <mat-select formControlName="Rotations">
                                    <mat-option *ngFor="let rota of autoFillItems"
                                        [value]="rota.value">{{rota.value}}</mat-option>
                                </mat-select>
                                <!-- <input type="text" formControlName="Rotations" matInput> -->
                                <mat-error>Please enter Rotations</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-lg-4">
                            <mat-form-field class="example-full-width">
                                <mat-label>Pelvic Tilts</mat-label>
                                <mat-select formControlName="PelvicTilts">
                                    <mat-option *ngFor="let pelvic of autoFillItems"
                                        [value]="pelvic.value">{{pelvic.value}}</mat-option>
                                </mat-select>
                                <!-- <input type="text" formControlName="PelvicTilts" matInput> -->
                                <mat-error>Please enter Pelvic Tilts</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-lg-4">
                            <mat-form-field class="example-full-width">
                                <mat-label>Abdominal ISO's</mat-label>
                                <mat-select formControlName="AbdominalISOs">
                                    <mat-option *ngFor="let abdominal of autoFillItems"
                                        [value]="abdominal.value">{{abdominal.value}}</mat-option>
                                </mat-select>
                                <!-- <input type="text" formControlName="AbdominalISOs" matInput> -->
                                <mat-error>Please enter Abdominal ISO's</mat-error>
                            </mat-form-field>
                        </div>



                    </div>
                    <div class="row">
                        <div class="col-lg-3">
                            <mat-form-field class="example-full-width">
                                <mat-label>Lion/Lat Stretch</mat-label>
                                <mat-select formControlName="LionLatStretch">
                                    <mat-option *ngFor="let lionLat of autoFillItems"
                                        [value]="lionLat.value">{{lionLat.value}}</mat-option>
                                </mat-select>
                                <!-- <input type="text" formControlName="LionLatStretch" matInput> -->
                                <mat-error>Please enter LionLatStretch</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-lg-3">
                            <mat-form-field class="example-full-width">
                                <mat-label>Child's Pose</mat-label>
                                <mat-select formControlName="ChildsPose">
                                    <mat-option *ngFor="let child of autoFillItems"
                                        [value]="child.value">{{child.value}}</mat-option>
                                </mat-select>
                                <!-- <input type="text" formControlName="ChildsPose" matInput> -->
                                <mat-error>Please enter Child's Pose</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-lg-3">
                            <mat-form-field class="example-full-width">
                                <mat-label>Cat & Camel Stretch</mat-label>
                                <mat-select formControlName="CatandCamelStretch">
                                    <mat-option *ngFor="let catCamel of autoFillItems"
                                        [value]="catCamel.value">{{catCamel.value}}</mat-option>
                                </mat-select>
                                <!-- <input type="text" formControlName="CatandCamelStretch" matInput> -->
                                <mat-error>Please enter Cat & Camel Stretch</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-lg-3">
                            <mat-form-field class="example-full-width">
                                <mat-label>Downward Dog</mat-label>
                                <mat-select formControlName="DownwardDog">
                                    <mat-option *ngFor="let downDog of autoFillItems"
                                        [value]="downDog.value">{{downDog.value}}</mat-option>
                                </mat-select>
                                <!-- <input type="text" formControlName="DownwardDog" matInput> -->
                                <mat-error>Please enter Downward Dog</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">

                        <div class="col-lg-3">
                            <mat-form-field class="example-full-width">
                                <mat-label>Doctor Name</mat-label>
                                <input type="text" formControlName="DoctorName" matInput>
                                <mat-error>Please enter Doctor Name</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-lg-3">
                            <mat-form-field class="example-full-width">
                                <mat-label>Doctor Signature</mat-label>
                                <input type="text" formControlName="Signature" matInput>
                                <mat-error>Please enter Signature</mat-error>
                            </mat-form-field>
                        </div>


                    </div>
                    <div class="row">
                        <div class="col-lg-3">
                            <mat-form-field class="example-full-width">
                                <mat-label>Time In</mat-label>
                                <input [ngxTimepicker]="picker" matInput formControlName="TimeIn">
                                <ngx-material-timepicker #picker></ngx-material-timepicker>
                                <mat-error>Please enter Time In</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-lg-3">
                            <mat-form-field class="example-full-width">
                                <mat-label>Time Out</mat-label>
                                <input [ngxTimepicker]="picker1" matInput formControlName="TimeOut">
                                <ngx-material-timepicker #picker1></ngx-material-timepicker>
                                <mat-error>Please enter Time Out</mat-error>
                            </mat-form-field>
                        </div>
                    </div>


                    <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
                        Successfully submitted
                        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <button class="float-right lgBtCl" mat-button>
                        <mat-icon>save</mat-icon>
                    </button>
                </form>
            </div>
        </div>
    </div>
</div>