import { Component, OnInit } from '@angular/core';
import { HospitalService } from 'src/app/services/hospital.service';
import { Hospital } from '../../Models/hospital.model';
import { ActivatedRoute } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { PatientService } from 'src/app/services/patient.service';

@Component({
  selector: 'app-view-hospital',
  templateUrl: './view-hospital.component.html',
  styleUrls: ['./view-hospital.component.css'],
})
export class ViewHospitalComponent implements OnInit {
  helper = new JwtHelperService();
  showItem = '';
  id = 0;
  myToken = window.localStorage.getItem('token');
  hospitalList: Hospital[] = [];
  hospital: Hospital;
  hospitalId;

  constructor(
    private hospitalService: HospitalService,
    private patientService: PatientService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    // let decodedToken = this.helper.decodeToken(this.myToken);
    // this.id = parseInt(decodedToken.nameid);

    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
    this.patientService.getpatienHospitalId(this.id).subscribe((res) => {
      this.hospitalId = res.Hospital.HospitalId;
      this.getHospitalById(this.hospitalId);
    });
    // this.getHospital();
  }

  getHospital() {
    this.hospitalService.getHospitals().subscribe((res) => {
      this.hospitalList = res;
    });
  }

  getHospitalById(id) {
    this.hospitalService.getHospitalById(id).subscribe((res) => {
      this.hospital = res;
    });
  }
}
