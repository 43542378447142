import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PatientIdProof } from 'src/app/Models/patientidproof.model';
import { PatientIdproofService } from 'src/app/services/patient-idproof.service';
import { PatientService } from 'src/app/services/patient.service';

@Component({
  selector: 'app-view-patient-idproof',
  templateUrl: './view-patient-idproof.component.html',
  styleUrls: ['./view-patient-idproof.component.css'],
})
export class ViewPatientIdproofComponent implements OnInit {
  patientIdProofList: PatientIdProof[];
  id = 0;
  displayStyle = 'none';
  Iddocument: string = '';

  constructor(
    private patientService: PatientService,
    private route: ActivatedRoute,
    private ptIdProofService: PatientIdproofService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
    this.getPatientIdProof(this.id);
  }
  openPopup() {
    this.displayStyle = 'block';
  }
  closePopup() {
    this.displayStyle = 'none';
  }

  getPatientIdProof(id) {
    this.patientService.getPatientIdProof(id).subscribe((res) => {
      this.patientIdProofList = res.PatientIdProofs;
    });
  }
  delete(id) {
    this.ptIdProofService.deletePatientIdproof(id).subscribe((res) => {
      this.getPatientIdProof(this.id);
    });
  }

  public viewIdBack(report: PatientIdProof): void {
    // console.log('report: ', report);
    this.Iddocument = report.IdImageBack;
  }
}
