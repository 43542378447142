import { Component, OnInit, ViewChild } from '@angular/core';
import { HospitalService } from 'src/app/services/hospital.service';
import { Hospital } from '../../Models/hospital.model';
import { ActivatedRoute } from '@angular/router';
import { Department } from '../../Models/department.model';
import { DepartmentService } from 'src/app/services/department.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatTabChangeEvent } from '@angular/material/tabs';

@Component({
  selector: 'app-view-department',
  templateUrl: './view-department.component.html',
  styleUrls: ['./view-department.component.css'],
})
export class ViewDepartmentComponent implements OnInit {
  id = 0;
  hospital: Hospital;
  dptList: Department[] = [];
  dataSource: MatTableDataSource<Department>;

  constructor(
    private hospitalService: HospitalService,
    private route: ActivatedRoute,
    private dptService: DepartmentService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
    this.getDepartment();
    //  this.getDpt(this.id)
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  tabChanged(event: MatTabChangeEvent): void {
    if (event.index === 0) {
      this.getDepartment();
    }
  }

  getDpt(id) {
    this.hospitalService.getDepartment(id).subscribe((res) => {
      // this.dptList = res.Departments;
    });
  }

  getDepartment() {
    this.dptService.getDepartments().subscribe((res) => {
      this.dptList = res;
    });
  }

  deleteDpt(id) {
    this.dptService.deleteDepartment(id).subscribe((res) => {
      // this.dptList=this.dptList.filter(x=>x.DepartmentId !=id)
      this.getDepartment();
    });
  }
}
