import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SocialHistory } from 'src/app/Models/socialhistory.model';
import { PatientService } from 'src/app/services/patient.service';
import { SocialhistoryService } from 'src/app/services/socialhistory.service';

@Component({
  selector: 'app-view-socialhistory',
  templateUrl: './view-socialhistory.component.html',
  styleUrls: ['./view-socialhistory.component.css'],
})
export class ViewSocialhistoryComponent implements OnInit {
  id = 0;
  socialHistoryList: SocialHistory[] = [];
  constructor(
    private route: ActivatedRoute,
    private patientService: PatientService,
    private socialHistoryService: SocialhistoryService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });

    this.getSocialHistory(this.id);
  }

  getSocialHistory(id) {
    this.patientService.getSocialHistory(id).subscribe((res) => {
      this.socialHistoryList = res.SocialHistorys;
    });
  }

  print() {
    window.print();
  }

  delete(id) {
    this.socialHistoryService.deleteSocialHistory(id).subscribe((res) => {
      this.getSocialHistory(this.id);
    });
  }
}
