import { BrowserModule } from '@angular/platform-browser';
import { NgModule, isDevMode } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';

import { PatientdashboardComponent } from './components/patientdashboard/patientdashboard.component';
import { AdmindashboardComponent } from './components/admindashboard/admindashboard.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material/material.module';
import { from } from 'rxjs';
import { NavbarComponent } from './navbar/navbar.component';

import { ErrorComponent } from './components/error/error.component';

import { AddpatientComponent } from './components/addpatient/addpatient.component';
import { ViewpatientComponent } from './components/viewpatient/viewpatient.component';
import { UpdatepatientComponent } from './components/updatepatient/updatepatient.component';

import { PatientdetailsComponent } from './components/patientdetails/patientdetails.component';
import { NotepadComponent } from './components/notepad/notepad.component';
import { AddAllergyComponent } from './components/add-allergy/add-allergy.component';
import { ViewAllergyComponent } from './components/view-allergy/view-allergy.component';
import { UpdateAllergyComponent } from './components/update-allergy/update-allergy.component';

import { AddSurgicalHistoryComponent } from './components/add-surgical-history/add-surgical-history.component';
import { ViewSurgicalHistoryComponent } from './components/view-surgical-history/view-surgical-history.component';
import { UpdateSurgicalHistoryComponent } from './components/update-surgical-history/update-surgical-history.component';
import { AddHospitalComponent } from './components/add-hospital/add-hospital.component';
import { UpdateHospitalComponent } from './components/update-hospital/update-hospital.component';
import { ViewHospitalComponent } from './components/view-hospital/view-hospital.component';
import { HospitaldetailsComponent } from './components/hospitaldetails/hospitaldetails.component';
import { HospitalDashboardComponent } from './components/hospital-dashboard/hospital-dashboard.component';
import { AddDepartmentComponent } from './components/add-department/add-department.component';
import { ViewDepartmentComponent } from './components/view-department/view-department.component';
import { AddAppointmentComponent } from './components/add-appointment/add-appointment.component';
import { AddInvoiceComponent } from './components/add-invoice/add-invoice.component';
import { ViewInvoiceComponent } from './components/view-invoice/view-invoice.component';
import { ViewAllInvoicesComponent } from './components/view-all-invoices/view-all-invoices.component';

import { EmergencyContactComponent } from './components/emergency-contact/emergency-contact.component';
import { AddEmergencyContactComponent } from './components/add-emergency-contact/add-emergency-contact.component';
import { AddPatientInsuranceComponent } from './components/add-patient-insurance/add-patient-insurance.component';
import { PatientsummaryComponent } from './components/patientsummary/patientsummary.component';
import { PtmedicalrecordsComponent } from './components/ptmedicalrecords/ptmedicalrecords.component';
import { PtinvoicesComponent } from './components/ptinvoices/ptinvoices.component';
import { ViewAppointmentComponent } from './components/view-appointment/view-appointment.component';
import { PatientComponent } from './components/patient/patient.component';
import { PrintinvoiceComponent } from './components/printinvoice/printinvoice.component';
import { ViewAllAppointmentsComponent } from './components/view-all-appointments/view-all-appointments.component';
import { UpdateInvoiceComponent } from './components/update-invoice/update-invoice.component';
import { AddCptcodeComponent } from './components/add-cptcode/add-cptcode.component';
import { ViewCptcodeComponent } from './components/view-cptcode/view-cptcode.component';

import { AddAppointmentPatientComponent } from './components/add-appointment-patient/add-appointment-patient.component';

import { SetpasswordComponent } from './components/setpassword/setpassword.component';
import { ViewAppointmentsTodayComponent } from './components/view-appointments-today/view-appointments-today.component';
import {
  DatePipe,
  HashLocationStrategy,
  LocationStrategy,
} from '@angular/common';
import { MatPaginatorModule } from '@angular/material/paginator';
import { DrawPadComponent } from './components/draw-pad/draw-pad.component';

import { RoundbookComponent } from './components/roundbook/roundbook.component';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatNativeDateModule } from '@angular/material/core';
import { TimeSlotComponent } from './components/time-slot/time-slot.component';
import { AddTimeSlotComponent } from './components/add-time-slot/add-time-slot.component';

import { AddInvoiceItemsComponent } from './components/add-invoice-items/add-invoice-items.component';

import { NotFoundComponentComponent } from './components/not-found-component/not-found-component.component';

import { SigningPadComponent } from './components/signing-pad/signing-pad.component';
import { ViewPatientInsuranceComponent } from './components/view-patient-insurance/view-patient-insurance.component';
import { AddPatientIdproofComponent } from './components/add-patient-idproof/add-patient-idproof.component';
import { ViewPatientIdproofComponent } from './components/view-patient-idproof/view-patient-idproof.component';
import { QRCodeModule } from 'angularx-qrcode';
import { AddMriComponent } from './components/add-mri/add-mri.component';
import { ViewMriComponent } from './components/view-mri/view-mri.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { NgxDicomModule } from 'ngx-dicom';
import { StripeModule } from 'stripe-angular';

import { SuperAdminDashboardComponent } from './components/super-admin-dashboard/super-admin-dashboard.component';
import { ScannerComponent } from './components/scanner/scanner.component';
import { NgxScannerQrcodeModule } from 'ngx-scanner-qrcode';
import { WebcamModule } from 'ngx-webcam';
import { CompanyComponent } from './components/company/company.component';
import { AddCompanyComponent } from './components/add-company/add-company.component';
import { PatientImportComponent } from './components/patient-import/patient-import.component';

import { CommonModule } from '@angular/common';
import { AllPatientsComponent } from './components/all-patients/all-patients.component';
import { AuthInterceptor } from './services/auth.interceptor';
import { PrintmedicationComponent } from './components/printmedication/printmedication.component';
import { AddDischargeSummaryComponent } from './components/add-discharge-summary/add-discharge-summary.component';
import { ViewDischargeSummaryComponent } from './components/view-discharge-summary/view-discharge-summary.component';
import { PrintDischargeSummaryComponent } from './components/print-discharge-summary/print-discharge-summary.component';
import { UpdateDischargeSummaryComponent } from './components/update-discharge-summary/update-discharge-summary.component';
import { AddVaccineComponent } from './components/add-vaccine/add-vaccine.component';
import { ViewVaccineComponent } from './components/view-vaccine/view-vaccine.component';

import { ToastrModule } from 'ngx-toastr';

import { PatientIntakeItemsComponent } from './components/patient-intake-items/patient-intake-items.component';
import { TwofaComponent } from './components/twofa/twofa.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { AddSocialhistoryComponent } from './components/add-socialhistory/add-socialhistory.component';
import { ViewSocialhistoryComponent } from './components/view-socialhistory/view-socialhistory.component';
import { UpdateSocialhistoryComponent } from './components/update-socialhistory/update-socialhistory.component';
import { AddFamilyhistoryComponent } from './components/add-familyhistory/add-familyhistory.component';
import { ViewFamilyhistoryComponent } from './components/view-familyhistory/view-familyhistory.component';
import { UpdateFamilyhistoryComponent } from './components/update-familyhistory/update-familyhistory.component';
import { AddMedicalHistoryComponent } from './components/add-medical-history/add-medical-history.component';
import { AddHippaComplianceComponent } from './components/add-hippa-compliance/add-hippa-compliance.component';
import { ViewHippaComplianceComponent } from './components/view-hippa-compliance/view-hippa-compliance.component';
import { AddPhysicalTherapyDoctorComponent } from './components/add-physical-therapy-doctor/add-physical-therapy-doctor.component';
import { ViewPhysicalTherapyDoctorComponent } from './components/view-physical-therapy-doctor/view-physical-therapy-doctor.component';
import { AddPhysicalTherapyVisitComponent } from './components/add-physical-therapy-visit/add-physical-therapy-visit.component';
import { ViewPhysicalTherapyVisitComponent } from './components/view-physical-therapy-visit/view-physical-therapy-visit.component';
import { AddPtInitialEvaluationComponent } from './components/add-pt-initial-evaluation/add-pt-initial-evaluation.component';
import { ViewPtInitialEvaluationComponent } from './components/view-pt-initial-evaluation/view-pt-initial-evaluation.component';
import { PhysicalTherapyDashboardComponent } from './components/physical-therapy-dashboard/physical-therapy-dashboard.component';
import { PhysicalTherapyPatientComponent } from './components/physical-therapy-patient/physical-therapy-patient.component';
import { AddPhysicalTherapyBookingComponent } from './components/add-physical-therapy-booking/add-physical-therapy-booking.component';
import { ViewPhysicalTherapyBookingComponent } from './components/view-physical-therapy-booking/view-physical-therapy-booking.component';
import { ViewPtDocctorAvailabilityComponent } from './components/view-pt-docctor-availability/view-pt-docctor-availability.component';
import { AddPtDocctorAvailabilityComponent } from './components/add-pt-docctor-availability/add-pt-docctor-availability.component';
import { SmsComponent } from './components/sms/sms.component';
import { EmailComponent } from './components/email/email.component';
import { ResetpasswordComponent } from './components/resetpassword/resetpassword.component';
import { ViewMedicalHistoryComponent } from './components/view-medical-history/view-medical-history.component';
import { AddPhysicaltherapyBookingByadminComponent } from './components/add-physicaltherapy-booking-byadmin/add-physicaltherapy-booking-byadmin.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AddUploadRadiologyReportComponent } from './components/add-upload-radiology-report/add-upload-radiology-report.component';
import { ViewUploadRadiologyReportComponent } from './components/view-upload-radiology-report/view-upload-radiology-report.component';
import { SafeHtmlPipe } from './safe-html.pipe';
import { ViewAllhospitalsComponent } from './components/view-allhospitals/view-allhospitals.component';
import { MyPatientsComponent } from './components/my-patients/my-patients.component';
import { MyAppointmentsComponent } from './components/my-appointments/my-appointments.component';
import { MyTodaysAppointmentsComponent } from './components/my-todays-appointments/my-todays-appointments.component';
import { ViewDoctorAppointmentsTodayComponent } from './components/view-doctor-appointments-today/view-doctor-appointments-today.component';
import { ViewDoctorAppointmentsComponent } from './components/view-doctor-appointments/view-doctor-appointments.component';
import { MatSelectSearchModule } from 'mat-select-search';
import { UpdateAppointmentComponent } from './components/update-appointment/update-appointment.component';
import { PatientDataScannerComponent } from './components/patient-data-scanner/patient-data-scanner.component';
import { UpdatePhysicalTherapyVisitComponent } from './components/update-physical-therapy-visit/update-physical-therapy-visit.component';
import { UpdatePtInitalEvaluationComponent } from './components/update-pt-inital-evaluation/update-pt-inital-evaluation.component';
import { CompletedFormsComponent } from './components/completed-forms/completed-forms.component';
import { AddAppointmentByDoctorComponent } from './components/add-appointment-by-doctor/add-appointment-by-doctor.component';
import { ViewPhysicalTherapyVisitPatientsideComponent } from './components/view-physical-therapy-visit-patientside/view-physical-therapy-visit-patientside.component';
import { PrintInvoicePatientSideComponent } from './components/print-invoice-patient-side/print-invoice-patient-side.component';
import { PatientHasBalanceTopayComponent } from './components/patient-has-balance-topay/patient-has-balance-topay.component';
import { AddRefferalComponent } from './components/add-refferal/add-refferal.component';
import { ViewRefferalComponent } from './components/view-refferal/view-refferal.component';
import { PatientHistoryComponent } from './components/patient-history/patient-history.component';
import { UpdateAppointmentByDoctorComponent } from './components/update-appointment-by-doctor/update-appointment-by-doctor.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { PatientHasUnpaidAmountComponent } from './components/patient-has-unpaid-amount/patient-has-unpaid-amount.component';
import { PatientHasInsurancePendingPaymentComponent } from './components/patient-has-insurance-pending-payment/patient-has-insurance-pending-payment.component';
import { BulkImportPatientComponent } from './components/bulk-import-patient/bulk-import-patient.component';
import { InvoiceHasABalanceComponent } from './components/invoice-has-a-balance/invoice-has-a-balance.component';
import { CalculatorComponent } from './components/calculator/calculator.component';
import { InsuranceClaimComponent } from './components/insurance-claim/insurance-claim.component';
import { AddOfficePolicyConsentComponent } from './components/add-office-policy-consent/add-office-policy-consent.component';
import { ViewOfficePolicyConsentComponent } from './components/view-office-policy-consent/view-office-policy-consent.component';
import { AddConsentForServiceComponent } from './components/add-consent-for-service/add-consent-for-service.component';
import { ViewConsentForServiceComponent } from './components/view-consent-for-service/view-consent-for-service.component';
import { PatientViewConsentForServiceComponent } from './components/patient-view-consent-for-service/patient-view-consent-for-service.component';
import { PatientViewOfficePolicyComponent } from './components/patient-view-office-policy/patient-view-office-policy.component';
import { PatientViewHippaComplianceComponent } from './components/patient-view-hippa-compliance/patient-view-hippa-compliance.component';
import { DryNeedlingConsentComponent } from './components/dry-needling-consent/dry-needling-consent.component';
import { AddDryNeedlingConsentComponent } from './components/add-dry-needling-consent/add-dry-needling-consent.component';
import { ViewDryNeedlingConsentComponent } from './components/view-dry-needling-consent/view-dry-needling-consent.component';
import { PatientViewDryNeedlingConsentComponent } from './components/patient-view-dry-needling-consent/patient-view-dry-needling-consent.component';
import { PatientViewExersiceLiabilityWaiverComponent } from './components/patient-view-exersice-liability-waiver/patient-view-exersice-liability-waiver.component';
import { ViewExersiceLiabilityWaiverComponent } from './components/view-exersice-liability-waiver/view-exersice-liability-waiver.component';
import { AddExersiceLiabilityWaiverComponent } from './components/add-exersice-liability-waiver/add-exersice-liability-waiver.component';
import { FaxComponent } from './components/fax/fax.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { ViewPatientPaymentComponent } from './components/view-patient-payment/view-patient-payment.component';
import { ViewPaymentHistoryComponent } from './components/view-payment-history/view-payment-history.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    PatientdashboardComponent,
    AdmindashboardComponent,
    NavbarComponent,
    ErrorComponent,
    AddpatientComponent,
    ViewpatientComponent,
    UpdatepatientComponent,
    PatientdetailsComponent,
    NotepadComponent,
    AddAllergyComponent,
    ViewAllergyComponent,
    UpdateAllergyComponent,
    AddSurgicalHistoryComponent,
    ViewSurgicalHistoryComponent,
    UpdateSurgicalHistoryComponent,
    AddHospitalComponent,
    UpdateHospitalComponent,
    ViewHospitalComponent,
    HospitaldetailsComponent,
    HospitalDashboardComponent,
    AddDepartmentComponent,
    ViewDepartmentComponent,
    AddAppointmentComponent,
    AddInvoiceComponent,
    ViewInvoiceComponent,
    ViewAllInvoicesComponent,
    EmergencyContactComponent,
    AddEmergencyContactComponent,
    AddPatientInsuranceComponent,
    PatientsummaryComponent,
    PtmedicalrecordsComponent,
    PtinvoicesComponent,
    ViewAppointmentComponent,
    PatientComponent,
    PrintinvoiceComponent,
    ViewAllAppointmentsComponent,
    UpdateInvoiceComponent,
    AddCptcodeComponent,
    ViewCptcodeComponent,
    AddAppointmentPatientComponent,
    SetpasswordComponent,
    ViewAppointmentsTodayComponent,
    DrawPadComponent,
    RoundbookComponent,
    TimeSlotComponent,
    AddTimeSlotComponent,

    AddInvoiceItemsComponent,

    NotFoundComponentComponent,

    SigningPadComponent,

    ViewPatientInsuranceComponent,

    AddPatientIdproofComponent,

    ViewPatientIdproofComponent,

    AddMriComponent,

    ViewMriComponent,

    SuperAdminDashboardComponent,
    ScannerComponent,
    CompanyComponent,
    AddCompanyComponent,
    PatientImportComponent,
    AllPatientsComponent,
    PrintmedicationComponent,
    AddDischargeSummaryComponent,
    ViewDischargeSummaryComponent,
    PrintDischargeSummaryComponent,
    UpdateDischargeSummaryComponent,
    AddVaccineComponent,
    ViewVaccineComponent,

    PatientIntakeItemsComponent,
    TwofaComponent,
    AddSocialhistoryComponent,
    ViewSocialhistoryComponent,
    UpdateSocialhistoryComponent,
    AddFamilyhistoryComponent,
    ViewFamilyhistoryComponent,
    UpdateFamilyhistoryComponent,
    AddMedicalHistoryComponent,
    AddHippaComplianceComponent,
    ViewHippaComplianceComponent,
    AddPhysicalTherapyDoctorComponent,
    ViewPhysicalTherapyDoctorComponent,
    AddPhysicalTherapyVisitComponent,
    ViewPhysicalTherapyVisitComponent,
    AddPtInitialEvaluationComponent,
    ViewPtInitialEvaluationComponent,
    PhysicalTherapyDashboardComponent,
    PhysicalTherapyPatientComponent,
    AddPhysicalTherapyBookingComponent,
    ViewPhysicalTherapyBookingComponent,
    ViewPtDocctorAvailabilityComponent,
    AddPtDocctorAvailabilityComponent,
    SmsComponent,
    EmailComponent,
    ResetpasswordComponent,
    ViewMedicalHistoryComponent,
    AddPhysicaltherapyBookingByadminComponent,
    AddUploadRadiologyReportComponent,
    ViewUploadRadiologyReportComponent,
    SafeHtmlPipe,
    ViewAllhospitalsComponent,
    MyPatientsComponent,
    MyAppointmentsComponent,
    MyTodaysAppointmentsComponent,
    ViewDoctorAppointmentsComponent,
    ViewDoctorAppointmentsTodayComponent,
    UpdateAppointmentComponent,
    PatientDataScannerComponent,
    UpdatePhysicalTherapyVisitComponent,
    UpdatePtInitalEvaluationComponent,
    CompletedFormsComponent,
    AddAppointmentByDoctorComponent,
    ViewPhysicalTherapyVisitPatientsideComponent,
    PrintInvoicePatientSideComponent,
    PatientHasBalanceTopayComponent,
    ViewRefferalComponent,
    AddRefferalComponent,
    PatientHistoryComponent,
    UpdateAppointmentByDoctorComponent,
    PrivacyPolicyComponent,
    PatientHasUnpaidAmountComponent,
    PatientHasInsurancePendingPaymentComponent,
    BulkImportPatientComponent,
    InvoiceHasABalanceComponent,
    CalculatorComponent,
    InsuranceClaimComponent,
    AddOfficePolicyConsentComponent,
    ViewOfficePolicyConsentComponent,
    AddConsentForServiceComponent,
    ViewConsentForServiceComponent,
    PatientViewConsentForServiceComponent,
    PatientViewOfficePolicyComponent,
    PatientViewHippaComplianceComponent,
    DryNeedlingConsentComponent,
    AddDryNeedlingConsentComponent,
    ViewDryNeedlingConsentComponent,
    PatientViewDryNeedlingConsentComponent,
    PatientViewExersiceLiabilityWaiverComponent,
    ViewExersiceLiabilityWaiverComponent,
    AddExersiceLiabilityWaiverComponent,
    FaxComponent,
    ViewPatientPaymentComponent,
    ViewPaymentHistoryComponent,
    // NgxMatTimepickerModule
  ],
  imports: [
    ScrollingModule,
    ToastrModule.forRoot(),
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    MatPaginatorModule,
    MatNativeDateModule,
    NgxMaterialTimepickerModule,
    NgApexchartsModule,
    NgxDicomModule,
    QRCodeModule,
    WebcamModule,
    NgxScannerQrcodeModule,
    NgOtpInputModule,
    MatSelectSearchModule,
    ClipboardModule,
    StripeModule.forRoot(
      'pk_live_51IyUaAJnyILmQggyZxkWE0x9h2yKfuLi8mA7nJLMscjV73ZBTxRQv1Jfm2kkodBZMJ2i1Oi5KmPGBi0piMVkyzZ000WSawXnfZ'
    ),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),

    // NgxMaterialTimepickerModule.setLocale('en-US'),
  ],
  providers: [
    DatePipe,
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },

    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
  ],

  bootstrap: [AppComponent],
})
export class AppModule {}
