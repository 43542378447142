import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { Doctor } from 'src/app/Models/doctor.model';
import { DoctorDate } from 'src/app/Models/doctordate.model';
import { PhysicalTherapyDoctor } from 'src/app/Models/physicaltherapydoctor.model';
import { PhysicalTherapyDoctorDate } from 'src/app/Models/physicaltherapydoctordate.model';
import { PtTimeSlot } from 'src/app/Models/pttimeslot.model';
import { EmailService } from 'src/app/services/email.service';
import { PhysicalTherapyDoctorDateService } from 'src/app/services/physical-therapy-doctor-date.service';
import { PhysicalTherapyDoctorService } from 'src/app/services/physical-therapy-doctor.service';
import { PhysicaltherapyBookingService } from 'src/app/services/physicaltherapy-booking.service';

@Component({
  selector: 'app-add-physical-therapy-booking',
  templateUrl: './add-physical-therapy-booking.component.html',
  styleUrls: ['./add-physical-therapy-booking.component.css'],
})
export class AddPhysicalTherapyBookingComponent implements OnInit {
  id = 0;
  options: string[] = ['New Patient', 'Follow Up'];
  doctorSpeciality: string[] = ['Physical Therapy', ,];
  doctorList: PhysicalTherapyDoctor[] = [];
  helper = new JwtHelperService();
  myToken = window.localStorage.getItem('token');
  @Output() showAppointmentList = new EventEmitter<string>();

  TimeSltId: any;
  DateId: any;
  DrId = 0;
  Codes: any[] = [];

  t = ['08:00 AM', '08:00 AM'];
  timeSlots: PtTimeSlot[] = [];

  takenSlots: any;
  UnAvailableDates: PhysicalTherapyDoctorDate[] = [];
  DoctorAvalableDateList: PhysicalTherapyDoctorDate[] = [];
  selectedItem;
  selectedSlot;
  selectedDate: PhysicalTherapyDoctorDate;
  isSuccess = false;
  filteredOptions: Observable<string[]>;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private appointmentService: PhysicaltherapyBookingService,
    private fb: UntypedFormBuilder,
    private doctorService: PhysicalTherapyDoctorService,
    private doctorDateService: PhysicalTherapyDoctorDateService,
    private emailService: EmailService,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    this.createTimeSlots();
    let decodedToken = this.helper.decodeToken(this.myToken);

    this.id = parseInt(decodedToken.nameid);

    this.getDoctors();
    this.countryCodes();
    this.filteredOptions =
      this.addVisitFrm.controls.CountryCode.valueChanges.pipe(
        startWith(''),
        map((value: string) => this._filter(value || ''))
      );
  }

  createTimeSlots() {
    const TimeSlots = [];
    for (let hours = 0; hours < 24; hours++) {
      for (let min = 0; min < 60; min = min + 15) {
        if (hours > 8 && hours < 18) {
          const minStr = String(min).padStart(2, '0');
          const isAm = hours < 12 || hours == 0;
          const formattedTime = `${hours % 12 ? hours % 12 : 12}:${minStr} ${
            isAm ? 'am' : 'pm'
          }`;
          TimeSlots.push(formattedTime);
        }
      }
    }
    return TimeSlots;
  }

  unAvailable(date: Date): boolean {
    if (this.UnAvailableDates) {
      const unAvailableDates = this.UnAvailableDates.filter(
        (date) => date.UnAvailableDate
      ).map((d) => new Date(d.UnAvailableDate).toISOString().slice(0, 10));
      const currentDate = date.toISOString().slice(0, 10);
      return (
        !unAvailableDates?.includes(currentDate) &&
        moment(date).valueOf() >= moment().startOf('day').valueOf()
      );
    }
    return true;
  }

  boundDateStart = this.unAvailable.bind(this);

  addVisitFrm = this.fb.group({
    DoctorName: [''],
    DoctorSpecialization: ['', Validators.required],
    VisitType: ['', Validators.required],
    VisitDate: ['', Validators.required],
    VisitTime: [''],
    CountryCode: ['+1', Validators.required],
    PhoneNumber: ['', Validators.required],
    Message: ['', Validators.required],
    PhysicalTherapyDoctorId: [''],
    PhysicalTherapyDoctorDateId: [],
    NotificationConsent: [null, Validators.required],
  });

  get visitForm() {
    return this.addVisitFrm.controls;
  }

  get PhysicalTherapyDoctorDateId() {
    return this.addVisitFrm.get('PhysicalTherapyDoctorDateId');
  }
  get PhysicalTherapyDoctorId() {
    return this.addVisitFrm.get('PhysicalTherapyDoctorId');
  }
  get VisitType() {
    return this.addVisitFrm.get('VisitType');
  }
  get DoctorName() {
    return this.addVisitFrm.get('DoctorName');
  }

  get DoctorSpecialization() {
    return this.addVisitFrm.get('DoctorSpecialization');
  }

  get VisitDate() {
    return this.addVisitFrm.get('VisitDate');
  }
  get VisitTime() {
    return this.addVisitFrm.get('VisitTime');
  }

  get Message() {
    return this.addVisitFrm.get('Message');
  }
  get PhoneNumber() {
    return this.addVisitFrm.get('PhoneNumber');
  }

  datePickerDisabled = true;

  getDoctors() {
    this.doctorService.getPhysicalTherapyDoctors().subscribe((res) => {
      this.doctorList = res;
    });
  }

  getTimeSlots() {
    this.doctorService
      .getDoctorAvailableDates(this.PhysicalTherapyDoctorId.value)
      .subscribe((res) => {
        this.UnAvailableDates = res.PhysicalTherapyDoctorDates;
        this.datePickerDisabled = false;
      });
  }

  formSubmit() {
    let doctorName = this.addVisitFrm.value['DoctorName'];
    let doctorSpecialization = this.addVisitFrm.value['DoctorSpecialization'];
    let visitType = this.addVisitFrm.value['VisitType'];
    let date = this.addVisitFrm.value['VisitDate'];
    date = moment(date).format('MM/DD/YYYY');

    let phoneNumber =
      this.addVisitFrm.value['CountryCode'] +
      this.addVisitFrm.value['PhoneNumber'];
    let time = this.addVisitFrm.value['VisitTime'];
    let message = this.addVisitFrm.value['Message'];
    let physicalTherapyDoctorDateId =
      this.addVisitFrm.value['PhysicalTherapyDoctorDateId'];
    let physicalTherapyDoctorId =
      this.addVisitFrm.value['PhysicalTherapyDoctorId'];
    let idoctorName = this.doctorList.filter(
      (it) => it.PhysicalTherapyDoctorId == physicalTherapyDoctorId
    )[0].DoctorName;

    let body = {
      PatientId: this.id,
      DoctorName: idoctorName,
      PhysicalTherapyDoctorId: physicalTherapyDoctorId,
      DoctorSpecialization: doctorSpecialization,
      VisitType: visitType,
      VisitDate: date,
      VisitTime: this.selectedSlot,
      BookedSlots: this.selectedSlot,
      Message: message,
      Status: 'not confirmed',
      PhoneNumber: phoneNumber,
      PatientEmail: this.helper.decodeToken(this.myToken).unique_name,
    };
    this.appointmentService.registerPhysicalTherapyBooking(body).subscribe({
      next: (res) => {
        this.emailService
          .sendEmail({
            Email: body.PatientEmail,
            Subject: 'Appointment',
            Template: `<h3>Your appointment is booked with ${res.DoctorName} ${res.DoctorSpecialization} on ${res.VisitDate} at ${res.VisitTime} </h3> `,
          })
          .subscribe();

        const smsBodyDetails =
          'Appointment is booked with ' +
          res.DoctorName.replace('.', ' ') +
          ' ' +
          res.DoctorSpecialization +
          ' on ' +
          res.VisitDate +
          ' at ' +
          res.VisitTime +
          ' ';
        let smsBody = {
          PhoneNumber: phoneNumber,
          Body: smsBodyDetails,
        };
        this.emailService.sendSms(smsBody).subscribe();
        this.isSuccess = true;
        this.addVisitFrm.reset();

        this.selectedSlot = '';
        setTimeout(() => {
          this.showAppointmentList.emit('4');
        }, 2000);
      },
    });
  }

  public checkForTimeSlots(selectedDate) {
    const today = selectedDate.toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    });
    const bookedSlots = this.appointmentService.getPhysicalTherapyBookings();
    bookedSlots.subscribe({
      next: (res) => {
        this.timeSlots = this.createTimeSlots();
        this.takenSlots = res
          .filter((x) => x.BookedSlots && x.VisitDate == today)
          .map((y) => y.BookedSlots);
      },
    });
  }

  public selectSlot(selectedSlot): void {
    this.selectedSlot = selectedSlot;
  }
  public selectDate(selectedDate): void {
    this.checkForTimeSlots(selectedDate.value);
  }

  public selectTime() {
    this.selectedSlot = '';
  }

  public countryCodes() {
    this.http.get('/assets/json/country-codes.json').subscribe({
      next: (res: any) => {
        this.Codes = res.map((x) => x.dial_code);
      },
    });
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.Codes.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }
}
