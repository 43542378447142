<div class="container mt-5">
  <div class="row">
      <div class="col-lg-4 col-md-6 p-info-cols mb-5" *ngFor="let Insurance of Insurances">
          <mat-card>
            <mat-card-content>
              <h2>Insurance Details</h2>
              <table class="w-100">
                <tr>
                  <td>Do you have Insurance:</td>
                  <td>{{ Insurance?.DoyouhaveInsurance ?'Yes':'No' }}</td>
                </tr>
                <tr>
                  <td>Primary Policy HolderName:</td>
                  <td>{{ Insurance?.PrimaryPolicyHolderName }}</td>
                </tr>
                <tr>
                  <td>Secondary Policy Holder Name:</td>
                  <td>{{ Insurance?.SecondaryPolicyHolderName }}</td>
                </tr>
                <tr>
                  <td>Provider:</td>
                  <td>{{ Insurance?.InsuaranceName }}</td>
                </tr>
                <tr>
                  <td>InsuaranceNo</td>
                  <td>{{ Insurance?.InsuaranceNo }}</td>
                </tr>
                <tr>
                  <td>Expiry Date</td>
                  <td>{{ Insurance?.InsuaranceExpiryDate }}</td>
                </tr>
                <tr>
                  <td>View Front</td>
                  <td>
                    <mat-icon
                      data-toggle="modal"
                      color="primary"
                      data-target="#exampleModalLong"
                      >visibility</mat-icon
                    >
                  </td>
                </tr>
                <tr>
                  <td>View Back</td>
                  <td>
                    <mat-icon
                      type="button"
                      color="primary"
                      (click)="openPopup()"
                      >visibility</mat-icon
                    >
                  </td>
                </tr>
              </table>
              
      <!-- Modal -->
      <div
      class="modal fade"
      id="exampleModalLong"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLongTitle"
      aria-hidden="true"
      >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">
              Inusrance Image Front
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="ins-Front-image mx-auto"
            [ngStyle]="{
              'background-image': 'url(' + Insurance.InsImageFront + ')'
            }"></div>
            <!-- <div [innerHTML]="Insurance?.InsImageFront"></div> -->
          </div>
      
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">
              Close
            </button>
            <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
          </div>
        </div>
      </div>
      </div>
      
      <!-- Back Image Modal -->
      <div
      class="modal"
      tabindex="-1"
      role="dialog"
      [ngStyle]="{ display: displayStyle }"
      >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">InsImageBack</h4>
          </div>
          <div class="modal-body">
            <div class="ins-Back-image mx-auto"
            [ngStyle]="{
              'background-image': 'url(' + Insurance.InsImageBack + ')'
            }"></div>
            <!-- <div [innerHTML]="Insurance?.InsImageBack"></div> -->
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-danger" (click)="closePopup()">
              Close
            </button>
          </div>
        </div>
      </div>
      </div>
      
            </mat-card-content>
          </mat-card>
      
          <button class="float-right" mat-mini-fab (click)="delete(Insurance.PatientInsuranceId)"><mat-icon>delete</mat-icon></button>
        </div>
      
        
  </div>
</div>