<div class="container">
    <div class="row">
        <div class="col-lg regFrm mat-elevation-z8">
            <h1>Informed Consent for Physical Therapy Services</h1>
            <form [formGroup]="Frm" (ngSubmit)="formSubmit()" class="example-form">

                <hr>
                <h2>
                    {{hospital.HospitalName}}

                </h2>

                <p>The purpose of physical therapy is to treat disease, injury and disability by examination,
                    evaluation, diagnosis, prognosis, and intervention by use of rehabilitative procedures,
                    mobilization, massage, exercises, and physical agents to aid the patient in achieving their
                    maximum potential within their capabilities and to accelerate convalescence and reduce the
                    length of functional recovery. All procedures will be thoroughly explained to you before you are
                    asked to perform them. </p>

                <p>
                    Response to physical therapy intervention varies from person to person; hence, it is not possible
                    to accurately predict your response to a specific modality, procedure, or exercise protocol.
                    We cannot guarantee what your reaction will be to a specific treatment, nor does it
                    guarantee that the treatment will help resolve the condition that you are seeking treatment for.
                    Furthermore, there is a possibility that the physical therapy treatment may result in aggravation of
                    existing symptoms and may cause pain or injury. It is very important to communicate with you
                    treating physical therapist throughout your treatment.
                </p>
                <p>It is your right to decline any part of your treatment at any time before or during treatment, should
                    you feel any discomfort or pain or have other unresolved concerns. It is your right to ask your
                    physical therapist about the treatment they have planned based on your individual history,
                    physical therapy diagnosis, symptoms, and examination results. Consequently, it is your right to
                    discuss the potential risks and benefits involved in your treatment. </p>
                <p>I have read this consent form and understand the risks involved in physical therapy and agree to
                    fully cooperate, participate in all physical therapy procedures, and comply with the established
                    plan of care. I authorize the release of my medical information to appropriate third parties.</p>



                <mat-label>Do you have a lawyer representing you for this medical condition??</mat-label>
                <mat-radio-group formControlName="IslawyerrepresentingyouForThisMedicalCondition">
                    <mat-radio-button class="ml-3" value="true">Yes</mat-radio-button>
                    <mat-radio-button class="ml-3" value="false">No</mat-radio-button>
                </mat-radio-group>






                <div class="row">

                    <div class="col-md-12">


                        <mat-form-field class="example-full-width">
                            <mat-label>This consent was signed by(Patient/Legal Representative Print Name):</mat-label>
                            <input type="text" formControlName="AuthorisedSignatoryName" matInput>
                            <mat-error>Please enter Patient/Legal Representative Print Name</mat-error>
                        </mat-form-field>
                        <!-- <mat-form-field class="example-full-width">
                <mat-label>Signature:</mat-label>
                <input type="text" formControlName="Signauture" matInput>
                <mat-error>Please enter Description</mat-error>
              </mat-form-field> -->


                    </div>

                    <div class="container mt-5">
                        <canvas (mousedown)="onMouseDown($event)" (mousemove)="onMouseMove($event)"
                            (touchmove)="onMouseMove($event)" (touchstart)="onMouseDown($event)" #signPad width="350"
                            height="200">
                        </canvas>
                        <button type="button" class="ml-3" color="primary" mat-raised-button
                            (click)="saveSignature($event)">Save</button>

                        <button type="button" class="ml-3" color="accent" mat-raised-button
                            (click)="clearSignature($event)">Clear</button>
                    </div>



                </div>
                <button type="submit" [disabled]="Frm.invalid" class="float-right lgBtCl" mat-button>
                    <mat-icon>save</mat-icon>
                </button>
                <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
                    Successfully submitted
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </form>

        </div>

    </div>
</div>