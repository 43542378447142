import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DischargeSummary } from 'src/app/Models/dischargesummary.model';
import { Patient } from 'src/app/Models/patient.model';
import { PatientService } from 'src/app/services/patient.service';

@Component({
  selector: 'app-print-discharge-summary',
  templateUrl: './print-discharge-summary.component.html',
  styleUrls: ['./print-discharge-summary.component.css'],
})
export class PrintDischargeSummaryComponent implements OnInit {
  id = 0;
  patient: Patient;
  dischargeSummaryList: DischargeSummary[] = [];

  constructor(
    private patientService: PatientService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
    this.getDischargeSummary(this.id);
    this.getPatient(this.id);
  }

  getDischargeSummary(id) {
    this.patientService.getdischargeSummary(id).subscribe((res) => {
      this.dischargeSummaryList = res.DischargeSummaries;
    });
  }

  getPatient(id) {
    this.patientService.getPatientById(id).subscribe((res) => {
      this.patient = res;
    });
  }

  print() {
    window.print();
  }
}
