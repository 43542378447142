<div class="mt-4 ">
  <mat-tab-group backgroundColor="primary">
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon>person</mat-icon>
        <span class="ml-2 mt-1">Patient Info</span>
      </ng-template>
      <div class="row my-4 mx-0">
        <div class="col-lg-3 col-md-6 h-100 mx-auto mb-5">
          <div class="patient-image mx-auto" [ngStyle]="{
              'background-image': 'url(' + Patient?.PatientImage + ')'
            }"></div>

        </div>
        <!-- <div class="col-lg-9 col-md-6"> -->
        <!-- <div class="row mx-0"> -->
        <div class="col-lg-3 col-md-6 p-info-cols mb-5">
          <mat-card>
            <mat-card-content>
              <h2>Personal Information</h2>
              <table class="w-100">
                <tr>
                  <td>D.O.B:</td>
                  <td>{{ Patient?.PatientDOB }}</td>
                </tr>
                <tr>
                  <td>Gender:</td>
                  <td>{{ Patient?.PatientGender }}</td>
                </tr>
                <tr>
                  <td>Email:</td>
                  <td>{{ Patient?.PatientEmail }}</td>
                </tr>
                <tr>
                  <td>Cell No:</td>
                  <td>{{ Patient?.PhoneNumber }}</td>
                </tr>
                <tr>
                  <td>SSN:</td>
                  <td>{{ Patient?.PatientNationalId }}</td>
                </tr>
                <tr>
                  <td>Blood Group:</td>
                  <td>{{ Patient?.BloodGroup }}</td>
                </tr>
                <tr>
                  <td>Height:</td>
                  <td>{{ Patient?.PatientHeight }}</td>
                </tr>
                <tr>
                  <td>Weight:</td>
                  <td>{{ Patient?.PatientWeight }}</td>
                </tr>
              </table>
            </mat-card-content>
          </mat-card>
        </div>
        <div class="col-lg-3 col-md-6 p-info-cols mb-5">
          <mat-card>
            <mat-card-content>
              <h2>Insurance Details</h2>
              <table class="w-100">
                <tr>
                  <td>Do you have Insurance:</td>
                  <td>{{ Insurance[0]?.DoyouhaveInsurance ?'Yes':'No' }}</td>
                </tr>
                <tr>
                  <td>Primary Policy HolderName:</td>
                  <td>{{ Insurance[0]?.PrimaryPolicyHolderName }}</td>
                </tr>
                <tr>
                  <td>Secondary Policy Holder Name:</td>
                  <td>{{ Insurance[0]?.SecondaryPolicyHolderName }}</td>
                </tr>
                <tr>
                  <td>Provider:</td>
                  <td>{{ Insurance[0]?.InsuaranceName }}</td>
                </tr>
                <tr>
                  <td>InsuaranceNo</td>
                  <td>{{ Insurance[0]?.InsuaranceNo }}</td>
                </tr>
                <tr>
                  <td>Expiry Date</td>
                  <td>{{ Insurance[0]?.InsuaranceExpiryDate }}</td>
                </tr>
                <tr>
                  <td>View Front</td>
                  <td>
                    <mat-icon data-toggle="modal" color="primary" data-target="#exampleModalLong">visibility</mat-icon>
                  </td>
                </tr>
                <tr>
                  <td>View Back</td>
                  <td>
                    <mat-icon type="button" color="primary" (click)="openPopup()">visibility</mat-icon>
                  </td>
                </tr>
              </table>
            </mat-card-content>
          </mat-card>
        </div>
        <div class="col-lg-3 col-md-6 p-info-cols mb-5">
          <mat-card>
            <mat-card-content>
              <h2>Emergency Contact Details</h2>
              <table class="w-100">
                <tr>
                  <td>Contact Name:</td>
                  <td>{{ emContacts[0]?.EmergencyContactName }}</td>
                </tr>
                <tr>
                  <td>Contact Phone#</td>
                  <td>{{ emContacts[0]?.EmergencyContactPhone }}</td>
                </tr>
                <tr>
                  <td>Relationship:</td>
                  <td>{{ emContacts[0]?.EmergencyContactRelation }}</td>
                </tr>

                <tr>
                  <td class="d-flex">Address</td>
                  <td [title]="Patient?.PatientAddress" class="wrap-long-text">
                    {{ Patient?.PatientAddress }}
                  </td>
                </tr>
              </table>
            </mat-card-content>
          </mat-card>
        </div>

        <!-- </div> -->
        <!-- </div> -->
      </div>
    </mat-tab>
    <!-- <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">medical_services</mat-icon>
        <span class="ml-2 mt-1">Medications</span>
      </ng-template>
      <div class="container-fluid w-100">
        <div class="row px-3">
          <div class="col-12 mt-4">
            <h2>Medications</h2>
            <table class="table table-stripped w-100">
              <thead class="">
                <th>Drug</th>
                <th>Strength</th>
                <th>DatePrescribed</th>
                <th>Quantity</th>
                <th>Instruction</th>
              </thead>
              <tbody>
                <tr *ngFor="let ps of prescriptions">
                  <td>{{ ps?.DrugName }}</td>
                  <td>{{ ps?.Strength }}</td>
                  <td>{{ ps?.DatePrescribed }}</td>
                  <td>{{ ps?.Quantity }}</td>
                  <td>{{ ps?.Instruction }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </mat-tab> -->

    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">content_cut</mat-icon>
        <span class="ml-2 mt-1">Surgical Hostory</span>
      </ng-template>
      <div class="container-fluid w-100">
        <div class="row px-3">
          <div class="col-md-12 mt-4">
            <h2>Surgical Histories</h2>
            <table class="table table-stripped w-100">
              <thead class="">
                <th>Surgery</th>
                <th>Date Of Surgery</th>
              </thead>
              <tbody>
                <tr *ngFor="let sr of surgeries">
                  <td>{{ sr?.SurgeyText }}</td>
                  <td>{{ sr?.DateOfSurgery }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">warning</mat-icon>
        <span class="ml-2 mt-1">Allergies</span>
      </ng-template>
      <div class="container-fluid ">
        <div class="row px-3">
          <div class="col-md-12 mt-4">
            <h2>Allergies</h2>
            <table class="table table-stripped w-100">
              <thead>
                <th>Allergic To Substance</th>
                <th>Reaction</th>
                <th>Description</th>
              </thead>
              <tbody>
                <tr *ngFor="let al of allergies">
                  <td>{{ al?.AllergicTo }}</td>
                  <td>{{ al?.Reaction }}</td>
                  <td>{{ al?.Description }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">history</mat-icon>
        <span class="ml-2 mt-1">Medical History</span>
      </ng-template>
      <app-view-medical-history></app-view-medical-history>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">history</mat-icon>
        <span class="ml-2 mt-1">Family History</span>
      </ng-template>
      <app-view-familyhistory></app-view-familyhistory>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">history</mat-icon>
        <span class="ml-2 mt-1">Social History</span>
      </ng-template>
      <app-view-socialhistory></app-view-socialhistory>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">vaccines</mat-icon>
        <span class="ml-2 mt-1">Immunization</span>
      </ng-template>
      <app-view-vaccine></app-view-vaccine>
    </mat-tab>
    <!-- <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">bug_report</mat-icon>
        <span class="ml-2 mt-1"> Lab Reports</span>
      </ng-template>
      <div class="container-fluid w-100">
        <div class="row px-3">
          <div class="col-md-12 mt-4">
            <h2 class="txt-primary mt-3">Lab Reports</h2>
            <table class="table table-stripped w-100">
              <thead>
                <th>Date</th>
                <th>ReportType</th>
                <th>Description</th>

                <th>OrderedBy</th>
                <th>LabName</th>
                <th>ReportedBy</th>
                <th>View Document</th>
              </thead>
              <tbody>
                <tr *ngFor="let item of labReport">
                  <td class="ml-1">{{ item?.Date }}</td>
                  <td>{{ item?.ReportType }}</td>
                  <td class="ml-1">{{ item?.Description }}</td>
                  <td class="ml-1">{{ item?.OrderedBy }}</td>
                  <td class="ml-1">{{ item?.LabName }}</td>
                  <td class="ml-1">{{ item?.ReportedBy }}</td>
                  <td>
                  
                    <button type="button" (click)="viewLabReport(item)" class="btn btn-primary" data-toggle="modal"
                      data-target="#lab_report_modal">
                      View Report
                    </button>

                    <div class="modal fade bd-example-modal-lg" id="lab_report_modal" tabindex="-1" role="dialog"
                      aria-labelledby="myLargeModalLabel" aria-hidden="true">
                      <div class="modal-dialog modal-lg">
                        <div class="modal-content">
                          <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLongTitle">
                              Lab Report
                            </h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div class="modal-body lab-document" id="printable">

                            <div [innerHTML]="labDocument"></div>

                          </div>
                          <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">
                              Close
                            </button>
                            <button (click)="print()" type="button" class="btn btn-primary mx-3" data-dismiss="modal">
                              Print
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </mat-tab> -->

    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">qr_code</mat-icon>
        <span class="ml-2 mt-1">QR Code</span>
      </ng-template>

      <div class="container">
        <div class="row">
          <div class="col-lg-6" id="printable">
            <qrcode *ngIf="PatientId" [qrdata]="PatientId" [width]="156" [errorCorrectionLevel]="'M'"></qrcode>
            <!-- <qr-code value="Yo world!" size="300" errorCorrectionLevel="M"></qr-code> -->

          </div>
          <button class="btn backG float-right mr-3" onClick="window.focus(); window.print()"
            value="print"><mat-icon>print</mat-icon></button>

          <!-- <a class="btn btn-light text-capitalize border-0" data-mdb-ripple-color="dark"  (click)="print()"><i
            class="fas fa-print text-primary"></i> Print</a> -->
        </div>

      </div>

    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">moving_ministry</mat-icon>
        <span class="ml-2 mt-1">Office Plolicy Consent</span>
      </ng-template>
      <app-patient-view-office-policy></app-patient-view-office-policy>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">medical_services</mat-icon>
        <span class="ml-2 mt-1">Consent For Services</span>
      </ng-template>
      <app-patient-view-consent-for-service></app-patient-view-consent-for-service>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">vaccines</mat-icon>
        <span class="ml-2 mt-1">IMS/Dry Needling Consent</span>
      </ng-template>
      <app-patient-view-dry-needling-consent></app-patient-view-dry-needling-consent>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">fitness_center</mat-icon>
        <span class="ml-2 mt-1">Excercise Liability Waiver</span>
      </ng-template>
      <app-patient-view-exersice-liability-waiver></app-patient-view-exersice-liability-waiver>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">history</mat-icon>
        <span class="ml-2 mt-1">Hipaa Compliance</span>
      </ng-template>
      <app-patient-view-hippa-compliance></app-patient-view-hippa-compliance>
    </mat-tab>
  </mat-tab-group>
</div>

<!-- <app-navbar></app-navbar> -->
<div class="mx-5">

</div>

<!-- LabReport  -->

<!-- Modal -->
<div class="modal fade" id="exampleModalLong" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">
          Inusrance Image Front
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="ins-Front-image mx-auto" [ngStyle]="{
          'background-image': 'url(' + Insurance[0]?.InsImageFront + ')'
        }"></div>
        <!-- <div [innerHTML]="Insurance[0]?.InsImageFront"></div> -->
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          Close
        </button>
        <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
      </div>
    </div>
  </div>
</div>

<!-- Back Image Modal -->
<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{ display: displayStyle }">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">InsImageBack</h4>
      </div>
      <div class="modal-body">
        <div class="ins-Back-image mx-auto" [ngStyle]="{
          'background-image': 'url(' + Insurance[0]?.InsImageBack + ')'
        }"></div>
        <!-- <div [innerHTML]="Insurance[0]?.InsImageBack"></div> -->
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="closePopup()">
          Close
        </button>
      </div>
    </div>
  </div>
</div>



<div class="card upcoming-appointment" *ngIf="upcomingAppointment">
  <div class="card-body mx-auto">
    <h2 class="mb-1">Upcoming Appointment </h2>
    <!-- <p>You have an upcoming appointment
    </p> -->
    <table class="w-100">
      <tr>
        <td>Doctor Name</td>
        <td>{{upcomingAppointment.DoctorName}}</td>
      </tr>
      <tr>
        <td>Doctor Speciality</td>
        <td>{{upcomingAppointment.DoctorSpecialization}}</td>
      </tr>
      <tr>
        <td>Date</td>
        <td>{{upcomingAppointment.VisitDate}}</td>
      </tr>
      <tr>
        <td>Time</td>
        <td>{{upcomingAppointment.VisitTime}}</td>
      </tr>
    </table>
  </div>
</div>