<div class="container mt-5 ">

  <div class="col-lg regFrm mat-elevation-z8">

    <form [formGroup]="addVisitFrm" (ngSubmit)="formSubmit()" class="example-form">
      <div class="row mt-5">
        <div class="col-lg-6">
          <mat-form-field class="example-full-width">
            <mat-label>Doctor Name</mat-label>
            <!-- <input type="text" formControlName="DoctorName" matInput >
                  <mat-error >Please enter Doctor Name</mat-error> -->

            <mat-select formControlName="DoctorId" (selectionChange)="getTimeSlots()">
              <mat-option *ngFor="let doctor of doctorList" [value]="doctor.DoctorId">{{ doctor.DoctorName
                }}</mat-option>
            </mat-select>
            <mat-error>Select a Doctor</mat-error>
          </mat-form-field>

          <div class="col-lg-6">

            <p class="text-primary">Please select a doctor to view Off Days</p>
            <mat-form-field class="example-full-width" appearance="fill"
              [ngClass]="datePickerDisabled ? 'strict-disabled': '' ">
              <mat-label>Select to View </mat-label>
              <input matInput formControlName="VisitDate" [matDatepicker]="picker" [disabled]="!UnAvailableDates.length"
                [matDatepickerFilter]="boundDateStart" />
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
              <mat-error>Select a Date </mat-error>

            </mat-form-field>
          </div>



        </div>




        <!-- </div> -->















        <!-- 
<mat-tab-group (selectedTabChange)="tabChanged($event)">

    <mat-tab >
        <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">doctor</mat-icon>
         View PT Doctor Un Avaialble Days (Off Duty)
          </ng-template>
  
          <div class="ml-3">
            <div class="row">
                <div class="col-lg-6 float-right">
                    <mat-form-field class="example-full-width mt-5">
                        <mat-label>Filter</mat-label>
                        <input matInput (keyup)="applyFilter($event)" placeholder="Ex.Mia" #input>
                      </mat-form-field>
                </div>
            </div>
          </div>
          <div class=" mat-elevation-z8">
            <table mat-table [dataSource]="dataSource" matSort>
          
            
              <ng-container matColumnDef="PhysicalTherapyDoctorId">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Doctor Id </th>
                <td mat-cell *matCellDef="let row"> {{row.PhysicalTherapyDoctorId}} </td>
              </ng-container>
          
          
        
              <ng-container matColumnDef="DoctorName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Doctor Name </th>
                <td mat-cell *matCellDef="let row"> {{row.DoctorName}} </td>
              </ng-container>
              <ng-container matColumnDef="UnAvailableDate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Un Available Dates </th>
                <td mat-cell *matCellDef="let row"> {{row.UnAvailableDate}} </td>
              </ng-container>
         
          
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
              </tr>
            </table>
          
            <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
          </div>
  </mat-tab>
        
  
  <mat-tab > 
    <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">add</mat-icon>
        Register PT Doctor Un Avaialble Days
      </ng-template>
      <app-add-pt-docctor-availability></app-add-pt-docctor-availability>
  </mat-tab>
  
  
  </mat-tab-group>
   -->