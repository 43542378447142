import { Injectable } from '@angular/core';
declare var webkitSpeechRecognition: any;

@Injectable({
  providedIn: 'root',
})
export class VoiceRecognitionPostureService {
  recognition = new webkitSpeechRecognition();
  isStoppedSpeechRecog = false;
  instantStarted = false;
  public posture = '';
  tempWords;

  constructor() {}

  init() {
    this.recognition.interimResults = true;
    this.recognition.lang = 'en-US';

    this.recognition.addEventListener('result', (e) => {
      const transcript = Array.from(e.results)
        .map((result) => result[0])
        .map((result) => result.transcript)
        .filter((result) => result)
        .join('');
      this.tempWords = transcript;
      // console.log(transcript);
    });
  }

  start() {
    if (!this.instantStarted) {
      this.isStoppedSpeechRecog = false;
      this.recognition.start();
      this.instantStarted = true;
      // console.log('Speech recognition started');
      this.recognition.addEventListener('end', (condition) => {
        if (this.isStoppedSpeechRecog) {
          this.recognition.stop();
          // console.log('End speech recognition');
        } else {
          this.wordConcat();
          this.recognition.start();
        }
      });
    }
  }
  stop() {
    if (this.instantStarted) {
      // console.log('Speech recognition stopped');
      this.isStoppedSpeechRecog = true;
      this.instantStarted = false;
      this.wordConcat();
      this.recognition.stop();
    }
    // console.log('End speech recognition');
  }

  wordConcat() {
    this.posture = this.posture + ' ' + this.tempWords + '.';
    this.tempWords = '';
  }
}
