<div class="mt-5">
  <mat-tab-group (selectedTabChange)="tabChanged($event)">
    <mat-tab *ngIf="!currentPatient.IsInsuranceCompleted">
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">add</mat-icon>
        Insurance
      </ng-template>
      <app-add-patient-insurance (submitAction)="handleSubmitAction()"></app-add-patient-insurance>

    </mat-tab>
    <mat-tab *ngIf="!currentPatient.IsEmergencyContactCompleted">
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">add</mat-icon>
        Emergency Contact
      </ng-template>
      <app-add-emergency-contact (submitAction)="handleSubmitAction()"></app-add-emergency-contact>

    </mat-tab>

    <mat-tab *ngIf="!currentPatient.IsSocialHistoryCompleted">
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">add</mat-icon>
        Social History
      </ng-template>
      <app-add-socialhistory (submitAction)="handleSubmitAction()"></app-add-socialhistory>

    </mat-tab>
    <mat-tab *ngIf="!currentPatient.IsFamilyHistoryCompleted">
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">add</mat-icon>
        Family History
      </ng-template>
      <app-add-familyhistory (submitAction)="handleSubmitAction()"></app-add-familyhistory>

    </mat-tab>

    <mat-tab *ngIf="!currentPatient.IsMedicalHistoryCompleted">
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">add</mat-icon>
        Medical History
      </ng-template>
      <app-add-medical-history (submitAction)="handleSubmitAction()"></app-add-medical-history>

    </mat-tab>
    <mat-tab *ngIf="!currentPatient.IsOfficePolicyConsentCompleted">
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">add</mat-icon>
        Office Policy
      </ng-template>
      <app-add-office-policy-consent (submitAction)="handleSubmitAction()"></app-add-office-policy-consent>

    </mat-tab>
    <mat-tab *ngIf="!currentPatient.IsConsentForServiceCompleted">
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">add</mat-icon>
        Consent For Service
      </ng-template>
      <app-add-consent-for-service (submitAction)="handleSubmitAction()"></app-add-consent-for-service>

    </mat-tab>
    <mat-tab *ngIf="!currentPatient.IsDryNeedlingConsentCompleted">
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">add</mat-icon>
        IMS/ Dry Needling Consent
      </ng-template>
      <app-add-dry-needling-consent (submitAction)="handleSubmitAction()"></app-add-dry-needling-consent>

    </mat-tab>
    <mat-tab *ngIf="!currentPatient.IsExcersiceLiabilityWaiverCompleted">
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">add</mat-icon>
        Excercise Liability Waiver
      </ng-template>
      <app-add-exersice-liability-waiver (submitAction)="handleSubmitAction()"></app-add-exersice-liability-waiver>

    </mat-tab>
    <mat-tab *ngIf="!currentPatient.IsHippaCompleted">
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">add</mat-icon>
        Hippa Compliance
      </ng-template>
      <app-add-hippa-compliance (submitAction)="handleSubmitAction()"></app-add-hippa-compliance>

    </mat-tab>
    <!-- *ngIf="!currentPatient.IsSurgicalHistoryCompleted" -->

    <!-- <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">visibility</mat-icon>
        View Insurance
      </ng-template>
      <app-view-patient-insurance></app-view-patient-insurance>

    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">visibility</mat-icon>
        View Emergency Contact
      </ng-template>
      <app-emergency-contact></app-emergency-contact>

    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">visibility</mat-icon>
        View Allergies
      </ng-template>
      <app-view-allergy></app-view-allergy>

    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">visibility</mat-icon>
        View Surgical History
      </ng-template>
      <app-view-surgical-history></app-view-surgical-history>

    </mat-tab> -->
  </mat-tab-group>
</div>