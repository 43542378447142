import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { VaccineService } from 'src/app/services/vaccine.service';

@Component({
  selector: 'app-add-vaccine',
  templateUrl: './add-vaccine.component.html',
  styleUrls: ['./add-vaccine.component.css']
})
export class AddVaccineComponent implements OnInit {
  @Output() submitAction = new EventEmitter<any>();
  id=0;
  isSuccess= false;
  constructor(private fb:UntypedFormBuilder,private vaccineService:VaccineService,private route:ActivatedRoute,private router:Router) { }

  ngOnInit(): void {

    this.route.params.subscribe(res=>{

      this.id= res['id'];

    })
  }


  Frm = this.fb.group({
    VaccineName : ['',Validators.required],
    VaccineProvider:['',Validators.required],
    DateTaken:['',Validators.required],
    Description:['',Validators.required]
  })

get VaccineName(){
  return this.Frm.get('VaccineName')
}
get VaccineProvider(){
  return this.Frm.get('VaccineProvider')
}
get DateTaken(){
  return this.Frm.get('DateTaken')
}
get Description(){
  return this.Frm.get('Description')
}






  formSubmit(){

   let vaccineName = this.Frm.value['VaccineName']
   let vaccineProvider = this.Frm.value['VaccineProvider']
   let dateTaken = this.Frm.value['DateTaken']
   dateTaken=  moment(dateTaken).format('MM/DD/YYYY');
   let description = this.Frm.value['Description']


   let body={
    "PatientId":this.id,
    "VaccineName":vaccineName,
    "VaccineProvider" :vaccineProvider,
    "DateTaken" :dateTaken,
    "Description":description
   }

this.vaccineService.registerVaccine(body).subscribe(res=>{
  this.isSuccess=true
  this.Frm.reset();
  this.submitAction.emit("")
  // this.router.navigate(['/patientDetails/',this.id])
})
  }
}
