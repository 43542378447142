<div class="toolbar mat-elevation-z6"> <button type="button" (click)="logout()"
    class="btn float-right mx-3 my-3 logout " color="info" mat-raised-button>
    <!-- <mat-icon matPrefix>highlight_off</mat-icon> -->
    <span>Logout</span>
  </button>
  <!-- <div class="header">
            <img src="../../../assets/tesss.jpg" class="image" alt="">
            <h4 class="text-white ml-2">Josh Tylor</h4>
           </div> -->
  <!-- *ngIf="!isNewPatient" -->
  <button mat-raised-button class="backG mt-3 ml-3" [matMenuTriggerFor]="crt"><mat-icon>menu</mat-icon>Menu</button>

  <mat-menu class="ItemS" #crt="matMenu">

    <div class="ItemS " (click)="showItem='5'" [ngClass]="{'active': showItem =='5'}">
      <mat-icon mat-list-icon>dashboard</mat-icon>
      <div class="mx-2">Dash Board</div>
      <mat-icon>navigate_next</mat-icon>
    </div>

    <div class="ItemS " (click)="showItem='7'" [ngClass]="{'active': showItem =='7'}">
      <mat-icon mat-list-icon>add</mat-icon>
      <div class="mx-2">New PhysicalTherapy Appointment</div>
      <mat-icon>navigate_next</mat-icon>
    </div>

    <div class="ItemS" (click)="showItem='2'" [ngClass]="{'active': showItem =='2'}">
      <mat-icon mat-list-icon>style</mat-icon>
      <div class="mx-2"> Medical Records</div>
      <mat-icon>navigate_next</mat-icon>
    </div>

    <div class="ItemS" (click)="showItem='3'" [ngClass]="{'active': showItem =='3'}">
      <mat-icon mat-list-icon>receipt</mat-icon>
      <div class="mx-2">View Invoices</div>
      <mat-icon>navigate_next</mat-icon>
    </div>


    <div class="ItemS" (click)="showItem='8'" [ngClass]="{'active': showItem =='8'}">
      <mat-icon mat-list-icon>schedule</mat-icon>
      <div class="mx-2"> Upcoming Appointment</div>
      <mat-icon>navigate_next</mat-icon>
    </div>
    <div class="ItemS" (click)="showItem='9'" [ngClass]="{'active': showItem =='9'}">
      <mat-icon mat-list-icon>schedule</mat-icon>
      <div class="mx-2"> Patient Forms</div>
      <mat-icon>navigate_next</mat-icon>
    </div>
    <div class="ItemS" (click)="showItem='10'" [ngClass]="{'active': showItem =='10'}">
      <mat-icon mat-list-icon>add</mat-icon>
      <div class="mx-2"> New History</div>
      <mat-icon>navigate_next</mat-icon>
    </div>
    <!-- <div class="ItemS" (click)="showItem='6'" [ngClass]="{'active': showItem =='4'}">
            <mat-icon mat-list-icon>schedule</mat-icon>
            <div class="mx-2"> QRcode Scanner</div>
            <mat-icon>navigate_next</mat-icon>
          </div> -->
  </mat-menu>


</div>

<!-- 
<span *ngIf="isNewPatient">
  <p>
    <app-patient-intake-items></app-patient-intake-items>
  </p>
</span> -->
<!-- *ngIf="!isNewPatient" -->
<span [ngSwitch]="showItem">


  <p *ngSwitchCase="'2'">
    <app-view-physical-therapy-visit-patientside></app-view-physical-therapy-visit-patientside>

  </p>

  <p *ngSwitchCase="'3'">
    <app-ptinvoices></app-ptinvoices>
  </p>


  <p *ngSwitchCase="'5'">
    <app-patientsummary></app-patientsummary>

  </p>

  <p *ngSwitchCase="'6'">
    <app-scanner></app-scanner>

  </p>
  <p *ngSwitchCase="'7'">
    <app-add-physical-therapy-booking (showAppointmentList)="innerRouting($event)"></app-add-physical-therapy-booking>
  </p>
  <p *ngSwitchCase="'8'">
    <app-view-physical-therapy-booking></app-view-physical-therapy-booking>
  </p>
  <p *ngSwitchCase="'9'">
    <app-patient-intake-items></app-patient-intake-items>
  </p>
  <p *ngSwitchCase="'10'">
    <app-patient-history></app-patient-history>
  </p>

  <p *ngSwitchDefault>
    <app-patientsummary></app-patientsummary>
  </p>






</span>