import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/internal/operators/catchError';
import { environment } from 'src/environments/environment';
import { Doctor } from '../Models/doctor.model';

@Injectable({
  providedIn: 'root'
})
export class DoctorService {
  url = environment.Url + 'Doctors';
  drBooking = environment.Url + 'Doctors/doctorBookings';
  drPatients = environment.Url + 'Doctors/doctorPatients';
  drTimeSlots = environment.Url + 'Doctors/doctorTimeSlts';
  drBookedSlots = environment.Url + 'Doctors/doctorBookedSlots';
  drAvailableDates = environment.Url + 'Doctors/doctorDates';

  imgUrl = environment.Url + 'ImageUpload';

  header = new HttpHeaders({

    'Authorization': 'token' + localStorage.getItem('token')

  })

  constructor(private http: HttpClient) { }

  imageUpload(body) {
    return this.http.post(this.imgUrl, body);
  }

  registerDoctor(body) {
    return this.http.post<Doctor>(this.url, body).pipe(
      catchError(this.handleError)
    );
  }

  getDoctors() {
    return this.http.get<Doctor[]>(this.url).pipe(catchError(this.handleError)

    );
  }
  getDoctorById(id) {
    return this.http.get<Doctor>(this.url + '/' + id).pipe(catchError(this.handleError));
  }

  getDoctorBooking(id) {
    return this.http.get<Doctor>(this.drBooking + '/' + id).pipe(catchError(this.handleError));
  }

  getDoctorBookedSlots(id) {
    return this.http.get<Doctor>(this.drBookedSlots + '/' + id).pipe(catchError(this.handleError));
  }
  getDoctorTimeSlots(id) {
    return this.http.get<Doctor>(this.drTimeSlots + '/' + id).pipe(catchError(this.handleError));
  }
  getDoctorAvailableDates(id) {
    return this.http.get<Doctor>(this.drAvailableDates + '/' + id).pipe(catchError(this.handleError));
  }
  getDoctorPatients(id) {
    return this.http.get<Doctor>(this.drPatients + '/' + id).pipe(catchError(this.handleError));
  }


  updateDoctor(id, body) {
    return this.http.put<Doctor>(this.url + '/' + id, body).pipe(catchError(this.handleError));
  }

  deleteDoctor(id) {
    return this.http.delete<Doctor>(this.url + '/' + id).pipe(catchError(this.handleError));
  }








  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      if (error.status == 400) {
        console.log(error.error)
        return throwError(
          error.error
        )


      }
      else if (error.status == 401) {
        return throwError('invalid username or password')

      }
      else if (error.status == 0) {
        return throwError(
          'network failure please try again later '
        )
      }
      return throwError(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  };


}



