import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Patient } from 'src/app/Models/patient.model';
import { RadiologyReport } from 'src/app/Models/radiologyreport.model';
import { UploadRadiologyReport } from 'src/app/Models/uploadradiologyreport.model';
import { PatientService } from 'src/app/services/patient.service';
import { UploadRadiologyReportService } from 'src/app/services/upload-radiology-report.service';

// import { UploadRadiologyReportService } from 'src/services/upload-radiology-report.service';

@Component({
  selector: 'app-view-upload-radiology-report',
  templateUrl: './view-upload-radiology-report.component.html',
  styleUrls: ['./view-upload-radiology-report.component.css'],
})
export class ViewUploadRadiologyReportComponent implements OnInit {
  displayedColumns: string[] = ['Date', 'View', 'Delete'];

  id = 0;
  uploadedRadiologyReport: UploadRadiologyReport[] = [];
  dataSource: MatTableDataSource<any>;
  radioDocument: string = '';
  patient: Patient;

  constructor(
    private patientService: PatientService,
    private uploadedRadiologyReportservice: UploadRadiologyReportService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });

    this.GetRadiologyReports(this.id);
    this.getPatient(this.id);
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  // ngAfterViewInit() {
  //   this.dataSource.paginator = this.paginator;
  //   this.dataSource.sort = this.sort;
  // }

  getPatient(id) {
    this.patientService.getPatientById(id).subscribe((res) => {
      this.patient = res;
    });
  }

  GetRadiologyReports(id) {
    this.patientService.GetUploadedRadiologyReport(id).subscribe((res) => {
      // this.radiologyReport = res.RadiologyReports
      this.dataSource = new MatTableDataSource(res.UploadRadiologyReports);
      this.dataSource.paginator = this.paginator;
    });
  }
  delete(id) {
    this.uploadedRadiologyReportservice
      .deleteUploadRadiologyReport(id)
      .subscribe((res) => {
        // this.radiologyReport = this.radiologyReport.filter(x=>x.RadiologyReportId !=id)
        this.GetRadiologyReports(this.id);
      });
  }
  view() {}

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  print() {
    window.print();
  }

  public viewRdiologyReport(report: UploadRadiologyReport): void {
    // console.log('report: ', report);
    this.radioDocument = report.RadiologyReportImage;
    setTimeout(() => {
      const isModal = document.querySelector('.modal-backdrop.show');
      if (isModal) {
        isModal.classList.remove('modal-backdrop');
      }
      const radiologyImage = document.querySelector(
        '.rad-document img'
      ) as HTMLImageElement;
      if (radiologyImage) {
        radiologyImage.style.width = '100%';
      }
    }, 0);
  }
}
