import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CptCode } from 'src/app/Models/cptcode.model';
import { IcdCode } from 'src/app/Models/icdcode.model';
import { PhysicalTherapyDoctor } from 'src/app/Models/physicaltherapydoctor.model';
import { CptcodeService } from 'src/app/services/cptcode.service';
import { IcdCodeService } from 'src/app/services/icd-code.service';
import { PatientService } from 'src/app/services/patient.service';

import { PhysicalTherapyDoctorService } from 'src/app/services/physical-therapy-doctor.service';
import { PhysicalTherapyVisitService } from 'src/app/services/physical-therapy-visit.service';
import { VoiceRecognitionAssessmentService } from 'src/app/services/voice-recognition-assessment.service';
import { VoiceRecognitionJointMobilizationService } from 'src/app/services/voice-recognition-joint-mobilization.service';
import { VoiceRecognitionObjectiveService } from 'src/app/services/voice-recognition-objective.service';
import { VoiceRecognitionPlanService } from 'src/app/services/voice-recognition-plan.service';
import { VoiceRecognitionSoftTissueMobilizationService } from 'src/app/services/voice-recognition-soft-tissue-mobilization.service';
import { VoiceRecognitionStretchingService } from 'src/app/services/voice-recognition-stretching.service';
import { VoiceRecognitionService } from 'src/app/services/voice-recognition.service';

@Component({
  selector: 'app-add-physical-therapy-visit',
  templateUrl: './add-physical-therapy-visit.component.html',
  styleUrls: ['./add-physical-therapy-visit.component.css'],
})
export class AddPhysicalTherapyVisitComponent implements OnInit {
  id = 0;
  DoctorId: Number;
  helper = new JwtHelperService();
  myToken = window.localStorage.getItem('token');
  doctorList: PhysicalTherapyDoctor[] = [];
  Frm: FormGroup;
  isSuccess = false;
  Cptcodes: any;
  icdCodeList: IcdCode[];
  filteredOptions: Observable<IcdCode[]>;
  data: any;
  selectedCPTCodes: string[] = [];
  autoFillItems = [
    { value: '1x' },
    { value: '2x' },
    { value: '3x' },
    { value: '4x' },
    { value: '5x' },
    { value: '6x' },
    { value: '7x' },
    { value: '8x' },
    { value: '9x' },
    { value: '10x' },
    { value: '11x' },
    { value: '12x' },
    { value: '13x' },
    { value: '14x' },
    { value: '15x' },
    { value: '16x' },
    { value: '17x' },
    { value: '18x' },
    { value: '19x' },
    { value: '20x' },
  ];
  message: string;
  listening = false;
  listeningObjective = false;
  listeningAssessment = false;
  listeningPatientNote = false;

  subjectiveText: string = '';
  subjectiveTextArr: string[] = [];
  hospitalId: number;
  listeningJoint: boolean;
  listeningSoftTissue: boolean;
  listeningStreching: boolean;

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private physicalTherpyVisitService: PhysicalTherapyVisitService,
    private physicaltherpyDoctorService: PhysicalTherapyDoctorService,
    private patientService: PatientService,
    private icdCodeService: IcdCodeService,
    private http: HttpClient,
    public service: VoiceRecognitionService,
    public serviceObjective: VoiceRecognitionObjectiveService,
    public serviceAssessment: VoiceRecognitionAssessmentService,
    public servicePlan: VoiceRecognitionPlanService,
    public serviceJoint: VoiceRecognitionJointMobilizationService,
    public serviceSoftTissue: VoiceRecognitionSoftTissueMobilizationService,
    public serviceStreching: VoiceRecognitionStretchingService
  ) {
    // this.filteredOptions = this.Frm.controls.DiagnosisCodes.valueChanges.pipe(
    //   startWith(''),
    //   map((value: string) => this._filter(value || ''))
    // );
    this.service.init();
    this.serviceObjective.init();
    this.serviceAssessment.init();
    this.servicePlan.init();
    this.serviceJoint.init();
    this.serviceSoftTissue.init();
    this.serviceStreching.init();
  }
  url: string = '/assets/json/ICD_Code.json';
  ngOnInit(): void {
    // this.http.get(this.url).subscribe((res) => {
    //   this.Cptcodes = res;
    // });
    this.getIcdCode();
    // this.ICDcodes();

    let decodedToken = this.helper.decodeToken(this.myToken);
    this.DoctorId = parseInt(decodedToken.nameid);
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
    this.patientService.getpatienHospitalId(this.id).subscribe((res) => {
      this.hospitalId = res.Hospital.HospitalId;
    });
    this.Frm = this.fb.group({
      DoctorId: this.DoctorId,
      DoctorName: [''],
      PatientId: this.id,
      TreatmentDate: ['', Validators.required],
      Episode: ['', Validators.required],
      Referrer: [''],
      DiagnosisCodes: this.fb.array([]),
      NextFollowUp: [''],
      Subjective: ['', Validators.required],
      Objective: ['', Validators.required],
      Assessment: ['', Validators.required],
      Plan: ['', Validators.required],
      JointMobilization: [''],
      SoftTissueMobilization: [''],
      Stretching: [''],
      SingleLegBalance: [''],
      StepUps: [''],
      QuadStr: [''],
      BirdDogs: [''],
      Rotations: [''],
      PelvicTilts: [''],
      AbdominalISOs: [''],
      LionLatStretch: [''],
      ChildsPose: [''],
      CatandCamelStretch: [''],
      DownwardDog: [''],
      Signature: [''],
      TimeIn: [''],
      TimeOut: [''],
      Status: [true],
    });
    this.addItem();
  }

  public addItem(): void {
    const item = this.fb.group({
      Code: [''],
      // Description: [''],
    });
    this.DiagnosisCodes.push(item);
  }

  public removeItem(i: number): void {
    this.DiagnosisCodes.removeAt(i);
  }

  get TreatmentDate() {
    return this.Frm.get('TreatmentDate');
  }
  get TimeOut() {
    return this.Frm.get('TimeOut');
  }

  get TimeIn() {
    return this.Frm.get('TimeIn');
  }

  get Signature() {
    return this.Frm.get('Signature');
  }
  get DoctorName() {
    return this.Frm.get('DoctorName');
  }

  get DownwardDog() {
    return this.Frm.get('DownwardDog');
  }

  get CatandCamelStretch() {
    return this.Frm.get('CatandCamelStretch');
  }

  get ChildsPose() {
    return this.Frm.get('ChildsPose');
  }

  get LionLatStretch() {
    return this.Frm.get('LionLatStretch');
  }
  get AbdominalISOs() {
    return this.Frm.get('AbdominalISOs');
  }
  get PelvicTilts() {
    return this.Frm.get('PelvicTilts');
  }

  get BirdDogs() {
    return this.Frm.get('BirdDogs');
  }

  get QuadStr() {
    return this.Frm.get('QuadStr');
  }
  get StepUps() {
    return this.Frm.get('StepUps');
  }

  get SingleLegBalance() {
    return this.Frm.get('SingleLegBalance');
  }

  get Stretching() {
    return this.Frm.get('Stretching');
  }

  get SoftTissueMobilization() {
    return this.Frm.get('SoftTissueMobilization');
  }

  get JointMobilization() {
    return this.Frm.get('JointMobilization');
  }

  get Plan() {
    return this.Frm.get('Plan');
  }

  get Assessment() {
    return this.Frm.get('Assessment');
  }

  get Objective() {
    return this.Frm.get('Objective');
  }

  get Subjective() {
    return this.Frm.get('Subjective');
  }

  get NextFollowUp() {
    return this.Frm.get('NextFollowUp');
  }

  get DiagnosisCodes(): UntypedFormArray {
    return this.Frm.get('DiagnosisCodes') as UntypedFormArray;
  }

  get Episode() {
    return this.Frm.get('Episode');
  }
  get Referer() {
    return this.Frm.get('Referrer');
  }
  getIcdCode() {
    this.icdCodeService.getIcdCodes().subscribe((res) => {
      this.icdCodeList = res;
    });
  }
  getPTdoctors() {
    this.physicaltherpyDoctorService
      .getPhysicalTherapyDoctors()
      .subscribe((res) => {
        this.doctorList = res;
      });
  }
  startService() {
    this.service.start();
    this.listening = true;
  }

  stopService() {
    this.service.stop();
    this.listening = false;
  }

  onSubjectiveChange(e) {
    this.service.message1 = e.target.value;
  }

  onSubjectiveBlur() {
    this.stopService();
    this.stopServiceObjective();
    this.stopServiceAssessment();
    this.stopServiceJoint();
    this.stopServiceSoftTissue();
    this.stopServiceStreching();
  }
  onObjectiveBlur() {
    this.stopService();
    this.stopServiceObjective();
    this.stopServiceAssessment();
    this.stopServiceJoint();
    this.stopServiceSoftTissue();
    this.stopServiceStreching();
  }

  onAssessmentBlur() {
    this.stopService();
    this.stopServiceObjective();
    this.stopServiceAssessment();
    this.stopServiceJoint();
    this.stopServiceSoftTissue();
    this.stopServiceStreching();
  }
  onPatientNoteBlur() {
    this.stopService();
    this.stopServiceObjective();
    this.stopServiceAssessment();
    this.stopServiceJoint();
    this.stopServiceSoftTissue();
    this.stopServiceStreching();
  }
  onJointBlur() {
    this.stopService();
    this.stopServiceObjective();
    this.stopServiceAssessment();
    this.stopServicePatientNote();
    this.stopServiceSoftTissue();
    this.stopServiceStreching();
  }
  onSoftTissueBlur() {
    this.stopService();
    this.stopServiceObjective();
    this.stopServiceAssessment();
    this.stopServicePatientNote();
    this.stopServiceJoint();
    this.stopServiceStreching();
  }
  onStrechingBlur() {
    this.stopService();
    this.stopServiceObjective();
    this.stopServiceAssessment();
    this.stopServicePatientNote();
    this.stopServiceJoint();
    this.stopServiceSoftTissue();
  }
  stopAll() {
    this.stopService();
    this.stopServiceObjective();
    this.stopServiceAssessment();
    this.stopServicePatientNote();
    this.stopServiceJoint();
    this.stopServiceSoftTissue();
    this.stopServiceStreching();
  }

  startServiceObjective() {
    this.serviceObjective.startObjective();
    this.listeningObjective = true;
  }
  stopServiceObjective() {
    this.serviceObjective.stopObjective();
    this.listeningObjective = false;
  }

  onObjectiveChange(e) {
    this.serviceObjective.Objective = e.target.value;
  }

  startServiceAssessment() {
    this.serviceAssessment.startAssessment();
    this.listeningAssessment = true;
  }
  stopServiceAssessment() {
    this.serviceAssessment.stopAssessment();
    this.listeningAssessment = false;
  }
  onAssessmentChange(e) {
    this.serviceAssessment.Assessment = e.target.value;
  }

  onPatientNoteChange(e) {
    this.servicePlan.PatientNote = e.target.value;
  }
  startServicePatientNote() {
    this.servicePlan.startPatientNote();
    this.listeningPatientNote = true;
  }
  stopServicePatientNote() {
    this.servicePlan.stopPatientNote();
    this.listeningPatientNote = false;
  }
  onJointChange(e) {
    this.serviceJoint.jointMobilisation = e.target.value;
  }
  startServiceJoint() {
    this.serviceJoint.start();
    this.listeningJoint = true;
  }
  stopServiceJoint() {
    this.serviceJoint.stop();
    this.listeningJoint = false;
  }
  onSoftTissueChange(e) {
    this.serviceSoftTissue.softTissue = e.target.value;
  }
  startServiceSoftTissue() {
    this.serviceSoftTissue.start();
    this.listeningSoftTissue = true;
  }
  stopServiceSoftTissue() {
    this.serviceSoftTissue.stop();
    this.listeningSoftTissue = false;
  }
  onStrechingChange(e) {
    this.serviceStreching.streching = e.target.value;
  }
  startServiceStreching() {
    this.serviceStreching.start();
    this.listeningStreching = true;
  }
  stopServiceStreching() {
    this.serviceStreching.stop();
    this.listeningStreching = false;
  }
  formSubmit() {
    let body = {
      ...this.Frm.value,
      HospitalId: this.hospitalId,
      SoftwareFee: 1.5,
    };
    this.physicalTherpyVisitService
      .registerPhysicalTherapyVisit(body)
      .subscribe((res) => {
        this.isSuccess = true;
        this.Frm.reset();
      });
  }

  // public ICDcodes() {
  //   this.http.get('/assets/json/ICD_Code.json').subscribe({
  //     next: (res: any) => {
  //       this.cptCodeList = res.Cptcodes;

  //     },
  //   });
  // }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.Cptcodes.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }
}
