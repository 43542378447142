import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Doctor } from 'src/app/Models/doctor.model';
import { PhysicalTherapyDoctor } from 'src/app/Models/physicaltherapydoctor.model';
import { AppointmentService } from 'src/app/services/appointment.service';
import { DoctorService } from 'src/app/services/doctor.service';
import { PhysicaltherapyBookingService } from 'src/app/services/physicaltherapy-booking.service';
import { PhysicalTherapyDoctorService } from 'src/app/services/physical-therapy-doctor.service';

@Component({
  selector: 'app-update-appointment',
  templateUrl: './update-appointment.component.html',
  styleUrls: ['./update-appointment.component.css'],
})
export class UpdateAppointmentComponent implements OnInit {
  id = 0;
  options: string[] = ['New Patient', 'Follow Up'];
  STS: string[] = [
    'Confirmed',
    'Confirmed by Phone',
    'not confirmed',
    'cancelled',
  ];
  doctorSpeciality: any[] = ['Physical Therapy'];
  doctorList: PhysicalTherapyDoctor[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private appointmentService: PhysicaltherapyBookingService,
    private fb: UntypedFormBuilder,
    private doctorService: PhysicalTherapyDoctorService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
    this.getDoctors();

    this.appointmentService
      .getPhysicalTherapyBookingById(this.id)
      .subscribe((res) => {
        this.DoctorName.setValue(res.DoctorName);
        this.DoctorSpecialization.setValue(res.DoctorSpecialization);
        this.VisitType.setValue(res.VisitType);
        this.VisitDate.setValue(res.VisitDate);
        this.VisitTime.setValue(res.VisitTime);
        this.Message.setValue(res.Message);
        this.Status.setValue(res.Status);
      });
  }

  addVisitFrm = this.fb.group({
    DoctorName: ['', Validators.required],
    DoctorSpecialization: ['', Validators.required],
    VisitType: ['', Validators.required],
    VisitDate: ['', Validators.required],
    VisitTime: ['', Validators.required],
    Status: ['', Validators.required],
    Message: [''],
  });

  get Status() {
    return this.addVisitFrm.get('Status');
  }
  get VisitType() {
    return this.addVisitFrm.get('VisitType');
  }
  get DoctorName() {
    return this.addVisitFrm.get('DoctorName');
  }

  get DoctorSpecialization() {
    return this.addVisitFrm.get('DoctorSpecialization');
  }

  get VisitDate() {
    return this.addVisitFrm.get('VisitDate');
  }
  get VisitTime() {
    return this.addVisitFrm.get('VisitTime');
  }

  get Message() {
    return this.addVisitFrm.get('Message');
  }

  getDoctors() {
    this.doctorService.getPhysicalTherapyDoctors().subscribe((res) => {
      this.doctorList = res;
    });
  }

  formSubmit() {
    let doctorName = this.addVisitFrm.value['DoctorName'];
    let doctorSpecialization = this.addVisitFrm.value['DoctorSpecialization'];
    let visitType = this.addVisitFrm.value['VisitType'];
    let date = this.addVisitFrm.value['VisitDate'];
    let time = this.addVisitFrm.value['VisitTime'];
    let message = this.addVisitFrm.value['Message'];
    let status = this.addVisitFrm.value['Status'];

    let body = {
      DoctorName: doctorName,
      DoctorSpecialization: doctorSpecialization,
      VisitType: visitType,
      VisitDate: date,
      VisitTime: time,
      Message: message,
      Status: status,
      Tocken: Date.now(),
    };

    this.appointmentService
      .updatePhysicalTherapyBooking(this.id, body)
      .subscribe((res) => {
        this.router.navigate(['hospitalDash']);
      });
  }
}
