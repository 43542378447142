import { Platform } from '@angular/cdk/platform';
import { CdkTextColumn } from '@angular/cdk/table';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DoctornoteService } from 'src/app/services/doctornote.service';
import { AfterViewInit, ElementRef } from '@angular/core';
import * as iink from 'iink-js';
@Component({
  selector: 'app-notepad',
  templateUrl: './notepad.component.html',
  styleUrls: ['./notepad.component.css'],
})
export class NotepadComponent implements AfterViewInit {
  // Get the action buttons via ViewChild
  @ViewChild('undo', { static: true })
  public undoBtn!: ElementRef<HTMLButtonElement>;
  @ViewChild('redo', { static: true })
  public redoBtn!: ElementRef<HTMLButtonElement>;
  @ViewChild('pen', { static: true })
  public penBtn!: ElementRef<HTMLButtonElement>;
  @ViewChild('eraser', { static: true })
  public erasorBtn!: ElementRef<HTMLButtonElement>;
  @ViewChild('editor', { static: true })
  public editor!: ElementRef<HTMLDivElement>;

  isEraserActive = false;
  isPenActive = true;

  constructor() {}

  ngAfterViewInit(): void {
    this.initializeDrawPad();
    this.addEventListeners();
    this.importText('Hello World');
  }

  private initializeDrawPad(): void {
    const editorElement: HTMLDivElement = this.editor.nativeElement;
    const recognitionParams = {
      type: 'TEXT',
      protocol: 'WEBSOCKET',
      server: {
        scheme: 'https',
        host: 'webdemoapi.myscript.com',
        applicationKey: '515131ab-35fa-411c-bb4d-3917e00faf60',
        hmacKey: '54b2ca8a-6752-469d-87dd-553bb450e9ad',
      },
      iink: {
        export: {
          jiix: {
            strokes: true, // to export
          },
        },
      },
    };
    // register drawpad
    //  iink.register(editorElement, { recognitionParams })
  }

  private addEventListeners(): void {
    const editorElement: any = this.editor.nativeElement;
    // get undo/redo change
    editorElement.addEventListener('changed', (event: any) => {
      this.undoBtn.nativeElement.disabled = !event.detail.canUndo;
      this.redoBtn.nativeElement.disabled = !event.detail.canRedo;
    });

    // undo btn
    this.undoBtn.nativeElement.addEventListener('click', () => {
      editorElement.editor.undo();
    });

    // redo btn
    this.redoBtn.nativeElement.addEventListener('click', () => {
      editorElement.editor.redo();
    });

    // erasor btn
    this.erasorBtn.nativeElement.addEventListener('click', () => {
      editorElement.editor.enableEraser();
      this.isEraserActive = true;
      this.isPenActive = false;
    });

    // pen btn
    this.penBtn.nativeElement.addEventListener('click', () => {
      editorElement.editor.disableEraser();
      this.isEraserActive = false;
      this.isPenActive = true;
    });
  }

  /**
   * Get the exported text
   */
  public getExportedText(): void {
    const editorElement: any = this.editor.nativeElement;
    editorElement.editor.export_();
    editorElement.addEventListener('exported', (evt) => {
      if (evt.detail) {
      }
    });
  }

  public importText(text): void {
    const editorElement: any = this.editor.nativeElement;
    editorElement.editor.import_(text, 'text/plain');
  }
}
