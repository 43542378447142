import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Patient } from 'src/app/Models/patient.model';
import { RadiologyOrder } from 'src/app/Models/radilogyorder.model';
import { Refferal } from 'src/app/Models/refferal.model';
import { PatientService } from 'src/app/services/patient.service';
import { RefferalService } from 'src/app/services/refferal.service';

@Component({
  selector: 'app-view-refferal',
  templateUrl: './view-refferal.component.html',
  styleUrls: ['./view-refferal.component.css'],
})
export class ViewRefferalComponent implements OnInit {
  @ViewChild('Refferal') Refferal!: ElementRef;

  displayedColumns: string[] = [
    'Date',
    'Subject',
    'RefferingDoctorName',
    'RefferedToDoctorName',
    'View',
    'Delete',
  ];

  id = 0;
  refferal: Refferal[] = [];
  dataSource: MatTableDataSource<any>;
  radioDocument: string = '';
  patient: Patient;

  email = new FormControl('');
  isHide = false;
  constructor(
    private patientService: PatientService,
    private refferalService: RefferalService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });

    this.GetRefferal(this.id);
    this.getPatient(this.id);
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  // ngAfterViewInit() {
  //   this.dataSource.paginator = this.paginator;
  //   this.dataSource.sort = this.sort;
  // }

  getPatient(id) {
    this.patientService.getPatientById(id).subscribe((res) => {
      this.patient = res;
    });
  }
  GetRefferal(id) {
    this.patientService.GetRefferal(id).subscribe((res) => {
      this.dataSource = new MatTableDataSource(
        res.Refferals.sort(function (x, y) {
          return y.RefferalId - x.RefferalId;
        })
      );

      this.dataSource.paginator = this.paginator;
    });
  }
  delete(id) {
    this.refferalService.deleteRefferal(id).subscribe((res) => {
      alert('deleted');
      this.GetRefferal(this.id);
    });
  }
  print() {
    window.print();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  sendtoDoctor() {
    let body = {
      Email: this.email.value,
      Subject: 'Refferal' + this.Refferal,
      Template: `${this.Refferal.nativeElement.innerHTML}`,
    };
    this.patientService.sendRefferal(body).subscribe((res) => {
      alert('Refferal sent');
    });
  }
}
