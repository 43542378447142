<div>

    <button mat-raised-button color="primary" [routerLink]="['/patientDash/',this.patientId]"><mat-icon
            mat-list-icon>undo</mat-icon>
        Dashboard</button>
</div>
<div class="card" #Invoice>
    <div class="card-body">
        <div class="container mb-5 mt-3">
            <div class="row d-flex align-items-baseline">
                <div class="col-xl-9">
                    <p style="color: #7e8d9f;font-size: 20px;">Invoice >> <span>ID: #{{invoice?.InvoiceId}}</span></p>
                </div>
                <div class="col-xl-3 float-end">
                    <a class="btn btn-light text-capitalize border-0" data-mdb-ripple-color="dark"
                        (click)="printPage()"><i class="fas fa-print text-primary"></i> Print</a>
                    <!-- <a class="btn btn-light text-capitalize" data-mdb-ripple-color="dark"><i
                class="far fa-file-pdf text-danger"></i> Export</a> -->
                    <a class="btn btn-light text-capitalize" data-mdb-ripple-color="dark" (click)="sendInvoice()"><i
                            class="far fa-envelope text-primary"></i> Email to patient</a>

                    <!-- <mat-form-field class="w-100 mr-3" appearance="outline">
                        <mat-label>Email invoice to Insurance</mat-label>
                        <input matInput type="text" [formControl]="email" placeholder="Enter email">
                    </mat-form-field>
                    <button mat-raised-button color="primary" class="mb-1" (click)="sendtoInsurance()">Send</button> -->
                </div>
                <hr>
            </div>

            <div class="" id="printable">


                <div class="container mt-3 ">
                    <div class="row">
                        <div class="col-lg-4 ">

                            <!-- <h2>Methodist Hospital</h2> -->
                            <!-- <img src="../../../assets/biserX.png" style="max-width: 270px;" alt=""> -->
                            <div [innerHTML]="hospital?.HospitalLogo" class="cell"></div>
                            <!-- <img src="{{hospital?.HospitalLogo}}" style="max-width: 270px;" alt=""> -->

                        </div>
                        <div class="col-lg-4"></div>
                        <div class="col-lg-4 ">
                            <p> {{hospital?.HospitalName}}</p>
                            <p>Address: {{hospital?.HospitalAddress}}</p>
                            <p>Phone: {{hospital?.HospitalPhone}}</p>
                            <p>Email: {{hospital?.HospitalEmail}}</p>
                        </div>
                    </div>
                </div>
                <hr>

                <p class="mt-3 text-center bld">{{invoice?.DoctorName}}</p>
                <hr>
                <div class="row">
                    <div class="col-xl-8">
                        <ul class="list-unstyled">
                            <li class="text-muted">Patient Id : <span
                                    style="color:#5d9fc5 ;">{{invoice?.PatientId}}</span></li>
                            <li class="text-muted">Patient Name : <span
                                    style="color:#5d9fc5 ;">{{invoice?.PatientName}}</span></li>

                            <li class="text-muted">Patient Address : <span
                                    style="color:#5d9fc5 ;">{{invoice?.PatientAddress}}</span>
                            </li>

                            <li class="text-muted">Patient DOB : <span
                                    style="color:#5d9fc5 ;">{{invoice?.PatientDOB}}</span></li>
                            <hr>
                            <li class="text-muted">Insurance No : <span
                                    style="color:#5d9fc5 ;">{{invoice?.InsuaranceNo}}</span>
                            </li>
                            <li class="text-muted">Group No : <span style="color:#5d9fc5 ;">{{invoice?.GroupNo}}</span>
                            </li>

                            <!-- <li class="text-muted">State, Country</li>
                  <li class="text-muted"><i class="fas fa-phone"></i> 123-456-789</li> -->
                        </ul>
                    </div>
                    <div class="col-xl-4">
                        <p class="text-muted">Invoice</p>
                        <ul class="list-unstyled">
                            <li class="text-muted"><i class="fas fa-circle" style="color:#84B0CA ;"></i> <span
                                    class="fw-bold ml-2">Invoice Id:</span>#{{invoice?.InvoiceId}}</li>
                            <li class="text-muted"><i class="fas fa-circle" style="color:#84B0CA ;"></i> <span
                                    class="fw-bold ml-2">Creation Date: </span>{{invoice?.Date}}</li>
                            <li class="text-muted"><i class="fas fa-circle" style="color:#84B0CA ;"></i> <span
                                    class=" fw-bold ml-2">Status:</span><span
                                    class="badge bg-warning text-black fw-bold ml-2">
                                    {{invoice?.Status}}</span></li>
                        </ul>
                    </div>
                </div>
                <hr>
                <div class="row d-flex ml-1">
                    <h2 style="color:#5d9fc5 ;">Subject: {{invoice?.Title}}</h2>
                </div>
                <div class="row my-2 mx-1 justify-content-center">
                    <table class="table table-striped table-borderless">
                        <thead id="invoice-header">
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">ProcedureCode</th>
                                <th scope="col">Description</th>
                                <!-- <th scope="col">Units</th>
                                <th scope="col">Duration</th> -->
                                <th scope="col">Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of invoice?.InvoiceItems; let i=index ">
                                <td scope="col">#{{i+1}}</td>
                                <td>{{item.ProcedureCode}}</td>
                                <td>{{item.Description}}</td>
                                <!-- <td>{{item.Units}}</td>
                                <td>{{item.Duration}}</td>-->
                                <td>${{item.Amount}}.00</td>
                            </tr>

                        </tbody>

                    </table>
                </div>
                <div class="row">
                    <div class="col-xl-8">
                        <!-- <p class="ms-3">Add additional notes and payment information</p> -->
                        <p style="font-size: 15px;">Co-Pay: <span>${{invoice?.CoPay}}.00</span></p>
                    </div>
                    <div class="col-xl-8">
                        <!-- <p class="ms-3">Add additional notes and payment information</p> -->
                        <!-- <p style="font-size: 15px;">Insurance Pay: <span>${{invoice?.InsurancePay}}.00</span></p> -->
                    </div>
                    <div class="col-xl-8">
                        <!-- <p class="ms-3">Add additional notes and payment information</p> -->
                        <!-- <p style="font-size: 15px;">Discount: <span>${{invoice?.AdjustmentAmount}}.00</span>
                        </p> -->
                    </div>
                    <div class="col-xl-3">
                        <ul class="list-unstyled">
                            <li class="text-muted ms-3"><span
                                    class="text-black me-4 mr-5">SubTotal</span>${{invoice?.SubTotal}}.00
                            </li>
                            <!-- <li class="text-muted ms-3 mt-2"><span class="text-black me-4">Tax(15%)</span>$111</li> -->
                        </ul>
                        <p class="text-black float-start"><span class="text-black me-3 mr-2 " style="font-size: 20px;">
                                Total
                                Amount</span><span style="font-size: 20px;"> ${{invoice?.Total}}.00</span></p>
                        <p class="text-black float-start"><span class="text-black me-3 mr-2 " style="font-size: 20px;">
                                Balance
                            </span><span style="font-size: 20px;"> ${{invoice?.BalanceAmount}}.00</span></p>
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-xl-10">
                        <p>Thank you for your visit</p>
                    </div>
                    <div class="modal-footer d-flex flex-sm-row flex-column justify-content-between align-items-center">



                        <div>

                        </div>
                        <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
                    </div>
                </div>

            </div>
            <!-- Start Paymet -->
            <mat-tab-group>
                <mat-tab label="Full Payment">
                    <div class="container mt-5">
                        <div class="row ">
                            <div class="col-lg-4 ">
                                <div *ngIf="invalidError">
                                    <!-- {{ invalidError.message }} -->
                                </div>
                                <div class="card-element">
                                    <stripe-card #stripeCard (catch)="onStripeError($event)" [(invalid)]="invalidError"
                                        (tokenChange)="setStripeToken($event)"
                                        (sourceChange)="setStripeSource($event)"></stripe-card>

                                    <button type="button" class="btn mt-5" (click)="stripeCard.createToken({})"
                                        color=primary mat-raised-button>Pay Now</button>
                                </div>
                                <div *ngIf="isPaymentSuccess" class="alert alert-success mt-2">Paid Sucessfully</div>

                            </div>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab label="Partial Payment">
                    <div class="container mt-5">
                        <div class="row ">
                            <div class="col-lg-4 ">
                                <div *ngIf="invalidError">
                                    <!-- {{ invalidError.message }} -->
                                </div>
                                <div class="card-element">
                                    <mat-form-field>
                                        <input matInput placeholder="Enter your amount" [(ngModel)]="partialAmount"
                                            name="partialAmount">
                                    </mat-form-field>

                                    <stripe-card #stripeCard (catch)="onStripeError($event)" [(invalid)]="invalidError"
                                        (tokenChange)="parialPayement($event,partialAmount)"
                                        (sourceChange)="setStripeSource($event)"></stripe-card>

                                    <button type="button" class="btn mt-5" (click)="stripeCard.createToken({})"
                                        color=primary mat-raised-button>Pay Now</button>
                                </div>
                                <div *ngIf="isPaymentSuccess" class="alert alert-success mt-2">Paid Sucessfully</div>

                            </div>
                        </div>
                    </div>
                </mat-tab>
            </mat-tab-group>




            <!-- End Payment -->

        </div>


    </div>
</div>