import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, Validators } from '@angular/forms';

import { UserService } from 'src/app/services/user.service';
import { Doctor } from 'src/app/Models/doctor.model';
import { DoctorService } from 'src/app/services/doctor.service';
import { WardService } from 'src/app/services/ward.service';
import { Ward } from 'src/app/Models/ward.model';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';

import { Observable, Subject } from 'rxjs';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { PhysicalTherapyDoctor } from 'src/app/Models/physicaltherapydoctor.model';
import { PhysicalTherapyDoctorService } from 'src/app/services/physical-therapy-doctor.service';
import { HttpClient } from '@angular/common/http';
import { map, startWith } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HospitalService } from 'src/app/services/hospital.service';
import { EmailService } from 'src/app/services/email.service';
import { PatientService } from 'src/app/services/patient.service';

@Component({
  selector: 'app-addpatient',
  templateUrl: './addpatient.component.html',
  styleUrls: ['./addpatient.component.css'],
})
export class AddpatientComponent implements OnInit {
  isSuccess = false;
  id = 0;
  Gender: string[] = ['Male', 'Female', 'Other'];
  bloodGroup: string[] = ['A+', 'B+', 'O+', 'AB+', 'A-', 'O-', 'B-', 'AB-'];
  maritalStatus: string[] = ['Married', 'Single', 'Widow', 'Other'];
  doctorSpeciality: string[] = ['Physical Therapy'];
  doctorList: PhysicalTherapyDoctor[] = [];
  wardList: Ward[];
  imageUrl: string;
  Codes: any[] = [];
  filteredOptions: Observable<string[]>;

  helper = new JwtHelperService();

  myToken = window.localStorage.getItem('token');
  hospitalName: string;

  constructor(
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private patientService: PatientService,
    private userService: UserService,
    private router: Router,
    private doctorService: PhysicalTherapyDoctorService,
    private wardService: WardService,
    private http: HttpClient,
    private hosptalService: HospitalService,
    private emailService: EmailService
  ) {}

  ngOnInit(): void {
    // this.route.params.subscribe((res) => {
    //   this.id = res['id'];
    // });
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.id = parseInt(decodedToken.nameid);

    this.hosptalService.getHospitalById(this.id).subscribe((res) => {
      this.hospitalName = res.HospitalName.substring(0, 3);
    });

    this.getDoctors(this.id);
    this.countryCodes();
    this.filteredOptions = this.ptFrm.controls.CountryCode.valueChanges.pipe(
      startWith(''),
      map((value: string) => this._filter(value || ''))
    );
  }

  ptFrm = this.fb.group({
    PatientFirstName: ['', Validators.required],
    PatientLastName: ['', Validators.required],
    PatientDOB: ['', Validators.required],
    PatientGender: ['', Validators.required],
    Email: ['', Validators.required],
    PhoneNumber: ['', Validators.required],
    PatientAddress: ['', Validators.required],
    DoctorName: ['', Validators.required],
    PatientNationalId: ['', Validators.required],
    MaritalStatus: ['', Validators.required],
    Occupation: [''],
    BloodGroup: ['', Validators.required],
    Password: [
      '',
      [
        Validators.required,
        Validators.pattern(
          '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-zd$@$!%*?&].{7,}$'
        ),
      ],
    ],
    PhysicalTherapyDoctorId: [],
    NurseName: [''],
    ReferredDoctor: [''],
    PatientHeight: [''],
    PatientWeight: [''],
    CountryCode: ['+1', Validators.required],
  });

  get PatientHeight() {
    return this.ptFrm.get('PatientHeight');
  }

  get PatientWeight() {
    return this.ptFrm.get('PatientWeight');
  }

  get ReferredDoctor() {
    return this.ptFrm.get('ReferredDoctor');
  }

  get PhysicalTherapyDoctorId() {
    return this.ptFrm.get('PhysicalTherapyDoctorId');
  }
  get PatientFirstName() {
    return this.ptFrm.get('PatientFirstName');
  }
  get PatientLastName() {
    return this.ptFrm.get('PatientLastName');
  }
  get PatientDOB() {
    return this.ptFrm.get('PatientDOB');
  }
  get PatientGender() {
    return this.ptFrm.get('PatientGender');
  }
  get PatientEmail() {
    return this.ptFrm.get('Email');
  }
  get PhoneNumber() {
    return this.ptFrm.get('PhoneNumber');
  }
  get PatientAddress() {
    return this.ptFrm.get('PatientAddress');
  }

  get DoctorName() {
    return this.ptFrm.get('DoctorName');
  }
  get PatientNationalId() {
    return this.ptFrm.get('PatientNationalId');
  }
  get MaritalStatus() {
    return this.ptFrm.get('MaritalStatus');
  }
  get Occupation() {
    return this.ptFrm.get('Occupation');
  }
  get BloodGroup() {
    return this.ptFrm.get('BloodGroup');
  }
  get Password() {
    return this.ptFrm.get('Password');
  }
  get CountryCode() {
    return this.ptFrm.get('CountryCode');
  }

  getDoctors(id) {
    this.hosptalService.getDoctors(id).subscribe((res) => {
      this.doctorList = res.PhysicalTherapyDoctors;
    });
  }
  image(e) {
    let formData = new FormData();
    let files = e.target.files;
    formData.append('file', files[0], files[0].name);

    this.patientService.imageUpload(formData).subscribe(
      (res) => {
        let stImg = environment.imgurl + res['path'];
        this.imageUrl = stImg;
        // this.imageUrl = `<p><img src="${stImg}"/></p>`;
      },
      (err) => console.log(err)
    );
  }

  private trigger: Subject<any> = new Subject();
  public webcamImage!: WebcamImage;
  private nextWebcam: Subject<any> = new Subject();
  sysImage = '';
  public getSnapshot(e): void {
    e.preventDefault;
    this.trigger.next(void 0);
  }
  public captureImg(webcamImage: WebcamImage): void {
    this.webcamImage = webcamImage;
    this.sysImage = webcamImage!.imageAsDataUrl;
  }
  public get invokeObservable(): Observable<any> {
    return this.trigger.asObservable();
  }
  public get nextWebcamObservable(): Observable<any> {
    return this.nextWebcam.asObservable();
  }

  formSubmit() {
    let patientFirstName = this.ptFrm.value['PatientFirstName'];
    let patientLastName = this.ptFrm.value['PatientLastName'];
    let patientDOB = this.ptFrm.value['PatientDOB'];
    patientDOB = moment(patientDOB).format('MM/DD/YYYY');

    let patientGender = this.ptFrm.value['PatientGender'];
    let patientEmail = this.ptFrm.value['Email'];

    let phoneNumber =
      this.ptFrm.value['CountryCode'] + this.ptFrm.value['PhoneNumber'];

    let patientAddress = this.ptFrm.value['PatientAddress'];
    let patientWeight = this.ptFrm.value['PatientWeight'];
    let patientHeight = this.ptFrm.value['PatientHeight'];
    let referredDoctor = this.ptFrm.value['ReferredDoctor'];

    let patientNationalId = this.ptFrm.value['PatientNationalId'];
    let doctorName = this.ptFrm.value['DoctorName'];
    let mMaritalStatus = this.ptFrm.value['MaritalStatus'];
    let occupation = this.ptFrm.value['Occupation'];
    let bloodGroup = this.ptFrm.value['BloodGroup'];
    let password = this.ptFrm.value['Password'];
    let doctorId = this.ptFrm.value['PhysicalTherapyDoctorId'];
    let idoctorName = this.doctorList.filter(
      (it) => it.PhysicalTherapyDoctorId == doctorId
    )[0].DoctorName;

    let userBody = {
      Email: patientEmail,
      Password: password,
      UserName: this.hospitalName + patientEmail,
      PhoneNumber: phoneNumber,
    };
    this.userService.registerUser(userBody).subscribe((res) => {
      let body = {
        HospitalId: this.id,
        PatientId: res.Id,
        PhysicalTherapyDoctorId: doctorId,
        PatientFirstName: patientFirstName,
        PatientLastName: patientLastName,
        PatientDOB: patientDOB,
        PatientGender: patientGender,
        PatientEmail: patientEmail,
        Password: password,
        PhoneNumber: phoneNumber,
        PatientAddress: patientAddress,
        PatientHeight: patientHeight,
        PatientWeight: patientWeight,
        ReferredDoctor: referredDoctor,
        DoctorName: idoctorName,
        PatientNationalId: patientNationalId,
        MaritalStatus: mMaritalStatus,
        Occupation: occupation,
        BloodGroup: bloodGroup,
        PatientImage: this.sysImage ? this.sysImage : this.imageUrl,
        Status: 'Active',
      };
      this.patientService.registerPatient(body).subscribe((res) => {
        this.isSuccess = true;
        this.ptFrm.reset();

        this.emailService
          .sendEmail({
            Email: body.PatientEmail,
            Subject: 'Patient Login Credentials',
            Template: `<h3>Your Username ${userBody.UserName} please keep this safe. You may reset password now at https://binserapt.com. </h3> `,
          })
          .subscribe();

        const smsBodyDetails =
          'Your Username ' +
          userBody.UserName +
          ' You may reset password now at https://binserapt.com ';
        let smsBody = {
          PhoneNumber: phoneNumber,
          Body: smsBodyDetails,
        };
        this.emailService.sendSms(smsBody).subscribe();
        this.isSuccess = true;
        this.ptFrm.reset();

        setTimeout(() => {}, 2000);
      });
    });
  }

  public countryCodes() {
    this.http.get('/assets/json/country-codes.json').subscribe({
      next: (res: any) => {
        this.Codes = res.map((x) => x.dial_code);
      },
    });
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.Codes.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }
  facingMode: string = 'user'; //Set front camera
  allowCameraSwitch = true;

  public get videoOptions(): MediaTrackConstraints {
    const result: MediaTrackConstraints = {};
    if (this.facingMode && this.facingMode !== '') {
      result.facingMode = { ideal: this.facingMode };
    }
    return result;
  }
}
