import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ExersiceLiabilityWaiver } from 'src/app/Models/excersiseliabilitywaiver.model';
import { Hospital } from 'src/app/Models/hospital.model';
import { ExersiceLiabilityWaiverService } from 'src/app/services/exersice-liability-waiver.service';
import { PatientService } from 'src/app/services/patient.service';

@Component({
  selector: 'app-patient-view-exersice-liability-waiver',
  templateUrl: './patient-view-exersice-liability-waiver.component.html',
  styleUrls: ['./patient-view-exersice-liability-waiver.component.css'],
})
export class PatientViewExersiceLiabilityWaiverComponent implements OnInit {
  id = 0;
  exersiceLiabilityWaiverList: ExersiceLiabilityWaiver[] = [];
  hospital: Hospital;
  PatientName: string;
  constructor(
    private route: ActivatedRoute,
    private patientService: PatientService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
    this.patientService.getpatienHospitalId(this.id).subscribe((res) => {
      this.hospital = res.Hospital;
    });
    this.getPatient(this.id);
    this.getExersiceLiabilityWaiver(this.id);
  }

  getExersiceLiabilityWaiver(id) {
    this.patientService.GetExersiceLiabilityWaiver(id).subscribe((res) => {
      this.exersiceLiabilityWaiverList = res.ExersiceLiabilityWaivers;
    });
  }
  getPatient(id) {
    this.patientService.getPatientById(id).subscribe((res) => {
      this.PatientName = res.PatientFirstName + res.PatientLastName;
    });
  }
  print() {
    window.print();
  }
}
