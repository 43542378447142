import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';
import { CptCode } from 'src/app/Models/cptcode.model';
import { IcdCode } from 'src/app/Models/icdcode.model';
import { PhysicalTherapyDoctor } from 'src/app/Models/physicaltherapydoctor.model';
import { CptcodeService } from 'src/app/services/cptcode.service';
import { DiagnosisCodeService } from 'src/app/services/diagnosis-code.service';
import { IcdCodeService } from 'src/app/services/icd-code.service';
import { PhysicalTherapyDoctorService } from 'src/app/services/physical-therapy-doctor.service';
import { PhysicalTherapyVisitService } from 'src/app/services/physical-therapy-visit.service';

@Component({
  selector: 'app-update-physical-therapy-visit',
  templateUrl: './update-physical-therapy-visit.component.html',
  styleUrls: ['./update-physical-therapy-visit.component.css'],
})
export class UpdatePhysicalTherapyVisitComponent implements OnInit {
  id = 0;
  DoctorId: Number;
  helper = new JwtHelperService();
  myToken = window.localStorage.getItem('token');
  doctorList: PhysicalTherapyDoctor[] = [];
  Frm: FormGroup;
  isSuccess = false;
  icdCodeList: IcdCode[];
  Cptcodes: any;
  filteredOptions: Observable<string[]>;
  data: any;

  autoFillItems = [
    { value: '1x' },
    { value: '2x' },
    { value: '3x' },
    { value: '4x' },
    { value: '5x' },
    { value: '6x' },
    { value: '7x' },
    { value: '8x' },
    { value: '9x' },
    { value: '10x' },
    { value: '11x' },
    { value: '12x' },
    { value: '13x' },
    { value: '14x' },
    { value: '15x' },
    { value: '16x' },
    { value: '17x' },
    { value: '18x' },
    { value: '19x' },
    { value: '20x' },
  ];
  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private physicalTherpyVisitService: PhysicalTherapyVisitService,
    private physicaltherpyDoctorService: PhysicalTherapyDoctorService,
    private icdCodeService: IcdCodeService,
    private router: Router,
    private http: HttpClient,
    private diagnosisCodeService: DiagnosisCodeService
  ) {
    // this.filteredOptions = this.Frm.controls.DiagnosisCodes.valueChanges.pipe(
    //   startWith(''),
    //   map((value: string) => this._filter(value || ''))
    // );
  }

  ngOnInit(): void {
    this.getCptCode();
    // this.ICDcodes();

    let decodedToken = this.helper.decodeToken(this.myToken);
    this.DoctorId = parseInt(decodedToken.nameid);
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
    this.physicalTherpyVisitService
      .getPhysicalTherapyVisitById(this.id)
      .subscribe((res) => {
        this.Frm.patchValue(res);
        res.DiagnosisCodes.forEach((items, index) => {
          this.addItem();
          this.DiagnosisCodes.controls[index].patchValue(items);
        });
      });
    this.Frm = this.fb.group({
      DoctorId: this.DoctorId,
      DoctorName: [''],
      Episode: ['', Validators.required],
      Referrer: [''],
      DiagnosisCodes: this.fb.array([]),
      NextFollowUp: [''],
      Subjective: ['', Validators.required],
      Objective: ['', Validators.required],
      Assessment: ['', Validators.required],
      Plan: ['', Validators.required],
      JointMobilization: [''],
      SoftTissueMobilization: [''],
      Stretching: [''],
      SingleLegBalance: [''],
      StepUps: [''],
      QuadStr: [''],
      BirdDogs: [''],
      Rotations: [''],
      PelvicTilts: [''],
      AbdominalISOs: [''],
      LionLatStretch: [''],
      ChildsPose: [''],
      CatandCamelStretch: [''],
      DownwardDog: [''],
      Signature: [''],
      TimeIn: [''],
      TimeOut: [''],
      Status: [true],
    });
  }

  public addItem(): void {
    const item = this.fb.group({
      DiagnosisCodeId: [],
      Code: [''],
      // Description: [''],
    });
    this.DiagnosisCodes.push(item);
  }
  public removeItem(i, CodeId): void {
    this.DiagnosisCodes.removeAt(i);
    this.diagnosisCodeService.deleteDiagnosisCode(CodeId).subscribe();
  }

  public updateCode(j, body) {
    let updateBody = {
      PhysicalTherapyVisitId: this.id,
      Code: body,
    };
    this.diagnosisCodeService.updateDiagnosisCode(j, updateBody).subscribe();
  }
  get TimeOut() {
    return this.Frm.get('TimeOut');
  }

  get TimeIn() {
    return this.Frm.get('TimeIn');
  }

  get Signature() {
    return this.Frm.get('Signature');
  }
  get DoctorName() {
    return this.Frm.get('DoctorName');
  }

  get DownwardDog() {
    return this.Frm.get('DownwardDog');
  }

  get CatandCamelStretch() {
    return this.Frm.get('CatandCamelStretch');
  }

  get ChildsPose() {
    return this.Frm.get('ChildsPose');
  }

  get LionLatStretch() {
    return this.Frm.get('LionLatStretch');
  }
  get AbdominalISOs() {
    return this.Frm.get('AbdominalISOs');
  }
  get PelvicTilts() {
    return this.Frm.get('PelvicTilts');
  }

  get BirdDogs() {
    return this.Frm.get('BirdDogs');
  }

  get QuadStr() {
    return this.Frm.get('QuadStr');
  }
  get StepUps() {
    return this.Frm.get('StepUps');
  }

  get SingleLegBalance() {
    return this.Frm.get('SingleLegBalance');
  }

  get Stretching() {
    return this.Frm.get('Stretching');
  }

  get SoftTissueMobilization() {
    return this.Frm.get('SoftTissueMobilization');
  }

  get JointMobilization() {
    return this.Frm.get('JointMobilization');
  }

  get Plan() {
    return this.Frm.get('Plan');
  }

  get Assessment() {
    return this.Frm.get('Assessment');
  }

  get Objective() {
    return this.Frm.get('Objective');
  }

  get Subjective() {
    return this.Frm.get('Subjective');
  }

  get NextFollowUp() {
    return this.Frm.get('NextFollowUp');
  }

  get DiagnosisCodes(): UntypedFormArray {
    return this.Frm.get('DiagnosisCodes') as UntypedFormArray;
  }

  get Episode() {
    return this.Frm.get('Episode');
  }
  get Referer() {
    return this.Frm.get('Referrer');
  }
  getCptCode() {
    this.icdCodeService.getIcdCodes().subscribe((res) => {
      this.icdCodeList = res;
    });
  }
  getPTdoctors() {
    this.physicaltherpyDoctorService
      .getPhysicalTherapyDoctors()
      .subscribe((res) => {
        this.doctorList = res;
      });
  }

  formSubmit() {
    this.physicalTherpyVisitService
      .updatePhysicalTherapyVisit(this.id, this.Frm.value)
      .subscribe((res) => {
        this.isSuccess = true;
        this.router.navigate(['physicalTherapyDash']);
      });
  }

  public ICDcodes() {
    // this.http.get('/assets/json/ICD_Code.json').subscribe({
    this.http.get('https://binsera.co.uk/ICDcode/ICD_Code.json').subscribe({
      next: (res: any) => {
        this.Cptcodes = res;
      },
    });
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.Cptcodes.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }
}
