<div class="conatiner mx-5 my-5" id="printable">
    <button type="button" (click)="print()" class="btn btn-primary float-right" data-toggle="modal"
        data-target=".bd-example-modal-lg"> Print </button>
    <div class="row">
        <div class="col-md-10" *ngFor="let item of consentForServiceList">

            <mat-card class="mat-elevation-z8 mt-3 ">
                <h2 class="clr">Informed Consent for Physical Therapy Services</h2>

                <p class="float-right">Date : {{item.createdDate | date: 'short' }}</p>

                <hr>

                <h2>
                    {{hospital.HospitalName}}

                </h2>
                <mat-card-content>
                    <p>The purpose of physical therapy is to treat disease, injury and disability by examination,
                        evaluation, diagnosis, prognosis, and intervention by use of rehabilitative procedures,
                        mobilization, massage, exercises, and physical agents to aid the patient in achieving their
                        maximum potential within their capabilities and to accelerate convalescence and reduce the
                        length of functional recovery. All procedures will be thoroughly explained to you before you are
                        asked to perform them. </p>

                    <p>
                        Response to physical therapy intervention varies from person to person; hence, it is not
                        possible
                        to accurately predict your response to a specific modality, procedure, or exercise protocol.
                        We cannot guarantee what your reaction will be to a specific treatment, nor does it
                        guarantee that the treatment will help resolve the condition that you are seeking treatment for.
                        Furthermore, there is a possibility that the physical therapy treatment may result in
                        aggravation of
                        existing symptoms and may cause pain or injury. It is very important to communicate with you
                        treating physical therapist throughout your treatment.
                    </p>
                    <p>It is your right to decline any part of your treatment at any time before or during treatment,
                        should
                        you feel any discomfort or pain or have other unresolved concerns. It is your right to ask your
                        physical therapist about the treatment they have planned based on your individual history,
                        physical therapy diagnosis, symptoms, and examination results. Consequently, it is your right to
                        discuss the potential risks and benefits involved in your treatment. </p>
                    <p>I have read this consent form and understand the risks involved in physical therapy and agree to
                        fully cooperate, participate in all physical therapy procedures, and comply with the established
                        plan of care. I authorize the release of my medical information to appropriate third parties.
                    </p>


                    <tr>
                        <th> Do you have a lawyer representing you for this medical condition?? </th>

                        <td>{{item?.IslawyerrepresentingyouForThisMedicalCondition ?'Yes':'No'}}</td>
                    </tr>


                    <p>I understand that I remain personally responsible for the amounts due this office for their
                        services.
                        I further understand and agree that this assignment and authorization does not constitute any
                        consideration for the office to await payments and they may demand payments from me
                        immediately upon rendering services at their option.
                        I authorize this office to release any information pertinent to my case to any insurance
                        company,
                        billing service, adjuster, or attorney to facilitate collection under this assignment and
                        authorization.</p>
                    <h1>MEDICARE PATIENTS: </h1>
                    <p>
                        We bill our standard fees to Medicare, they pay 80% of their
                        allowable fees, and the difference is billed to your secondary insurance. If you do not have a
                        secondary insurance or your secondary insurance denies payment, you will receive a statement
                        following the receipt of Medicare’s allotment. Unless other arrangements are made, you will be
                        responsible for the remaining 20% of the Medicare allowable rate.

                    </p>
                    <strong>By signing below, I acknowledge that I have read, understand, and agree to the terms and
                        conditions of this form and that I am authorized as the patient or the Patient’s Legal
                        Representative to sign this document.</strong>

                    <ul>
                        <li> Office Pick Up CD/Films can be picked up for a {{hospital.HospitalName}} office for no
                            charge.</li>
                        <li> U.S. Shipment CDS/Films returned to the patient within the United States will be charged a
                            $50
                            handling fee plus actual postage determined by carrier (USPS/FedEx, DHL).</li>
                        <li> International Shipment CDS/Films returned to the patient outside of the United States will
                            be
                            charged a $200 handling fee plus actual postage determined by carrier (USPS/FedEx, DHL).
                        </li>
                    </ul>



                    <tr>
                        <th>This consent was signed by: </th>
                        <td>{{item?.AuthorisedSignatoryName}}</td>

                    </tr>

                    <tr>
                        <th> Signature: </th>
                        <td><img src="{{item?.Signature}}" alt=""></td>

                    </tr>

                </mat-card-content>
                <button (click)="delete(item.OfficePolicyId)" mat-mini-fab
                    color="accent"><mat-icon>delete</mat-icon></button>
                <!-- <button class="float-right" [routerLink]="['/editFamilyHistory/',item.FamilyHistoryId]" color="primary" mat-mini-fab><mat-icon>edit</mat-icon> </button>
    -->

            </mat-card>
        </div>
    </div>
</div>