<div>

  <button mat-raised-button color="primary" [routerLink]="['/hospitalDash/']"><mat-icon mat-list-icon>undo</mat-icon>
    Dashboard</button>
</div>
<div class="container">
  <div class="row">
    <div class="col-lg regFrm mat-elevation-z8">
      <h2>Update Patient</h2>
      <form [formGroup]="ptFrm" (ngSubmit)="formSubmit()" class="example-form">

        <div class="row">

          <div class="col-lg-4">

            <mat-form-field class="example-full-width">
              <mat-label>First Name</mat-label>
              <input type="text" formControlName="PatientFirstName" matInput>
              <mat-error>Please enter First Name</mat-error>
            </mat-form-field>

          </div>
          <div class="col-lg-4">

            <mat-form-field class="example-full-width">
              <mat-label>Last Name</mat-label>
              <input type="text" formControlName="PatientLastName" matInput>
              <mat-error>Please enter Last Name</mat-error>
            </mat-form-field>

          </div>
          <div class="col-lg-4">

            <mat-form-field class="example-full-width">
              <mat-label>Birth Date</mat-label>
              <input type="string" formControlName="PatientDOB" matInput>
              <mat-error>Please enter D.O.B</mat-error>
            </mat-form-field>

          </div>
        </div>

        <div class="row">
          <div class="col-lg-4">

            <mat-form-field class="example-full-width">
              <mat-label>Gender</mat-label>
              <!-- <input type="text" formControlName="PatientGender" matInput >
            <mat-error >Please enter Gender</mat-error> -->
              <mat-select formControlName="PatientGender">

                <mat-option *ngFor="let sex of Gender" [value]="sex">{{sex}}</mat-option>

              </mat-select>
            </mat-form-field>

          </div>
          <div class="col-lg-4">

            <mat-form-field class="example-full-width">
              <mat-label>Email</mat-label>
              <input type="email" formControlName="Email" matInput>
              <mat-error>Please enter Last Name</mat-error>
            </mat-form-field>

          </div>
          <!-- <div class="col-1">
            <mat-form-field class="example-full-width">
              <mat-label>Country Code</mat-label>
              <input type="text" formControlName="CountryCode" [matAutocomplete]="auto" matInput placeholder="+1, +91">
              <mat-error>Please enter a Phone Number with country code eg: +1,+91,+44</mat-error>
              <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                  {{ option }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div> -->
          <div class="col-lg-3">

            <mat-form-field class="example-full-width">
              <mat-label>Phone Number</mat-label>
              <input type="text" formControlName="PhoneNumber" matInput>
              <mat-error>Please enter Cell Phone Number</mat-error>
            </mat-form-field>

          </div>
        </div>


        <div class="row">
          <div class="col-lg-4">
            <mat-form-field class="example-full-width">
              <mat-label>Doctor Name</mat-label>

              <mat-select formControlName="PhysicalTherapyDoctorId">

                <mat-option *ngFor="let doctor of doctorList"
                  [value]="doctor.PhysicalTherapyDoctorId">{{doctor.DoctorName}}</mat-option>

              </mat-select>
            </mat-form-field>



          </div>

          <div class="col-lg-4">

            <mat-form-field class="example-full-width">
              <mat-label> National Id</mat-label>
              <input type="text" formControlName="PatientNationalId" matInput>
              <mat-error>Please enter Patient NationalId</mat-error>
            </mat-form-field>

          </div>
        </div>
        <div class="row">
          <div class="col-lg-4">

            <mat-form-field class="example-full-width">
              <mat-label>Blood Group</mat-label>
              <!-- <input type="text" formControlName="BloodGroup" matInput > -->
              <!-- <mat-error >Please enter Blood Group</mat-error> -->

              <mat-select formControlName="BloodGroup">

                <mat-option *ngFor="let bl of bloodGroup" [value]="bl">{{bl}}</mat-option>

              </mat-select>
            </mat-form-field>

          </div>
          <div class="col-lg-4">

            <mat-form-field class="example-full-width">
              <mat-label>Marital Status</mat-label>
              <!-- <input type="text" formControlName="MaritalStatus" matInput > -->
              <!-- <mat-error >Please enter Patient Marital Status</mat-error> -->
              <mat-select formControlName="MaritalStatus">

                <mat-option *ngFor="let ms of maritalStatus" [value]="ms">{{ms}}</mat-option>

              </mat-select>
            </mat-form-field>

          </div>
          <div class="col-lg-4">

            <mat-form-field class="example-full-width">
              <mat-label>Occupation</mat-label>
              <input type="text" formControlName="Occupation" matInput>
              <!-- <mat-error >Please enter Occupation</mat-error> -->
            </mat-form-field>

          </div>
        </div>


        <div class="row">
          <div class="col-lg-4">


            <mat-form-field class="example-full-width">
              <mat-label>Referred Doctor </mat-label>
              <input type="text" formControlName="ReferredDoctor" matInput>
              <mat-error>Please enter Referred Doctor</mat-error>
            </mat-form-field>
          </div>
          <div class="col-lg-8">
            <mat-form-field appearance="fill" class="example-full-width">
              <mat-label>Patient Address </mat-label>

              <textarea matInput formControlName="PatientAddress" cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="1" cdkAutosizeMaxRows="8"></textarea>

            </mat-form-field>


          </div>
          <div class="col-lg-2">
            <mat-form-field class=" w-100">
              <mat-label>Status</mat-label>
              <mat-select formControlName="Status">
                <mat-option *ngFor="let status of StatusArr" [value]="status.value">
                  {{status.title}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-lg-2">
            <mat-form-field class=" w-100">
              <mat-label>Is New Patient</mat-label>
              <mat-select formControlName="IsNewPatient">
                <mat-option *ngFor="let np of IsNewPatientArr" [value]="np.value">
                  {{np.head}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <hr>
        <div class="row mt-3">
          <div class="col-lg-6">
            <input type="file" (change)="image($event)">


          </div>
        </div>

        <div class="row mt-3">
          <div class="col-md-12">
            <h4>Capture Image from Camera</h4>

            <webcam [trigger]="invokeObservable" (imageCapture)="captureImg($event)" [height]="180" [width]="180"
              [videoOptions]="videoOptions" [allowCameraSwitch]="allowCameraSwitch">
            </webcam>
          </div>
          <div class="col-md-12">
            <button class="btn btn-danger" (click)="getSnapshot($event)" type="button">
              Capture Image
            </button>
          </div>
          <div class="col-12">
            <div id="results">Your taken image manifests here...</div>
            <img [src]="webcamImage?.imageAsDataUrl" height="180px" />
          </div>
        </div>
        <button class="float-right lgBtCl" mat-button>
          <mat-icon>save</mat-icon>
        </button>

      </form>
      <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
        Successfully Updated Record
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
  </div>
</div>


<!-- 
        <div class="row">
          <div class="col-lg-4">
  
              <mat-form-field class="example-full-width">
              <mat-label>Emergency Contact Name</mat-label>
              <input type="text" formControlName="EmergencyContactName" matInput >
              <mat-error >Please enter Emergency Contact Name</mat-error>
            </mat-form-field>
          
          </div>
          <div class="col-lg-4">
  
              <mat-form-field class="example-full-width">
              <mat-label>Emergency Contact Phone</mat-label>
              <input type="number" formControlName="EmergencyContactPhone" matInput >
              <mat-error >Please enter Emergency Contact Phone</mat-error>
            </mat-form-field>
          
          </div>
          <div class="col-lg-4">
  
              <mat-form-field class="example-full-width">
              <mat-label>Emergency Contact Relation</mat-label>
              <input type="text" formControlName="EmergencyContactRelation" matInput >
              <mat-error >Please enter Emergency Contact Relation</mat-error>
            </mat-form-field>
          
          </div>
      </div> -->

<!-- 
      <div class="row">
        <div class="col-lg-4">

            <mat-form-field class="example-full-width">
            <mat-label>InsuaranceName</mat-label>
            <input type="text" formControlName="InsuaranceName" matInput >
            <mat-error >Please enter Insuarance Name</mat-error>
          </mat-form-field>
        
        </div>
        <div class="col-lg-4">

            <mat-form-field class="example-full-width">
            <mat-label>Insuarance No</mat-label>
            <input type="text" formControlName="InsuaranceNo" matInput >
            <mat-error >Please enter Patient Insuarance No</mat-error>
          </mat-form-field>
        
        </div>
        <div class="col-lg-4">

            <mat-form-field class="example-full-width">
            <mat-label>Insuarance Expiry Date</mat-label>
            <input type="date" formControlName="InsuaranceExpiryDate" matInput >
            <mat-error >Please enter Insuarance Expiry Date</mat-error>
          </mat-form-field>
        
        </div>
    </div> -->