<div class="calculator bg-white">

  <input type="text" class="calculator-screen" [value]="currentNumber" disabled />

  <div class="calculator-keys">

    <button type="button" (click)="getOperation('+')" class="operator" value="+">+</button>
    <button type="button" (click)="getOperation('-')" class="operator" value="-">-</button>
    <button type="button" (click)="getOperation('*')" class="operator" value="*">&times;</button>
    <button type="button" (click)="getOperation('/')" class="operator" value="/">&divide;</button>

    <button type="button" (click)="getNumber('7')" value="7">7</button>
    <button type="button" (click)="getNumber('8')" value="8">8</button>
    <button type="button" (click)="getNumber('9')" value="9">9</button>


    <button type="button" (click)="getNumber('4')" value="4">4</button>
    <button type="button" (click)="getNumber('5')" value="5">5</button>
    <button type="button" (click)="getNumber('6')" value="6">6</button>


    <button type="button" (click)="getNumber('1')" value="1">1</button>
    <button type="button" (click)="getNumber('2')" value="2">2</button>
    <button type="button" (click)="getNumber('3')" value="3">3</button>


    <button type="button" (click)="getNumber('0')" value="0">0</button>
    <button type="button" (click)="getDecimal()" class="decimal" value=".">.</button>
    <button type="button" (click)="getOperation('%')" class="operator" value="%">%</button>

    <button type="button" (click)="clear()" class="all-clear" value="all-clear">AC</button>

    <button type="button" (click)="getOperation('=')" class="equal-sign" value="=">=</button>

    <!-- <mat-dialog-actions align="end">
      <button (click)="onPress()" class="btn btn-danger bg-danger text-white" color="white" mat-mat-raised-button
        mat-dialog-close>close</button>

    </mat-dialog-actions> -->
  </div>

</div>