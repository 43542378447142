<div class="container mt-5">
    <h2>Import Patients</h2>

    <div class="col-lg regFrm mat-elevation-z8">
        <div class="row">
            <div class="col-lg-10">
                <div class="d-flex justify-content-end m-2">
                    <form (ngSubmit)="uploadFile()">
                        <input type="file" class="formControl" (change)=" onFileSelected($event)"
                            accept="application/json">
                        <button type="submit btn btn-primary" mat-raised-button color="primary">Upload Patients</button>
                    </form>

                </div>
            </div>
        </div>
    </div>
</div>
<hr>

<div class="container mt-5">
    <h2>Import Physcal Therapists</h2>

    <div class="col-lg regFrm mat-elevation-z8">
        <div class="row">

            <div class="col-lg-10">
                <div class="d-flex justify-content-end m-2">
                    <form (ngSubmit)="uploadFilePhyscalTherapist()">
                        <input type="file" class="formControl" (change)=" selectedPhysicalTherapistFile($event)"
                            accept="application/json">
                        <button type="submit btn btn-primary" mat-raised-button color="primary">Upload Physical Therapy
                            Doctors</button>
                    </form>

                </div>
            </div>
        </div>
    </div>
</div>

<div class="container mt-5">
    <h2>Import Patient Visits</h2>

    <div class="col-lg regFrm mat-elevation-z8">
        <div class="row">

            <div class="col-lg-10">
                <div class="d-flex justify-content-end m-2">
                    <form (ngSubmit)="uploadPatientVisit()">
                        <input type="file" class="formControl" (change)=" selectedPatientVisitFile($event)"
                            accept="application/json">
                        <button type="submit btn btn-primary" mat-raised-button color="primary">Upload
                            Patient Visits</button>
                    </form>

                </div>
            </div>
        </div>
    </div>
</div>
<hr>