<div class="conatiner mx-5 my-5" id="printable">
    <button type="button" (click)="print()" class="btn btn-primary float-right" data-toggle="modal"
        data-target=".bd-example-modal-lg"> Print </button>
    <div class="row">
        <div class="col-md-10" *ngFor="let item of exersiceLiabilityWaiverList">

            <mat-card class="mat-elevation-z8 mt-3 ">
                <h1>INTRAMUSCULAR STIMULATION (IMS) OR
                    TRIGGER POINT DRY NEEDLING (TDN) RELEASE
                    FORM</h1>

                <p class="float-right">Date : {{item.createdDate | date: 'short' }}</p>

                <hr>
                <mat-card-content>
                    <h2>
                        {{hospital.HospitalName}}

                    </h2>
                    <strong>Waiver, Release, and Assumption of Risk Form</strong>
                    <p>This form is an important legal document. It explains the risks you are
                        assuming by participation in an exercise program developed as part of my
                        physical therapy program. It is important that you read and understand it
                        completely. After you have done so, please print your name legibly and sign
                        in the spaces provided at the bottom.

                    </p>
                    <strong>Waiver, Informed Consent, and Covenant Not to Sue</strong>
                    <p>
                        I,{{PatientName}}, have volunteered to participate in a
                        physical therapy program under the direction of {{hospital.HospitalName}}, which will include,
                        but
                        may not be limited to, weight
                        and/or resistance training, body weight exercises, balance and stretching
                        activities. In consideration of the agreement with {{hospital.HospitalName}} to instruct,
                        assist,
                        and
                        train me, I do here and forever release and discharge and hereby hold
                        harmless {{hospital.HospitalName}}, and their respective agents, heirs, assigns, contractors,
                        and
                        employees from any and all claims, demands, damages, rights of action or
                        causes of action, present or future, arising out of or connected with my
                        participation in this or any exercise program including any injuries resulting
                        there from. This waiver and release of liability includes, without limitation,
                        injuries which may occur as a result of 1͜ equipment that may malfunction or
                        break, 2͜ any slip, fall, dropping of equipment and 3͜ negligent instruction or
                        supervision.
                    </p>
                    <strong>Assumption of Risk</strong>
                    <p> recognize that exercise might be difficult and strenuous and that there
                        could be dangers inherent in exercise for some individuals. I acknowledge
                        that the possibility of certain unusual physical changes during exercise does
                        exist. These changes include abnormal blood pressure, fainting, disorders in
                        heartbeat, heart attack, and, in rare instances, death.</p>
                    <p>
                        understand that as a result of my participation, I could suffer an injury or
                        physical disorder that could result in my becoming partially or totally disabled
                        and incapable of performing any gainful employment or having a normal
                        social life.

                    </p>
                    <p>
                        I recognize that it is my responsibility to communicate with my therapist any
                        reasons why I should not participate in an exercise program. I acknowledge
                        and agree that I assume the risks associated with any and all activities and/or
                        exercises in which I participate.
                    </p>
                    <h2>I ACKNOWLEDGE THAT I HAVE THOROUGHLY READ THIS WAIVER AND
                        RELEASE AND FULLY UNDERSTAND THAT IT IS A RELEASE OF LIABILITY.
                        BY SIGNING THIS DOCUMENT, I AM WAIVING ANY RIGHT I OR MY
                        SUCCESSORS MIGHT HAVE TO BRING A LEGAL ACTION OR ASSERT A
                        CLAIM AGAINST the NSCA, or OTHERS REFERRED TO IN THIS DOCUMENT
                        FOR ANY NEGLIGENCE OR THAT OF OUR EMPLOYEES, AGENTS, OR
                        CONTRACTORS.</h2>


                    <strong>By signing below, I acknowledge that I have read, understand, and agree to the terms and
                        conditions of this form and that I am authorized as the patient or the Patient’s Legal
                        Representative to sign this document.</strong>




                    <tr>
                        <th>This consent was signed by: </th>
                        <td>{{item?.AuthorisedSignatoryName}}</td>

                    </tr>

                    <tr>
                        <th> Signature: </th>
                        <td><img src="{{item?.Signature}}" alt=""></td>

                    </tr>

                </mat-card-content>

            </mat-card>
        </div>
    </div>
</div>