import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EmergencyContact } from 'src/app/Models/emergencycontact.models';
import { EmergencycontactService } from 'src/app/services/emergencycontact.service';
import { PatientService } from 'src/app/services/patient.service';

@Component({
  selector: 'app-emergency-contact',
  templateUrl: './emergency-contact.component.html',
  styleUrls: ['./emergency-contact.component.css'],
})
export class EmergencyContactComponent implements OnInit {
  id = 0;
  EmergencyContactList: EmergencyContact[] = [];
  constructor(
    private route: ActivatedRoute,
    private patientService: PatientService,
    private emContactService: EmergencycontactService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
    this.getEmContacts(this.id);
  }

  getEmContacts(id) {
    this.patientService.getPatientEMcontact(id).subscribe((res) => {
      this.EmergencyContactList = res.EmergencyContacts;
    });
  }

  delete(id) {
    this.emContactService.deleteEmergencyContact(id).subscribe((res) => {
      this.getEmContacts(this.id);
    });
  }
}
