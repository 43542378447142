import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { SurgicalHistory } from 'src/app/Models/surgicalhistory.model';
import { SurgicalhistoryService } from 'src/app/services/surgicalhistory.service';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { PatientService } from 'src/app/services/patient.service';

@Component({
  selector: 'app-view-surgical-history',
  templateUrl: './view-surgical-history.component.html',
  styleUrls: ['./view-surgical-history.component.css'],
})
export class ViewSurgicalHistoryComponent implements OnInit {
  id = 0;
  surgicalHistoryList: SurgicalHistory[] = [];

  displayedColumns: string[] = ['DateOfSurgery', 'SurgeyText', 'Delete'];

  dataSource: MatTableDataSource<any>;

  constructor(
    private patientService: PatientService,
    private route: ActivatedRoute,
    private router: Router,
    private surgicalHistoryService: SurgicalhistoryService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });

    this.getSurgery(this.id);
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  // ngAfterViewInit() {
  //   this.dataSource.paginator = this.paginator;
  //   this.dataSource.sort = this.sort;
  // }

  getSurgery(id) {
    this.patientService.getPatientsurgery(id).subscribe((res) => {
      this.dataSource = new MatTableDataSource(res.SurgicalHistories);
      this.dataSource.paginator = this.paginator;
      //  this.surgicalHistoryList= res.SurgicalHistories
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  tabChanged(event: MatTabChangeEvent): void {
    if (event.index === 0) {
      this.getSurgery(this.id);
    }
  }

  delete(id) {
    this.surgicalHistoryService.deleteSurgicalHistory(id).subscribe((res) => {
      this.getSurgery(this.id);
    });
  }
}
