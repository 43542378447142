import { Component, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder, UntypedFormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Department } from 'src/app/Models/department.model';
import { Doctor } from 'src/app/Models/doctor.model';
import { Medicine } from 'src/app/Models/medicine.model';
import { AttendedDoctorService } from 'src/app/services/attended-doctor.service';
import { DepartmentService } from 'src/app/services/department.service';
import { DischargeMedicationService } from 'src/app/services/discharge-medication.service';
import { DischargeSummaryService } from 'src/app/services/discharge-summary.service';
import { DoctorService } from 'src/app/services/doctor.service';
import { MedicineService } from 'src/app/services/medicine.service';

@Component({
  selector: 'app-update-discharge-summary',
  templateUrl: './update-discharge-summary.component.html',
  styleUrls: ['./update-discharge-summary.component.css']
})
export class UpdateDischargeSummaryComponent implements OnInit {
  id = 0;
  medicineList: Medicine[];

  isSuccess = false;

  Frm: FormGroup;
  doctorList: Doctor[];
  dpartmentList: Department[];
  doctorSpeciality: string[] = [
    'Cardiology',
    'Nerology',
    'General Medicine',
    'Orthopedic',
    'General Surgeon',
    'Spinal Surgeon',
  ];


  constructor(private fb: UntypedFormBuilder, private route: ActivatedRoute,private medicineService:MedicineService,private router:Router,
    private departmentService:DepartmentService,private doctorService:DoctorService,private dischargeService:DischargeSummaryService,
    private dischargeMedicationService:DischargeMedicationService,private attendedDoctorService:AttendedDoctorService) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });

  

    this.Frm = this.fb.group({
      // PatientId: this.id,
      Complaints: [''],
      Diagnosis: [''],
      Treatments: [''],
      FutureTreatmentPlan: [''],
      Description: [''],
      Signature: [''],

      DischargeMedications: this.fb.array([]),
      AttendedDoctors: this.fb.array([]),
    });
    this.addItem();

    this.getMedicine();
    this.getDoctors();
    this.addAttendedDoctors();
    this.getDepartments();
    this.getData();
  }


  private getData(): void {

    this.dischargeService.getDischargeSummaryById(this.id).subscribe({
  
      next: (res) => {
        console.log(res)
        this.Frm.patchValue(res);
        res.DischargeMedications.forEach((drug, id) => {
          this.addItem();
          this.DischargeMedications.at(id).patchValue(drug)
        })
        res.AttendedDoctors.forEach((doctor, id) => {
          this.addAttendedDoctors();
          this.AttendedDoctors.at(id).patchValue(doctor)
        })
      }
    });

  }




  public addItem(): void {
    const item = this.fb.group({
      DischargeMedicationId: [''],
      DrugName: [''],
      Dosage: [''],
      Frequency: [''],
      Duration: [''],
      Quantity: [''],
    });
    this.DischargeMedications.push(item);
  }


  public removeItem(i,id): void {
    // console.log(i,id)
    this.DischargeMedications.removeAt(i);
    this.dischargeMedicationService.deleteDischargeMedication(id).subscribe();
  }


  public addAttendedDoctors(): void {
    const doctor = this.fb.group({
      AttendedDoctorsId: [''],
      DoctorName: [''],
      DoctorSpeciality: [''],
      DepartmentName: [''],
    });
    this.AttendedDoctors.push(doctor);
  }

 

  public removeAttendedDoctors(i,doctorId): void {
    // console.log(i,doctorId)
    this.AttendedDoctors.removeAt(i);
    this.attendedDoctorService.deleteAttendedDoctor(doctorId).subscribe();
  }


  get Complaints() {
    return this.Frm.get('Complaints');
  }
  get Diagnosis() {
    return this.Frm.get('Diagnosis');
  }
  get Treatments() {
    return this.Frm.get('Treatments');
  }
  get FutureTreatmentPlan() {
    return this.Frm.get('FutureTreatmentPlan');
  }
  get Description() {
    return this.Frm.get('Description');
  }



  get AttendedDoctors(): UntypedFormArray {
    return this.Frm.get('AttendedDoctors') as UntypedFormArray;
  }

  get AttendedDoctorsId() {
    return this.Frm.get('AttendedDoctorsId');
  }
  get DoctorSpeciality() {
    return this.Frm.get('DoctorSpeciality');
  }
  get DoctorName() {
    return this.Frm.get('DoctorName');
  }
  get DepartmentName() {
    return this.Frm.get('DepartmentName');
  }




  get DischargeMedications(): UntypedFormArray {
    return this.Frm.get('DischargeMedications') as UntypedFormArray;
  }

  get DischargeMedicationId() {
    return this.Frm.get('DischargeMedicationId');
  }
  get Quantity() {
    return this.Frm.get('Quantity');
  }

  get NurseName() {
    return this.Frm.get('NurseName');
  }
  get DrugName() {
    return this.Frm.get('DrugName');
  }
  get Frequency() {
    return this.Frm.get('Frequency');
  }
  get Dosage() {
    return this.Frm.get('Dosage');
  }
  get Duration() {
    return this.Frm.get('Duration');
  }

  get Signature() {
    return this.Frm.get('Signature');
  }



  getMedicine(){
    this.medicineService.getMedicines().subscribe(res=>{
      this.medicineList = res;
    })
  }

  getDoctors(){
    this.doctorService.getDoctors().subscribe(res=>{
      this.doctorList = res
    })
  }
  getDepartments(){
    this.departmentService.getDepartments().subscribe(res=>{
      this.dpartmentList = res
    })
  }

  formSubmit() {


    // this.Frm.value.DischargeMedication = this.Frm.value.DischargeMedication.filter(drug => drug.DrugId === '').map(drug => {
    //   delete drug.DrugId;
    //   return drug;
    // });
    // this.Frm.value.AttendedDoctor = this.Frm.value.AttendedDoctor.filter(doctor => doctor.doctorId === '').map(doctor => {
    //   delete doctor.doctorId;
    //   return doctor;
    // });


this.dischargeService.updateDischargeSummary(this.id,this.Frm.value).subscribe(res=>{
  // this.Frm.reset();
  // this.isSuccess=true;
  this.router.navigate(['Patient'])
})
  }
}

