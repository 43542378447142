<div class="pb-5">

  <!-- <div class="content">
        <div class="sidebar mat-elevation"> -->
  <!-- <div class="header">
            <img src="../../../assets/admin.png" class="image" alt="">

            <h4 class="text-white ml-2">Patient Details</h4>
           </div> -->
  <div class="toolbar mat-elevation-z6">
    <div class="dls-menu-item" style="float: right;">
      <button mat-raised-button color="accent" (click)="onPress()">Calculator</button>
    </div>

    <div class="comp-render" *ngIf="display">
      <app-calculator></app-calculator>
    </div>
    <button mat-raised-button class="backG" [matMenuTriggerFor]="crt"><mat-icon>menu</mat-icon>Menu</button>

    <mat-menu class="ItemS" #crt="matMenu">

      <div class="ItemS ">
        <mat-icon mat-list-icon>dashboard</mat-icon>
        <div class="mx-2" [routerLink]="['/hospitalDash/']">Hospital Dashboard</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS " (click)="showItem='27'" [ngClass]="{'active': showItem =='27'}">
        <mat-icon mat-list-icon>dashboard</mat-icon>
        <div class="mx-2">back</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <hr>
      <div class="ItemS" (click)="showItem='57'" [ngClass]="{'active': showItem =='57'}">
        <mat-icon mat-list-icon>visibility</mat-icon>
        <div class="mx-2"> View Appointment</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS" (click)="showItem='75'" [ngClass]="{'active': showItem =='75'}">
        <mat-icon mat-list-icon>schedule</mat-icon>
        <div class="mx-2"> Completed Forms & History</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <hr>

      <div class="ItemS " (click)="showItem='1'" [ngClass]="{'active': showItem =='1'}">
        <mat-icon mat-list-icon>nature_people</mat-icon>
        <div class="mx-2">Patient Summary</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <hr>

      <div class="ItemS" (click)="showItem='54'" [ngClass]="{'active': showItem =='54'}">
        <mat-icon mat-list-icon> summarize </mat-icon>
        <div class="mx-2">View PT Visit</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <hr>
      <div class="ItemS" (click)="showItem='55'" [ngClass]="{'active': showItem =='55'}">
        <mat-icon mat-list-icon> summarize </mat-icon>
        <div class="mx-2">View PT Initial Evaluation</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <hr>
      <div class="ItemS" (click)="showItem='56'" [ngClass]="{'active': showItem =='56'}">
        <mat-icon mat-list-icon>add</mat-icon>
        <div class="mx-2"> New Appointment</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <!-- <div class="ItemS" (click)="showItem='58'" [ngClass]="{'active': showItem =='58'}">
        <mat-icon mat-list-icon>add</mat-icon>
        <div class="mx-2"> New Physical Therapy Appointment</div>
        <mat-icon>navigate_next</mat-icon>
      </div> -->

      <div class="ItemS" (click)="showItem='63'" [ngClass]="{'active': showItem =='63'}">
        <mat-icon mat-list-icon>add</mat-icon>
        <div class="mx-2"> New History</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS" (click)="showItem='51'" [ngClass]="{'active': showItem =='51'}">
        <mat-icon mat-list-icon>add</mat-icon>
        <div class="mx-2"> New Patient Forms</div>
        <mat-icon>navigate_next</mat-icon>
      </div>



      <hr>


      <div class="ItemS" (click)="showItem='37'" [ngClass]="{'active': showItem =='37'}">
        <mat-icon mat-list-icon>add</mat-icon>
        <div class="mx-2">New ID Proof</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS" (click)="showItem='32'" [ngClass]="{'active': showItem =='32'}">
        <mat-icon mat-list-icon>add</mat-icon>
        <div class="mx-2">New Inusurance</div>
        <mat-icon>navigate_next</mat-icon>
      </div>

      <hr>
      <div class="ItemS" (click)="showItem='16'" [ngClass]="{'active': showItem =='16'}">
        <mat-icon mat-list-icon>add</mat-icon>
        <div class="mx-2">New Invoice</div>
        <mat-icon>navigate_next</mat-icon>
      </div>


      <div class="ItemS" (click)="showItem='17'" [ngClass]="{'active': showItem =='17'}">
        <mat-icon mat-list-icon>receipt</mat-icon>
        <div class="mx-2">View Invoice</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <hr>
      <div class="ItemS" (click)="showItem='81'" [ngClass]="{'active': showItem =='81'}">
        <mat-icon mat-list-icon>payments</mat-icon>
        <div class="mx-2">View Payment History</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <hr>

      <div class="ItemS" (click)="showItem='38'" [ngClass]="{'active': showItem =='38'}">
        <mat-icon mat-list-icon>visibility</mat-icon>
        <div class="mx-2">View ID Proof </div>
        <mat-icon>navigate_next</mat-icon>
      </div>

      <div class="ItemS" (click)="showItem='36'" [ngClass]="{'active': showItem =='36'}">
        <mat-icon mat-list-icon>visibility</mat-icon>
        <div class="mx-2">View Inusurance</div>
        <mat-icon>navigate_next</mat-icon>
      </div>

      <hr>
      <div class="ItemS" (click)="showItem='13'" [ngClass]="{'active': showItem =='13'}">
        <mat-icon mat-list-icon>warning</mat-icon>
        <div class="mx-2">Allergies</div>
        <mat-icon>navigate_next</mat-icon>
      </div>


      <div class="ItemS" (click)="showItem='15'" [ngClass]="{'active': showItem =='15'}">
        <mat-icon mat-list-icon>content_cut</mat-icon>
        <div class="mx-2">Surgical History</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <hr>









      <!-- <div class="ItemS" (click)="showItem='61'" [ngClass]="{'active': showItem =='61'}">
        <mat-icon mat-list-icon>add</mat-icon>
        <div class="mx-2">New Refferal</div>
        <mat-icon>navigate_next</mat-icon>
      </div> -->

      <hr>

      <div class="ItemS" (click)="showItem='62'" [ngClass]="{'active': showItem =='62'}">
        <mat-icon mat-list-icon>file_open</mat-icon>
        <div class="mx-2">View Refferal</div>
        <mat-icon>navigate_next</mat-icon>
      </div>


      <hr>
      <!-- <div class="ItemS" (click)="showItem='18'" [ngClass]="{'active': showItem =='18'}">
        <mat-icon mat-list-icon>note</mat-icon>
        <div class="mx-2"> Roundbook</div>
        <mat-icon>navigate_next</mat-icon>
      </div> -->
      <hr>

      <hr>
      <div class="ItemS" (click)="showItem='39'" [ngClass]="{'active': showItem =='39'}">
        <mat-icon mat-list-icon>image</mat-icon>
        <div class="mx-2">Upload MRI Image</div>
        <mat-icon>navigate_next</mat-icon>
      </div>

      <div class="ItemS" (click)="showItem='40'" [ngClass]="{'active': showItem =='40'}">
        <mat-icon mat-list-icon>image</mat-icon>
        <div class="mx-2">View MRI images</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <hr>
      <div class="ItemS" (click)="showItem='59'" [ngClass]="{'active': showItem =='59'}">
        <mat-icon mat-list-icon>show_chart</mat-icon>
        <div class="mx-2">Upload Radiology Doc</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS" (click)="showItem='60'" [ngClass]="{'active': showItem =='60'}">
        <mat-icon mat-list-icon>view_array</mat-icon>
        <div class="mx-2">View Uploaded Radiology Doc</div>
        <mat-icon>navigate_next</mat-icon>
      </div>

      <hr>
      <div class="ItemS" (click)="showItem='43'" [ngClass]="{'active': showItem =='43'}">
        <mat-icon mat-list-icon>add</mat-icon>
        <div class="mx-2">Add Discharge-Summary</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS" (click)="showItem='44'" [ngClass]="{'active': showItem =='44'}">
        <mat-icon mat-list-icon> summarize </mat-icon>
        <div class="mx-2">View Discharge-Summary</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <hr>
      <!-- <div class="ItemS" (click)="showItem='52'" [ngClass]="{'active': showItem =='52'}">
            <mat-icon mat-list-icon> add </mat-icon>
            <div class="mx-2">PT Visit</div>
            <mat-icon>navigate_next</mat-icon>
          </div> -->
      <!-- <div class="ItemS" (click)="showItem='53'" [ngClass]="{'active': showItem =='53'}">
            <mat-icon mat-list-icon> add </mat-icon>
            <div class="mx-2">PT Initial Evaluation</div>
            <mat-icon>navigate_next</mat-icon>
          </div> -->

      <div class="ItemS">
        <mat-icon mat-list-icon>undo</mat-icon>
        <div class="mx-2" [routerLink]="['/adminDash/']"> Admin Dashboard</div>
        <!-- <mat-icon>navigate_next</mat-icon> -->
      </div>
      <!-- 
      <h2 class="ml-5 text-white text-center"><img class="myimg" src="{{doctor.DoctorImage}}" alt="">
        {{doctor.DoctorName}} <span class="text-white "> </span></h2> -->
    </mat-menu>
  </div>


  <span [ngSwitch]="showItem">

    <p *ngSwitchCase="'1'">
      <app-patientsummary></app-patientsummary>
    </p>
    <p *ngSwitchCase="'6'">
      <app-draw-pad></app-draw-pad>
    </p>

    <p *ngSwitchCase="'12'">
      <app-add-allergy></app-add-allergy>
    </p>
    <p *ngSwitchCase="'13'">
      <app-view-allergy></app-view-allergy>
    </p>

    <p *ngSwitchCase="'14'">
      <app-add-surgical-history></app-add-surgical-history>
    </p>
    <p *ngSwitchCase="'15'">
      <app-view-surgical-history></app-view-surgical-history>
    </p>
    <p *ngSwitchCase="'16'">
      <app-add-invoice></app-add-invoice>
    </p>
    <p *ngSwitchCase="'17'">
      <app-view-invoice></app-view-invoice>
    </p>

    <p *ngSwitchCase="'19'">
      <app-patientdetails></app-patientdetails>
    </p>


    <p *ngSwitchCase="'27'">
      <app-patientdetails></app-patientdetails>

    </p>

    <p *ngSwitchCase="'29'">
      <app-add-radiology-order></app-add-radiology-order>

    </p>

    <p *ngSwitchCase="'31'">
      <app-view-radiology-order></app-view-radiology-order>

    </p>



    <p *ngSwitchCase="'34'">
      <app-view-radiology-report></app-view-radiology-report>

    </p>
    <p *ngSwitchCase="'35'">
      <app-upload-radiology-report></app-upload-radiology-report>
    </p>
    <p *ngSwitchCase="'32'">
      <app-add-patient-insurance></app-add-patient-insurance>
    </p>
    <p *ngSwitchCase="'36'">
      <app-view-patient-insurance></app-view-patient-insurance>
    </p>

    <p *ngSwitchCase="'37'">
      <app-add-patient-idproof></app-add-patient-idproof>
    </p>

    <p *ngSwitchCase="'38'">
      <app-view-patient-idproof></app-view-patient-idproof>
    </p>
    <p *ngSwitchCase="'39'">
      <app-add-mri></app-add-mri>
    </p>

    <p *ngSwitchCase="'40'">
      <app-view-mri></app-view-mri>
    </p>


    <!-- <p *ngSwitchCase="'42'">
      <app-add-radiology-order-subject></app-add-radiology-order-subject>
    </p> -->


    <p *ngSwitchCase="'43'">
      <app-add-discharge-summary></app-add-discharge-summary>
    </p>
    <p *ngSwitchCase="'44'">
      <app-view-discharge-summary></app-view-discharge-summary>
    </p>







    <p *ngSwitchCase="'51'">
      <app-patient-intake-items></app-patient-intake-items>

    </p>

    <p *ngSwitchCase="'52'">
      <app-add-physical-therapy-visit></app-add-physical-therapy-visit>
    </p>

    <p *ngSwitchCase="'53'">
      <app-add-pt-initial-evaluation></app-add-pt-initial-evaluation>

    </p>

    <p *ngSwitchCase="'54'">
      <app-view-physical-therapy-visit></app-view-physical-therapy-visit>

    </p>

    <p *ngSwitchCase="'55'">
      <app-view-pt-initial-evaluation></app-view-pt-initial-evaluation>

    </p>
    <p *ngSwitchCase="'56'">
      <app-add-appointment></app-add-appointment>
    </p>
    <p *ngSwitchCase="'57'">
      <app-view-physical-therapy-booking></app-view-physical-therapy-booking>
    </p>
    <p *ngSwitchCase="'58'">
      <app-add-physical-therapy-booking-byadmin></app-add-physical-therapy-booking-byadmin>
    </p>

    <!-- <p *ngSwitchCase="'43'">
       <app-admindashboard></app-admindashboard>
       </p> -->
    <p *ngSwitchCase="'59'">
      <app-add-upload-radiology-report></app-add-upload-radiology-report>

    </p>

    <p *ngSwitchCase="'60'">
      <app-view-upload-radiology-report></app-view-upload-radiology-report>

    </p>
    <!-- <p *ngSwitchCase="'61'">
      <app-add-refferal></app-add-refferal>
    </p> -->
    <p *ngSwitchCase="'62'">
      <app-view-refferal></app-view-refferal>

    </p>
    <p *ngSwitchCase="'63'">
      <app-patient-history></app-patient-history>
    </p>

    <p *ngSwitchCase="'75'">
      <app-completed-forms></app-completed-forms>

    </p>
    <p *ngSwitchCase="'81'">
      <app-view-payment-history></app-view-payment-history>

    </p>

    <p *ngSwitchDefault>

      <app-view-physical-therapy-visit></app-view-physical-therapy-visit>
    </p>







  </span>

  <!-- </div>
    </div> -->
</div>